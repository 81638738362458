.root {
  display: flex;
  align-items: center;
  gap: var(--gap-s);
}

.line {
  width: 12px;
  height: 2px;

  &_solid {
    background-color: var(--color-light-graphic-secondary);
  }

  &_dashed {
    background-image: linear-gradient(
      to right,
      var(--color-light-graphic-secondary) 0,
      var(--color-light-graphic-secondary) 20%,
      transparent 20%,
      transparent 40%,
      var(--color-light-graphic-secondary) 40%,
      var(--color-light-graphic-secondary) 60%,
      transparent 60%,
      transparent 80%,
      var(--color-light-graphic-secondary) 80%,
      var(--color-light-graphic-secondary) 100%
    );
  }
}

.content {
  flex: 1 1 auto;
}
