.buySingleButton:disabled,
.sellSingleButton:disabled,
.buyButton:disabled,
.sellButton:disabled {
  opacity: 0.4;
}

.buyButton {
  --size-xs-height: 24px;
  padding-top: var(--gap-2xs);
  padding-bottom: var(--gap-2xs);
  border-radius: 4px !important;
  background-color: var(--color-buy) !important;
  color: var(--color-dark-text-positive-inverted) !important;
}

.sellButton {
  --size-xs-height: 24px;
  padding-top: var(--gap-2xs);
  padding-bottom: var(--gap-2xs);
  border-radius: 4px !important;
  background-color: var(--color-sell) !important;
  color: var(--color-dark-text-negative-inverted) !important;
}
html[data-direction-colors="blueAndWhite"] .sellButton {
  color: var(--color-light-text-primary-inverted) !important;
}

.buyButton span,
.sellButton span {
  font-weight: bold;
  font-size: 11px;
  line-height: 16px;
}
