.loginForm {
  --code-input-bg-color: var(--form-control-bg-color);

  margin-bottom: var(--gap-4xl);
}

.spinnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--gap-3xl);
}

.cancel {
  margin-top: var(--gap-3xl);
}
