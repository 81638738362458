.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}

.infoContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  min-height: 65px;
}

.modalWrapper {
  color: inherit;
}
