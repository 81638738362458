.otherConfigurationsTable {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-height: 300px;
}

.otherConfigurationsTable > div:nth-child(odd) {
  background-color: var(--color-light-bg-secondary-alpha-30);
}

.otherConfigurationsRow {
  display: flex;
  justify-content: space-between;
  padding: var(--gap-2xs) var(--gap-xs);
}

.otherConfigurationsButtons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sizeFilter {
  display: flex;
  gap: var(--gap-xs);
  margin-bottom: var(--gap-m);
}
