.container {
    background: var(--color-light-bg-secondary);
    border-radius: var(--border-radius-m);
    padding: var(--gap-s) var(--gap-m);
    display: flex;
    align-items: center;
    cursor: pointer;
}

.content {
    flex-grow: 1;
}

.action {
    min-width: 148px;
}