.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.controls {
  align-items: center;
  display: flex;
  gap: var(--gap-m);
  color: var(--color-light-graphic-secondary);
}

.controls svg:hover {
  cursor: pointer;
  color: var(--color-light-text-primary);
}

.buy {
  color: var(--color-buy);
}

.sell {
  color: var(--color-sell);
}

.loader {
  margin: 20% auto;
}
