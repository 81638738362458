/*Общий компонент Произвольных поручений*/
.freeOrdersContainer {
  min-height: 528px;
  max-height: 592px;
}

.tab {
  padding: var(--gap-xl) 0;
}

/*компонент пустого списка*/
.emptyListContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.emptyListIcon {
  margin: 84px auto 24px;
}

.emptyListTitle {
  margin-bottom: var(--gap-s);
}

.emptyListDescription {
  width: 250px;
  text-align: center;
}

/* новое поручение */

.sendButton {
  width: 239px;
}

/*контейнер списка ордеров*/

.scrollContainer {
  margin-top: var(--gap-2xl);
  margin-bottom: var(--gap-l);
}

.listItemWrapper {
  padding: var(--gap-2xs) 0;
}

.listItemWrapper:nth-of-type(even) {
  background-color: var(--color-light-bg-secondary);
}

.displayedDate:not(:first-of-type) {
  display: inline-block;
  margin-top: var(--gap-2xl);
  padding: 0 var(--gap-s) var(--gap-2xs);
}

/*элемент списка запросов*/
.orderItemContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.orderItemIcon {
  flex-basis: var(--gap-2xl);
  display: flex;
  justify-content: center;
  align-items: center;
}

.orderItemTitle {
  flex-grow: 1;
}

.orderItemStatus {
  flex-basis: var(--gap-7xl);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--gap-xs);
}

/*details*/

.detailsContainer {
  padding: 0 var(--gap-xs);
}

.detailsTitle {
  display: flex;
  justify-content: space-between;
}

.detailsTitle > span {
  display: flex;
  align-items: center;
  gap: var(--gap-2xs);
}

.detailsSubtitle {
  display: flex;
  flex-direction: column;
}

/*Фильтры*/

.filterRowWrapper {
  margin-top: var(--gap-m);
}

.filterRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--gap-xs);
}

.datePickerContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.filterColumns {
  display: flex;
  gap: var(--gap-m);
}

.screen {
  height: 100%;
}
