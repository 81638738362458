.labelRoot {
  display: flex;
  align-items: center;
  gap: var(--gap-s);
  padding-left: var(--gap-xs);
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 16px;
  border-radius: 2px;
  background-color: var(--color-light-bg-tertiary);
  color: var(--color-light-graphic-quaternary-inverted);

  svg {
    width: 10px;
    height: 10px;
  }
}

.label {
  flex: 1 1 auto;
}
