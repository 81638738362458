.dataRowContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 8px;
}

.dataRowPart {
  display: flex;
  flex-basis: 48%;
  justify-content: space-between;
}

.blockEnd {
  margin-bottom: 24px;
}
