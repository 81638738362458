@import url('@alfalab/core-components/vars/typography.css');

.table {
  scrollbar-color: var(--color-light-bg-secondary) var(--color-light-bg-primary);
}

.cell {
  justify-content: flex-end;
  text-align: end;
  background-color: var(--color-light-bg-primary);
}

.evenRowCell {
  --row-color: color-mix(
    in srgb,
    var(--color-light-bg-secondary) 60%,
    transparent
  );

  background-image: linear-gradient(0deg, var(--row-color), var(--row-color));
}

.firstColumnCell {
  justify-content: flex-start !important;
  text-align: start !important;
}

.activeSort {
  display: block !important;
}

.filterIconOpen {
  display: block !important;
}

.textFilter {
  padding: var(--gap-s) var(--gap-m);
}

.activeFilter {
  display: block !important;
  color: var(--color-light-text-accent);
}

.sortDesc {
  transform: rotate(-0.5turn);
}

.rowSelected {
  background-color: var(--color-light-bg-tertiary) !important;
}

.customCell {
  position: relative;

  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: inherit;

  min-width: 0;
  height: 100%;
  padding: var(--gap-xs);
}

.customCell > :first-child {
  overflow: hidden;
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.filterPopover {
  padding: 0;
}

.customInput {
  --form-control-s-min-height: 32px;

  width: 280px;
}

.buttonsWrapper {
  display: flex;
  gap: var(--gap-xs);
  justify-content: flex-end;

  margin-top: var(--gap-s);
  margin-right: var(--gap-m);
}

.tooltipContent {
  max-width: 200px;
}

.tooltipTarget {
  overflow: hidden;
  text-overflow: ellipsis;
}

.leftAlign {
  justify-content: flex-start;
}

.doubleRowCell [class*='customCell'] {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  padding-top: 6px;
}

.doubleRowCell [class*='customCell']::after {
  margin-top: -6px;
}

.doubleRowHeaderCellSecondRow {
  padding: 0 var(--gap-xs);
}

.doubleRowCell [class*='.bp4-table-header-content'] > :first-child {
  flex: initial;
}

.doubleRowCell [class*='bp4-table-truncated-text'] {
  cursor: pointer;

  display: flex;
  flex: 1 1;
  align-items: inherit;
  justify-content: inherit;

  height: 100%;
  padding: 0;
}

.borderedRowCell {
  border-bottom: 1px solid var(--color-light-border-secondary);
}

.checkbox {
  --checkbox-bg-color: transparent;
  --checkbox-border-color: var(--color-light-graphic-secondary);
  --checkbox-hover-bg-color: transparent;
  --checkbox-hover-border-color: var(--checkbox-border-color);
  --checkbox-icon-color: #dcdcdd;

  border-radius: var(--border-radius-circle);
}

.tableContainer {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;

  max-width: 100%;
  max-height: 100%;
}
