/*
 Переопределения стилей компонента Select
*/

@import '@alfalab/core-components/vars/typography.css';

:root {
  --select-arrow-color: var(--color-light-graphic-secondary);

  --select-options-list-border-radius: 0;
  --select-options-list-box-shadow: var(--shadow-m);
  --select-options-list-top-padding: var(--gap-xs);
  --select-options-list-bottom-padding: var(--gap-xs);

  --select-option-left-padding: var(--gap-xs);
  --select-option-right-padding: var(--gap-xs);
  --select-option-background: var(--layout-color-surface-1);
  --select-option-selected-background: var(--select-option-background);
  --select-option-hover-background: var(--color-light-specialbg-component);
  --select-option-active-background: var(--select-option-hover-background);
  --select-option-disabled-background: var(--select-option-background);

  --select-optgroup-background: var(--select-option-background);

  --select-checkmark-margin-after: var(--gap-2xs);
  --select-checkmark-size: 16px;
  --select-checkmark-before-display: none;
  --select-checkmark-after-display-single: block;
  --select-checkmark-background: transparent;
}

[class*='select__arrow'] {
  width: var(--select-checkmark-size);
  height: var(--select-checkmark-size);
}

[class*='select__value'] {
  color: var(--select-option-color);
}

[class*='select__contentWrapper_'] {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
}

[class*='select__option_'] {
  [class*='select__content_'] {
    padding-top: var(--gap-2xs);
    padding-bottom: var(--gap-2xs);

    [class*='select__s_']& {
      @mixin paragraph_primary_small;
    }
  }
}

[class*='select__checkmark_'] {
  svg {
    width: var(--select-checkmark-size);
    height: var(--select-checkmark-size);
    opacity: 0.5;
  }
}

[class*='select__field_'] {
  --form-control-focus-shadow: none;
}

[class*='select__optionsListBorder_'] {
  display: none;
}
