.window {
  position: absolute;
  z-index: 100;
  right: 12px;
  bottom: 12px;

  overflow: hidden;
  display: none;

  width: 360px;
  height: 718px;
  max-height: calc(100vh - 24px);

  background-color: var(--layout-color-surface-0);
  border-radius: 0 0 var(--border-radius-m) var(--border-radius-m);
  box-shadow: var(--shadow-m);
}

.windowVisible {
  display: unset;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: var(--gap-xs) var(--gap-s);

  background-color: var(--color-light-bg-tertiary);
}

.closeButton {
  cursor: pointer;
}

.nofiticationsContainer {
  margin: var(--gap-m) var(--gap-l) 0 var(--gap-m);
}
