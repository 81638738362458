@import url('@alfalab/core-components/vars/typography.css');

.onlineBadge {
  margin-right: var(--gap-2xs);
  color: #2fc26e;
  vertical-align: middle;
}

.errorBadge {
  @mixin paragraph_secondary_small;

  display: inline-block;
  margin-right: var(--gap-xs);
  color: var(--color-light-text-accent);
  vertical-align: top;
}

.centeringContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}
