.layout {
  position: relative;

  overflow: auto;
  display: flex;
  flex: 1;

  margin: var(--gap-3xs);

  background-color: var(--layout-color-background-0);
}

.colision {
  position: absolute;
  opacity: 0;
}

.leftColision {
  height: 100%;
  border-right: 1px dashed var(--layout-color-background-accent);
}

.rightColision {
  height: 100%;
  border-left: 1px dashed var(--layout-color-background-accent);
}

.topColision {
  width: 100%;
  border-bottom: 1px dashed var(--layout-color-background-accent);
}

.bottomColision {
  width: 100%;
  border-top: 1px dashed var(--layout-color-background-accent);
}
