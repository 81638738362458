.container {
  --main-menu-width: 240px;
  --main-menu-padding-vertical: 12px;
  --main-menu-padding-horizontal: 0;
  --main-menu-item-gap: 8px;
}

.sizeXxs {
  --main-menu-width: 200px;
}

.container {
  width: var(--main-menu-width);
  max-width: 100%;
  padding: var(--main-menu-padding-vertical) var(--main-menu-padding-horizontal);
}

.item {
  margin-bottom: var(--main-menu-item-gap);
}
