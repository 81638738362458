.tertiary {
  color: var(--color-light-graphic-tertiary-inverted);
}

.positive {
  color: var(--color-light-status-positive-inverted);
}

.negative {
  color: var(--color-light-graphic-accent);
}

.neutral {
  color: var(--color-light-graphic-secondary);
}

.progressBar {
  --progressbar-positive-color: var(--color-light-status-positive-inverted);
  background-color: var(--color-light-graphic-accent);
}

.flexBetween {
  display: flex;
  justify-content: space-between;
}

.circularProgressContainer {
  display: flex;
  align-items: center;
  gap: var(--gap-m);
}

.grow {
  flex-grow: 1;
}

.bestWorstContainer {
  display: flex;
  align-items: stretch;
  gap: var(--gap-m);
}

.bestWorstCard {
  background-color: var(--color-light-bg-secondary);
  border-radius: var(--border-radius-l);
  padding: var(--gap-s) var(--gap-m);
}

.bestWorstContainer > .bestWorstCard {
  flex-grow: 1;
}

.averageList {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--gap-xs);
}