@import url('@alfalab/core-components/vars/typography.css');

.container {
  --about-header-margin-bottom: var(--gap-xl);
  --about-documents-gap: 22px;
  --about-agreement-title-margin-bottom: 28px;

  @mixin paragraph_secondary_large;
}

.header {
  margin-bottom: var(--about-header-margin-bottom);
}

.copy {
  margin-top: var(--gap-xl);
}

.actions {
  margin-top: var(--gap-xl);
}

.documentList {
  display: flex;
  flex-direction: column;
  gap: var(--about-documents-gap);
}

.agreementTitle {
  @mixin paragraph_primary_medium;

  margin-bottom: var(--about-agreement-title-margin-bottom);
}

.agreementText {
  @mixin paragraph_secondary_large;
}

.tabs {
  overflow-y: auto;
  max-height: 80vh;
  margin: calc(-1 * var(--gap-xl)) calc(-1 * var(--gap-m));
}

.tabs [role='tablist'] {
  position: sticky;
  z-index: 10;
  top: -1px;

  padding: var(--gap-xl) var(--gap-m) 0;

  background-color: var(--color-light-bg-primary);

  transition: var(--button-transition);
}

.tabs [role='tablist']::before {
  width: calc(100% - var(--gap-m) * 2);
}

.tabs [role='tablist'].sticked {
  background-color: var(--color-light-bg-secondary);
}

.tabs [role='tabpanel'] {
  padding-right: var(--gap-m);
  padding-left: var(--gap-m);
}

.tab {
  overflow-y: auto;
  height: 375px;
  padding: var(--gap-m) 0 30px;
}

.changelogEntryHeader {
  display: flex;
  flex-direction: column;
}

.changelogEntryBody {
  color: var(--color-light-graphic-tertiary-inverted);
}

.label {
  display: flex;
  align-items: center;
  margin: 0 0 var(--gap-2xs);
}

.title {
  @mixin accent_primary_small;
}

.modalInner {
  overflow-y: hidden;
}
