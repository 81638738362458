.container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.navigationContainer {
  width: 100%;
  padding: var(--modal-header-mobile-content-paddings);
}

.controlls {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: var(--gap-xs);
  padding: var(--gap-xs) var(--gap-l) var(--gap-l);
  position: fixed;
  bottom: 0;
  left: 0;
}

.form {
  width: 100%;
}
