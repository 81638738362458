/*
 Не редактировать. Сгенерировано автоматически
*/

:root {
  --color-light-specialbg-primary-grouped: #f3f4f5;
  --color-light-specialbg-secondary-grouped: #ffffff;
  --color-light-specialbg-tertiary-grouped: #f3f4f5;
  --color-light-specialbg-secondary-transparent: rgba(11, 31, 53, 0.05);
  --color-light-specialbg-tertiary-transparent: rgba(11, 31, 53, 0.1);
  --color-light-specialbg-component: rgba(11, 31, 53, 0.07);
  --color-light-specialbg-nulled: rgba(255, 255, 255, 0);
  --color-light-specialbg-overlay-fallback: #5c5c5c;

  --color-light-specialbg-secondary-transparent-inverted: rgba(
    255,
    255,
    255,
    0.06
  );
  --color-light-specialbg-tertiary-transparent-inverted: rgba(
    255,
    255,
    255,
    0.11
  );
  --color-light-specialbg-component-inverted: rgba(255, 255, 255, 0.1);

  --color-light-specialbg-tertiary-transparent-shade-7: rgba(10, 29, 49, 0.163);
  --color-light-specialbg-tertiary-transparent-shade-15: rgba(9, 26, 45, 0.235);
  --color-light-specialbg-component-shade-7: rgba(10, 29, 49, 0.1351);
  --color-light-specialbg-component-shade-10: rgba(10, 28, 48, 0.163);
  --color-light-specialbg-component-shade-15: rgba(9, 26, 45, 0.2095);
  --color-light-specialbg-component-shade-30: rgba(8, 22, 37, 0.349);
  --color-light-specialbg-component-alpha-2: rgba(11, 31, 53, 0.02);
  --color-light-specialbg-component-alpha-14: rgba(11, 31, 53, 0.14);
  --color-light-specialbg-component-alpha-23: rgba(11, 31, 53, 0.23);
  --color-light-specialbg-component-alpha-30: rgba(11, 31, 53, 0.3);
  --color-light-specialbg-tertiary-transparent-inverted-tint-15: rgba(
    255,
    255,
    255,
    0.2435
  );
  --color-light-specialbg-tertiary-transparent-inverted-tint-20: rgba(
    255,
    255,
    255,
    0.288
  );
  --color-light-specialbg-component-inverted-alpha-30: rgba(255, 255, 255, 0.3);
  --color-light-specialbg-component-inverted-tint-15: rgba(
    255,
    255,
    255,
    0.235
  );
  --color-light-specialbg-component-inverted-tint-20: rgba(255, 255, 255, 0.28);
}
