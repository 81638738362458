.pcRoomWindow {
  position: unset;
  z-index: 10;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  width: 100%;
  height: 100vh;
  max-height: -webkit-fill-available;

  background-color: var(--color-light-bg-primary);
}

.chatRoomHeader {
  position: relative;

  height: 48px;
  padding: 0 var(--gap-m);

  background-color: var(--color-light-bg-secondary);
  backdrop-filter: blur(100px);
}

.pcHeaderTitle {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  height: 100%;
  padding: var(--gap-2xs) 0;
  padding-left: 36px;
}

.main {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-grow: 1;
}

.footer {
  padding: var(--gap-s) var(--gap-l);
  background-color: var(--color-light-bg-secondary);
}

.itemTitle {
  overflow: hidden;
  margin-bottom: var(--gap-2xs);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.backButton {
  cursor: pointer;

  position: absolute;
  top: 0;
  bottom: 0;

  margin: auto 0;
}
