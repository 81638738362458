/*
 Переопределения стилей компонента Input
*/

:root {
  --input-success-icon-display: none;
}

[class*='input__input_']:not([class*='code-input__input_']),
[class*='with-suffix__suffixContainer_'] {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
}

[class*='input__clearIcon_'] {
  width: 20px;
  height: 20px;
}
