.container {
  padding: var(--gap-m);
  border-radius: var(--border-radius-s);
  background: var(--dark-bg-secondary);
}

.head {
  display: flex;
  align-items: center;
}

.tooltip {
  margin-left: var(--gap-s);
  color: var(--color-light-graphic-secondary);
  width: 16px;
  height: 16px;
}

.tooltipContent {
  max-width: 240px;
}
