.container {
  background: var(--color-light-bg-tertiary);
  border-radius: var(--border-radius-m);
  position: relative;
}

.container::after {
  content: "";
  display: block;
  width: 1px;
  height: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--color-light-graphic-tertiary);
}

.btn [class*='button__stretchText'] {
  flex-grow: initial;
}

.size_s {
  --icon-button-s-size: 24px
}
.size_s .btn {
  width: 32px;
  height: 32px;
}

.size_m {
  --icon-button-s-size: 24px
}
.size_m .btn {
  width: 40px;
  height: 40px;
}

.size_l {
  --icon-button-s-size: 24px
}
.size_l .btn {
  width: 48px;
  height: 48px;
}