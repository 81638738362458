@import url('@alfalab/core-components/vars/typography.css');

.customInput {
  --form-control-s-min-height: 24px;
  --form-control-error-mobile-shadow: var(--form-control-error-shadow);
}

.customInputField input {
  @mixin action_secondary_large;
}

.customInputField input:disabled {
  opacity: 0;
}

.customInputField input + div {
  @mixin action_secondary_large;
  /* stylelint-disable-next-line property-disallowed-list */
  font-weight: 500 !important;
}

.priceAddons {
  user-select: none;
  display: flex;
  gap: var(--gap-2xs);
  align-items: center;
}

.convertedValue {
  padding-right: var(--gap-2xs);
}

.stepper,
.additionalVolume {
  transition: background 0.2s ease;
}
