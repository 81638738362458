.chatBadge {
  position: absolute;
  top: -2px;
  right: -2px;
}

.tooltipContent {
  cursor: pointer;

  display: grid;
  grid-gap: 18px;
  grid-template-columns: 18px 238px 18px;
  align-items: start;
}

.tooltipPopover {
  padding-right: var(--gap-xs);
}

.description {
  margin-top: var(--gap-2xs);
}
