.treaty {
  margin: 0 0 var(--gap-2xl);
}

.menu {
  margin: 0 0 var(--gap-xl);
}

.formControlWrapper {
  --form-control-s-min-height: 24px;
  --form-control-mobile-s-border-radius: 4px;
}
