.dotsContainer {
  display: flex;
  gap: 6px;
}

.stepDot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--color-light-graphic-secondary);
}

.stepDotActive {
  background-color: var(--color-light-graphic-accent);
}