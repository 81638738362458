@import url('@alfalab/core-components/vars/typography.css');

.modal {
  background: var(--color-light-bg-primary);
}

.container {
  max-width: 520px;
  padding: var(--gap-m);
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  margin: 0 0 var(--gap-2xl);
}

.title {
  @mixin accent_primary_medium;
}

.closeIcon {
  cursor: pointer;
  color: var(--color-light-graphic-secondary);
  transition: var(--button-transition);
}

.closeIcon:hover {
  color: var(--color-light-text-primary);
}

.top {
  display: flex;
  align-items: flex-end;
  padding: 0 0 var(--gap-m);
  border-bottom: 1px solid var(--divider-color);
}

.date {
  @mixin paragraph_secondary_small;

  display: block;
  margin-left: var(--gap-m);
  color: var(--color-light-text-secondary);
}

.text {
  @mixin paragraph_secondary_large;

  padding: var(--gap-m) 0 var(--gap-m);
  border-bottom: 1px solid var(--divider-color);
}

.submit {
  padding: var(--gap-s) 0 var(--gap-m);
}

.multiplyProgress {
  margin: var(--gap-m) 0;
}

.errors {
  @mixin paragraph_secondary_small;

  margin: var(--gap-m) 0;
}

.logo {
  width: 170px;
  color: var(--color-light-graphic-tertiary-inverted);
}

.logo svg {
  width: 170px;
}
