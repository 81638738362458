.chip {
  display: flex;
  align-items: center;
  padding: 0 var(--gap-xs) 0 var(--gap-2xs);
  gap: var(--gap-3xs);
  background-color: var(--color-light-bg-tertiary);
  border-radius: 100px;
}

.withClose {
  padding-right: var(--gap-2xs);
}

.tertiary {
  color: var(--color-light-graphic-tertiary-inverted)
}