.item {
  align-items: center;
  font-size: 13px;
  cursor: pointer;
}

.isin {
  color: var(--color-light-text-secondary);
}

.actionIcon {
  color: var(--color-light-text-secondary);
}

.amountSign {
  margin-right: 0.2em;
  font-weight: 500;
}

.amountValue {
  font-weight: 500;
}

.amountIn .amountValue {
  color: var(--color-light-text-positive);
}

.amountIn .amountSign {
  color: var(--color-light-text-positive);
}

.amountUnit {
  margin-left: 0.2em;
  color: var(--color-light-text-secondary);
}