.wrapper {
  width: 100%;
  height: calc(
    var(--vh, 1vh) * 100 - var(--mobile-header-height) - env(safe-area-inset-bottom) - 14px
  );
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: var(--gap-xl) var(--gap-xl) 0 var(--gap-xl);

  position: relative;
}


.title {
  padding: var(--gap-xs) 0;
}

.phoneWrapper {
  display: flex;
  flex-direction: column;
}

.button {
  margin-top: auto;
  margin-bottom: var(--gap-xl);
}

.controlls {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: var(--gap-xs);
  padding: var(--gap-xs) var(--gap-l) var(--gap-l);
  position: fixed;
  bottom: 0;
  left: 0;
}

.btn {
  height: 56px;
  border-radius: 16px;
}

