.container {
  --securities-history-padding-top: 21px;
  --securities-history-treaty-margin-bottom: 40px;
  --securities-history-filter-margin-bottom: var(--gap-xl);
  --history-results-min-height: 305px;
}

.container {
  padding: var(--securities-history-padding-top) 0 0;
}

.treaty {
  margin-bottom: var(--securities-history-treaty-margin-bottom);
}

.filter {
  margin-bottom: var(--securities-history-filter-margin-bottom);
}

.history {
  min-height: var(--history-results-min-height);
}
