.container {
  background-color: var(--layout-color-background-1);
  color: var(--dark-text-primary);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.body {
  max-width: 488px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-7xl);
}

.info {
  margin-top: var(--gap-xl);
}

.subTitle {
  margin-top: var(--gap-2xs)
}

.button {
  margin-top: var(--gap-xl);
}

.footer {
  display: flex;
  flex-direction: column;
  gap: var(--gap-s)
}