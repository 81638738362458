.container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  border-radius: var(--border-radius-s);
  background-color: var(--color-light-bg-primary);
  padding: var(--gap-m) var(--gap-m) var(--gap-2xl);
  min-width: 500px;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dark {
  background-color: var(--color-light-bg-secondary);
}

.header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: var(--gap-s);
}

.close {
  padding: 0;
}

.cancel {
    margin-top: var(--gap-3xl);
}