.root {
  cursor: pointer;

  display: grid;
  grid-gap: 18px;
  grid-template-columns: 20px 1fr 24px;
  align-items: center;

  padding: var(--gap-xs) var(--gap-l);

  &:hover,
  &:focus {
    background-color: var(--color-light-bg-secondary);
  }
}

.iconContainer {
  height: 20px;
}

.icon {
  color: var(--color-light-graphic-secondary);
}

.info {
  overflow: hidden;
}

.title {
  overflow: hidden;
  margin-bottom: var(--gap-2xs);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
