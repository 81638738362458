@import '@alfalab/core-components/vars/typography.css';

.wrapper {
  display: flex;
  flex: 1;
  width: 100%;
  height: var(--margin-call-height);
  max-height: var(--margin-call-height);
  align-items: center;
  justify-content: center;

  &_immediate {
    background-color: var(--color-light-bg-negative-muted);
  }

  &_requirement {
    background-color: var(--color-light-bg-attention-muted);
  }
}

.buttonsWrapper,
.infoWrapper {
  display: flex;
  align-items: center;
}

.buttonsWrapper > :nth-child(even) {
  margin-left: var(--gap-xs);
}

.buttonsWrapper {
  margin-left: var(--gap-4xl);
}

.customButton {
  min-width: 152px;
}

.content {
  display: flex;
  flex-direction: column;
}

.accountTitle {
  margin-bottom: var(--gap-xs);
}

.accountFiltersWrapper {
  flex: 1;
  margin-right: unset;
}

.accountFiltersSelectButton {
  flex: 1;
  width: unset;
}

.tabsWrapper {
  margin-top: var(--gap-2xl);
}

.marketWrapper {
  display: flex;
  flex-direction: column;
}

.marketTitle {
  margin-bottom: var(--gap-xs);
}

.marketValue {
  display: flex;
  align-items: center;
  margin-bottom: var(--gap-m);
}

.requirementSum {
  margin-top: var(--gap-xs);
}

.accountRequirementByMarkets {
  margin-top: var(--gap-2xs);
  margin-bottom: var(--gap-s-neg);
}

.transferButtonModal {
  margin-top: var(--gap-xl);
}

.tooltipContent {
  max-width: 200px;
}

.tooltipTarget {
  margin-left: 0.5em;
}

.chartWrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  margin: var(--gap-2xl) 0;
  height: 122px;
  max-height: 122px;
}

.chart {
  margin-top: 40px;
  --progressbar-bg-color: var(--color-light-graphic-tertiary);
}

.chartLiquedBalance {
  position: absolute;
  transform: translateX(-80%);
  top: 0;
  @mixin accent_secondary_medium {};
}

.chartLiquedBalanceReq {
  transform: translateX(-50%);
}

.chartLiquedBalanceImm {
  transform: translateX(-20%);
}

.chartMargin {
  display: flex;
  flex-direction: column;
  text-align: center;
  transform: translateX(-50%);

  position: absolute;
  bottom: 0;
}

.chartMarginText {
  margin-bottom: var(--gap-2xs);
}

.chartZeroMargin {
  text-align: start;
  transform: none;
}

.marginPoint {
  position: absolute;
  top: 40px;
  width: 4px;
  height: 8px;
  border-radius: 0 0 var(--border-radius-s) var(--border-radius-s);
  background-color: var(--color-light-graphic-positive);
}

.liquedBalancePoint {
  position: absolute;
  z-index: 2;
  top: 42px;
  border-radius: 100%;
  height: 14px;
  width: 14px;
  color: var(--progressbar-positive-color);
  background-color: var(--color-light-graphic-tertiary);
  border: 2px solid currentColor;
  transform: translate(-7px, -7px);

  &_immediate {
    color: var(--progressbar-negative-color);
  }

  &_requirement {
    color: var(--progressbar-attention-color);
  }
}

.liquedBalancePoint::after {
  content: '';
  position: absolute;
  top: -12px;
  left: 4px;
  height: 12px;
  width: 2px;
  background-color: currentColor;
  border-radius: var(--border-radius-s) var(--border-radius-s) 0 0;
}

.infoIcon {
  width: 14px;
  height: 14px;
  color: var(--color-light-graphic-secondary);
  vertical-align: middle;
}

.notAvailableContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: var(--gap-7xl) 0;
}

.notAvailableTitle {
  margin: var(--gap-xl) 0 var(--gap-s);
}
