.state {
  --registration-state-icon-margin: 10px;
  --registration-state-font-size: 13px;
}

.sizeXxs {
  --registration-state-icon-margin: 8px;
  --registration-state-font-size: 11px;
}

.state {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: var(--registration-state-icon-margin);
  display: flex;
  align-items: center;
}

.iconSuccess {
  color: var(--dark-graphic-positive);
}

.name {
  font-size: var(--registration-state-font-size);
}
