.root:not(.fix-specificity) {
  color: var(--color-light-graphic-tertiary-inverted);

  span {
    display: inline-flex;
  }

  * {
    color: inherit;
  }

  svg {
    color: var(--color-light-graphic-secondary);
  }
}

.inactive {
  pointer-events: none;
}

.icon {
  width: 16px;
  height: 16px;
}

.chevron {
  width: 16px;
  height: 16px;
  margin-left: auto;
  transition: transform 0.12s ease;
}

.chevronRotate {
  transform: rotate(-0.5turn);
}
