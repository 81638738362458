.content {
  --sms-sign-confirmation-mobile-margin-top: 60px;

  --sms-sign-confirmation-font-size: 11px;
  --sms-sign-confirmation-text-margin-top: 17px;
  --sms-sign-confirmation-text-font-size: 11px;
  --sms-sign-confirmation-text-line-height: 16px;
  --sms-sign-confirmation-close-right: 20px;
  --sms-sign-confirmation-close-top: 20px;
}

.modal {
  width: 650px;
  height: 440px;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 10;
  overflow: hidden;
}

.header {
  position: absolute;
  right: var(--sms-sign-confirmation-close-right);
  top: var(--sms-sign-confirmation-close-top);
  z-index: 100;
}

.container {
  --code-input-bg-color: var(--form-control-bg-color);
  --confirmation-text-font-size: 13px;

  padding: var(--gap-xl) var(--gap-4xl);
  font-size: var(--sms-sign-confirmation-font-size);
  position: relative;
}

.closeIcon {
  color: var(--color-light-graphic-secondary);
  cursor: pointer;
  transition: var(--button-transition);
}

.closeIcon:hover {
  color: var(--color-light-text-primary);
}

.text {
  text-align: center;
  margin-top: var(--sms-sign-confirmation-text-margin-top);
  font-size: var(--sms-sign-confirmation-text-font-size);
  line-height: var(--sms-sign-confirmation-text-line-height);
}

.textGlyph {
  width: 18px;
  height: 18px;
  color: var(--dark-graphic-tertiary-inverted);
}

.drawer {
  position: absolute;
  z-index: 50;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  background-color: rebeccapurple;
}

.container [class*='smsComeLink'] {
  font-size: var(--sms-sign-confirmation-font-size);
}

.container [class*='countdownContainer'] {
  margin-bottom: 0;
}

.container [class*='button__xxs'] {
  font-size: var(--sms-sign-confirmation-font-size);
}

@media (max-width: 768px) {
  .container {
    padding: 0;
  }

  .confirmation {
    margin-top: var(--sms-sign-confirmation-mobile-margin-top);
  }
}
