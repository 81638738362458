.amountWrapper {
  display: flex;
  align-items: center;
  color: var(--color-light-text-secondary);
}

.icon {
  margin-left: var(--gap-2xs);
  margin-right: var(--gap-s);
  min-height: 18px;
  min-width: 18px;
  color: var(--color-light-graphic-secondary);
}

.triangleUp {
  color: var(--color-buy);
}

.triangleDown {
  color: var(--color-sell);
}

.amount {
  color: var(--color-light-text-primary);
}
