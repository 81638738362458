.content {
  display: flex;
  min-width: 480px;
}

.popover [class*='tooltip__tooltip_'][class*='popover__inner_'] {
  background-color: var(--layout-color-surface-0);
  border: unset;
}

[class*='tooltip__tooltip_'][class*='popover__inner_']
  [class*='tooltip__component'].content {
  padding: var(--gap-xl) var(--gap-xl) var(--gap-l) var(--gap-xl);
}

.layoutBlock {
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.tooltipArrow::after {
  display: none;
}

.newBadge {
  margin-left: var(--gap-xs);
  padding: var(--gap-3xs) var(--gap-2xs);

  color: var(--color-static-graphic-light);

  background-color: var(--layout-color-background-accent);
  border-radius: var(--border-radius-s);
}

div.onboardingPopover {
  min-width: 300px;
}
