.groupLabel {
  display: flex;
  gap: var(--gap-2xs);
  align-items: center;

  margin-bottom: var(--gap-2xs);
  padding: 0 var(--gap-xs);
}

.icon {
  position: relative;
  top: 2px;
}
