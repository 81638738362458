@import '@alfalab/core-components/vars/typography.css';

.customInputField > div:first-child > div > div:first-of-type {
  @mixin action_secondary_large;
}

.label {
  display: block;
  margin-bottom: var(--gap-s);
}
