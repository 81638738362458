.symbol {
  color: var(--color-light-text-secondary);
}

.change {
  display: inline-flex;
  align-items: center;
}

.positive {
  color: var(--color-buy);
}

.negative {
  color: var(--color-sell);
}

div.tooltipTarget {
  display: inline-flex;
}

div.tooltipContent {
  max-width: 180px;
}

.tertiary {
  color: var(--color-light-graphic-tertiary-inverted)
}