.qrModal {
  max-width: 400px;
  padding: 50px 40px;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--dark-bg-primary);
  z-index: 2000;
}

.qrTitle {
  margin: 0 0 var(--gap-2xl);
  color: var(--dark-text-tertiary-inverted);
}

.qrImageWrap {
  width: 300px;
  height: 300px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.qrImageWrap img {
  display: block;
  max-width: 100%;
}

.qrBackdrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
}

.qrCloseIcon {
  display: inline-block;
  width: 18px;
  height: 18px;
  color: var(--icon-button-primary-base-color);
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}
