div.popover {
  max-width: unset;
  min-width: unset;
  padding-top: 0;
}

.field {
  --form-control-s-min-height: 24px;
  --form-control-s-border-radius: 0;
  --form-control-bg-color: transparent;
  --form-control-hover-bg-color: transparent;
  --form-control-focus-bg-color: transparent;
}

.addons {
  padding-left: var(--gap-xs);
}

.input {
  padding: 0 var(--gap-xs);
}

.menu {
  min-width: 160px;
}

.picker {
  display: flex;
  align-items: center;
  height: 24px;
  gap: var(--gap-2xs);
  min-width: 0;
  user-select: none;
  cursor: pointer;
  padding: var(--gap-xs);
  border-radius: 4px;
  color: var(--form-control-color);
  background-color: var(--form-control-bg-color);
  transition: background-color 0.2s ease, border 0.2s ease, color 0.2s ease,
  transform 0.12s ease;
}

.picker:hover {
  background-color: var(--form-control-hover-bg-color);
}

.accountName {
  flex: 1;
  color: var(--color-light-graphic-quaternary-inverted);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chevron {
  margin-left: auto;
  transition: color 0.2s ease, border 0.2s ease, color 0.2s ease,
  transform 0.12s ease;
}

.chevronUp {
  transform: rotate(-0.5turn);
}