.card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  width: 48px;
  height: 48px;
  margin-bottom: var(--gap-m);
  padding: var(--gap-s);

  background-color: var(--color-light-bg-secondary);
  border-radius: var(--border-radius-xl);
}

.title {
  --color-light-text-tertiary-inverted: #c5c5c7;

  margin-bottom: var(--gap-xs);
}

.description {
  margin-bottom: 36px;
}

.button {
  width: 40%;
}
