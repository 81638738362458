.bar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.tooltipTarget:not(.fix-specificity)  {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.tooltipContent {
  display: flex;
  gap: var(--gap-2xs);
  align-items: center;
}

.label {
  display: block;
  /* Белый цвет для обеих тем */
  color: #fff;
}
