.title {
    margin-bottom: var(--gap-l);
}

.text {
    margin-bottom: var(--gap-xl);
}

.list {
  padding: 0 0 0 var(--gap-s);
}

.list li {
  margin-bottom: var(--gap-m);
}

.available {
    color: inherit;
}