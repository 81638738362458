.icon {
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.icon.size_m {
  width: 64px;
  height: 64px;
  background-size: 64px 64px;
}

.icon.size_s {
  width: 48px;
  height: 48px;
  background-size: 48px 48px;
}

.image {
  max-width: 100%;
}