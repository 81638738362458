.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100%;
}

.icon {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.title {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 4px;
}

.subTitle {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
}

.wrapperMobile {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.wrapperDesktop {
  height: 100%;
}
