.container {
  --documents-list-footer-font-size: 11px;
  --documents-list-footer-line-height: 14px;
}

.head {
  display: flex;
  background: var(--color-light-bg-secondary);
  color: var(--color-light-text-secondary);
}

.cell {
  padding: var(--gap-xs);
  flex-shrink: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.cellName {
  flex: 1;
  min-width: 0;
}

.cellAction {
  width: 18.1159%;
  text-align: right;
}

.footer {
  margin-top: var(--gap-xs);
}

.cellFooter {
  font-size: var(--documents-list-footer-font-size);
  line-height: var(--documents-list-footer-line-height);
  color: var(--color-light-text-secondary);
}
