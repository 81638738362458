.container {
  --platforms-header-margin-bottom: 28px;
  --platforms-logo-width: 101px;
  --platforms-logo-margin-bottom: 32px;
  --platforms-item-margin-bottom: 20px;
}

.sizeXxs {
  --platforms-header-margin-bottom: 20px;
  --platforms-logo-width: 71px;
  --platforms-logo-margin-bottom: 24px;
  --platforms-item-margin-bottom: 13px;
}

.header {
  margin-bottom: var(--platforms-header-margin-bottom);
}

.title {
  display: block;
  margin-bottom: var(--gap-s);
}

.divider {
  margin: var(--gap-l) 0 var(--gap-xl);
}

.logo {
  max-width: var(--platforms-logo-width);
  margin-bottom: var(--platforms-logo-margin-bottom);
}

.logo img {
  max-width: 100%;
}

.platformItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--platforms-item-margin-bottom);
}

.platformLabel {
  display: flex;
  align-items: center;
}

.platformTooltip {
  margin-left: 4px;
}

.platformStatus {
  width: 190px;
}

.registrationState {
  margin-bottom: 4px;
}
