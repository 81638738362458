.moreButton {
  min-width: unset !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.fileList {
  display: none;
}

.actions {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap-xs);
  align-items: center;
}

.uploadButton {
  margin-left: auto;
}

.flexCentered {
  display: flex;
  gap: var(--gap-2xs);
  align-items: center;
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
}
