.body {
  justify-content: flex-start;
  margin-top: var(--gap-m);
  padding-top: var(--gap-m);
  padding-left: var(--gap-s);
  padding-right: var(--gap-s);
  border-top: 1px solid var(--divider-color);
}

.message {
  margin-left: var(--gap-s);
  flex-direction: column;
  max-width: 220px;
}

.icon {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}