/*
 Переопределения стилей компонента Popover
*/

/* where для понижения специфичности стилей */
:where([class*='popover__inner']:not([class*='universal-date-input'])) {
  border: none;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;

  [class*='popover__arrow'] {
    background-color: inherit;

    &::after {
      background-color: inherit;
    }
  }
}
