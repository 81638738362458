.firstStepChoice {
  display: flex;
  justify-content: center;
  gap: 21px;
}

.container {
  width: 238px;

  .iconColors {
    --green: #5B5B5F;
    --red: #8A8A8E;
    --blue: #8A8A8E;

    & rect,
    & path {
      transition: fill 0.2s ease-in-out;
    }
  }


  &:hover .iconColors,
  &.containerActive .iconColors {
    --green: var(--chartiq-color-candle-up);
    --red: var(--chartiq-color-candle-down);
    --blue: var(--color-light-graphic-link);
  }
}

