.container {
    height: 32px;
    background-color: var(--color-light-bg-info-muted);
    padding: var(--gap-2xs) var(--gap-xl) var(--gap-2xs) var(--gap-xs);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.closeButton {
    position: absolute;
    right: var(--gap-xs);
    top: 50%;
    transform: translateY(-50%);
}

.moreButton {
    --size-xxs-height: 24px;
    background-color: var(--color-light-specialbg-tertiary-transparent);
    color: var(--color-light-bg-quaternary-inverted);
}

.text {
    color: var(--color-light-bg-quaternary-inverted);
}

.imageContainer {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%), var(--color-light-bg-tertiary);
    display: flex;
    justify-content: center;
}

.body {
    background-color: var(--color-light-bg-secondary);
    padding: var(--gap-2xl) var(--gap-m);
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.list {
    --list-marker-color: var(--color-light-bg-quaternary-inverted);
    --list-item-margin: var(--gap-l);
    color: var(--color-light-bg-quaternary-inverted);
}

.submitButton {
    margin: 0 var(--gap-m);
}