.container {
  --notifications-main-filter-margin-bottom: var(--gap-l);
  --notifications-main-filter-results-min-height: 305px;
}

.sizeXxs {
  --notifications-main-filter-margin-bottom: var(--gap-m);
}

.filter {
  margin-bottom: var(--notifications-main-filter-margin-bottom);
}

.results {
  min-height: var(--notifications-main-filter-results-min-height);
}
