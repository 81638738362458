.rangeTitle {
  margin: 9px 0;
}

.keyIndicatorsTitle {
  margin-bottom: 28px;
  margin-top: 17px;
}

.gridTextAlign {
  text-align: right;
}

.gridSpacing {
  margin-bottom: var(--gap-xs) !important;
}

.gridBlockSpacing {
  margin-bottom: var(--gap-xl) !important;
}
