.treaty {
  margin-bottom: var(--gap-2xl);
}

.tab {
  display: flex;
  flex-direction: column;

  height: 100%;
  min-height: 436px;
  padding: var(--gap-2xl) 0;
}

.errorModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  min-height: 536px;

  text-align: center;
}

.refreshButton {
  width: 100%;
  max-width: 238px;
}
