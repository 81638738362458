@import url('@alfalab/core-components/vars/typography.css');

/* popover */
.popover {
  min-width: unset !important;
  padding-top: 0;
}

.menu {
  min-width: 180px;
}

.inputDesktop {
  &.field.custom {
    --form-control-s-min-height: 24px;
    --form-control-s-border-radius: 0;

    box-shadow: none;

    &:hover {
      background-color: transparent;
      box-shadow: none;
    }

    &:focus-within {
      box-shadow: none;
    }
  }

  &.addons {
    padding-left: var(--gap-xs);
  }

  &.input.inputCustom {
    @mixin paragraph_secondary_large;

    flex-grow: 1;
    width: 0;
    padding: 0 var(--gap-xs);
  }

  & [class*='input__clearIcon_'] {
    width: 16px;
    height: 16px;
  }
}

.allAccountsButton {
  cursor: pointer;

  display: flex;
  justify-content: space-between;

  padding: var(--gap-2xs) var(--gap-xs);

  color: var(--color-light-graphic-quaternary-inverted);
}

.allAccountsButtonContent {
  display: flex;
  gap: var(--gap-xs);
}

.accountItemOrderBook {
  pointer-events: none;
}

.accountOption {
  display: flex;
  flex-direction: column;
}

.accountOptionOrderBook {
  cursor: default;
}

.option {
  position: relative;
  display: flex;
  gap: var(--gap-2xs);
  align-items: center;
}

.optionWithRequirements {
  margin-left: var(--gap-xl);
}

.requirementIcon {
  position: absolute;
  margin-left: var(--gap-xl-neg);
}

.accountSelectedIcon {
  margin-left: auto;
}
