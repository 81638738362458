.root {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: grid;
  place-content: center;
  background-color: var(--transfer-modal-bg);
}

.paymentProcessing {
  display: grid;
  row-gap: var(--gap-xl);
  text-align: center;
  justify-items: center;
}
