/*
 Переопределения стилей компонента Tooltip
*/

@import '@alfalab/core-components/vars/typography.css';

:root {
  --tooltip-hint-text-color: var(--color-light-graphic-tertiary-inverted);
}

[class*='popover__inner_'] {
  [class*='tooltip__tooltip_']&,
  [class*='tooltip__hint_']& {
    border-radius: 8px;
    box-shadow: var(--shadow-l);
    background-color: var(--layout-color-surface-2);
    border: 1px solid var(--layout-color-surface-2);
  }

  [class*='tooltip__component'] {
    @mixin paragraph_secondary_small;

    [class*='tooltip__tooltip_']& {
      padding: var(--gap-s);
    }
  }

  [class*='tooltip__arrow'] {
    [class*='tooltip__hint_']& {
      display: none;
    }

    &::after {
      border: none;
    }
  }
}
