.item {
  position: relative;
  cursor: pointer;
}

.docIcon {
  color: var(--light-graphic-secondary);
  margin-right: 11px;
  vertical-align: middle;
}

.name {
  vertical-align: middle;
}

.redact {
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 0;
  color: var(--dark-graphic-secondary);
  cursor: pointer;
  transition: color 0.25s ease-in-out;
}

.redact:hover {
  color: var(--dark-text-primary);
}
