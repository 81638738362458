.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 var(--gap-xl);
}

.customSuperEllipse {
  --color-light-bg-secondary: #202022;
  margin-bottom: var(--gap-l);
}

.textWrapper {
  margin: auto 0;
}

.title {
  margin-bottom: var(--gap-xs);
  color: var(--color-dark-graphic-tertiary-inverted);
}

.subtitle {
  color: var(--color-dark-text-secondary);
}

.button {
  margin-bottom: var(--gap-xs);
}
