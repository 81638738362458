.root {
  width: 320px;
  padding: var(--gap-m) var(--gap-m) var(--gap-xl);
  background-color: var(--color-light-bg-secondary);
}

.info,
.content,
.note {
  margin-top: var(--gap-s);
}

.info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-2xs);
}

.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--gap-m);
  margin-top: var(--gap-xl);
}

.amount {
  margin-left: auto;

  &_color {
    &_red {
      color: var(--color-sell);
    }

    &_green {
      color: var(--color-buy);
    }
  }
}

.market {
  color: var(--color-light-text-secondary);
}
