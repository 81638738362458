@import '@alfalab/core-components/vars/typography.css';

.container {
  --actives-list-footer-font-size: 11px;
  --actives-list-footer-line-height: 14px;
}

.head {
  display: flex;
  background: var(--color-light-bg-secondary);
  color: var(--color-light-text-secondary);
}

.cell {
  padding: var(--gap-xs);
  flex-shrink: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.cellName {
  flex: 1;
  min-width: 0;
}

.cellCount {
  width: 19.2028%;
}

.cellAction {
  width: 18.1159%;
  text-align: right;
}

.footer {
  margin-top: var(--gap-xs);
}

.cellFooter {
  @mixin paragraph_secondary_small;
  color: var(--color-light-text-secondary);
}
