/*
  Переопределения старых токенов на новые.
  Удалить, когда все старые токены будут заменены на новые.
*/

:root {
  --dark-text-primary: var(--color-light-text-primary);
  --dark-text-secondary: var(--color-light-text-secondary);
  --dark-text-primary-inverted: var(--color-light-text-primary-inverted);
  --dark-text-tertiary-inverted: var(--color-light-text-tertiary-inverted);

  --text-color-secondary: var(--color-light-text-secondary);
  --text-color-disabled: var(--color-light-text-disabled);
  --text-color-tertiary-inverted: var(--color-light-text-tertiary-inverted);

  --dark-bg-primary: var(--color-light-bg-primary);
  --dark-bg-secondary: var(--color-light-bg-secondary);
  --dark-bg-tertiary: var(--color-light-bg-tertiary);
  --dark-bg-quaternary: var(--color-light-bg-quaternary);
  --dark-bg-tertiary-inverted: var(--color-light-bg-tertiary-inverted);

  --dark-graphic-accent: var(--color-light-graphic-accent);
  --dark-graphic-negative: var(--color-light-graphic-negative);
  --dark-graphic-positive: var(--color-light-graphic-positive);
  --dark-graphic-secondary: var(--color-light-graphic-secondary);
  --dark-graphic-tertiary: var(--color-light-graphic-tertiary);
  --dark-graphic-quaternary: var(--color-light-graphic-quaternary);
  --dark-graphic-secondary-inverted: var(
    --color-light-graphic-secondary-inverted
  );
  --dark-graphic-tertiary-inverted: var(
    --color-light-graphic-tertiary-inverted
  );
  --dark-graphic-link: var(--color-light-graphic-link);
  --light-graphic-secondary: var(--color-light-graphic-secondary);

  --dark-border-color-primary: var(--color-light-border-primary);
  --dark-border-color-secondary: var(--color-light-border-secondary);

  --select-button-background: var(--form-control-bg-color);
  --select-button-color: var(--select-option-color);
  --select-button-hover-background: var(--form-control-hover-bg-color);
  --select-button-active-background: var(--form-control-focus-bg-color);
  --select-button-active-color: var(--select-button-color);
}
