.newsImportantColor {
  color: var(--color-light-text-attention);
}

.newsItemWrapper {
  margin: var(--gap-m);
  background-color: var(--color-light-bg-secondary);
  padding: 12px 16px 16px 16px;
  border-radius: 6px;
  cursor: pointer;
}

.newsItemWrapper:first-child {
  margin-top: var(--gap-xs);
}

.newsItemWrapper:hover {
  background-color: var(--color-light-bg-tertiary);
}

.attentionIcon {
  margin-top: -1px;
  margin-right: var(--gap-3xs);
}

.importantText {
  margin-right: var(--gap-xs);
  font-size: 13px;
  color: var(--color-light-text-attention);
}

.newsItemHeader {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  color: var(--color-light-graphic-tertiary-inverted);
}

.newsItemHeaderSimple {
  margin-bottom: var(--gap-s);
  row-gap: var(--gap-2xs);
}

.newsItemHeaderImportant {
  margin-bottom: var(--gap-xs);
}

.newsItemHeaderItem {
  font-size: 13px;
}

.newsItemHeaderItemSource {
  margin-right: var(--gap-xs);
  font-weight: bold;
}

.newsItemHeaderImportantDateContainer {
  display: flex;
  flex-wrap: wrap;
  row-gap: var(--gap-2xs);
}

.newsItemHeaderItemDate {
  color: var(--color-light-text-secondary);
}

.newsItemBody {
  color: var(--color-light-graphic-quaternary-inverted);
  display: -webkit-box;
  overflow: hidden;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.newsImportantColor {
  color: var(--color-light-text-attention);
}
