/*
 Не редактировать. Сгенерировано автоматически
*/

:root {
  --color-dark-text-primary: #ffffff;
  --color-dark-text-secondary: rgba(235, 235, 245, 0.54);
  --color-dark-text-tertiary: rgba(235, 235, 245, 0.32);
  --color-dark-text-disabled: rgba(235, 235, 245, 0.2);
  --color-dark-text-accent: #7938e0;
  --color-dark-text-link: #0072ef;
  --color-dark-text-positive: #13a463;
  --color-dark-text-attention: #cb830f;
  --color-dark-text-negative: #f15045;

  --color-dark-text-primary-inverted: #0f0f0f;
  --color-dark-text-secondary-inverted: rgba(60, 60, 67, 0.66);
  --color-dark-text-tertiary-inverted: rgba(60, 60, 67, 0.4);
  --color-dark-text-disabled-inverted: rgba(60, 60, 67, 0.22);

  --color-dark-text-primary-alpha-40: rgba(255, 255, 255, 0.4);
  --color-dark-text-primary-alpha-30: rgba(255, 255, 255, 0.3);
  --color-dark-text-primary-shade-24: rgb(194, 194, 194);
  --color-dark-text-primary-shade-30: rgb(179, 179, 179);
  --color-dark-text-primary-shade-40: rgb(153, 153, 153);
  --color-dark-text-primary-shade-50: rgb(128, 128, 128);
  --color-dark-text-primary-tint-20: rgb(255, 255, 255);
  --color-dark-text-primary-tint-40: rgb(255, 255, 255);
  --color-dark-text-secondary-shade-20: rgba(188, 188, 196, 0.632);
  --color-dark-text-secondary-shade-30: rgba(164, 164, 172, 0.678);
  --color-dark-text-secondary-tint-20: rgba(239, 239, 247, 0.632);
  --color-dark-text-secondary-tint-40: rgba(243, 243, 249, 0.724);
  --color-dark-text-link-shade-30: rgb(0, 80, 167);
  --color-dark-text-link-shade-50: rgb(0, 57, 120);
  --color-dark-text-link-tint-20: rgb(51, 142, 242);
  --color-dark-text-link-tint-40: rgb(102, 170, 245);
  --color-dark-text-primary-inverted-alpha-30: rgba(15, 15, 15, 0.3);
  --color-dark-text-primary-inverted-alpha-40: rgba(15, 15, 15, 0.4);
  --color-dark-text-primary-inverted-shade-20: rgb(12, 12, 12);
  --color-dark-text-primary-inverted-shade-30: rgb(11, 11, 11);
  --color-dark-text-primary-inverted-tint-7: rgb(32, 32, 32);
  --color-dark-text-primary-inverted-tint-15: rgb(51, 51, 51);
  --color-dark-text-primary-inverted-tint-24: rgb(73, 73, 73);
  --color-dark-text-primary-inverted-tint-30: rgb(87, 87, 87);
  --color-dark-text-primary-inverted-tint-40: rgb(111, 111, 111);
  --color-dark-text-primary-inverted-tint-50: rgb(135, 135, 135);
  --color-dark-text-secondary-inverted-shade-20: rgba(48, 48, 54, 0.728);
  --color-dark-text-secondary-inverted-shade-30: rgba(42, 42, 47, 0.762);
}
