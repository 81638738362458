:root {
  /* TODO: избавиться от этого токена */
  --button-transition: color 150ms ease-in-out,
    background-color 150ms ease-in-out;

  --color-buy: var(--color-light-text-positive);
  --color-sell: var(--color-light-graphic-negative);
  /* TODO: Избавиться от этого токена */
  --color-sell-inverted: var(--color-light-graphic-negative);
  /* TODO: Избавиться от этого токена */
  --color-buy-inverted: var(--color-light-text-positive);
}
