.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding-bottom: var(--gap-m);
}

.image {
  height: 184px;
  object-fit: cover;
  width: calc(100% + 2 * var(--gap-m));
  margin: 0 var(--gap-m-neg);
}

.quaternary {
  color: var(--color-light-graphic-quaternary-inverted);
}