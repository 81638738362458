.textWithTooltip {
  display: flex;
  align-items: center;
  margin-bottom: var(--gap-m);
}

.textWithTooltip svg {
  margin-left: var(--gap-2xs);
  margin-right: var(--gap-2xs);
}

.textWithTooltip > div {
  display: flex;
}

.textWithTooltip svg:hover {
  color: var(--dark-text-primary);
}

.tooltipContent {
  max-width: 180px !important;
  display: flex;
  flex-direction: column;
  gap: var(--gap-xs);
}

.secondaryTitle {
  display: block;
  margin-top: var(--gap-m);
  margin-bottom: var(--gap-xs);
}

.formRow {
  display: grid;
  grid-template-columns: calc(50% - var(--gap-xs) / 2) calc(
      50% - var(--gap-xs) / 2
    );
  justify-content: space-between;
  gap: var(--gap-xs);
  margin-bottom: var(--gap-xs);
}

.formRow.directionColumn {
  flex-direction: column;
}

.form {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.customInputLabel {
  color: #8d8d93;
  margin-top: var(--gap-xs);
  margin-bottom: var(--gap-2xs);
}

.dropdownRef {
  display: flex;
  flex: 1;
  align-items: center;
  user-select: none;
  cursor: pointer;
  height: 24px;

  padding: var(--gap-2xs) var(--gap-xs);
  border-radius: 4px;
  color: var(--form-control-color);
  background-color: var(--form-control-bg-color);
  transition: background-color 0.2s ease, border 0.2s ease, color 0.2s ease,
  transform 0.12s ease;
}

.dropdownRef:hover {
  background-color: var(--form-control-hover-bg-color);
}

.additionalInstructionSelectDesktop {
  --select-option-background: var(--color-light-bg-tertiary);
}

.dropDownContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: var(--gap-m);
  min-width: 0;
}

.orderName {
  flex: 1;
  color: var(--dark-text-primary);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chevron {
  margin-left: auto;
  transition: color 0.2s ease, border 0.2s ease, color 0.2s ease,
  transform 0.12s ease;
}

.chevronUp {
  transform: rotate(-0.5turn);
}

.dropDownItemContent {
  display: flex;
  gap: var(--gap-2xs);
}

.customInput {
  --form-control-s-min-height: 32px;
}

.customInputField > div:first-child > div > div:first-of-type {
  font-size: 13px;
  line-height: 16px;
  font-weight: normal;
}

.customInputField input {
  font-size: 13px;
  line-height: 16px;
  font-weight: normal;
}

.customInputField input:disabled {
  opacity: 0;
}

.priceAddons {
  display: flex;
  gap: var(--gap-2xs);
  align-items: center;
  user-select: none;
}

.additionalInstructionSearchValue {
  display: flex;
  align-items: center;
}


