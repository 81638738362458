.button {
  display: block;
  width: 100%;
  padding: 6px var(--gap-xs);
  height: 32px;
  border: 0;
  border-radius: var(--border-radius-s);
  color: var(--select-button-color);
  background-color: var(--select-button-background);
  transition: background 0.2s ease, border 0.2s ease, color 0.2s ease,
    transform 0.12s ease;
  cursor: pointer;
}

.buttonMobile {
  height: 40px;
  padding: 6px var(--gap-s);
}

.button:hover {
  background-color: var(--select-button-hover-background);
}
.button:active {
  background-color: var(--select-button-active-background);
  box-shadow: var(--form-control-focus-shadow);
}

.buttonContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonIcon {
  margin-right: var(--gap-s);
  color: var(--light-graphic-secondary);
}

.buttonText {
  color: inherit;
}

.chevron {
  color: var(--light-graphic-secondary);
  margin-left: auto;
  transition: color 0.2s ease, border 0.2s ease, color 0.2s ease,
    transform 0.12s ease;
}

.chevronUp {
  transform: rotate(-0.5turn);
}

.dropdownContent {
  max-height: calc(32px * 8);
  overflow-y: auto;
}
