.text {
  margin-bottom: var(--gap-l);
  color: var(--text-color-secondary);
}

.list {
  padding-left: var(--gap-m);
}

.file {
  margin-bottom: var(--gap-m);
}
