.title {
  display: block;
  margin-bottom: var(--gap-l);
}

.filters {
  margin-bottom: var(--gap-l);
}

.submit {
  --access-list-submit-width: 239px;

  display: block;
  max-width: var(--access-list-submit-width);
}

.divider {
  margin-top: var(--gap-l);
  margin-bottom: var(--gap-l);
}
