.chart {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 32px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.title {
  margin-top: 20px;
  margin-bottom: 10px;
}

.link {
  color: var(--dark-graphic-link);
}
