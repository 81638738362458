.group {
  position: relative;

  &:not(:last-child) {
    margin-bottom: var(--gap-s);
  }
}

.avatar {
  position: absolute;
  bottom: 6px;
  left: 20px;

  width: 32px;
  height: 32px;
}

.author {
  margin-bottom: var(--gap-3xs);
  padding-left: 78px;
}
