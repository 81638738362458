@import url('@alfalab/core-components/vars/typography.css');

.missedBadge {
  position: relative;
  transform: scale(0);

  width: 24px;
  height: 24px;

  border-radius: 100%;

  transition: transform 0.1s;

  &::after {
    @mixin accent_primary_medium;

    content: attr(data-count);

    position: absolute;
    top: 0;
    left: 0;
    transform: scale(0);

    display: inline;

    width: 24px;
    height: 24px;

    color: var(--color-static-text-primary-light);
    text-align: center;
    vertical-align: middle;

    border-radius: 100%;

    transition: transform 0.1s;
  }
}

.missedBadgeActive {
  transform: scale(1);

  &::after {
    transform: scale(1);
  }
}
