/*
 Не редактировать. Сгенерировано автоматически
*/

:root {
  --color-light-text-primary: #0f0f0f;
  --color-light-text-secondary: rgba(60, 60, 67, 0.66);
  --color-light-text-tertiary: rgba(60, 60, 67, 0.4);
  --color-light-text-disabled: rgba(60, 60, 67, 0.22);
  --color-light-text-accent: #7938e0;
  --color-light-text-link: #0072ef;
  --color-light-text-positive: #13a463;
  --color-light-text-attention: #cb830f;
  --color-light-text-negative: #d91d0b;

  --color-light-text-primary-inverted: #ffffff;
  --color-light-text-secondary-inverted: rgba(235, 235, 245, 0.54);
  --color-light-text-tertiary-inverted: rgba(235, 235, 245, 0.32);
  --color-light-text-disabled-inverted: rgba(235, 235, 245, 0.2);

  --color-light-text-primary-alpha-40: rgba(15, 15, 15, 0.4);
  --color-light-text-primary-alpha-30: rgba(15, 15, 15, 0.3);
  --color-light-text-primary-tint-24: rgb(73, 73, 73);
  --color-light-text-primary-tint-30: rgb(87, 87, 87);
  --color-light-text-primary-tint-40: rgb(111, 111, 111);
  --color-light-text-primary-tint-50: rgb(135, 135, 135);
  --color-light-text-primary-shade-20: rgb(12, 12, 12);
  --color-light-text-primary-shade-40: rgb(9, 9, 9);
  --color-light-text-secondary-tint-20: rgba(99, 99, 105, 0.728);
  --color-light-text-secondary-tint-30: rgba(119, 119, 123, 0.762);
  --color-light-text-secondary-shade-20: rgba(48, 48, 54, 0.728);
  --color-light-text-secondary-shade-40: rgba(36, 36, 40, 0.796);
  --color-light-text-link-tint-30: rgb(77, 156, 244);
  --color-light-text-link-tint-50: rgb(128, 185, 247);
  --color-light-text-link-shade-20: rgb(0, 91, 191);
  --color-light-text-link-shade-40: rgb(0, 68, 143);
  --color-light-text-primary-inverted-alpha-30: rgba(255, 255, 255, 0.3);
  --color-light-text-primary-inverted-alpha-40: rgba(255, 255, 255, 0.4);
  --color-light-text-primary-inverted-tint-20: rgb(255, 255, 255);
  --color-light-text-primary-inverted-tint-30: rgb(255, 255, 255);
  --color-light-text-primary-inverted-shade-7: rgb(237, 237, 237);
  --color-light-text-primary-inverted-shade-15: rgb(217, 217, 217);
  --color-light-text-primary-inverted-shade-24: rgb(194, 194, 194);
  --color-light-text-primary-inverted-shade-30: rgb(179, 179, 179);
  --color-light-text-primary-inverted-shade-40: rgb(153, 153, 153);
  --color-light-text-primary-inverted-shade-50: rgb(128, 128, 128);
  --color-light-text-secondary-inverted-tint-20: rgba(239, 239, 247, 0.632);
  --color-light-text-secondary-inverted-tint-30: rgba(241, 241, 248, 0.678);
}
