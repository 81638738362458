.root {
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 48px;
  height: 32px;
  padding: var(--gap-xs) 10px;

  border-radius: var(--border-radius-s);
}

.root_size_s {
  width: 40px;
  height: 16px;
  padding: var(--gap-3xs) var(--gap-2xs);
}

.root_size_m {
  width: 80px;
  border-radius: var(--border-radius-s);
}

.icon {
  cursor: pointer;
  color: var(--color-light-graphic-secondary, #86868a);
}

.icon_size_s {
  width: 12px;
  height: 12px;
}

.icon_size_m {
  width: 18px;
  height: 18px;
}

.icon:hover {
  color: var(--color-light-graphic-primary, #0f0f0f);
}

.divider {
  width: 1px;
  height: 100%;
  opacity: 0.4;
  background-color: var(--color-light-graphic-secondary, #86868a);
}

.divider_small {
  height: 8px;
}
