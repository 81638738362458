.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-light-bg-primary);
}

.frame {
  margin: auto;
  width: 820px;
  height: 640px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.onboardingHeader {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--color-light-border-primary);
}

.backButtonHidden {
  visibility: hidden;
}

.logo {
  color: var(--color-light-graphic-tertiary-inverted);
  width: 120px;
  display: flex;
  justify-content: center;
  margin: auto;
  flex-grow: 1;
}

.logo > svg {
  width: 98px;
}

.exitButton.exitButton {
  margin-left: auto;
}

.nextButton {
  --size-s-height: 48px;
}

.onboardingPage {
  padding-top: var(--gap-2xl);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--gap-3xl);
}

.onboardingFooter {
  width: 462px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.onboardingFooterWider {
  width: 496px;
}

.pagination {
  padding: var(--gap-2xl);
}
