.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: var(--gap-s);

  padding: 0 var(--gap-xs) 0 var(--gap-xs);

  background-color: var(--color-light-bg-primary);
}

.content .limitsPortal {
  display: flex;
  margin: 0 var(--gap-xs-neg);
  box-shadow: 0 2px 0 0 var(--layout-color-background-1);
}

.content .limitsPortal > div {
  display: flex;
  flex: 1;
}
