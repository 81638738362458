.item {
  cursor: pointer;
  align-items: center;
}

.direction {
  display: flex;
  align-items: center;
}

.directionIcon {
  color: var(--color-light-graphic-secondary);
  margin-right: var(--gap-xs);
  flex-shrink: 0;
}

.directionIconTransfer {
  margin-right: 6px;
}

.loader {
  height: 20px;
}

.download {
  padding: 0;
  border: 0;
  background: transparent;
  color: var(--color-light-graphic-tertiary-inverted);
  cursor: pointer;
}

.download:hover {
  color: var(--color-light-graphic-secondary-inverted);
}

.positive {
  color: var(--color-light-status-positive);
}

.negative {
  color: var(--color-light-status-negative);
}

.attention {
  color: var(--color-light-status-attention);
}

.neutral {
  color: var(--color-light-text-secondary);
}
