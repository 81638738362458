/*
 Переопределения стилей компонента Radio
*/

@import '@alfalab/core-components/vars/typography.css';

:root {
  --radio-bg-color: transparent;
  --radio-border-color: var(--color-light-graphic-secondary);
  --radio-hover-bg-color: var(--radio-bg-color);
  --radio-hover-border-color: var(--color-light-graphic-secondary-shade-30);
  --radio-icon-color: var(--color-static-graphic-light);

  --radio-checked-bg-color: var(--control-accent-bg-color);
  --radio-checked-hover-bg-color: var(--control-accent-hover-bg-color);
  --radio-checked-active-bg-color: var(--control-accent-active-bg-color);

  --radio-disabled-bg-color: var(--radio-bg-color);
  --radio-disabled-border-color: var(--color-light-graphic-tertiary);
}

[class*='radio__container_'] {
  &[class*='radio__s_'] {
    [class*='radio__label_'] {
      @mixin paragraph_secondary_large;
    }

    [class*='radio__hint_'] {
      @mixin paragraph_secondary_large;
    }

    [class*='radio__circle_'] {
      margin: 0;
    }
  }
}
