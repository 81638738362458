.title {
  color: var(--color-light-graphic-tertiary-inverted);
  display: inline-flex;
  align-items: center;
  gap: var(--gap-xs);
}

.tooltipTarget {
  display: inline-flex;
}

div.tooltipContent {
  max-width: 180px;
}

.tertiary {
  color: var(--color-light-graphic-tertiary-inverted)
}