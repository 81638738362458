@import '@alfalab/core-components/vars/typography.css';

.table {
  @mixin paragraph_secondary_small;
}

.row {
  display: flex;
  width: 100%;
  min-height: 32px;
  gap: 2px;
  overflow: hidden;
}

.rowOdd {
  background: var(--color-light-bg-secondary);
}

.cell {
  padding: var(--gap-xs);
  flex-shrink: 0;
}

.cellFade {
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}

.cellHead {
  background: var(--color-light-bg-secondary);
  color: var(--color-light-text-secondary);
}

.cellName {
  width: 38%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cellType {
  width: 24%;
}

.cellAmount {
  width: 29%;
  text-align: right;
}

.cellAction {
  width: 8%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.body {
  min-height: 300px;
}
