.container {
  background: var(--color-light-bg-tertiary);
  border-radius: var(--border-radius-l);
}

.header {
  padding: var(--gap-s);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.title {
  color: var(--color-light-graphic-tertiary-inverted);
}

.trigger {
  flex-shrink: 0;
  color: var(--color-light-graphic-secondary)
}

.content {
  display: none;
  padding: 0 var(--gap-s) var(--gap-s);
}

.trigger {
  transition: transform 0.2s;
}

.open .trigger {
  transform: rotate(180deg);
}

.open .content {
  display: block;
}