.color_0_step {
  color: var(--color-light-text-secondary);
}

.color_1_step {
  color: var(--color-light-text-negative);
}

.color_2_step {
  color: var(--color-light-text-negative);
}

.color_3_step {
  color: var(--color-light-text-attention);
}

.color_4_step {
  color: var(--color-light-text-attention);
}

.color_5_step {
  color: var(--color-light-graphic-tertiary-inverted);
}

.color_6_step {
  color: var(--color-light-graphic-tertiary-inverted);
}

.color_7_step {
  color: var(--color-light-text-positive);
}

.color_8_step {
  color: var(--color-light-text-positive);
}

.color_9_step {
  color: var(--color-light-text-positive);
}

.color_10_step {
  color: var(--color-light-text-positive);
}

.progressBarContainer {
  margin-bottom: var(--gap-3xl);
}

.progressBarHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--gap-xl);
}

.progressBar {
  margin-bottom: var(--gap-xs);
  --color-light-graphic-primary: var(--color-light-graphic-quaternary-inverted);
}

.tooltipContent {
  width: 260px;

  b {
    margin-bottom: var(--gap-s);
  }
}