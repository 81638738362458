.listHead {
  padding: var(--gap-xs);
  color: var(--color-light-text-secondary);
  background: var(--color-light-bg-secondary);
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  line-height: 16px;
}

.tooltipContent {
  max-width: 345px;
}

.countLoader {
  width: 20px;
  height: 20px;
}

.file {
  margin-bottom: var(--gap-m);
}
