.textWithTooltip {
  display: flex;
  align-items: center;
  margin-bottom: var(--gap-m);
}

.textWithTooltip svg {
  margin-left: var(--gap-2xs);
}

.textWithTooltip > div {
  display: flex;
}

.textWithTooltip svg:hover {
  color: var(--dark-text-primary);
}

.tooltipContent {
  max-width: 200px;
}

.chevron {
  margin-left: auto;
  transition: color 0.2s ease, border 0.2s ease, color 0.2s ease,
  transform 0.12s ease;
}

.chevronUp {
  transform: rotate(-0.5turn);
}

.error {
  color: var(--form-control-error-color);
}