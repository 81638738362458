.screen {
  position: absolute;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: var(--dark-bg-primary);
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.header {
  position: relative;
  height: var(--mobile-header-height);
  padding: 0 var(--gap-m);
  backdrop-filter: blur(100px);
}

.backButton {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  cursor: pointer;
}

.headerTitle {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 4px 0;
}

.main {
  padding: var(--gap-s) var(--gap-m);
  flex-grow: 1;
  overflow: auto;
}

.footer {
  padding: var(--gap-m) var(--gap-m);
  padding-bottom: max(env(safe-area-inset-bottom), var(--gap-m));
  background-color: var(--dark-bg-secondary);
}
