.container {
  --corporate-actions-treaty-margin-bottom: 32px;
  --corporate-actions-tab-padding-vertical: 33px;
}

.sizeXxs {
  --corporate-actions-treaty-margin-bottom: 24px;
  --corporate-actions-tab-padding-vertical: 25px;
}

.treaty {
  margin-bottom: var(--corporate-actions-treaty-margin-bottom);
}

@media (max-width: 768px) {
  .treaty {
    margin-bottom: 20px;
  }
}

.tab {
  padding: var(--corporate-actions-tab-padding-vertical) 0;
}
