.root {
  max-width: calc(100% - var(--gap-xl) * 2);
}

.header {
  height: 32px;
  padding: 0 var(--gap-xs);
  background-color: var(--color-light-bg-tertiary);
}

.content {
  padding: var(--gap-m) var(--gap-xs);
}
