.filterIconOpen {
  display: block !important;
}

.activeFilter {
  display: block !important;
  color: var(--control-accent-bg-color);
}

.popper {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  border-radius: 1px;
}

.filterPopover {
  padding: 0;
}

.popper.filterPopover {
  background-color: var(--select-option-background);
  box-shadow: var(--select-options-list-box-shadow);
}

.popper > :first-child {
  width: 100%;
}

.popoverChildrenWrapper {
  display: flex;
  flex-direction: column;
  padding: var(--gap-xs) 0;
}
