.title {
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 var(--gap-l);
}

.divider {
  margin: var(--gap-m) 0 var(--gap-xl);
}

.agree {
  margin-top: var(--gap-xs);
  color: var(--text-color-secondary);
}

.agree .agreeLink {
  color: var(--text-color-secondary);
}

.section {
  display: flex;
  flex-wrap: wrap;
}