.popoverButton {
  --size-xxs-height: 24px;
}

.templatesWrapper {
  width: 341px;
}

.templatesTitle {
  padding: var(--gap-xs) var(--gap-xs) 0;
}

.templatesHeader {
  margin-bottom: var(--gap-xs);
  color: var(--color-light-graphic-tertiary-inverted);
}

.defaultTemplatesWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap-xs);

  width: 100%;
  padding: 0 var(--gap-xs);
}

.templatesList {
  box-shadow: none;
}

.defaultTemplateItem {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  width: calc(50% - 16px);
  min-width: auto;
  height: 88px;
  padding: var(--gap-m) var(--gap-s);

  /* Иначе высоту не задать */
  /* stylelint-disable */
  line-height: 13px;
  /* stylelint-enable */
  color: var(--color-light-graphic-tertiary-inverted);
  text-align: left;
}

.tooltipTarget {
  display: inline-flex;
}

.tabs {
  --tag-filled-background-color: var(--tag-filled-background-color-hover);

  padding: 0 var(--gap-xs);
}

.tabs button:hover {
  --tag-filled-background-color-hover: var(--button-secondary-hover-bg-color);
}

.tooltipContent {
  display: flex;
  flex-direction: column;
  gap: var(--gap-xs);
  max-width: 180px !important;
}

.template > * {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0;
}
