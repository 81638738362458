.container {
  width: 320px;
  padding: var(--gap-m);
  background-color: var(--color-light-bg-secondary);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.closeIcon {
  cursor: pointer;
  color: var(--color-light-graphic-tertiary);
  transition: var(--button-transition);
}

.closeIcon:hover {
  color: var(--color-light-text-primary);
}

.info {
  margin: var(--gap-m) 0 var(--gap-2xl);
}

.buttonToolbar {
  display: flex;
  gap: var(--gap-m);
  justify-content: flex-end;
}
