.otherConfigurationsRow {
  display: flex;
  justify-content: space-between;
  padding: var(--gap-2xs) var(--gap-xs);
}

.otherConfigurationsButtons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.moreButton {
  min-width: unset !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.flexCentered {
  display: flex;
  gap: var(--gap-2xs);
  align-items: center;
}
