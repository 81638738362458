.glyph {
  color: var(--color-light-graphic-secondary);
}

.section {
  margin: 0 0 var(--gap-xl);
}

.taxDetail {
  margin: 0 0 var(--gap-2xl);
}

.position {
  margin: 0 0 var(--gap-2xl);
}

.footer {
  margin: var(--gap-l) 0 0;
}
