.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--gap-m) var(--gap-l) var(--gap-xl);
}

.title {
  padding: var(--gap-xs) 0;
}


.form {
  display: flex;
  flex-direction: column;
  gap: var(--gap-xl);
}

.field {
  display: flex;
  flex-direction: column;
  gap: var(--gap-2xs);
}

.controlls {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: var(--gap-xs);
  padding: var(--gap-xs) var(--gap-l) var(--gap-m);
  position: fixed;
  bottom: 0;
  left: 0;
}
