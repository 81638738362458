/*
 Переопределения стилей компонента Modal
*/

:root {
  --modal-border-radius: var(--border-radius-m);
}

[class*='base-modal__component'] {
  box-shadow: var(--shadow-xl);
  background-color: var(--layout-color-surface-0);
}

/* Header */
:root {
  --modal-s-header-paddings: 0;
  --modal-s-header-desktop-content-paddings: var(--gap-xs);
  --modal-s-header-desktop-font-size: 13px;
  --modal-s-header-desktop-line-height: 16px;

  --icon-button-s-size: 14px;
  --icon-button-primary-base-color: var(--color-light-graphic-secondary);

}

[class*='modal__header'] {
  background-color: var(--color-light-bg-tertiary);
}

[class*='modal__header'] [class*='navigation-bar__content'] {
  min-height: 24px;
}

[class*='modal__header'] [class*='navigation-bar__addon'] {
  min-width: 32px;
  height: 32px;
}

[class*='modal__header'] [class*='navigation-bar__closer'] {
  width: 32px;
  height: 32px;
}

/* Content */
:root {
  --modal-s-content-paddings: var(--gap-xl) var(--gap-xl) var(--gap-m);
}

[class*='modal__content'][class*='modal__withHeader'] {
  padding-top: var(--gap-s);
  background: var(--color-light-bg-primary);
}

/* Footer */
:root {
  --modal-s-footer-paddings: var(--gap-s);
}

[class*='modal__footer'] {
  background-color: var(--color-light-bg-tertiary);
}