@import '@alfalab/core-components/vars/typography.css';

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  background-color: var(--layout-color-background-1);
  color: var(--color-light-text-primary);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @mixin paragraph_secondary_large;
}

*::-webkit-scrollbar {
  width: 16px;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border: 6px solid transparent;
  border-radius: 24px;
  background-color: var(--color-light-specialbg-secondary-transparent);
  background-clip: content-box;
}

*::-webkit-scrollbar-thumb:hover {
  border: 4px solid transparent;
  background-color: var(--color-light-specialbg-tertiary-transparent);
}

*::-webkit-scrollbar-corner {
  background-color: transparent;
}

* {
  scrollbar-width: thin;
}

[alfa-portal-container] * {
  scrollbar-width: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
