.buttonsWrapper {
  display: flex;
  gap: var(--gap-xs);
  justify-content: space-between;
  padding: var(--gap-s) var(--gap-xs) 0;
}

.customButton {
  --size-xxs-height: 24px;
}
