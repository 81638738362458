.successMessage {
  text-align: center;
  max-width: 320px;
}

.successText {
  color: var(--color-light-text-secondary);
}

.resultButtonBlock {
  max-width: 224px;
  margin: var(--gap-xl) auto 0;
  display: flex;
  flex-direction: column;
  gap: var(--gap-s);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 76px;
}

.icon {
  margin-bottom: var(--gap-m);
}

.glyph {
  color: var(--color-light-graphic-secondary);
}

.title {
  display: block;
  color: var(--color-light-text-primary);
  font-weight: 700;
}

.successMessage {
  margin-top: var(--gap-s);
}
