.form {
  display: flex;
  flex-direction: column;
  padding: 0 var(--gap-xs) var(--gap-s) var(--gap-xs);
}

.xsmall .priceQuantityBlock,
.xsmall .totalBlock {
  flex-direction: column;
}
.xsmall .priceQuantityBlock > *:not(:first-child),
.xsmall .totalBlock .formCol:not(:first-child) {
  margin-left: 0;
  margin-top: var(--gap-xs);
}

.medium .formCol,
.large .formCol,
.xlarge .formCol {
  flex-direction: unset;
}
.medium .formCol .plate,
.large .formCol .plate,
.xlarge .formCol .plate {
  flex: 1;
}
.medium .totalBlock .formCol:first-child:not(:has(*:only-child)),
.large .totalBlock .formCol:first-child:not(:has(*:only-child)),
.xlarge .totalBlock .formCol:first-child:not(:has(*:only-child)) {
  flex: 2;
}
.medium .formCol > *:not(:first-child),
.large .formCol > *:not(:first-child),
.xlarge .formCol > *:not(:first-child) {
  margin-left: var(--gap-xs);
  margin-top: 0;
}

.large .buttonsBlock,
.xlarge .buttonsBlock {
  flex-direction: row;
}
.large .buttonsBlock .tradeMarketButtonsBlock,
.xlarge .buttonsBlock .tradeMarketButtonsBlock {
  margin-left: var(--gap-xs);
}

.orderParamsBlock {
  display: flex;
  flex-direction: column;
}

.xlarge .orderParamsBlock {
  flex-direction: row;
}
.xlarge .orderParamsBlock > *:not(:first-child) {
  margin-left: var(--gap-xs);
}

.formRow {
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-top: var(--gap-xs);
}

.formRow > *:not(:first-child) {
  margin-left: var(--gap-xs);
}

.formRow > div {
  flex: 1;
}

.customInput {
  --form-control-s-min-height: 24px;
}

.customInputField > div:first-child > div > div:first-of-type {
  font-size: 13px;
  line-height: 16px;
  font-weight: 500 !important;
}

.customInputField input {
  font-size: 13px;
  line-height: 16px;
  font-weight: 500 !important;
}

.dropdownRef {
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  padding: var(--gap-2xs) var(--gap-xs);
  border-radius: 4px;
  color: var(--form-control-color);
  background-color: var(--form-control-bg-color);
  transition: background-color 0.2s ease, border 0.2s ease, color 0.2s ease,
    transform 0.12s ease;
}

.dropdownRefMaxWidthHalf {
  max-width: calc((100% / 2) - (var(--gap-xs) / 2));
}

.dropdownRef:hover {
  color: var(--form-control-color);
  background-color: var(--form-control-hover-bg-color);
}

.dropDownContent {
  display: flex;
  flex: 1;
  min-width: 0;
}
.dropDownContent > *:not(:first-child) {
  margin-left: var(--gap-2xs);
}
.dropDownContent > *:last-child {
  margin-left: auto;
}

.orderName {
  flex: 1;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chevron {
  color: var(--color-light-graphic-secondary);
  margin-left: auto;
  transition: color 0.2s ease, border 0.2s ease, color 0.2s ease,
    transform 0.12s ease;
}

.chevronUp {
  transform: rotate(-0.5turn);
}

.menuTitleWrapper {
  display: flex;
  color: var(--text-color-secondary);
}

.dropDownItemContent {
  display: flex;
  align-items: center;
}
.dropDownItemContent > *:not(:first-child) {
  margin-left: var(--gap-2xs);
}

.sltp {
  color: var(--color-light-graphic-tertiary-inverted);
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--gap-2xs);
}

.sltpText {
  display: flex;
  align-items: center;
}

.totalBlock {
  margin-top: var(--gap-m);
}

.formCol {
  display: flex;
  flex-direction: column;
}

.formCol > *:not(:first-child) {
  margin-top: var(--gap-xs);
}

.plate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--gap-2xs) var(--gap-xs);

  border: 1px solid var(--color-light-border-secondary);
  border-radius: var(--border-radius-s);

  font-weight: 500;
  color: var(--color-light-text-secondary);
}

.amountValueText {
  color: var(--color-light-graphic-tertiary-inverted);
}

.buttonsBlock {
  display: flex;
  flex-direction: column;
  margin-top: var(--gap-2xs);
}

.buySingleButton * {
  color: var(--color-buy) !important;
}

.sellSingleButton * {
  color: var(--color-sell) !important;
}

.buySingleButton,
.sellSingleButton {
  --size-xs-height: 24px;
  padding-top: var(--gap-2xs);
  padding-bottom: var(--gap-2xs);
}

.buySingleButton span,
.sellSingleButton span {
  font-weight: bold;
  font-size: 11px;
  line-height: 16px;
}

.buySingleButton:disabled,
.sellSingleButton:disabled,
.buyButton:disabled,
.sellButton:disabled {
  opacity: 0.4;
}
