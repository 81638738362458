/*
 Не редактировать. Сгенерировано автоматически
*/

:root {
  --color-dark-specialbg-primary-grouped: #202022;
  --color-dark-specialbg-secondary-grouped: #2c2c2e;
  --color-dark-specialbg-tertiary-grouped: #3a3a3c;
  --color-dark-specialbg-secondary-transparent: rgba(255, 255, 255, 0.06);
  --color-dark-specialbg-tertiary-transparent: rgba(255, 255, 255, 0.11);
  --color-dark-specialbg-component: rgba(255, 255, 255, 0.1);
  --color-dark-specialbg-nulled: rgba(0, 0, 0, 0);
  --color-dark-specialbg-overlay-fallback: #070707;

  --color-dark-specialbg-secondary-transparent-inverted: rgba(11, 31, 53, 0.05);
  --color-dark-specialbg-tertiary-transparent-inverted: rgba(11, 31, 53, 0.1);
  --color-dark-specialbg-component-inverted: rgba(11, 31, 53, 0.07);

  --color-dark-specialbg-tertiary-transparent-tint-7: rgba(
    255,
    255,
    255,
    0.1723
  );
  --color-dark-specialbg-tertiary-transparent-tint-15: rgba(
    255,
    255,
    255,
    0.2435
  );
  --color-dark-specialbg-component-tint-7: rgba(255, 255, 255, 0.163);
  --color-dark-specialbg-component-tint-10: rgba(255, 255, 255, 0.19);
  --color-dark-specialbg-component-tint-15: rgba(255, 255, 255, 0.235);
  --color-dark-specialbg-component-tint-30: rgba(255, 255, 255, 0.37);
  --color-dark-specialbg-component-alpha-2: rgba(255, 255, 255, 0.02);
  --color-dark-specialbg-component-alpha-14: rgba(255, 255, 255, 0.14);
  --color-dark-specialbg-component-alpha-23: rgba(255, 255, 255, 0.23);
  --color-dark-specialbg-component-alpha-30: rgba(255, 255, 255, 0.3);
  --color-dark-specialbg-tertiary-transparent-inverted-shade-15: rgba(
    9,
    26,
    45,
    0.235
  );
  --color-dark-specialbg-tertiary-transparent-inverted-shade-20: rgba(
    9,
    25,
    42,
    0.28
  );
  --color-dark-specialbg-component-inverted-alpha-30: rgba(11, 31, 53, 0.3);
  --color-dark-specialbg-component-inverted-shade-15: rgba(9, 26, 45, 0.2095);
  --color-dark-specialbg-component-inverted-shade-20: rgba(9, 25, 42, 0.256);
}
