.table {
  margin-left: var(--gap-xs-neg);
}

.itemName {
  display: inline-flex;
  gap: var(--gap-xs);
}

.operationsContainer {
  position: relative;
}

.bottomBlock {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 150px;
}

.bottomBlockInvisible {
  display: none;
}