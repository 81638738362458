.title {
  margin: 0 0 var(--gap-m);
}

.inputAttention {
  box-shadow: inset 0 0 0 1px var(--color-light-graphic-attention);
}

.tooltipContent {
  width: 280px;
}

.acceptEmailButton {
  margin-top: var(--gap-m);
}
