/*
 Переопределения стилей компонента SliderInput
*/

:root {
  --slider-input-progress-margin-horizontal: 0;
}

[class*='slider-input__slider_'] {
  .noUi-connect {
    border-radius: inherit;
  }

  &[class*='slider__s_'] {
    --slider-input-progress-border-radius: 0 0
      var(--form-control-s-border-radius) var(--form-control-s-border-radius);
  }
}
