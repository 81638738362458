.content {
  max-width: 300px;
}

.target {
  display: inline-block;
  vertical-align: middle;
}

.icon {
  width: 16px;
  height: 16px;
  color: var(--color-light-graphic-secondary);
  vertical-align: top;
}
