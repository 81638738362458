/*
 Не редактировать. Сгенерировано автоматически
*/

:root {
  --color-dark-border-primary: #2b2b2e;
  --color-dark-border-secondary: #262629;
  --color-dark-border-tertiary: #1c1c1e;
  --color-dark-border-underline: #464649;
  --color-dark-border-key: #ffffff;
  --color-dark-border-accent: #7938e0;
  --color-dark-border-link: #0072ef;

  --color-dark-border-primary-inverted: #dcdcdd;
  --color-dark-border-secondary-inverted: #e9e9eb;
  --color-dark-border-tertiary-inverted: #f3f4f5;
  --color-dark-border-underline-inverted: #c5c5c7;
  --color-dark-border-key-inverted: #000000;

  --color-dark-border-underline-alpha-30: rgba(70, 70, 73, 0.3);
  --color-dark-border-key-alpha-7: rgba(255, 255, 255, 0.07);
  --color-dark-border-key-alpha-15: rgba(255, 255, 255, 0.15);
  --color-dark-border-underline-inverted-alpha-30: rgba(197, 197, 199, 0.3);
  --color-dark-border-key-inverted-alpha-7: rgba(0, 0, 0, 0.07);
  --color-dark-border-key-inverted-alpha-15: rgba(0, 0, 0, 0.15);
}
