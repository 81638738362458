.select {
  margin: var(--gap-xs) 0;
}

.settingNameWrapper {
  display: flex;
  flex: 1;
  gap: var(--gap-xs);
  justify-content: flex-start;
}

.settingNameWrapper span {
  flex: unset !important;
}

.tooltipContent {
  max-width: 200px;
}
