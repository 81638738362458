.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 320px;
  text-align: center;
  margin: 0 auto;
}

.title {
  color: var(--color-light-graphic-primary);
}

.codeInput {
  --code-input-bg-color: var(--color-light-bg-quaternary);
  margin-top: var(--gap-3xl);
  margin-bottom: var(--gap-l);
}

.button {
  margin-top: 60px !important;
}