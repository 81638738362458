.root {
  position: relative;
}

.icon {
  width: 15px;
  height: 15px;
  vertical-align: bottom;
}

.badge {
  position: absolute;
  top: -2px;
  right: -2px;
}
