.root {
  background-color: var(--color-light-bg-primary);
}

.header {
  background-color: var(--dark-bg-secondary);
}

.headerContent {
  display: flex;
  align-items: center;
  padding-right: var(--gap-xl);
}

.headerTitle {
  flex: 1 1 auto;
  font-weight: 600;
  text-align: center;
}

.headerCrossIcon {
  fill: var(--color-light-graphic-secondary);
  cursor: pointer;
}

.content {
  background-color: var(--color-light-bg-primary);
}

.contentSection {
  padding-top: var(--gap-xl);
  padding-bottom: var(--gap-xl);
}

.textSection {
  text-align: center;
}

.confirmation {
  --confirmation-title-font-size: 20px;
  --confirmation-title-line-height: 24px;
  --confirmation-title-font-weight: 500;
  --confirmation-title-font-family: var(--font-family);
  --code-input-bg-color: var(--form-control-bg-color);
}

.confirmation [class*='confirmation__header'],
.confirmation [class*='confirmation__inputContainer'] {
  margin-bottom: var(--gap-4xl);
}

.confirmation [class*='Confirmation_title'] {
  font-size: var(--confirmation-title-font-size);
  line-height: var(--confirmation-title-line-height);
  margin-bottom: var(--gap-xs);
}

.confirmation [class*='Confirmation_subTitle'] {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: var(--gap-xs);
}

.confirmation [class*='Confirmation_phoneRow'] {
  font-size: 13px;
  line-height: 18px;
  margin-bottom: var(--gap-xs);
}
