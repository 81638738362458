.textWithTooltip {
  display: flex;
  align-items: center;
  margin-bottom: var(--gap-m);
}

.textWithTooltip svg {
  margin-left: var(--gap-2xs);
  margin-right: var(--gap-2xs);
}

.textWithTooltip > div {
  display: flex;
}

.textWithTooltip svg:hover {
  color: var(--dark-text-primary);
}

.tooltipContent {
  max-width: 180px !important;
  display: flex;
  flex-direction: column;
  gap: var(--gap-xs);
}

.customInput {
  --form-control-s-min-height: 32px;
}

.customInputField > div:first-child > div > div:first-of-type {
  font-size: 13px;
  line-height: 16px;
  font-weight: normal;
}

.customInputField input {
  font-size: 13px;
  line-height: 16px;
  font-weight: normal;
}

.customInputField input:disabled {
  opacity: 0;
}

