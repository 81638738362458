.card {
  padding: var(--gap-m);
  background-color: var(--color-light-bg-secondary);
  border-radius: var(--border-radius-l)
}

.tertiary {
  color: var(--color-light-graphic-tertiary-inverted)
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.positive {
  color: var(--color-buy);
}

.negative {
  color: var(--color-sell);
}