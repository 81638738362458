.root {
  --layout-color-surface-0: var(--color-light-bg-secondary);

  width: 350px;
  padding: var(--gap-m);
  border-radius: var(--border-radius-l);
}

.header {
  height: auto;
  padding: unset;
  background: unset;
}

.footer {
  padding: unset;
  background: unset;
}

.content {
  padding: var(--gap-xs) 0 var(--gap-xl);
  color: var(--color-light-graphic-tertiary-inverted);
}

/* stylelint-disable-next-line selector-class-pattern */
.closeIcon:not(.fix-specificity) svg {
  width: 24px;
  height: 24px;
}
