.container {
  position: relative;
  padding-bottom: var(--gap-l);
}

.text {
  margin: var(--gap-l) 0;
}

.divider {
  margin: var(--gap-l) 0;
}

.field {
  max-width: 250px;
}

.submit {
  max-width: 250px;
}

.checkbox {
  max-width: 450px;
}

.tooltipLink {
  color: inherit;
}
