.grid {
   display: grid;
}

.row {
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: subgrid;

  &:nth-child(odd) {
    background-color: color-mix(in srgb, var(--color-light-bg-secondary) 60%, transparent);
  }

  &.clickable {
    cursor: pointer;

    &:hover, &:focus {
      background-color: var(--color-light-bg-tertiary);

      & .cell.withoverflow::after {
        background: linear-gradient(90deg, transparent 0%, var(--color-light-bg-tertiary) 24px);
      }
    }
  }

  & .cell {
    height: 24px;
    padding: 0 var(--gap-xs);
    display: flex;
    align-items: center;
    position: relative;
    text-wrap: nowrap;
    overflow: hidden;

    &.withoverflow::after {
      content: '';
      width: 24px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background: linear-gradient(90deg, transparent 0%, var(--color-light-bg-primary) 24px);
    }
  }

  &:nth-child(odd) .cell.withoverflow::after {
    background: linear-gradient(90deg, transparent 0%, var(--shadow-bg-secondary-60) 24px);
  }
}

