.tertiary {
  color: var(--color-light-graphic-tertiary-inverted)
}

.summary {
  display: flex;
  align-items: center;
  gap: var(--gap-s);
}

.profitItem {
  display: flex;
  align-items: center;
  gap: var(--gap-2xs);
}

.profitItem:not(:last-child) {
  margin-bottom: var(--gap-xs);
}

.summary > *:nth-child(2), .profitItem > *:nth-child(2) {
  flex-grow: 1;
}