.chipseRow {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap-xs);
  padding: 0 var(--gap-s);
}

.addButton {
  color: var(--color-light-graphic-tertiary-inverted)
}

.addButton > span:first-child {
  margin-right: 0;
}

.tertiary {
  color: var(--color-light-graphic-tertiary-inverted)
}