/*
 Переопределения стилей компонента Tabs
*/

:root {
  --primary-tablist-s-gaps: var(--gap-xl);
  --primary-tablist-s-font-size: 13px;
  --primary-tablist-s-font-weight: 500;
  --primary-tablist-s-padding: 0 0 var(--gap-s);
}
