.container {
  --main-info-section-margin-bottom: 24px;
}

.info {
  margin-bottom: var(--main-info-section-margin-bottom);
}

.requisites {
  margin-bottom: var(--main-info-section-margin-bottom);
}

.control {
  margin-bottom: var(--main-info-section-margin-bottom);
}

.label {
  display: flex;
  align-items: center;
  margin: 0 0 var(--gap-2xs);
}