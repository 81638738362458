@font-face {
  font-family: 'Styrene UI';
  src: url('@terminal/common/src/styles/desktop/fonts/styrene-ui/styrene-ui_regular.woff2') format('woff2'),
  url('@terminal/common/src/styles/desktop/fonts/styrene-ui/styrene-ui_regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Styrene UI';
  src: url('@terminal/common/src/styles/desktop/fonts/styrene-ui/styrene-ui_medium.woff2') format('woff2'),
  url('@terminal/common/src/styles/desktop/fonts/styrene-ui/styrene-ui_medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Styrene UI';
  src: url('@terminal/common/src/styles/desktop/fonts/styrene-ui/styrene-ui_bold.woff2') format('woff2'),
  url('@terminal/common/src/styles/desktop/fonts/styrene-ui/styrene-ui_bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
