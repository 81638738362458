.container {
  background-color: var(--dark-bg-secondary);
  width: 320px;
  padding: var(--gap-m);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeIcon {
  color: #8d8d93;
  cursor: pointer;
  transition: var(--button-transition);
}

.closeIcon:hover {
  color: var(--dark-text-primary);
}

.info {
  margin: var(--gap-m) 0 var(--gap-m);
}

.buttonToolbar {
  display: flex;
  gap: var(--gap-m);
}

.customButton span {
  font-size: 11px;
  line-height: 16px;
}

.customCancelButton {
  padding-left: var(--gap-s) !important;
  padding-right: var(--gap-m);
  border: 1px solid #464649;
}

.orderTextButton {
  display: flex;
  align-items: center;
  margin: var(--gap-l) 0;
  color: var(--dark-text-primary);
}

.orderTextButton > span {
  flex: 1;
  margin: 0 var(--gap-m);
  text-decoration: underline;
  cursor: pointer;
}

.orderTextButton.mobileVersion > span {
  flex: 1;
  margin: 0 var(--gap-m);
  text-decoration: none;
}

.orderTextButton > svg:last-child {
  display: none;
}

.orderTextButton.mobileVersion > svg {
  display: block;
}
