.header {
  display: flex;
  grid-area: header;
  gap: var(--gap-2xl);
  align-items: center;
  justify-content: space-between;

  height: var(--header-height);
  padding: 0 var(--gap-xs);

  background-color: var(--layout-color-surface-0);
}

.mainPart {
  overflow: hidden;
  display: flex;
  gap: var(--gap-2xl);
  align-items: center;

  height: 100%;
}

.rightPart {
  display: flex;
  gap: var(--gap-xl);
  align-items: center;
}

.rightButtons {
  display: flex;
  gap: var(--gap-m);
  align-items: center;
}

.topUpButton {
  color: var(--button-primary-color) !important;
  background-color: var(--button-primary-base-bg-color) !important;
}

.logoIcon {
  cursor: pointer;

  position: relative;

  align-items: center;
  align-self: center;
  justify-content: center;

  width: 24px;
  height: 24px;
  padding: var(--gap-2xs);

  background-color: var(--color-light-bg-accent);
  border-radius: 100%;
}

.logoBadge {
  position: absolute;
  top: -2px;
  right: -2px;
}

.logoIcon:hover {
  filter: brightness(120%);

  & .logoBadge {
    filter: brightness(83%);
  }
}

.logoIcon:active,
.logoIcon:focus {
  filter: brightness(130%);

  & .logoBadge {
    filter: brightness(76%);
  }
}

.menuTitleWrapper {
  display: flex;
  gap: var(--gap-xs);
  padding: var(--gap-xs);
  color: var(--color-light-graphic-tertiary-inverted);
}

.menuItem {
  width: 268px;
}

.menuDescription {
  color: var(--color-light-text-secondary);
}

.menuItemBabge {
  align-self: flex-start;
  margin-left: var(--gap-2xs);
}

.rightIcons {
  display: flex;
  gap: var(--gap-xs);
  align-items: center;
  justify-self: right;
}

.showTabsButton {
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 32px;
  height: 32px;
  margin-left: var(--gap-2xs);
  padding: var(--gap-xs) 0;

  /* stylelint-disable-next-line property-disallowed-list */
  font-size: 24px;
  color: var(--color-light-graphic-secondary);
}

.showTabsButton:hover,
.showTabsButtonActive {
  color: var(--color-light-text-primary);
}

.clockWrapper {
  display: flex;
  gap: var(--gap-m);
  align-items: center;
  justify-content: space-between;

  min-width: 112px;
}

.clockWrapperItem {
  display: flex;
  align-items: center;
}

.notificationIconContainer {
  width: auto;
  margin-left: var(--gap-2xs);
}

.transferContainer {
  background-color: var(--transfer-modal-bg);
}
