.back {
  --button-ghost-base-color: var(--color-light-text-secondary);
}

.backCircle {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: var(--button-secondary-base-bg-color);
  color: var(--color-light-graphic-tertiary-inverted);
  display: flex;
  justify-content: center;
  align-items: center;
}

.back:hover .backCircle {
  background-color: var(--button-secondary-hover-bg-color);
}