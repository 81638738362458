.title {
  margin-bottom: var(--gap-s);
  text-align: center;
}

.description {
  text-align: center;
}

.bottomOffset {
  margin: 0 auto var(--gap-2xl);
}
