.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iconWrapper {
  position: relative;
  display: grid;
  place-content: center;
  margin-bottom: var(--gap-xl);
  width: 64px;
  height: 64px;
}

.iconBackground {
  fill: var(--transfer-panel-bg-icon);
}

.icon {
  position: relative;
}

.title {
  color: var(--color-light-graphic-tertiary-inverted);
  margin-bottom: var(--gap-s);
}

.description {
  margin-bottom: var(--gap-xl);
  text-align: center;
  color: var(--transfer-panel-description-color);
}
