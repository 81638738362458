.descriptionText {
  margin-bottom: 30px;
}

.dataRow {
  display: flex;
  width: 100%;
  margin-bottom: 8px;
  justify-content: space-between;
}

.mainInfoBlock {
  margin-bottom: 28px;
}
