.accountsFilter {
    flex-grow: 1;
}

.containerModal {
    margin: 10% auto;
    position: absolute;
    width: 80%;
    max-height: 80%;
    overflow: unset;
}

.modal {
    width: 100%;
    max-height: 100%;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--gap-s) var(--gap-xs);
    background-color: var(--color-light-bg-tertiary);
}

.modalContent {
    display: flex;
    flex-direction: column;
    background-color: var(--layout-color-surface-0);
    overflow: auto;
}