.graphic {
  color: var(--color-light-graphic-tertiary-inverted);
}

.hintText {
  cursor: pointer;
  text-decoration: underline;
}

.activeConfiguration {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: var(--gap-m);

  padding: var(--gap-xs) var(--gap-m) var(--gap-m);

  background: var(--color-light-bg-secondary);
  border-radius: 5px;
}

.buttons {
  display: flex;
  grid-column-start: 2;
  gap: var(--gap-xs);
  justify-content: flex-start;
}

.button {
  color: var(--color-light-text-primary) !important;
  background-color: var(--color-light-bg-quaternary) !important;
}

.moreButton {
  min-width: unset !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.resetModal {
  width: 320px;
}

/* stylelint-disable-next-line selector-class-pattern */
.menuItem:not(.fix-specificity) {
  padding-right: var(--gap-m);
}
