.container {
  display: flex;
  align-items: flex-start;
}

.icon {
  flex-shrink: 0;
  margin-right: var(--gap-xs);
  width: 64px;
  height: 64px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 64px 64px;
}

.image {
  display: block;
}