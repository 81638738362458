.item {
  cursor: pointer;
}

.direction {
  display: flex;
  align-items: flex-start;
}

.directionIcon {
  color: #8a8a8e;
  margin-right: 10px;
  flex-shrink: 0;
}

.directionIconTransfer {
  margin-right: 6px;
}
