.ticker {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.market {
  margin-left: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-light-text-secondary);
}
.labelCenter {
  display: flex;
}

.linkedOrderIcon {
  margin-left: var(--gap-xs);
  display: flex;
  align-items: center;
}
.baseOrderId {
  margin-left: var(--gap-xs);
  color: #8d8d93;
  font-weight: 400;
}

