/*
 Не редактировать. Сгенерировано автоматически
*/

:root {
  --color-dark-bg-primary: #121212;
  --color-dark-bg-secondary: #202022;
  --color-dark-bg-tertiary: #2c2c2e;
  --color-dark-bg-quaternary: #3a3a3c;
  --color-dark-bg-accent: #7938e0;
  --color-dark-bg-info: #001c3c;
  --color-dark-bg-info-muted: #242a39;
  --color-dark-bg-positive-muted: #042117;
  --color-dark-bg-attention-muted: #332104;
  --color-dark-bg-negative-muted: #35110d;
  --color-dark-bg-opaque: rgba(255, 255, 255, 0.8);
  --color-dark-bg-overlay: rgba(0, 0, 0, 0.8);

  --color-dark-bg-primary-inverted: #ffffff;
  --color-dark-bg-secondary-inverted: #f3f4f5;
  --color-dark-bg-tertiary-inverted: #e9e9eb;
  --color-dark-bg-quaternary-inverted: #dcdcdd;

  --color-dark-bg-primary-alpha-4: rgba(18, 18, 18, 0.04);
  --color-dark-bg-primary-alpha-8: rgba(18, 18, 18, 0.08);
  --color-dark-bg-primary-alpha-12: rgba(18, 18, 18, 0.12);
  --color-dark-bg-primary-alpha-15: rgba(18, 18, 18, 0.15);
  --color-dark-bg-primary-alpha-16: rgba(18, 18, 18, 0.16);
  --color-dark-bg-primary-alpha-20: rgba(18, 18, 18, 0.2);
  --color-dark-bg-primary-alpha-30: rgba(18, 18, 18, 0.3);
  --color-dark-bg-primary-alpha-32: rgba(18, 18, 18, 0.32);
  --color-dark-bg-primary-alpha-37: rgba(18, 18, 18, 0.37);
  --color-dark-bg-primary-alpha-40: rgba(18, 18, 18, 0.4);
  --color-dark-bg-primary-tint-7: rgb(35, 35, 35);
  --color-dark-bg-primary-tint-15: rgb(54, 54, 54);
  --color-dark-bg-secondary-alpha-30: rgba(32, 32, 34, 0.3);
  --color-dark-bg-secondary-shade-15: rgb(27, 27, 29);
  --color-dark-bg-secondary-shade-20: rgb(26, 26, 27);
  --color-dark-bg-secondary-tint-7: rgb(48, 48, 49);
  --color-dark-bg-secondary-tint-15: rgb(65, 65, 67);
  --color-dark-bg-tertiary-tint-7: rgb(59, 59, 61);
  --color-dark-bg-quaternary-alpha-30: rgba(58, 58, 60, 0.3);
  --color-dark-bg-quaternary-tint-7: rgb(72, 72, 74);
  --color-dark-bg-quaternary-tint-10: rgb(78, 78, 80);
  --color-dark-bg-quaternary-tint-15: rgb(88, 88, 89);
  --color-dark-bg-quaternary-tint-30: rgb(117, 117, 119);
  --color-dark-bg-accent-alpha-30: rgba(121, 56, 224, 0.3);
  --color-dark-bg-accent-shade-15: rgb(103, 48, 190);
  --color-dark-bg-accent-shade-20: rgb(97, 45, 179);
  --color-dark-bg-accent-tint-7: rgb(130, 70, 226);
  --color-dark-bg-accent-tint-10: rgb(134, 76, 227);
  --color-dark-bg-accent-tint-15: rgb(141, 86, 229);
  --color-dark-bg-accent-tint-30: rgb(161, 116, 233);
  --color-dark-bg-primary-inverted-alpha-3: rgba(255, 255, 255, 0.03);
  --color-dark-bg-primary-inverted-alpha-4: rgba(255, 255, 255, 0.04);
  --color-dark-bg-primary-inverted-alpha-5: rgba(255, 255, 255, 0.05);
  --color-dark-bg-primary-inverted-alpha-7: rgba(255, 255, 255, 0.07);
  --color-dark-bg-primary-inverted-alpha-8: rgba(255, 255, 255, 0.08);
  --color-dark-bg-primary-inverted-alpha-10: rgba(255, 255, 255, 0.1);
  --color-dark-bg-primary-inverted-alpha-12: rgba(255, 255, 255, 0.12);
  --color-dark-bg-primary-inverted-alpha-15: rgba(255, 255, 255, 0.15);
  --color-dark-bg-primary-inverted-alpha-16: rgba(255, 255, 255, 0.16);
  --color-dark-bg-primary-inverted-alpha-20: rgba(255, 255, 255, 0.2);
  --color-dark-bg-primary-inverted-alpha-24: rgba(255, 255, 255, 0.24);
  --color-dark-bg-primary-inverted-alpha-30: rgba(255, 255, 255, 0.3);
  --color-dark-bg-primary-inverted-alpha-32: rgba(255, 255, 255, 0.32);
  --color-dark-bg-primary-inverted-alpha-40: rgba(255, 255, 255, 0.4);
  --color-dark-bg-primary-inverted-alpha-50: rgba(255, 255, 255, 0.5);
  --color-dark-bg-primary-inverted-shade-7: rgb(237, 237, 237);
  --color-dark-bg-primary-inverted-shade-15: rgb(217, 217, 217);
  --color-dark-bg-primary-inverted-shade-20: rgb(204, 204, 204);
  --color-dark-bg-secondary-inverted-alpha-30: rgba(243, 244, 245, 0.3);
  --color-dark-bg-secondary-inverted-shade-15: rgb(207, 207, 208);
  --color-dark-bg-secondary-inverted-shade-20: rgb(194, 195, 196);
  --color-dark-bg-secondary-inverted-tint-7: rgb(244, 245, 246);
  --color-dark-bg-secondary-inverted-tint-15: rgb(245, 246, 246);
  --color-dark-bg-quaternary-inverted-alpha-30: rgba(220, 220, 221, 0.3);
  --color-dark-bg-quaternary-inverted-shade-15: rgb(187, 187, 188);
  --color-dark-bg-quaternary-inverted-shade-20: rgb(176, 176, 177);
}
