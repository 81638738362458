.container {
  --add-account-form-height: 554px;
  --add-account-form-title-font-size: 18px;
  --add-account-form-title-margin-bottom: 32px;
  --add-account-form-submit-width: 239px;
}

.sizeXxs {
  --add-account-form-title-font-size: 14px;
  --add-account-form-height: 480px;
  --add-account-form-title-margin-bottom: 24px;
}

.container {
  min-height: var(--add-account-form-height);
}

.docIcon {
  color: var(--color-light-graphic-secondary);
}

.submit {
  max-width: var(--add-account-form-submit-width);
}
