.controlRow {
  display: flex;
  flex-wrap: nowrap;
  align-items: end;
}

.input {
  --form-control-s-min-height: 32px;
  --form-control-paddings: 8px;
  --textarea-s-min-height: 32px;
  --textarea-s-padding-top: 8px;

  flex-grow: 1;
}

.send {
  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;

  width: 40px;
  height: 40px;
  margin-left: var(--gap-xs);
  padding: 11px;

  color: var(--color-light-text-primary-inverted);

  background-color: #ef3124;
  border-radius: 100%;
}
