.list {
  display: flex;
  flex-direction: column;
  gap: var(--gap-m);
  margin: var(--gap-s) 0 var(--gap-l);
}

.submit {
  display: block;
  max-width: 223px;
}
