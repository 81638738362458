.body {
  --history-item-section-margin-bottom: var(--gap-xs);
}

.title {
  margin-bottom: var(--gap-m);
}

.sectionTitle {
  margin-top: var(--gap-xl);
  margin-bottom: var(--gap-m);
}

.section {
  margin-bottom: var(--history-item-section-margin-bottom);
  display: flex;
  flex-wrap: wrap;
}

.field {
  width: calc(50% - var(--gap-m));
  margin-bottom: var(--gap-m);
  margin-right: var(--gap-m);
}

.fieldFull {
  width: 100%;
  margin-left: 0;
}

.divider {
  border: 0;
  border-top: 1px solid var(--color-light-border-primary);
  margin: 0 0 var(--gap-l);
}
