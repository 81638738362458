.attach.attachBlock {
  display: flex;
}

.attach [class*='button__addons'] {
  display: none;
}

.attach [class*='attach__file'] {
  display: none;
}

.attach [class*='attach__noFile'] {
  display: none;
}
