.ciq-help-button{
	display: none;
	float: left;
	width: 32px;
	height: 32px;
	margin: 5px;
	padding: 4px 0;
	cursor: pointer;

	&:after{
		display:block;
		content: "?";
		font-size:20px;
		line-height: 22px;
		color: $DEFAULT-bg;
		background: #000;
		border-radius: 10px;
		height: 22px;
		width: 27px;
		font-weight: bold;
		opacity: 0.7;
		transition: opacity .20s 0s;

		*.ciq-show-help  & {
			color: $DEFAULT-bg;
			background: $DEFAULT-help;
		}
	}

	&:hover:after{
		opacity: 0.9;
	}
}

body,
*[cq-context],
cq-context{

	&.ciq-show-help cq-help.help-available .ciq-help-widget {
		opacity: 1;
	}
}

cq-help {
	--help-widget-size: 6px;
	display:block;
	position: absolute;
	top: 0;
	left: 0;
	border: none;
	width:100%;
	height: 100%;
	transition: all 500ms;

	.press-indicator{
		content: '';
		display:block;
		position: absolute;
		left: 50%;
		height: 100%;
		background: radial-gradient(circle, rgba(255, 255, 255, 0.5) 25%, $DEFAULT-help 50%, $DEFAULT-help 60%, rgba(255, 255, 255, 0) 61%);
		transform: translateX(-50%) scale(0);

		img{
			display: block;
			height:100%;
			width: auto;
		}
	}

	&.pressing .press-indicator{
		animation: pulse 1000ms 200ms cubic-bezier(0.00, 0.915, 1.000, 0.645);
	}

	.ciq-help-widget {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: var(--help-widget-size);
		height: var(--help-widget-size);
		background: $DEFAULT-help;
		color: $DEFAULT-help;
		text-align: center;
		font-weight: bold;
		text-shadow: 1px 1px 1px rgba(0,0,0,0.25);
		transition: all 500ms;
		border: none;
		animation: shimmer 4s 0s infinite;
		border-radius: 5px;
		opacity: 0;
	}

}

html:not([ciq-last-interaction='touch']) cq-toggle:hover > cq-help,
cq-toggle:active > cq-help,
.ciq-nav cq-toggle > cq-help
{
	border: none;
}

@keyframes shimmer {
	50% {
		background-color: $DEFAULT-bg;
		box-shadow: 0 0 4px 1px mix($DEFAULT-bg, $DEFAULT-help, 25% );
	}
}

@keyframes pulse {
	0% {
		transform: translateX(-50%) scale(0);
		opacity: 0;
	}

	50% {
		transform: translateX(-50%) scale(1);
		opacity: 0.8;
	}

	100% {
		transform: translateX(-50%) scale(2);
		opacity: 0;
	}
}

@keyframes delayed-fade {
	0% {
		opacity: 1;
	}

	75% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}
