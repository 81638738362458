$DARK:                    ciq-night !default;
$DARK-bg:                 #151f28 !default;
$DARK-main:               #1c2a35 !default;
$DARK-hu-bg:              #1C2A35 !default;
$DARK-submenu-bg:         scale-color( $DARK-bg, $lightness: 7% );
$DARK-menu-highlight-bg:  scale-color( $DARK-bg, $lightness: 15% );
$DARK-hu-bg-dynamic:      #2a51d0 !default;
$DARK-text:               #fff !default;
$DARK-blue:               #398dff;
$DARK-help:               #feb912;


.#{$DARK} {
	background:$DARK-bg;
	color: mix(#fff, $DARK-bg, 100% );
}
.ciq-phone .#{$DARK} {background:$DARK-main;}

cq-loader, cq-comparison-loader {
	.#{$DARK} & {background-image:url(img/loading-spin-white.svg);}
}

.ciq-color, .stx-border-color {/* Swatch / Open Palette Button */
	.#{$DARK} & {background-color:$DARK-text;}
}

.ciq-nav {
	.#{$DARK} & {
		border:1px solid darken( $DARK-bg, 20% );
		background: #151f28;
		color: var(--dark-text-primary);
	}
}

.sidenav {
	.#{$DARK} & {
		border-left:1px solid darken( $DARK-bg, 20% );
		background: #151f28;
	}
}

cq-toolbar {
	.#{$DARK} & {border-bottom:1px solid darken( $DARK-main, 5% );}
}

.#{$DARK} cq-undo-section cq-undo.ciq-icon-btn{
	background-image: url(img/ciq-undo-white.svg);
}

.#{$DARK} cq-undo-section cq-redo.ciq-icon-btn{
	background-image: url(img/ciq-redo-white.svg);
}

.#{$DARK} cq-undo-section .ciq-icon-btn.ciq-clear-drawings{
	background-image: url(img/ciq-clear-drawings-white.svg);
}

.ciq-chart-area {
	.#{$DARK} & {background:$DARK-bg;}
}

.ciq-chart {
	.#{$DARK} & {/* Darker Drop-shadow and different background in #{$DARK} mode */
		@include box-shadow (0px, 2px, 5px, rgba(0, 0, 0, .3));
	}
	.break-sm.#{$DARK} & {/* Hide Drop-shadow when app is in break-sm + #{$DARK} mode */
		@include box-shadow (0px, 2px, 5px, rgba(0, 0, 0, 0));
	}
	.#{$DARK} &:first-of-type {
		background: $DARK-main;
	}
}

.chartContainer {
	.#{$DARK} & {
		background: $DARK-main;
		color: $DARK-text;
	}
}

.ciq-footer {
	.#{$DARK} & {
		border-top:1px solid darken( $DARK-bg, 20% );
		background: #151f28;
	  color: $DARK-text;}
}

cq-lookup-input {
	.#{$DARK} & input {
		background: rgba($DARK-bg, 0);
		color: $DARK-text;
	}

	#{$hovering} .#{$DARK} &:hover input {
		background: lighten( $DARK-main, 4% );
	}

	.#{$DARK} .stxMenuActive & input {
		background: lighten( $DARK-main, 4% );
	}
}

/*
	Icons
*/
cq-lookup-icon {
	.#{$DARK} & {background-position: -50px -50px;}
}
cq-plus,
.ciq-edit {
	.#{$DARK} & {background-position-y: -50px;}
}

.#{$DARK} cq-separator,
cq-dialog.#{$DARK} hr {
	border: none; /* clear out the default border because we're not using left and right here */
	border-top: solid 1px scale-color( $DARK-bg, $lightness: -25%, $saturation: -50% );
	border-bottom: solid 1px scale-color( $DARK-bg, $lightness: 25%, $saturation: -50% );
}

cq-context.#{$DARK} input,
[cq-context].#{$DARK}  input,
cq-dialog.#{$DARK} input,
.#{$DARK} .share-copy .share-link-div{
		border-color: scale-color( $DARK-bg, $lightness: 25%, $saturation: -50% );
		background-color: $DARK-bg;
		color: $DARK-text;

	&:hover{
		background-color: $DARK-menu-highlight-bg;
	}
}

.#{$DARK} .ciq-select {
	background-color: $DARK-bg;

	&:hover {
		background-color: $DARK-menu-highlight-bg;
	}

	option {
		background-color: $DARK-bg;
	}
}

/* Invert the date & time field icons in webkit browsers. Will appear white instead of black. */
::-webkit-calendar-picker-indicator{
	.#{$DARK} & { filter:invert(100%); }
}

cq-color-picker{
	.#{$DARK} & {
		border-color: scale-color( $DARK-bg, $lightness: 25%, $saturation: -50% );
		& ul li span {
				#{$hovering} &:hover,
				&:active {border-color: #fff;}
		}
	}
}

/*
	Menus
*/


input[type="search"]{
	.#{$DARK} & {
		background: $DARK-bg;
		color: $DARK-text;
		border-color: $DARK-blue;
	}
}

cq-menu-dropdown,
cq-lookup-results
{
	.#{$DARK} &,
	&.#{$DARK} {
		background: $DARK-bg;
		color: $DARK-text;
		@include box-shadow (0px, 2px, 4px, rgba(255, 255, 255, .2));
	}
}

/* Applies only to "lifted" menus */
cq-menu-dropdown.#{$DARK} {
	@include box-shadow(1, 1px, 5px, scale-color( $DARK-bg, $lightness: 25%, $saturation: -50% ));
}

.#{$DARK} {
	html:not([ciq-last-interaction='touch']) & cq-item:hover,
	html[ciq-last-interaction='touch'] & cq-item:active,
	cq-item[cq-focused]{
		background-color: $DARK-menu-highlight-bg;
		color: $DARK-text;
	}
}

cq-menu {
	.#{$DARK} & {
		color:mix($DARK-text, $DARK-bg, 75% );

		html:not([ciq-last-interaction='touch']) & cq-item:hover,
		html[ciq-last-interaction='touch'] & cq-item:active,
		cq-item[cq-focused]{
			background-color: $DARK-menu-highlight-bg;
			color: $DARK-text;
		}
	}

	#{$hovering} .#{$DARK} &:hover {
		color:$DARK-text;
	}
	&:active {
		.#{$DARK} & {color:$DARK-text;}
	}

	&.stxMenuActive {
		.#{$DARK} & {color:$DARK-text;}

		.break-sm & {
			.#{$DARK} & {color:$DARK-text;}
		}
	}
}

.ciq-menu{
	&:after {
		.#{$DARK} & {
			border-right:solid thin mix($DARK-text, $DARK-bg, 50% );
			border-bottom:solid thin mix($DARK-text, $DARK-bg, 50% );
		}
	}
}

cq-study-legend cq-section-dynamic{
	.#{$DARK} & {
		background: $DARK-submenu-bg;
		border-top: none;
		border-bottom: solid 1px rgba(255,255,255,0.25);
	}
}

ciq-btn,
.annotationCancel,
.annotationSave{
	.#{$DARK} & {
		background-color: #2c4354;
		color: $DARK-text;
		border: solid 1px $DARK-text;
	}
}

cq-dialog.#{$DARK} h4 {color: $DARK-text;}

.ciq-icon.ciq-close:after,
cq-close:after {
  .#{$DARK} & {background-position: -250px -50px;}
}

cq-lookup-results cq-item span:nth-of-type(1),
cq-lookup-results cq-item span:nth-of-type(3){
  .#{$DARK} & {color: inherit;}
}

/*
	Context Menu
*/

.#{$DARK} .ciq-context-menu {
	#{$hovering} & div:hover {
			background-color: $DARK-menu-highlight-bg;
			color: $DARK-text;
	}
	div{
		color: $DARK-text;
	}

	.context-button-right-arrow {
		border-left-color: $DARK-text;
	}
}

/*
	Dialogs
*/

cq-dialog.#{$DARK} {
	@include box-shadow (0, 0, 10px, rgba(255, 255, 255, .2));

	&[cq-drawing-context],
	&[cq-study-context] {
		@include box-shadow (0, 2px, 4px, rgba(255, 255, 255, .2));
	}

	html:not([ciq-last-interaction='touch']) & {
		cq-language-name:hover,
		cq-timezone-dialog li:hover {
		background-color: #2c4354;
		}
	}
	.timezoneDialogWrapper{
		border-color: scale-color( $DARK-bg, $lightness: 25%, $saturation: -50% );
	}
}

.ciq-select{
	.#{$DARK} & {border-color: scale-color( $DARK-bg, $lightness: 25%, $saturation: -50% );}

	&:hover{
		.#{$DARK} & {background-color: $DARK-menu-highlight-bg;}
	}
}

/*
	Buttons
*/

.ciq-btn, .ciq-btn-negative, .annotationCancel, .annotationSave{
	.#{$DARK} & {
		background-color: scale-color( $DARK-bg, $lightness: 10% );
		border:none;
		box-shadow: none;
	}
}

/*
	Toggles
*/

.ciq-toggles {
	&:after {
		.#{$DARK} & {background: darken( $DARK-bg, 20% );}

	}
}

cq-toggle {
	&.active {
		.#{$DARK} & {color:$DARK-text;}
	}
}


#{$hovering} .#{$DARK} cq-toggle:hover > span,
.#{$DARK} cq-toggle:active > span,
#{$hovering} .break-sm .#{$DARK} .ciq-menu.collapse:hover > span,
.break-sm .#{$DARK} .ciq-menu.collapse:active > span ,
.#{$DARK} .ciq-menu.toggle-options:hover {
	border:solid 1px lighten( $DARK-bg, 10% );
}

.#{$DARK} .stx-watch span {background-position: -150px -50px;}
.#{$DARK} .stx-trade span {background-position: -200px -50px;}
.#{$DARK} .ciq-draw span {background-position: -250px -50px;}
.#{$DARK} .ciq-HU span {background-position: -400px -50px;}
.#{$DARK} .ciq-CH span {background-position: -443px -50px;}
.#{$DARK} .ciq-DT span {background-position: -848px -50px;}
.#{$DARK} .ciq-sidenav span {background-position: -815px -50px;}
.#{$DARK} .ciq-menu.ciq-preferences > span {background-position: -300px -50px;}


.break-sm, .break-md {
	& .#{$DARK}, &.#{$DARK} {
		.ciq-menu.ciq-views > span:after {background-position: -481px -50px;}
	}
}
.break-sm, .break-md {
	& .#{$DARK}, &.#{$DARK} {
		.ciq-menu.ciq-studies > span:after {background-position: -300px -50px;}
	}
}
.break-sm, .break-md {
	& .#{$DARK}, &.#{$DARK} {
		.ciq-menu.ciq-display > span:after {background-position: -350px -50px;}
	}
}
.break-sm, .break-md {
	& .#{$DARK}, &.#{$DARK} {
		.ciq-menu.stx-markers > span:after {background-position: -100px -50px;}
	}
}

cq-toolbar .ciq-heading { /* Background and line color headings */
	.#{$DARK} & {color:mix($DARK-text, $DARK-main, 40% );}
}

cq-toolbar .ciq-select {/* Menu Triggers in toolbar */
	.#{$DARK} & {
		background:none;
		border-color:lighten( $DARK-main, 10% );
	}
	#{$hovering} .#{$DARK} &:hover,
	.#{$DARK} &:active,
	.#{$DARK} &.stxMenuActive {color:$DARK-text;}
}

cq-annotation .ciq-btn {/* Menu Triggers in toolbar */
	.#{$DARK} & {
		color:mix($DARK-text, $DARK-main, 70% );
		background:none;
	}

	#{$hovering} .#{$DARK} &:hover {
		color:$DARK-text;
		border-color:lighten( $DARK-main, 10% );
	}
	.#{$DARK} &:ciq-active {
		color:$DARK-text;
		border-color:lighten( $DARK-main, 10% );
	}

	&.ciq-active,
	&.ciq-active:active {
		.#{$DARK} & {color:$DARK-text;}
	}
}

.#{$DARK} cq-wave-parameters {

	.ciq-btn {
		.#{$DARK} & {
			color:mix($DARK-text, $DARK-main, 70% );
			background:none;
		}

		#{$hovering} .#{$DARK} &:hover {
			color:$DARK-text;
			border-color:lighten( $DARK-main, 10% );
		}
		.#{$DARK} &:ciq-active {
			color:$DARK-text;
			border-color:lighten( $DARK-main, 10% );
		}

		&.ciq-active,
		&.ciq-active:active {
			.#{$DARK} & {color:$DARK-text;}
		}

		cq-tooltip {
			font-family: Roboto;
			height: 15px;
		}
	}

	.ciq-icon-btn.ciq-btn[decoration="none"] {
		background-image: url(img/ew-none-white.svg);
	}

	.ciq-icon-btn.ciq-btn[decoration="parentheses"] {
		background-image: url(img/ew-parentheses-white.svg);
	}

	.ciq-icon-btn.ciq-btn[decoration="enclosed"] {
		background-image: url(img/ew-circle-white.svg);
	}

	.ciq-show-lines {
		color: $DARK-text;
		border-color: #2e4456;
	}
}



cq-toolbar .ciq-checkbox {
	.#{$DARK} & span {border-color:lighten( $DARK-main, 30% );}
	#{$hovering} .#{$DARK} &:hover span {border-color:lighten( $DARK-main, 50% );}
}


cq-toolbar-settings > * { /* Line and background color modules */
	//.#{$DARK} & {border-left:1px solid darken( $DARK-main, 5% );}
}

cq-toolbar-settings div[cq-toolbar-action="done_edit"] {
	.#{$DARK} & {
		background-image: url(img/ciq-done-edit-white.svg);
	}
}
cq-toolbar-settings div[cq-toolbar-action="save"] {
	.#{$DARK} & {
		background-image: url(img/ciq-save-white.svg);
	}
}
cq-toolbar-settings div[cq-toolbar-action="restore"] {
	.#{$DARK} & {
		background-position: -253px -50px;
	}
}


span.ciq-line.ciq-selected {/* Formatting for selected line */
	.#{$DARK} & {border-color:$DARK-text;}
}

cq-hu-dynamic .ciq-hu-bg { /* HU BG Image Fill */
	.#{$DARK} & {fill:$DARK-hu-bg-dynamic;}
}

cq-hu-dynamic cq-hu-date {
	.#{$DARK} & {color:#64adff;}
}
cq-hu-dynamic cq-hu-price {
	.#{$DARK} & {color:$DARK-text;}
}

cq-hu-dynamic cq-volume-grouping > div:nth-of-type(1) { /* Volume Heading */
	.#{$DARK} & {
		color:#64adff;
		border-top:1px solid #90c9ff;
	}
}

cq-hu-dynamic cq-volume-grouping > div:nth-of-type(2) { /* Volume Bar Area */
	.#{$DARK} & {background:#2a6ce4;}
}

cq-hu-dynamic cq-volume-visual { /* Volume Bar Fill */
	.#{$DARK} & {background:#64adff;}
}

cq-hu-dynamic cq-volume-grouping > div:nth-of-type(3) {/* Volume Number Text */
	.#{$DARK} & {color:#b8e2ff;}
}

cq-hu-dynamic cq-hu-col2 > * { /* Hu Data Groups */
	.#{$DARK} & {color:#b8e2ff;}
	.#{$DARK} &:before {color:#4ea1fe;}
}


cq-hu-static {
	.#{$DARK}:not(.break-sm) & {
		background: #1c2a35;
		.break-sm & {
			background:transparent;
		}
	}
	> div > div { /* label */
		.#{$DARK} & {color:#ccc;}
	}
	> div > * { /* value */
		.#{$DARK} & {
			color:#8BD0FF;
		}

		.#{$DARK} &:before {color:#ccc;}
	}
}

.#{$DARK} stx-hu-tooltip {
	border: 2px solid #4ea1fe;
	background-color: rgba(42,81,208,.5);
	color: var(--dark-text-primary);
	fill: #2a51d0;
}

/* legend will have light icons */
cq-study-legend {
	.#{$DARK} & .ciq-icon.ciq-close:after {
		background-position: -250px -50px;
	}
	.#{$DARK} & .ciq-icon.ciq-edit:after, .#{$DARK} & .ciq-edit {
		background-position: -300px -50px;
	}
	.#{$DARK} & .ciq-icon.ciq-delete:after {
		background-position: -400px -50px;
	}
}

.mSticky {
	.#{$DARK} & {
		border: 2px solid #4ea1fe;
		background-color: rgba(42,81,208,.5);
		color: var(--dark-text-primary);
	}
}


cq-chart-title {
	.#{$DARK} & {
		background: $DARK-main;
		color: $DARK-text;
	}
}
cq-chart-title cq-chart-price {
	.#{$DARK} & {color:mix(#fff, $DARK-main, 50%);}
}

.#{$DARK} .stx-panel-title {
	color: $DARK-text;
	box-shadow: none;
	-webkit-box-shadow: none;
}

cq-comparison-add-label {
	.#{$DARK}  & {color:mix(#FFF, $DARK-main, 60%);}
	#{$hovering} .#{$DARK} cq-menu.cq-comparison-new:hover &,
	.#{$DARK} cq-menu.cq-comparison-new:active & {  /* Brighter text on hover */
		color:mix(#FFF, $DARK-main, 100%);
	}
}

cq-comparison-add {
	.#{$DARK} .stxMenuActive & {background:lighten( $DARK-main, 5%);}

	& input {
		.#{$DARK} & {
			color:mix(#FFF, $DARK-main, 80% );
			/*@include placeholder {
				color: mix(#FFF, $theme2-main, 40% );
			}*/
		}
	}

	& .stx-btn {
		.#{$DARK} & {
			color: mix(#FFF,$DARK-main, 60% ) !important;
			#{$hovering} &:hover, &:active {
				color:$DARK-text !important;
			}
		}
	}
}

cq-comparison-item {
	#{$hovering} .#{$DARK} &:hover {
		background:lighten( $DARK-main, 3%);
	}
	.#{$DARK} & .ciq-close {
		background-position: -250px -50px;
	}
}
/* Use the white version of the icon for Night Theme */
.#{$DARK} cq-comparison-key .stx-btn-ico.close:after {background-position: -250px -50px;}

.#{$DARK} cq-attrib-container {
  background:rgba(0,0,0,.5);
  color: rgba(255,255,255,.6);
}

.#{$DARK} cq-attrib-container cq-attrib-source a {
  color: $DARK-text;
}
/* --------------------------------------------------------- FOOTER --------------------------------------------------------- */

.ciq-footer {
	.#{$DARK} & {color:mix(#fff, $DARK-bg, 75% );}
}

cq-show-range { /* Set Range Button List Container */
	& > div {
		#{$hovering} .#{$DARK} &:hover {
			color:$DARK-text;
		}
		&:active {
			.#{$DARK} & {color:$DARK-text;}
		}
	}
}


.stx-stem { /* Basic Stems */
	.#{$DARK} & {border-left: 1px dotted rgba(255, 255, 255, 0.6);}
}

#{$hovering} .#{$DARK} .stx-marker.square:hover .stx-visual:after,
#{$hovering} .#{$DARK} .stx-marker.circle:hover .stx-visual:after {
	border-color: #fff;
}
.stx-marker.square.highlight .stx-visual:after,
.stx-marker.circle.highlight .stx-visual:after {
	.#{$DARK} & {
		border-color: #fff;
		opacity: .15;
	}
}

.stx-marker.dividend .stx-visual{
	.#{$DARK} & {
		background-color:#fff;
		background-position: -150px -25px;
	}
}

.stx-marker.square .stx-marker-expand,
.stx-marker.circle .stx-marker-expand {
	.#{$DARK} & {background:rgba(255, 255, 255, 0.75);}
}
.stx-marker.highlight.square .stx-marker-expand,
.stx-marker.highlight.circle .stx-marker-expand {
	.#{$DARK} & {outline: 1px solid rgba(255, 255, 255, 0.3);}
}


.stx-marker.callout.highlight .stx-marker-content:after {
	.#{$DARK} & {border-color: #fff;}
}

.stx-performance-marker.stx-marker-expand {
	.#{$DARK} & {outline: 1px solid rgba(255, 255, 255, 0.3);}
}


cq-share-button {
	.#{$DARK} & {color:mix(#fff, $DARK-bg, 75% );}

	#{$hovering} .#{$DARK} &:hover {
		color:$DARK-text;
	}
	&:active {
		.#{$DARK} & {color:$DARK-text;}
	}

	&.stxMenuActive {
		.#{$DARK} & {color:$DARK-text;}

		.break-sm & {
			.#{$DARK} & {color:$DARK-text;}
		}
	}
}


/* -------- Dark Buttons and Panel Controls -------- */

.#{$DARK} .stx-btn-panel > span.stx-ico-up {background-position: -150px -50px;}
.#{$DARK} .stx-btn-panel > span.stx-ico-down {background-position: -200px -50px;}
.#{$DARK} .stx-btn-panel > span.stx-ico-close {background-position: -250px -50px;}
.#{$DARK} .stx-btn-panel > span.stx-ico-edit {background-position: -300px -50px;}
.#{$DARK} .stx-btn-panel > span.stx-ico-focus {background-position: -350px -50px;}

.#{$DARK} .chartSize, .#{$DARK} .stx_notification_tray, .#{$DARK} .stx_jump_today {
	background: rgba(lighten( $DARK-main, 3% ), 0.8);
	@include box-shadow (0px, 1px, 1px, rgba(0, 0, 0, .3));
}
.#{$DARK} .chartSize:after {background:rgba(255,255,255,.1);}
.#{$DARK} .chartSize .stx-zoom-out {background-position: -45px -50px;}
.#{$DARK} .chartSize .stx-zoom-in {background-position: -95px -50px;}
.#{$DARK} .chartSize .stx-smart-zoom {background-image: url(img/smart-zoom-white.svg);}
.#{$DARK} .chartSize .stx-full-screen {background-image:url(img/full-screen-white.svg);}

.#{$DARK} .stx_jump_today span {background-position: -448px -50px;}

/* ------- chart controls -----*/


.#{$DARK} .stx-float-date { background-color: #fff; color: #333;}
.#{$DARK} .stx-float-price { background-color: #fff; color:#333;}

.#{$DARK} .stx_xaxis {color:mix(#fff, $DARK-bg, 75%);}
.#{$DARK} .stx_xaxis_dark {color: $DARK-text;}
.#{$DARK} .stx_yaxis {color:mix(#fff, $DARK-bg, 75%);}

.#{$DARK} .stx_crosshair {background-color:rgba(255,255,255,.4);}
.#{$DARK} .stx_crosshair_drawing {background-color:rgba(255,255,255,.9);}

.#{$DARK} .stx_panel_border{color:#666;}
.#{$DARK} .stx_panel_drag {color:#ccc;}

.#{$DARK} .stx_grid {color:lighten( $DARK-main, 3% );}
.#{$DARK} .stx_grid_dark {color:lighten( $DARK-main, 5% );}

.#{$DARK} .stx_line_chart {color:$DARK-text;}
.#{$DARK} .stx_bar_chart {color:$DARK-text;}

.#{$DARK} .stx_candle_shadow, .#{$DARK} .stx_bar_even {color:#ccc;}

.#{$DARK} .stx_candle_down, .#{$DARK} .stx_line_down, .#{$DARK} .stx_histogram_down { border-left-color: #e34621; }
.#{$DARK} .stx_candle_up, .#{$DARK} .stx_line_up, .#{$DARK} .stx_histogram_up { border-left-color: #b8dea8; }

.#{$DARK} .stx_histogram_even{border-left-color:#cccccc;}

.#{$DARK} .stx_hollow_candle_even{color:#cccccc;}

.#{$DARK} .stx_baseline{color:#ccc;}

.#{$DARK} .stx_mountain_chart {/* Default color for mountain Charts */
	background-color: rgba(0,156,255,.5);	/* background color for mountain */
	color: rgba(0,102,255,.01);				/* Optional gradient */
	border: #009cff;	/* Optional line color */
	width: 2px;					/* Optional line width */
}

/* Volume underlay styles */
.#{$DARK} .stx_volume_underlay_up {
	color: rgb(140, 193, 118);  /* legacy only. Use study library output "Up Volume" instead */
	opacity: .4;
	border-left-color: rgb(148, 201, 126);
}
.#{$DARK} .stx_volume_underlay_down {
	color: rgb(184, 45, 12); /* legacy only. Use study library output "Down Volume" instead */
	opacity: .4;
	border-left-color: rgb(242, 103, 70);
}

/* Volume profile styles */
.#{$DARK} .stx_volume_profile {
	color: #b64a96;
	opacity: .3;
	border-color: rgb(255, 255, 255);
}

.#{$DARK} .stx_projection {color: $DARK-text;}

.#{$DARK} .stx_histogram{
	color:$DARK-text;
	opacity:.3;
}

.#{$DARK} .stx-btn-panel{
	/*background-color: $DARK-main;*/
}

.#{$DARK} .stx-ico-handle{
	background-color: lighten( $DARK-main, 30% );
}

.#{$DARK} .stx_market_session.pre {
	background-color: rgba(10,229,237,0.3);
}

.#{$DARK} .stx_market_session.post {
	background-color: rgba(41,131,214,0.3);
}

#{$hovering} .#{$DARK} cq-study-legend[cq-hovershow]:hover > cq-marker-label {
	color:white;
}
.#{$DARK} cq-study-legend[cq-hovershow] {
  color:#a2a8ad;
}

.#{$DARK} cq-study-legend[cq-hovershow] > cq-item,
.#{$DARK} cq-study-legend[cq-hovershow] cq-comparison-item {
  color:$DARK-text;
}
#{$hovering} .#{$DARK} cq-study-legend[cq-hovershow] > cq-item:hover > .ciq-edit {
	background-position: -300px -50px;
}
#{$hovering} .#{$DARK} cq-study-legend[cq-hovershow] > cq-item:hover > .ciq-icon.ciq-close:after {
	background-position: -250px -50px;
}

#{$hovering} .#{$DARK} cq-study-legend[cq-hovershow] > cq-item:hover,
#{$hovering} .#{$DARK} cq-study-legend[cq-hovershow] cq-comparison-item:hover {
	background:lighten( $DARK-main, 3% );
}

.#{$DARK} cq-chartcontrol-group{
	background:rgba($DARK-main, 0.75);
	border: solid thin rgba(255,255,255,0.05);
}

.#{$DARK} .ciq-shortcut-button {
	background-position-y: -48px;
}

/* Data Table */
.#{$DARK} .ciq-data-table-container{
	background-color: rgba($DARK-main, 0.95);
	color: $DARK-text;

	tr:hover{
		background-color: $DARK-menu-highlight-bg;
	}
	th, td{
		border-color: scale-color( $DARK-bg, $lightness: 25%, $saturation: -50% );
	}
	button{
		background-color: scale-color( $DARK-bg, $lightness: 10% );
		border-color: scale-color( $DARK-bg, $lightness: 25%, $saturation: -50% );
	}
}

.#{$DARK} .ciq-spinner{
	background-image:url(img/loading-spin-white.svg);
}


.ciq-help-button:after {
	.#{$DARK} & {
		color: $DARK-bg;
		background:#fff;
	}
	*.ciq-show-help.#{$DARK} & {
		color: $DARK-bg;
		background-color: $DARK-help;
	}
}

.#{$DARK} cq-help {
	.press-indicator{
		background: radial-gradient(circle, rgba(255, 255, 255, 0.5) 25%, lighten($DARK-help, 15%) 50%, lighten($DARK-help, 30%)  60%, rgba(255, 255, 255, 0) 61%);
	}

	.ciq-help-widget {
		background: $DARK-help;
	}
}
