.container {
  display: flex;
  flex-direction: column;
  gap: var(--gap-m);

  width: 320px;
  padding: var(--gap-m);

  background-color: var(--color-light-bg-primary);
}

.buttonWrapper {
  display: flex;
  gap: var(--gap-m);
  justify-content: flex-end;
}
