.icon {
  width: 20px;
  height: 20px;
  color: var(--color-light-graphic-secondary);
}

.loader {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  color: var(--text-color-secondary);
}

.requirementIcon {
  margin-right: var(--gap-xs);
}

.option {
  display: flex;
  align-items: center;
}

.iisLabel {
  color: var(--text-color-secondary);
  margin-left: var(--gap-xs);
}

.field {
  --form-control-s-min-height: 24px;
}
