.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1;
}

.pwaGap {
  margin-left: 5px;
}

.entry {
  background-color: var(--color-light-bg-secondary);
  margin: var(--gap-xs);
  border-radius: 4px;
  padding: var(--gap-s);
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
}

.entryGap {
  padding-left: 30px;
}

.summary {
  margin-top: 8px;
  margin-right: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.iconForward {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  width: 24px;
}

.dotContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 30px;
}
