.amount {
  color: var(--color-light-text-primary);
}

.error {
  color: var(--color-light-graphic-negative);
}

.simple {
  color: var(--color-light-text-secondary);
}

.limitsBlock {
  display: flex;
  flex: 1;
  align-items: center;
  color: var(--color-light-text-secondary);
}

.tooltipView {
  align-items: flex-start;
}

.limitsWrapper {
  overflow: hidden;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: var(--gap-2xs);

  text-overflow: ellipsis;
}

.tooltipView .limitsWrapper {
  flex-direction: column;
}

.limitsBlockText {
  display: flex;
}

.icon {
  min-width: 16px;
  min-height: 16px;
  margin-right: var(--gap-xs);
  margin-left: var(--gap-2xs);

  color: var(--color-light-graphic-secondary);
}
