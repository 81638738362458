@import url('@alfalab/core-components/vars/typography.css');

.backdrop {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  background: var(--backdrop-visible-background);
}

.container {
  position: fixed;
  z-index: 2000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;

  width: 400px;
  padding: var(--gap-l);

  background-color: var(--color-light-bg-secondary);
  border-radius: var(--border-radius-m);
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.headerCloseIcon {
  cursor: pointer;

  display: inline-block;

  width: 18px;
  height: 18px;

  color: var(--icon-button-primary-base-color);
}

.main {
  display: flex;
  flex-direction: column;
  padding: var(--gap-l);
}

.mailIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 64px;
  height: 64px;
  margin: 0 auto;
  margin-bottom: var(--gap-2xl);

  background: var(--button-secondary-base-bg-color);
  border-radius: var(--border-radius-3xl);
}

.textHeader {
  @mixin accent_primary_large margin: 0 auto;

  margin-bottom: var(--gap-s);
  color: var(--button-secondary-color);
  text-align: center;
}

.textEmail {
  @mixin accent_secondary_large margin: 0 auto;

  color: var(--button-secondary-color);
}

.textTheme {
  @mixin action_secondary_large margin: 0 auto;

  color: var(--color-light-text-secondary);
}

.link {
  display: flex;
  justify-content: center;
  margin-top: var(--gap-2xl);
}
