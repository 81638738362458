/*
 * Общий стиль для элементов форм и кастомизация библиотечных компонентов.
 * Возможно, стили отсюда нужно перенести в index.css или типа того
*/

/*
 *  Устарело. Использовать компоненты напрямую из core-components.
*/

:root {
  --forms-subtitle-margin-bottom: 12px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 var(--gap-s);
}

.title {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}

.subtitle {
  font-size: 13px;
  line-height: 16px;
  font-weight: 700;
  color: var(--dark-text-tertiary-inverted);
  margin-bottom: var(--forms-subtitle-margin-bottom);
}

.label {
  display: flex;
  align-items: center;
  margin: 0 0 4px;
}

.labelTooltip {
  display: inline-flex;
  align-items: center;
  margin-left: var(--gap-2xs);
}

.labelTooltipContent {
  padding: var(--gap-s);
}

.hint {
  font-size: 11px;
  color: var(--text-color-secondary);
  margin: 8px 0 0;
}

.staticValueFade {
  color: var(--text-color-secondary);
}

.plate {
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: var(--gap-xs);
  border: 1px solid #2b2b2e;
  border-radius: var(--border-radius-s);
}

.plateLoader {
  width: 17px;
  height: 17px;
}

.icon {
  color: var(--color-light-graphic-tertiary-inverted);
}

/* Inputs modification */
.inputSmall {
  --gap-s: 8px;
  --form-control-s-min-height: 32px;
  --form-control-paddings: 0 8px;
  --form-control-hint-color: var(--text-color-secondary);
  --input-disabled-color: var(--text-color-disabled);
  --input-placeholder-color: var(--text-color-tertiary);
  --input-focus-placeholder-color: var(--text-color-tertiary);
  --form-control-disabled-bg-color: var(--dark-bg-tertiary);
  --input-placeholder-color: var(--dark-text-tertiary);
  --input-focus-placeholder-color: var(--dark-text-tertiary);
}

.inputSmall input {
  font-size: 13px;
}

.inputSmall input + div[class*='suffixContainer'] {
  font-size: 13px;
}

.inputSmall [class*='form-control__sub'] {
  font-size: 11px;
}

.inputSmallAddons {
  color: var(--text-color-secondary);
}

.inputSmallAddons [role='img'] {
  width: 20px;
  height: 20px;
}

.inputAutocompletePopover [class*='select__s'] {
  min-height: 32px;
}

.inputAutocompletePopover [class*='select__option'] {
  font-size: 13px;
  line-height: 16px;
}

.inputAutocompletePopover [class*='select__option']:before {
  display: none;
}

.inputAutocompletePopover [class*='select__textContent'] {
  padding-top: var(--gap-xs);
  padding-bottom: var(--gap-xs);
}

.inputAutocompletePopover [class*='select__checkmark'] {
  display: none;
}

/* Textarea modifications */
.textareaSmall {
  --form-control-s-min-height: 32px;
  --form-control-paddings: 8px;
  --textarea-s-min-height: 32px;
  --textarea-s-padding-top: 8px;
  --form-control-hint-color: var(--text-color-secondary);
  --input-disabled-color: var(--text-color-secondary);
  --form-control-disabled-bg-color: var(--dark-bg-tertiary);
}

.textareaSmall textarea {
  font-size: 13px;
  line-height: 16px;
}

.textareaSmall [class*='form-control__sub'] {
  font-size: 11px;
}

.textareaSmallAddons {
  color: var(--text-color-secondary);
}

/* Buttons modification */
.buttonSmall {
  --button-tertiary-base-border-color: var(--button-secondary-base-bg-color);
  font-size: 11px;
}

.buttonXS {
  font-size: 13px;
}

.buttonGhost [class*='button__addons']:first-child {
  margin-right: var(--gap-xs);
}

/* Tag modification */
.tag {
  --tag-background-color-checked: #e9e9eb;
}

.tagXXS {
  --size-xs-height: 24px;
}

/* Attach modifications */
.attach.attachBlock {
  display: flex;
}

.attach [class*='button__addons'] {
  display: none;
}

.attach [class*='attach__file'] {
  display: none;
}

.attach [class*='attach__noFile'] {
  display: none;
}

/* Select modifications */
.selectSmall {
  --form-control-s-min-height: 32px;
  --form-control-hint-color: var(--text-color-secondary);
  --form-control-hover-bg-color: var(--select-button-hover-background);
  --form-control-focus-bg-color: var(--select-button-hover-background);
  --form-control-color: var(--dark-text-tertiary-inverted);
  --form-control-disabled-bg-color: var(--select-button-background);
  --form-control-disabled-color: var(--text-color-secondary);
  --form-control-disabled-opacity: 1;

  --select-arrow-color: var(--light-graphic-secondary);
  --select-arrow-hover-opacity: 1;
}

.selectSmall [class*='select__contentWrapper'] {
  font-size: 13px;
  line-height: 16px;
}

.selectSmall
  [class*='select__field']:not([class*='form-control__disabled']):hover {
  --select-arrow-color: var(--dark-text-primary);

  color: var(--dark-text-primary);
}

.selectSmall
  [class*='select__field']:not([class*='form-control__disabled']):active {
  --form-control-hover-bg-color: var(--select-button-active-background);
  --form-control-focus-bg-color: var(--select-button-active-background);
  --select-arrow-color: var(--dark-text-primary);

  color: var(--dark-text-primary);
}

.selectSmall[aria-expanded='true'] {
  --form-control-hover-bg-color: var(--select-button-active-background);
  --form-control-focus-bg-color: var(--select-button-active-background);
  --form-control-color: var(--dark-text-primary);
  --select-arrow-color: var(--dark-text-primary);
}

.selectSmall [class*='form-control__leftAddons'] {
  padding-left: var(--gap-xs);
}

.selectSmall [class*='form-control__rightAddons'] {
  padding-right: var(--gap-xs);
}

.selectSmall [class*='form-control__sub'] {
  font-size: 11px;
}

.selectSmall [class*='select__arrow'] {
  width: 18px;
  height: 18px;
}

.selectSmallPopover {
  --select-option-divider-display: none;
  --select-option-color: var(--dark-text-tertiary-inverted);
  --select-option-selected-color: var(--dark-text-tertiary-inverted);
  --select-option-hover-color: var(--dark-text-tertiary-inverted);
  --select-option-active-color: var(--dark-text-tertiary-inverted);

  --select-checkmark-margin: 0 0 0 var(--gap-xs);
  --select-checkmark-size: 18px;
  --select-checkmark-before-display: none;
  --select-checkmark-after-display: 'flex';
  --select-checkmark-unselected-opacity: 0;
  --select-checkmark-background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23c5c5c7' width='24' height='24' viewBox='0 0 24 24' class='icon'%3E%3Cpath d='M20 7.5L10.5 17 4 10.5 5.5 9l5 5 8-8L20 7.5z' fill-rule='evenodd' clip-rule='evenodd'%3E%3C/path%3E%3C/svg%3E");
  --select-checkmark-border-radius: var(--border-radius-circle);
}

.selectSmallOption {
  --size-s-height: 32px;
  --select-option-left-padding: var(--gap-xs);
  --select-option-right-padding: var(--gap-xs);

  font-size: 13px;
  line-height: 16px;
}

.selectSmallOption div[class*='select__textContent'] {
  padding-top: var(--gap-xs);
  padding-bottom: var(--gap-xs);
}

/* Checkbox modifications */
.checkboxCustom[class*='checkbox'] span[class*='checkbox__content'] {
  margin-left: var(--gap-xs);
  margin-top: 3px;
}

.checkboxCustom[class*='checkbox'] span[class*='checkbox__label'] {
  font-size: 13px;
  line-height: 16px;
}

.checkboxCustom[class*='checkbox'] span[class*='checkbox__box'] {
  width: 15px;
  height: 15px;
}

.checkboxCustom[class*='checkbox'] span[class*='checkbox__box'] svg {
  width: 18px;
  height: 18px;
}

.checkboxCustom[class*='checkbox__s_'] span[class*='checkbox__label'] {
  font-size: 11px;
}

.checkboxCustom[class*='checkbox__s_'] span[class*='checkbox__box'] {
  width: 14px;
  height: 14px;
}

.checkboxCustom[class*='checkbox__s_'] span[class*='checkbox__box'] svg {
  width: 18px;
  height: 18px;
}

/* Switch modifications */
.switchSmall {
  --color-light-graphic-primary-inverted: #fff;
  align-items: center !important;
}

.switchSmall [class*='switch__switch'] {
  border-radius: 9px;
  width: 30px;
  height: 18px;
  margin: 0;
}

.switchSmall [class*='switch__switch']::before {
  width: 14px;
  height: 14px;
}

.switchSmall[class*='switch__checked'] [class*='switch__switch']::before {
  transform: translateX(12px);
}

.switchSmall [class*='switch__label'] {
  font-size: 13px;
  line-height: 16px;
}

/* Calendar modification */
.calendarInput {
  --form-control-s-min-height: 32px;
}

.calendarInputAddons > svg {
  color: var(--light-graphic-secondary);
  width: 18px;
  height: 18px;
}

.calendarInputField > div:first-child > div > div:first-of-type {
  font-size: 13px;
  line-height: 16px;
}

.calendarInputField input {
  font-size: 13px;
  line-height: 16px;
}

.datePicker {
  --form-control-s-min-height: 32px;
  height: var(--form-control-s-min-height);
}

.datePicker > span {
  --form-control-s-min-height: 32px;
  height: var(--form-control-s-min-height);
}

.calendarPopover {
  --color-light-bg-tertiary: var(--select-option-hover-background);
  --color-light-bg-tertiary-shade-7: var(--select-option-hover-background);
  --calendar-highlighted-background: var(--select-option-hover-background);
  --calendar-range-background: rgba(235, 235, 245, 0.3);
  --calendar-range-complete-background: rgba(235, 235, 245, 0.3);
  --calendar-select-icon-background: center/contain no-repeat
    url(@terminal/common/icons/chevron-down_m.svg);
  --calendar-arrow-background: center/contain no-repeat
    url(@terminal/common/icons/chevron-back_m.svg);

  --calendar-day-border-radius: 100%;
}

/* Link */
.linkColorInherit {
  color: inherit;
}

@media (max-width: 768px) {
  .subtitle {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: var(--text-color-secondary);
    margin-bottom: 4px;
  }
}
