.container {
  display: flex;
  padding: var(--gap-s) var(--gap-l);
  background: var(--color-light-bg-secondary);
  opacity: 1;
  cursor: pointer;
  border-radius: var(--border-radius-s);
}

.disabled {
  opacity: 0.5;
  cursor: default;
}

.container:not(.disabled):hover {
  background: var(--color-light-bg-tertiary);
}

.index {
  margin-right: var(--gap-xs);
}

.title {
  flex-grow: 1;
}

.addons {
  margin-left: var(--gap-xs);
  display: flex;
  align-items: center;
}

.actionIcon {
  margin-left: var(--gap-xs);
}