.stage {
  background: var(--color-light-bg-secondary);
  border-radius: var(--border-radius-m);
  overflow: hidden;
  margin-bottom: var(--gap-l);
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  display: block;
  max-width: 100%;
}

.title {
  margin-bottom: var(--gap-l);
}

.answerItem {
  margin-top: var(--gap-l);
  width: 100%;
}

.answerItem:first-child {
  margin-top: 0;
}

.answerItem[class*='radio__container_'][class*='radio__s_'] [class*='radio__label_'] {
  font-size: 11px;
  line-height: 16px;
  font-weight: 400;
}

.answerItem[class*='checkbox__component_'][class*='checkbox__s_'] [class*='checkbox__label_'] {
  font-size: 11px;
  line-height: 16px;
  font-weight: 400;
}