.progressBarContainer {
  width: 100%;
}

.negativeBar {
  transform: scaleX(-1);
}

.table {
  margin-left: var(--gap-xs-neg);
}