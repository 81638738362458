.container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.account {
    margin-right: var(--gap-m);
}

.place {
    margin-right: var(--gap-m);
}

.leftAddons {
  margin-right: var(--gap-s);
  display: flex;
  align-items: center;
}