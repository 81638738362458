.container {
  --account-broker-link-font-size: 13px;
  --account-broker-link-info-margin: 10px;
}

.sizeXxs {
  --account-broker-link-font-size: 11px;
  --account-broker-link-info-margin: 15px;
}

.container {
  font-size: var(--account-broker-link-font-size);
}

.info {
  margin-left: var(--account-broker-link-info-margin);
  color: var(--color-light-text-secondary);
}

@media (max-width: 768px) {
  .container {
    margin: 4px 0 20px !important;
  }
}
