.container {
  background: var(--color-light-bg-secondary);
  border-radius: var(--border-radius-s);
  padding: var(--gap-s) var(--gap-l);
}

.status {
  display: flex;
  margin-bottom: var(--gap-s);
}

.statusIcon {
  margin-right: var(--gap-2xs);
}

.title {
  margin-bottom: var(--gap-2xs);
}

.list {
  margin: 0;
  padding: 0 0 0 var(--gap-m);
}