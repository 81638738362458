.tooltipContent {
    max-width: 200px;
    display: flex;
    flex-direction: column;
    gap: var(--gap-xs);
}

.tooltipTarget {
    display: inline-flex;
}

.defaultQuantity {
    display: flex;
    justify-content: flex-end;
    color: rgba(235, 235, 245, 0.6);
}

.popover {
  width: 200px;
  padding: 0;
}

.dropDownItemContent {
  display: flex;
  gap: var(--gap-2xs);
}

.right {
  display: flex;
  align-items: center;
  gap: var(--gap-2xs);
}

.suffix {
  color: var(--color-light-graphic-tertiary-inverted);
}