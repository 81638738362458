.agencyCellHigh {
  color: #0cc44d;
}

.agencyCellMedium {
  color: #fda136;
}

.agencyCellLow {
  color: #ff5431;
}

.alignLeftCell {
  text-align: start;
}
