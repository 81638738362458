.container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.icon {
  margin-right: 24px;
}

.tooltipIcon {
  width: 16px;
  height: 16px;
  color: var(--color-light-graphic-secondary);
  vertical-align: middle;
}

.tooltipTarget {
  display: inline-block;
}

.select {
  max-width: 400px;
}

.buttons {
  width: 100%;
}

.certificateRows {
  width: 100%;
  max-width: 400px;
}
