.container {
  --transfer-result-submit-max-width: 286px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  margin-bottom: var(--gap-l);
}

.submit {
  width: var(--transfer-result-submit-max-width);
}
