.container {
  --messages-list-group-margin-bottom: var(--gap-l);
  --messages-list-group-title-padding: 4px 8px;
}

.group {
  margin-bottom: var(--messages-list-group-margin-bottom);
}

.groupTitle {
  padding: var(--messages-list-group-title-padding);
  font-size: 11px;
  line-height: 16px;
  font-weight: 700;
  color: var(--color-light-text-secondary);
}
