.tableWrapper {
  overflow-y: auto;
  max-height: 250px;
}

.table {
  border-collapse: collapse;
  width: 100%;
  text-align: left;
}

.header {
  position: sticky;
  z-index: 1;
  top: 0;

  color: var(--color-light-graphic-tertiary-inverted);

  background-color: var(--color-light-bg-secondary);
}

.row {
  vertical-align: middle;
}

.cell {
  padding: var(--gap-xs);
}

.body tr:nth-child(even) {
  background-color: var(--color-light-bg-secondary);
}

.accountCell {
  display: inline-flex;
  align-items: center;
}

.accountInfo {
  margin-left: var(--gap-xs);
}

.actionCell {
  width: 70px;
  text-align: right;
}

.actionButtons {
  display: flex;
  justify-content: space-around;
}

.userIcon {
  color: var(--color-light-graphic-tertiary-inverted);
}
