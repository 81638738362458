.label {
  margin-bottom: var(--gap-2xs);
  display: flex;
  align-items: center;
}

.graphic {
  color: var(--color-light-graphic-tertiary-inverted);
}

.customInput {
  --form-control-s-min-height: 24px;
}

.customInputField > div:first-child > div > div:first-of-type {
  font-size: 13px;
  line-height: 16px;
  font-weight: normal;
}

.customInputField input {
  font-size: 13px;
  line-height: 16px;
  font-weight: normal;
}

.customInputField input:disabled {
  opacity: 0;
}

.priceAddons {
  display: flex;
  gap: var(--gap-2xs);
  align-items: center;
  user-select: none;
}

.tooltipTarget {
  display: inline-flex;
}

.tooltipContent {
  max-width: 180px !important;
  display: flex;
  flex-direction: column;
  gap: var(--gap-xs);
}

