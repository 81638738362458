@import url('@alfalab/core-components/vars/typography.css');

.message {
  padding: var(--gap-3xs) 0;
}

.recommendationContainer {
  display: grid;
  grid-template-columns: 32px 16px 96px 28px 14px;
  align-items: center;
  padding-right: var(--gap-xs);
}

.marketBoard {
  margin-left: var(--gap-2xs);
  color: #8d8d93;
}

.directionRow {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  color: #8d8d93;
}

.operationType {
  margin-left: var(--gap-2xs);
}

.directionIcon {
  flex-shrink: 0;
  margin-right: var(--gap-3xs);
}

.amount {
  @mixin paragraph_secondary_medium;
}
