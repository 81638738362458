
.dropdownRef {
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  padding: var(--gap-2xs) var(--gap-xs);
  border-radius: 4px;
  color: var(--form-control-color);
  background-color: var(--form-control-bg-color);
  transition: background-color 0.2s ease, border 0.2s ease, color 0.2s ease,
  transform 0.12s ease;
}

.dropdownRef:hover {
  color: var(--form-control-color);
  background-color: var(--form-control-hover-bg-color);
}

.activeOrdersDropdown {
  flex: 0;
  margin-top: calc(-1 * var(--gap-xs));
  margin-bottom: var(--gap-m);
}

.activeOrdersDropdown.mobileVersion {
  margin: var(--gap-m) 0 0;
}

.orderInfoWrapper {
  margin: 4px 8px;
}


.dropDownContent {
  display: flex;
  flex: 1;
  min-width: 0;
}
.dropDownContent > *:not(:first-child) {
  margin-left: var(--gap-2xs);
}
.dropDownContent > *:last-child {
  margin-left: auto;
}

.dropDownItemContent {
  display: flex;
  align-items: center;
}
.dropDownItemContent > *:not(:first-child) {
  margin-left: var(--gap-2xs);
}

.orderName {
  flex: 1;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chevron {
  color: var(--color-light-graphic-secondary);
  margin-left: auto;
  transition: color 0.2s ease, border 0.2s ease, color 0.2s ease,
  transform 0.12s ease;
}

.chevronUp {
  transform: rotate(-0.5turn);
}


.popoverFull {
  width: 100%;
  padding: 0;
  border: none;
}

.popoverFull > div > div,
.popover > div > div {
  padding: 0;
}
