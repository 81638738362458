.tabs {
  margin-left: var(--gap-l);
}

.infoActionsWrapper {
  display: flex;
  gap: 20px;
  margin-top: var(--gap-2xs);
}

.deleteChecked {
  --size-xxs-height: 24px;
  --button-font-weight: 400;
}

.actionsWrapper {
  display: flex;
  gap: var(--gap-xs);
  align-items: center;
}

.popoverButton {
  --size-xxs-height: 24px;
}
