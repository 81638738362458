/*Используется извне*/
.directionCell {
  display: flex;
  align-items: center;
}

.directionCell > *:not(:first-child) {
  margin-left: var(--gap-xs);
}

.typeCell {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--color-light-text-secondary);
}

.actionsCell {
  display: flex;
  justify-content: flex-end;
  gap: var(--gap-xs);
}

.typeCell > *:not(:first-child) {
  margin-left: var(--gap-xs);
}

.sell {
  color: var(--color-sell);
}

.buy {
  color: var(--color-buy);
}
