.container {
  --notifications-treaty-margin-bottom: 32px;
}

.sizeXxs {
  --notifications-treaty-margin-bottom: 24px;
}

.treaty {
  margin-bottom: var(--notifications-treaty-margin-bottom);
}

@media (max-width: 768px) {
  .treaty {
    margin-bottom: 20px;
  }
}
