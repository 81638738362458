/*
 Переопределения стилей компонента Button
*/

@import '@alfalab/core-components/vars/typography.css';

:root {
  --button-accent-disabled-color: var(--color-light-text-primary-alpha-40);
  --button-accent-disabled-bg-color: var(--color-light-bg-accent-alpha-40);

  --button-m-size-border-radius: var(--border-radius-m);

  --button-secondary-hover-bg-color: var(--color-light-bg-quaternary-shade-10);
  --button-secondary-active-bg-color: var(--color-light-bg-quaternary-shade-30);
  --button-secondary-disabled-bg-color: var(
    --color-light-bg-quaternary-alpha-30
  );

  --button-ghost-hover-color: var(--color-light-text-primary-tint-30);
  --button-ghost-active-color: var(--color-light-text-primary-tint-50);
  --button-ghost-disabled-color: var(--color-light-text-primary-alpha-30);

  --button-tertiary-base-border-color: var(--color-light-border-underline);
  --button-tertiary-hover-border-color: var(
    --button-tertiary-base-border-color
  );
}

[class*='button__component']:not([class*='icon-button__component']):not(
    [class*='button__iconOnly_']
  ) {
  &[class*='button__xs_'],
  &[class*='button__s_'] {
    padding: 0 var(--gap-m);
    @mixin action_secondary_large;

    gap: var(--gap-2xs);

    &[class*='button__withLeftAddons_'] {
      padding-left: var(--gap-s);
    }

    &[class*='button__withRightAddons_'] {
      padding-right: var(--gap-xs);
    }
  }

  &[class*='button__m_'] {
    @mixin action_secondary_large;
  }

  &[class*='button__ghost_'] {
    &[class*='button__withLeftAddons_'] {
      padding-left: 0;
    }

    &[class*='button__withRightAddons_'] {
      padding-right: 0;
    }
  }
}
