.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 360px;
  text-align: center;
}

.title {
  margin-top: var(--gap-2xl);
  color: var(--color-light-text-primary);
}

.description {
  margin-top: var(--gap-s);
  color: var(--color-light-text-secondary);
}
