.input {
  max-width: 160px;
}

.customInput {
  --form-control-s-min-height: 24px;
  --form-control-paddings: 0 var(--gap-xs);
}

.customInput [class*='form-control__leftAddons'] {
  padding-left: var(--gap-xs);
}

.customInput [class*='form-control__rightAddons'] {
  padding-right: var(--gap-xs);
}

.customInput input {
  background-color: transparent !important;
}

.marketBoardLabel {
  position: absolute;
  top: 50%;
  transform: translate(18px, -50%);
  padding: var(--form-control-paddings);
  pointer-events: none;
  color: #8d8d93;
  white-space: nowrap;
  /* 52px = Ширина лого + ширина иконки справа */
  max-width: calc(100% - 52px);
  overflow: hidden;
}

.marketBoardPadding {
  visibility: hidden;
  margin-right: 0.5em;
}

.optionContent {
  display: flex;
  align-items: baseline;
  white-space: nowrap;
  gap: 1rem;
  padding-left: 1rem;
}

.optionName {
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  min-width: 20%;
}

.optionSymbol,
.optionMarketCode {
  display: contents;
  overflow: hidden;
}

.optionsList {
  min-width: 400px;
}
