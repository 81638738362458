.buySell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.direction {
  width: 14px;
  height: 14px;
  margin-right: var(--gap-xs);
  color: var(--color-light-graphic-secondary);
}

.directionEmpty {
  width: 23px;
}

.buy {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-buy);
}

.sell {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-sell);
}

.highlightedCell {
  border: 2px solid var(--color-light-graphic-secondary);
  position: relative;
  z-index: 100;
}
