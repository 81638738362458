.container {
  --message-item-padding-vertical: 6px;
  --message-item-padding-horizontal: 8px;
  --message-item-font-size: 11px;
  --message-item-line-height: 14px;
  --message-item-marker-size: 8px;
}

.container {
  display: flex;
  gap: var(--gap-xs);
  padding: var(--message-item-padding-vertical)
    var(--message-item-padding-horizontal);
  font-size: var(--message-item-font-size);
  line-height: var(--message-item-line-height);
  cursor: pointer;
}

.odd {
  background: var(--color-light-bg-secondary);
}

.status {
  width: var(--message-item-marker-size);
  flex-shrink: 0;
}

.marker {
  display: block;
  width: var(--message-item-marker-size);
  height: var(--message-item-marker-size);
  border-radius: 100%;
  background-color: currentColor;
  margin-top: calc(
    (var(--message-item-line-height) - var(--message-item-marker-size)) / 2
  );
}
