.quaternary {
  color: var(--color-light-graphic-quaternary-inverted);
}

label.radioItem {
  display: flex;
  gap: var(--gap-m);
  align-items: center;

  width: 100%;
  height: 40px;
  margin-bottom: 0;
  padding: 0 var(--gap-xs);
}

.radioItem:hover,
.selectedRadioItem {
  background-color: var(--select-option-hover-background);
}

.footerButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-top: var(--gap-m);
  padding: var(--gap-m);

  border-top: 1px solid var(--color-light-border-primary);
}

.replaceModal {
  width: 320px;
}
