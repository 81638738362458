.plate {
  max-width: 100%;
  height: 32px;
  min-height: 32px;
  padding: 0;
}

.content {
  max-width: 100%;
}

.shortContent div {
  padding-top: 0;
  padding-left: var(--gap-3xs);
  padding-right: var(--gap-3xs);
}

.badge {
  --badge-color: var(--color-light-bg-attention-muted);
  margin-right: var(--gap-s);
  width: 15px;
  height: 15px;
  color: var(--badge-color);
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.message {
  white-space: nowrap;
  overflow: hidden;
}
