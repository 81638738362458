.chooseButtonContainer {
  min-width: 222px;
  max-width: 238px;
  border-radius: 10px;
  border: 1px solid var(--color-light-graphic-tertiary);
  padding: var(--gap-m);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: var(--color-light-bg-tertiary);
  }
}

.isActive {
  background-color: var(--color-light-bg-tertiary);
}

.chooseButtonIcon {
  margin: 24px auto 0;
}
