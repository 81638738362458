.root {
  display: flex;
  gap: var(--gap-s);
}

.button {
  padding-left: var(--gap-s);
  padding-right: var(--gap-s);
  font-size: 13px;
  line-height: 16px;
}
