/*
 Переопределения стилей компонента Tag
*/

@import '@alfalab/core-components/vars/typography.css';

:root {
  --tag-filled-background-color: var(--form-control-bg-color);
  --tag-filled-background-color-hover: var(--form-control-hover-bg-color);

  --tag-text-color: var(--color-light-graphic-tertiary-inverted);
  --tag-text-color-checked: var(--color-light-text-primary-inverted);
  --tag-text-color-disabled: var(--tag-text-color);
  --tag-text-color-checked-disabled: var(--tag-text-color-checked);

  --tag-border-color-hover: var(--tag-filled-border-color);
  --tag-border-color-active: var(--tag-filled-border-color);
  --tag-border-color-checked: var(--tag-filled-border-color);
  --tag-border-color-disabled: var(--tag-filled-border-color);
}

[class*='tag__xs_'] {
  @mixin paragraph_secondary_large;
  padding: 0 var(--gap-s);

  &[class*='tag__withRightAddons_'] {
    padding-right: var(--gap-xs);
  }

  &[class*='tag__withLeftAddons_'] {
    padding-left: var(--gap-xs);
  }
}

[class*='tag__s_'] {
  @mixin paragraph_secondary_large;
}
