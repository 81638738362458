.plate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  border: 1px solid var(--color-light-border-primary);
}

.size_s {
  height: 32px;
  padding: var(--gap-xs);
  border-radius: var(--border-radius-s);
}

.size_xs {
  height: 24px;
  padding: var(--gap-xs);
  border-radius: var(--border-radius-s);
}
