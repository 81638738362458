.container {
  display: block;
}

.sectionTitle {
  margin-bottom: var(--gap-s);
}

.section {
  margin: 0 0 var(--gap-l);
}

.submitIcon {
  color: var(--color-light-graphic-secondary);
}

.hint {
  margin-top: var(--gap-s);
}
