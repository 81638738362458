.buttons {
  width: 220px;
  display: flex;
  flex-direction: column;
  gap: var(--gap-s);
}

.glyph {
  color: var(--color-light-graphic-tertiary-inverted);
  width: 18px;
  height: 18px;
}