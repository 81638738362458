@import url('@alfalab/core-components/vars/typography.css');

.containerModal {
  position: absolute;
}

.customButton span {
  @mixin action_secondary_small;
}

.doubleRowWrapper {
  display: flex;
  flex-direction: column;
  min-width: 1000px;
  max-width: 1000px;
}

.doubleContent {
  overflow: hidden auto;
  display: flex;
  flex-direction: column;

  max-height: 60vh;
  padding: var(--gap-xs);
}

.buttonsWrapper {
  display: flex;
  gap: var(--gap-m);
}

.addColumnButton {
  max-width: 224px;
  margin: var(--gap-s) var(--gap-xs) !important;
}

.ghostButton {
  margin: 0 var(--gap-xs) !important;
}

.header,
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 48px;
  padding: var(--gap-m) var(--gap-xs);

  background-color: var(--color-light-bg-tertiary);
}
