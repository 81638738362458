.container {
  display: flex;
  flex-direction: column;
  padding: var(--gap-xl) var(--gap-l) var(--gap-l) var(--gap-l);
}

.container > * + * {
  margin-top: 32px;
}

.displayInstrumentTypeSelect {
  margin-top: var(--gap-s);
}

.displayInstrumentTypeSelectText {
  margin-top: var(--gap-xs);
}

.desktop .selectContainer {
  margin-top: var(--gap-l);
  width: 236px;
}
