.container {
  --add-account-success-banner-padding: 41px 50px 41px 45px;
  --add-account-success-banner-image-size: 220px 139px;
  --add-account-success-banner-image-left: 45px;
  --add-account-success-banner-margin-bottom: 48px;
  --add-account-success-title-font-size: 22px;
  --add-account-success-title-line-height: 26px;
  --add-account-success-title-margin-left: 245px;
  --add-account-success-advantage-width: 200px;
}

.sizeXxs {
  --add-account-success-banner-padding: 57px 40px 32px 22px;
  --add-account-success-banner-image-size: 169px 107px;
  --add-account-success-banner-image-left: 22px;
  --add-account-success-banner-margin-bottom: 48px;
  --add-account-success-title-font-size: 18px;
  --add-account-success-title-line-height: 22px;
  --add-account-success-title-margin-left: 200px;
  --add-account-success-advantage-width: 200px;
}

.container {
  padding: 0 0 var(--gap-m);
}

.banner {
  border: 2px solid var(--dark-border-color-secondary);
  border-radius: 16px;
  padding: var(--add-account-success-banner-padding);
  background-image: url(./img/banner.png);
  background-repeat: no-repeat;
  background-size: var(--add-account-success-banner-image-size);
  background-position: var(--add-account-success-banner-image-left) bottom;
  margin: 0 0 48px;
  display: flex;
  justify-content: flex-end;
}

.title {
  font-size: var(--add-account-success-title-font-size);
  line-height: var(--add-account-success-title-line-height);
  font-weight: 700;
  margin-left: var(--add-account-success-title-margin-left);
}

.steps {
  display: flex;
  justify-content: space-between;
  gap: 25px;
}

.advantage {
  max-width: var(--add-account-success-advantage-width);
}
