.supportPanel {
  margin-bottom: var(--gap-s);
}

.copyTooltip {
  pointer-events: none;

  position: absolute;
  z-index: 100;
  bottom: 72px;
  left: 50%;
  transform: translateX(-50%) translateY(20px);

  padding: var(--gap-xs);

  opacity: 0;
  background-color: var(--color-light-bg-quaternary);
  border-radius: var(--border-radius-m);

  transition: transform 0.3s;
}

.copyTooltipVisible {
  transform: translateX(-50%) translateY(0);
  opacity: 1;
}
