:root {
  --column-setting-height: 32px;
}

.containerModal {
  position: absolute;
}

.content {
  display: flex;
  flex-direction: column;
  width: 240px;
  background-color: var(--dark-bg-secondary);
}

.title {
  display: flex;
  padding: var(--gap-m) var(--gap-s);
}

.search {
  padding: var(--gap-xs);
}

.buttons {
  display: flex;
  gap: var(--gap-xs);
  justify-content: center;
  padding: var(--gap-xs);
}

.columns {
  overflow-y: auto;

  /* 8.5 cтрок */
  max-height: calc(var(--column-setting-height) * 8.5);
}

.setting {
  user-select: none;

  display: flex;
  gap: var(--gap-xs);
  align-items: center;

  height: 32px;
  padding: 0 var(--gap-xs);

  background-color: var(--dark-bg-secondary);
}

.draggingSetting {
  background-color: var(--select-option-hover-background);
}

.setting:not(.disabledSetting):hover {
  cursor: grab;
  background-color: var(--select-option-hover-background);
}

.columns *:focus {
  outline: none;
}

.customButton {
  min-height: 24px;
}

.dragIcon {
  cursor: grab !important;
  display: none;
}

.draggingSetting .dragIcon,
.setting:hover .dragIcon {
  cursor: inherit;
  display: block;
}

.settingNameWrapper {
  display: flex;
  flex: 1;
  gap: var(--gap-xs);
  justify-content: flex-start;
}

.tooltipContent {
  max-width: 200px;
}

.tooltipArrow::after {
  left: 6px !important;
}

.tooltip[data-popper-placement='top-end'] .tooltipArrow::after {
  left: -18px !important;
}

.tooltip {
  box-shadow: 0 12px 16px rgb(0 0 0 / 16%), 0 4px 8px rgb(0 0 0 / 16%),
    0 0 1px rgb(0 0 0 / 16%);
}

.disabledSetting {
  cursor: not-allowed !important;
  opacity: 0.4;
}

.disabledSetting svg {
  cursor: not-allowed !important;
}

.setting:hover svg {
  cursor: pointer;
}

.buttonsWrapper {
  display: flex;
  gap: var(--gap-m);
}

.settingNameWrapper span {
  flex: unset !important;
}
