.headerTabsContainer {
  overflow: hidden;
  display: flex;
}

.layoutTabs {
  overflow: hidden;
  display: flex;
  align-items: center;
}

.layoutTabs::-webkit-scrollbar {
  display: none;
}

.auxButtons {
  display: flex;
  align-items: center;
}

.tabsMenu {
  width: 160px;
  min-width: 160px;
}

.tabsMenuTitle {
  padding: var(--gap-xs);
  padding-top: 0;
  color: var(--color-light-graphic-secondary);
}

.tabsMenuDotStub {
  visibility: hidden;
}
