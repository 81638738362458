.detailsContainer {
  padding: 0 var(--gap-xs);
  width: 100%;
}

.detailsTitle {
  margin-bottom: var(--gap-l);
}

.divider {
  margin-bottom: var(--gap-xl);
}

.downloadButton {
  max-width: 233px;
}

.glyph {
  color: var(--color-light-graphic-tertiary-inverted);
}

.emailContainer {
  display: grid;
  grid-template-columns: auto auto;
  gap: var(--gap-m);
  align-items: center;
}