/*
 Переопределения стилей компонента ProgressBar
*/

:root {
  --progressbar-accent-color: var(--control-accent-bg-color);
  --progressbar-negative-color: var(--color-light-text-negative);
  --progressbar-attention-color: var(--color-light-text-attention);
  --progressbar-positive-color: var(--color-light-text-positive);
}
