/*
 Переопределения стилей компонента FormControl
*/

@import '@alfalab/core-components/vars/typography.css';

:root {
  --form-control-labeled-m-paddings: 24px var(--gap-s) 4px;

  --form-control-bg-color: var(--color-light-bg-tertiary);
  --form-control-hover-bg-color: var(
    --color-light-bg-quaternary
  );
  --form-control-focus-bg-color: var(--form-control-bg-color);

  --form-control-focus-shadow: inset 0 0 0 1px
    var(--color-light-border-underline);
  --form-control-error-shadow: inset 0 0 0 1px
    var(--color-light-graphic-negative);
  --form-control-focus-error-shadow: var(--form-control-error-shadow);

  --form-control-disabled-opacity: 0.4;
}

[class*='form-control__component_'] {
  [class*='form-control__above_'],
  [class*='form-control__sub_'] {
    [class*='form-control__s_']& {
      @mixin paragraph_secondary_medium;
    }
  }

  [class*='form-control__above_'] {
    --gap-s: var(--gap-xs);
  }

  [class*='form-control__sub_'] {
    margin-top: var(--gap-2xs);
  }

  [class*='form-control__input_'] {
    [class*='form-control__s_']& {
      @mixin paragraph_primary_small;
    }
  }
}
