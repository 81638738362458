.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--gap-m);
  padding: var(--gap-xs) var(--gap-m);
}

.inputs {
  display: flex;
  align-items: center;
}

.input {
  flex-grow: 1;
}

.dash {
  margin: 0 var(--gap-xs);
}
