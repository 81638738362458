.red {
  color: var(--color-sell);
}

.green {
  color: var(--color-buy);
}

.grey {
  color: var(--color-light-text-secondary);
}
