.filterIconOpen {
  display: block !important;
}

.activeFilter {
  display: block !important;
  color: var(--control-accent-bg-color);
}

.popper {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  border-radius: 1px;
}

.filterPopover {
  padding: 0;
}

.popper.filterPopover {
  background-color: var(--select-option-background);
  box-shadow: var(--select-options-list-box-shadow);
}

.popper > :first-child {
  width: 100%;
}

.popoverChildrenWrapper {
  display: flex;
  flex-direction: column;
  padding: var(--gap-xs) 0;
}

.chevron {
  margin-left: auto;
  color: var(--color-light-graphic-secondary);
  transition: color 0.2s ease, border 0.2s ease, color 0.2s ease,
    transform 0.12s ease;
}

.chevronUp {
  transform: rotate(-0.5turn);
}

.popoverTitle {
  padding: 0 var(--gap-xs) var(--gap-xs);
}

.tooltipContent {
  display: flex;
  flex-direction: column;
  gap: var(--gap-xs);
  max-width: 180px !important;
}

.chipsDetail {
  padding-left: var(--gap-2xs);
  opacity: 0.85;
}

.crossButton {
  --icon-button-primary-hover-color: var(--icon-button-primary-base-color);
}

.multiSelectAutoScroll {
  overflow-y: auto;
}
