@import '@alfalab/core-components/vars/typography.css';

.directionTitle {
  margin: 0 0 var(--gap-m);
  display: flex;
  align-items: center;
}

.directionIcon {
  color: var(--color-light-graphic-secondary);
  margin-right: 10px;
}

.directionText {
  @mixin accent_secondary_large;
}

.item {
  margin: 0 0 var(--gap-s);
}
