.plate {
  max-width: 100%;
  height: 24px;
  min-height: 24px;
  padding: 0 var(--gap-xs);
}

.content {
  max-width: 100%;
  padding-top: 0;
  display: contents;
}

.content div {
  flex: 1;
  padding-left: var(--gap-3xs);
  padding-right: var(--gap-3xs);
}

.badge {
  --badge-color: var(--color-light-bg-attention-muted);
  margin-right: 0;
  width: 15px;
  height: 15px;
  color: var(--badge-color);
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.message {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.addon {
  display: flex;
  padding: var(--gap-2xs);
  width: 24px;
  align-items: center;
  justify-self: center;
  color: var(--icon-button-secondary-base-color);
}
