.container {
  display: flex;
  align-items: center;
  min-height: 24px;
  max-height: 24px;
  position: relative;

  background-color: var(--layout-color-surface-0);
  border-top: 2px solid var(--layout-color-background-1);
}

.wrapper {
  display: flex;
  align-items: center;
  padding: var(--gap-3xs) var(--gap-m);
  white-space: nowrap;
}

.wrapper::after {
  content: ' ';
  position: absolute;
  right: 0;
  display: block;
  width: 60px;
  height: 100%;

  background: linear-gradient(
    90deg,
    var(--color-light-specialbg-nulled) -7.81%,
    var(--layout-color-surface-0) 62.5%
  );
}

.paramsWrapper {
  display: flex;
  align-items: center;

  margin-right: var(--gap-m);
}

.paramsWrapper > * {
  margin-right: var(--gap-xs);
}

.label {
  color: var(--color-light-text-secondary);
  margin-right: var(--gap-xs);
}

.up {
  color: var(--color-buy);
}

.down {
  color: var(--color-sell);
}

.arrow {
  align-self: flex-end;
}

.tooltipTarget {
  position: absolute;
  top: 0;
  right: var(--gap-xs);
}

.tooltipContent > *:not(:last-child) {
  margin-bottom: var(--gap-xs);
}

.tooltipContent > * {
  margin-right: 0;
}
