.outlier-sticker{

	left: 0;
	top: 0;
	position:absolute;
	display:inline-block;
	width:50px;
	height:50px;
	z-index: 20;
	cursor: pointer;
	text-align:center;

	&.quote{
		width: 15px;
		height: 13px;
		padding-top: 37px;
		transition: transform 500ms cubic-bezier(0.680, -1.550, 0.265, 2.550);

		&:hover{
			z-index: 100;

			.outlier-value{
				border: solid 1px rgba(0,0,0,0.25);
				.#{$DARK} & {
					border: solid 1px rgba(255,255,255,0.25);
				}
			}
		}

		.pill{
			position: absolute;
			top: 5px;
			left: 0;
			width: 15px;
			height: 26px;
			overflow: hidden;
			border-radius: 8px;
			background: #f8f8f8;
			border: solid 1px #4c5668;

			.#{$DARK} & {
				background-color: #151f28;
			}

			.icon{
				position: absolute;
				top: 0;
				left: 0;
				width:100%;
				height: 100%;
				background: rgba(255,255,255,0.5) url(img/outliers_day_more-above.svg) center center no-repeat;

				.#{$DARK} & {
					background-color: rgba(0,0,0,0.25);
					background-image: url('img/outliers_night_more-above.svg');
				}
			}
		}

		&.mini{
			padding-top: 26px;
			.pill{
				height: 15px;
			}

			&.micro{
				padding-top: 20px;
				.pill{
					height: 10px;
					background-color: transparent;
					border: none;
				}
			}
		}

		&.nano .outlier-value{
			display: none;
		}

		.outlier-value{
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			background-color: darken($DEFAULT-main, 5%);
			padding: 1px 10px;
			border-radius: 5px;

			.#{$DARK} & {
				background-color: darken($DARK-main, 5%);
			}
		}

		&.low:not(.flipped), &.high.flipped{
			padding-top: 0;
			height: 50px;
			background-repeat: no-repeat;

			.pill{
				top: auto;
				bottom: 5px;

				.icon{
					background-image: url('img/outliers_day_more-below.svg');

					.#{$DARK} & {
						background-image: url('img/outliers_night_more-below.svg');
					}
				}
			}

			&.mini{
				height: 39px;

				&.micro{
					height: 33px;
				}
			}

		}

		&:hover{
			transform: translateY(-3px);
			&.low{
				transform: translateY(3px);
			}
		}
	}

	&.axis{
		margin-left: 1px;
		text-align: left;
		height: 100px;

		.expansion, .compression{
			position: absolute;
			top: 0;
			left: 0;
			width:50px;
			height:50px;
			overflow: hidden;
			background: darken($DEFAULT-main, 2%); // url('img/outliers_day_expansion.svg') center no-repeat;
			transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);

			.#{$DARK} & {
				background-color: lighten($DARK-main, 5%);
				//background-image: url('img/outliers_night_expansion.svg');
			}

			.pill{
				position: absolute;
				top: 15px;
				left: 50%;
				width: 15px;
				height: 26px;
				transform: translateX(-50%);
				overflow: hidden;
				border-radius: 8px;
				background: #f8f8f8;
				border: solid 1px #4c5668;

				.#{$DARK} & {
					background-color: #151f28;
				}

				.icon{
					position: absolute;
					top: 0;
					left: 0;
					width:100%;
					height: 100%;
					background: rgba(255,255,255,0.5) url('img/outliers_day_expansion.svg') center center no-repeat;

					.#{$DARK} & {
						background-color: rgba(0,0,0,0.25);
						background-image: url('img/outliers_night_expansion.svg');
					}
				}
			}
		}

		.expansion{

			background-position-y: calc(50% + 5px);

			.tick{
				position: absolute;
				height: 6px;
				width: 2px;
				border-bottom: solid 1px;
				transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
			}

			.outlier-value{
				margin-left:5px;
				margin-right: 0;
				transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
			}

		}

		&.right .expansion{
			margin-left: 0;
			margin-right: 1px;
			text-align: right;

			.tick{
				right: 0;
			}

			.outlier-value{
				margin-left:0;
				margin-right: 5px;
			}
		}

		.compression{
			height: 0;
			margin-top: 50px;
			//background-image: url('img/outliers_day_compression.svg');
			background-position: center -100%;
			border-top: 1px solid;
			border-bottom: 1px solid;
			box-shadow: 0 3px 5px rgba(0,0,0,0.25);

			.#{$DARK} & {
				//background-image: url('img/outliers_night_compression.svg');
				box-shadow: 0 3px 7px #000;
			}

			.pill .icon{
				background: rgba(255,255,255,0.5) url('img/outliers_day_compression.svg') center center no-repeat;

				.#{$DARK} & {
					background-color: rgba(0,0,0,0.25);
					background-image: url('img/outliers_night_compression.svg');
				}
			}
		}

		&.compress{
			.expansion{
				height: 0;
				top: 50px;
			}
			.compression{
				height: 50px;
				background-position: center 50%;
				box-shadow: 0 2px 5px rgba(0,0,0,0.4);
			}
		}

		&.low:not(.flipped), &.high.flipped{
			.expansion{
				.pill{
					top: 5px;
				}
			}
			.compression{
				.pill{
					top: 10px;
				}
			}
			.expansion {
				background-position-y: calc(50% - 5px);
				padding-top: 35px;
		 		height: 15px; /* padding-top + height = 50px */
			}
			.compression{
				margin-top: 0px;
				background-position: center 200%;
				box-shadow: 0 -3px 5px rgba(0,0,0,0.25);

				.#{$DARK} & {
					box-shadow: 0 -3px 7px #000;
				}
			}
			&.compress{
				.expansion{
					top: 0;
					height: 0;
					padding-top: 0;

					.tick, .outlier-value{
						opacity: 0;
					}
				}
				.compression{
					margin-top: -52px;
					background-position: center 50%;
					box-shadow: 0 -2px 5px rgba(0,0,0,0.4);

					.#{$DARK} & {
						box-shadow: 0 -2px 7px #000;
					}
				}
			}
		}

		&.mini{
			height: 70px;

			.expansion{
				height: 35px;

				.pill{
					.icon{
						background-image: url('img/outliers_day_expansion_mini.svg');

						.#{$DARK} & {
							background-image: url('img/outliers_night_expansion_mini.svg');
						}
					}
				}
			}
			.compression{
				margin-top:35px;
				.pill{
					top: 10px;

					.icon{
						background-image: url('img/outliers_day_compression_mini.svg');

						.#{$DARK} & {
							background-image: url('img/outliers_night_compression_mini.svg');
						}
					}
				}
			}
			.pill{
				height: 15px;
			}

			&.micro{
				.pill{
					height: 10px;
					background-color: transparent;
					border: none;
				}
			}

			&.compress{
				.expansion{
					height: 0;
					top: 35px;
				}
				.compression{
					height: 35px;
				}
			}

			&.low:not(.flipped), &.high.flipped{
				margin-top: 15px;
				.expansion{
					padding-top: 20px;
				}

				&.compress{
					.expansion{
						padding-top: 0;
					}
					.compression{
						margin-top: -37px;
					}
				}
			}
		}

		&.micro{
			height: 30px;
			.expansion{
				height: 15px;
			}
			.compression{
				margin-top:15px;
				background-image: none;
			}
			.pill{
				display: none;
			}

			&.compress{
				.expansion{
					height: 0;
					top: 15px;
				}
				.compression{
					height: 15px;
					.pill{
						display: block;
						top: 3px;
					}
				}
			}

			&.low:not(.flipped), &.high.flipped{
				margin-top: 30px;
				.expansion{
					padding-top: 5px;
				}

				&.compress{
					.expansion{
						padding-top: 0;
					}
					.compression{
						margin-top: -17px;
					}
				}
			}
		}

		&.nano{
			.expansion, .compression{
				display:none;
			}

			&.compress{
				.compression{
					display: block;
					margin-top: 13px;
				}
			}
		}
	}
}
