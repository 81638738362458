.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 24px;
  height: 100vh;
}

.logo {
  width: 100%;
  margin-top: 190px;
}

.description {
  margin-top: 24px;
}

.subDescription {
  margin-top: 16px;
}

.supportFooter {
  margin-top: auto;
  margin-bottom: 39px;
}

.contacts {
  margin-top: 12px;
}
