.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  &_size {
    &_s {
      margin: var(--gap-l) var(--gap-s);
    }

    &_xs {
      margin: var(--gap-l) var(--gap-xs);
    }
  }

  &_fullHeight {
    height: 100%;
    margin: auto;
  }
}

.iconBlock {
  margin: var(--gap-4xl) auto var(--gap-l);

  &_small {
    margin: 0 auto;
  }
}

.sheetTitle {
  text-align: center;
  color: var(--color-light-bg-quaternary-inverted);
}

.sheetText {
  text-align: center;
}