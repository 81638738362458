.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.newsImportantColor {
  color: var(--color-light-text-attention);
}

.attentionIcon {
  margin-top: -1px;
  margin-right: 2px;
}

.importantText {
  margin-left: 10px;
  font-size: 13px;
  color: var(--color-light-text-attention);
}

.newsItemHeader {
  display: flex;
  justify-content: flex-start;
  color: var(--color-light-graphic-tertiary-inverted);
}

.newsItemHeaderSimple {
  margin-bottom: 16px;
}

.newsItemHeaderImportant {
  margin-bottom: 10px;
}

.newsItemHeaderItem {
  font-size: 13px;
}

.newsItemHeaderItemSource {
  font-weight: bold;
}

.newsItemHeaderItemDate {
  margin-left: 10px;
}

.newsPageContent {
  margin: 16px;
  max-width: 480px;
}

.newsPageTitle {
  color: var(--color-light-graphic-quaternary-inverted);
  margin: 10px 0 24px;
}

.newsParagraph {
  color: var(--color-light-graphic-quaternary-inverted);
  white-space: pre-wrap;
}

.newsParagraph + .newsParagraph {
  margin-top: 16px;
}

.newsPageOtherNewsTitle {
  margin: 16px;
  margin-top: 32px;
  color: var(--color-light-graphic-secondary-inverted);
}
