.otherButton {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;
  padding: var(--gap-3xs);

  border-radius: var(--border-radius-xs);
}

.popover {
  padding: 0;
}

.typePopover {
  min-width: 112px !important;
}

.precisionPopover {
  min-width: 80px !important;
}

.dropDownItemContent {
  display: flex;
  gap: var(--gap-2xs);
}

.icon {
  /* stylelint-disable-next-line stylelint-core-vars/do-not-use-dark-colors */
  color: var(--color-dark-graphic-secondary);
}

.icon:hover {
  color: var(--color-light-graphic-primary);
}
