.multiply {
  display: flex;
  align-items: flex-start;
}

.tooltipTrigger {
  margin-left: 10px;
}

.tooltipContent {
  max-width: 215px;
}

.multiplyCancel {
  margin-top: var(--gap-m);
}
