.dateWrapper {
  position: sticky;
  z-index: 100;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--gap-s);
}

.date {
  padding: var(--gap-2xs) var(--gap-s);
  border-radius: 100px;
  transition: all 0.3s;

  .dateWrapperVisible > & {
    pointer-events: unset !important;
    opacity: 1 !important;
  }

  .dayTop[data-stuck] + .dateWrapper > & {
    pointer-events: none;
    cursor: pointer;
    opacity: 0;
    background-color: var(--color-light-bg-tertiary);
  }
}
