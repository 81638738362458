.container {
  padding: var(--gap-xs);
  display: flex;
  align-items: center;
  gap: var(--gap-xs);
  font-size: 13px;
  line-height: 16px;
  cursor: pointer;
}

.odd {
  background: var(--color-light-bg-secondary);
}

.text {
  flex-grow: 1;
}

.icon {
  color: var(--color-light-graphic-secondary);
}

.title {
  display: flex;
}

.tooltip {
  width: 16px;
  height: 16px;
  margin-right: var(--gap-xs);
}

.hint {
  font-size: 11px;
  line-height: 14px;
  color: var(--text-color-secondary);
}
