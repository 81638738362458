.sidebar {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  background-color: var(--layout-color-surface-0);
  transform: translate(0, 0);
  transition: transform 0.25s ease-in-out;
  visibility: hidden;
  overflow: hidden;
}

.sidebarOpen {
  transform: translate(-100%, 0);
  visibility: visible;
}

/* Фикс бага с размытием шрифта на кнопках внутри sidebar */
.sidebar [class*='button__component'] {
  will-change: initial;
}

.inner {
  height: 100%;
  overflow-y: auto;
  position: relative;
  padding: calc(var(--gap-l) + 56px + var(--gap-xl) + var(--gap-xs))
    var(--gap-m) var(--gap-m);
}

.head {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(
    100% - var(--gap-m)
  ); /* Отступ справа, чтобы было видно скроллбар */
  padding: var(--gap-l) var(--gap-m) calc(var(--gap-xl) + var(--gap-xs));
  background-color: var(--layout-color-surface-0);
}

.title {
  font-size: 16px;
  line-height: 24px;
  color: var(--color-light-text-primary);
  margin-bottom: var(--gap-l);
}

.back {
  color: var(--color-light-text-secondary);
  font-size: 13px;
  font-weight: 500;
}

.backCircle {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background-color: var(--button-secondary-base-bg-color);
  color: var(--color-light-graphic-tertiary-inverted);
  display: flex;
  justify-content: center;
  align-items: center;
}

.back:hover .backCircle {
  background-color: var(--button-secondary-hover-bg-color);
}

.body {
  font-size: 11px;
  line-height: 16px;
}
