.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 var(--gap-l);
}

.addIcon {
  color: var(--dark-graphic-tertiary-inverted);
}
