.notification {
  --icon-button-primary-base-color: var(--icon-button-secondary-base-color);
  --icon-button-primary-hover-color: var(--icon-button-secondary-hover-color);

  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  padding: var(--gap-s);
  box-shadow: var(--shadow-xl);
  margin-top: var(--gap-xs) !important;
}

.notification [class*='toast-plate__closeButtonWrapper'] {
  top: var(--gap-s);
  right: var(--gap-s);
  bottom: var(--gap-s);
}

.notification [class*='toast-plate__title'] {
  color: var(--color-light-bg-quaternary-inverted);
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
}

.notificationContent {
  display: flex;
  margin-right: var(--gap-s);
}

.notificationContentIconContainer {
  margin-right: var(--gap-s);
}

.notificationContentInstrumentIcon {
  min-width: 24px;
  min-height: 24px;
  width: 24px;
  height: 24px;
}

.orderBuySellIcon,
.orderBuySellIcon {
  background-color: var(--color-light-bg-tertiary);
  border-radius: 50%;
  padding: 1px;
  width: 18px;
  height: 18px;
  position: relative;
  top: -30px;
  right: -15px;
}

.notificationContentMain {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
}

.notificationContentHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--gap-3xs);
}

.notificationContentTitle {
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: var(--color-light-graphic-quaternary-inverted);
}

.notificationContentTime {
  position: absolute;
  top: var(--gap-s);
  right: var(--gap-s);
  background-color: var(--toast-plate-inverted-bg-color);
  z-index: 1;
  height: 50%;
}

.notification [class*='icon-button__iconWrapper'] {
  width: 24px;
  height: 24px;
}

.notification:hover .notificationContentTime {
  display: none;
}

.manager {
  top: var(--header-height);
  right: var(--gap-s);
}
