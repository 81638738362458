.volumeFilter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--gap-xs);
  gap: var(--gap-l);
  margin-bottom: var(--gap-m);
}

.volumeFilterAmount {
  width: 150px;
}

.volumeFilterRadio {
  margin-top: var(--gap-l);
}

.volumeFilterButtons {
  display: flex;
  justify-content: space-between;
  padding: var(--gap-xs) var(--gap-xs) 0;
}

.volumeFilterButtons button + button {
  margin-left: 8px;
}
