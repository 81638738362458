.container {
  --document-item-button-width: 18px;
  --document-item-button-height: 18px;
}

.container {
  display: flex;
  width: 100%;
}

.name {
  flex-grow: 1;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-xs);
}

.button {
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--document-item-button-width);
  height: var(--document-item-button-height);
  color: var(--dark-text-primary);
  transition: color 0.25s;
}

.buttonDelete {
  color: var(--dark-graphic-secondary);
}

.button:hover {
  color: var(--dark-graphic-tertiary-inverted);
}
