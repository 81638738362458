.buy {
  color: var(--color-buy);
}

.sell {
  color: var(--color-sell);
}

.numEDocumentCell {
  display: flex;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.headerWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 32px;
  padding: var(--gap-2xs) var(--gap-xs);
  background-color: var(--layout-color-surface-0);
}

.controls {
  align-items: center;
  display: flex;
  gap: var(--gap-xs);
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.settingsDropdown {
  width: 100%;
}

.settingsDropdown [class*='dropdownItem'] {
  margin: 4px 0;
  padding: 8px;
  height: 32px;
  flex: none;
}

.customSwitch {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.customSwitch span::before {
  background-color: var(--color-light-text-primary);
}

.customSwitch [class*='switch__label'] {
  font-size: 13px;
  line-height: 16px;
}

.customSwitch [class*='switch__switch'] {
  flex: none;
}

.cellDoubleRow {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.symbolCellDoubleRow {
  display: flex;
}

.symbolCellDoubleRowRightSide {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 9px;
}

.symbolCellSecondRow {
  color: var(--color-light-text-secondary);
}

.firstRowContent {
  min-height: 15.5px;
}

.secondRowWrapper {
  min-height: 15.5px;
}

.secondRowContent {
  margin: -4px -8px 0;
}
