.cellAccount {
  width: 19.3841%;
}
.cellType {
  width: 22.2826%;
}
.cellAmount {
  width: 20.8333%;
  text-align: right;
}
.cellPlace {
  width: 16.4855%;
  text-align: right;
}
.cellTime {
  width: 19.5652%;
  text-align: right;
}
