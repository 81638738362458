/*
 Не редактировать. Сгенерировано автоматически
*/

:root {
  --color-light-bg-primary: #ffffff;
  --color-light-bg-secondary: #f3f4f5;
  --color-light-bg-tertiary: #e9e9eb;
  --color-light-bg-quaternary: #dcdcdd;
  --color-light-bg-accent: #7938e0;
  --color-light-bg-info: #d6e8fc;
  --color-light-bg-info-muted: #e4f0ff;
  --color-light-bg-positive-muted: #e7f6ef;
  --color-light-bg-attention-muted: #f7ebd9;
  --color-light-bg-negative-muted: #fbe8e7;
  --color-light-bg-opaque: rgba(255, 255, 255, 0.8);
  --color-light-bg-overlay: rgba(0, 0, 0, 0.6);

  --color-light-bg-primary-inverted: #121212;
  --color-light-bg-secondary-inverted: #202022;
  --color-light-bg-tertiary-inverted: #2c2c2e;
  --color-light-bg-quaternary-inverted: #3a3a3c;

  --color-light-bg-primary-alpha-4: rgba(255, 255, 255, 0.04);
  --color-light-bg-primary-alpha-8: rgba(255, 255, 255, 0.08);
  --color-light-bg-primary-alpha-12: rgba(255, 255, 255, 0.12);
  --color-light-bg-primary-alpha-15: rgba(255, 255, 255, 0.15);
  --color-light-bg-primary-alpha-16: rgba(255, 255, 255, 0.16);
  --color-light-bg-primary-alpha-20: rgba(255, 255, 255, 0.2);
  --color-light-bg-primary-alpha-30: rgba(255, 255, 255, 0.3);
  --color-light-bg-primary-alpha-32: rgba(255, 255, 255, 0.32);
  --color-light-bg-primary-alpha-37: rgba(255, 255, 255, 0.37);
  --color-light-bg-primary-alpha-40: rgba(255, 255, 255, 0.4);
  --color-light-bg-primary-shade-7: rgb(237, 237, 237);
  --color-light-bg-primary-shade-15: rgb(217, 217, 217);
  --color-light-bg-secondary-alpha-30: rgba(243, 244, 245, 0.3);
  --color-light-bg-secondary-tint-15: rgb(245, 246, 246);
  --color-light-bg-secondary-tint-20: rgb(245, 246, 247);
  --color-light-bg-secondary-shade-7: rgb(226, 227, 228);
  --color-light-bg-secondary-shade-15: rgb(207, 207, 208);
  --color-light-bg-tertiary-shade-7: rgb(217, 217, 219);
  --color-light-bg-quaternary-alpha-30: rgba(220, 220, 221, 0.3);
  --color-light-bg-quaternary-shade-7: rgb(205, 205, 206);
  --color-light-bg-quaternary-shade-10: rgb(198, 198, 199);
  --color-light-bg-quaternary-shade-15: rgb(187, 187, 188);
  --color-light-bg-quaternary-shade-30: rgb(154, 154, 155);
  --color-light-bg-accent-alpha-40: rgba(121, 56, 224, 0.4);
  --color-light-bg-accent-alpha-30: rgba(121, 56, 224, 0.3);
  --color-light-bg-accent-tint-15: rgb(141, 86, 229);
  --color-light-bg-accent-tint-20: rgb(148, 96, 230);
  --color-light-bg-accent-shade-7: rgb(113, 52, 208);
  --color-light-bg-accent-shade-10: rgb(109, 50, 202);
  --color-light-bg-accent-shade-15: rgb(103, 48, 190);
  --color-light-bg-accent-shade-30: rgb(85, 39, 157);
  --color-light-bg-primary-inverted-alpha-3: rgba(18, 18, 18, 0.03);
  --color-light-bg-primary-inverted-alpha-4: rgba(18, 18, 18, 0.04);
  --color-light-bg-primary-inverted-alpha-5: rgba(18, 18, 18, 0.05);
  --color-light-bg-primary-inverted-alpha-7: rgba(18, 18, 18, 0.07);
  --color-light-bg-primary-inverted-alpha-8: rgba(18, 18, 18, 0.08);
  --color-light-bg-primary-inverted-alpha-10: rgba(18, 18, 18, 0.1);
  --color-light-bg-primary-inverted-alpha-12: rgba(18, 18, 18, 0.12);
  --color-light-bg-primary-inverted-alpha-15: rgba(18, 18, 18, 0.15);
  --color-light-bg-primary-inverted-alpha-16: rgba(18, 18, 18, 0.16);
  --color-light-bg-primary-inverted-alpha-20: rgba(18, 18, 18, 0.2);
  --color-light-bg-primary-inverted-alpha-24: rgba(18, 18, 18, 0.24);
  --color-light-bg-primary-inverted-alpha-30: rgba(18, 18, 18, 0.3);
  --color-light-bg-primary-inverted-alpha-32: rgba(18, 18, 18, 0.32);
  --color-light-bg-primary-inverted-alpha-40: rgba(18, 18, 18, 0.4);
  --color-light-bg-primary-inverted-alpha-50: rgba(18, 18, 18, 0.5);
  --color-light-bg-primary-inverted-tint-7: rgb(35, 35, 35);
  --color-light-bg-primary-inverted-tint-15: rgb(54, 54, 54);
  --color-light-bg-primary-inverted-tint-20: rgb(65, 65, 65);
  --color-light-bg-secondary-inverted-alpha-30: rgba(32, 32, 34, 0.3);
  --color-light-bg-secondary-inverted-tint-15: rgb(65, 65, 67);
  --color-light-bg-secondary-inverted-tint-20: rgb(77, 77, 78);
  --color-light-bg-secondary-inverted-shade-7: rgb(30, 30, 32);
  --color-light-bg-secondary-inverted-shade-15: rgb(27, 27, 29);
  --color-light-bg-quaternary-inverted-alpha-30: rgba(58, 58, 60, 0.3);
  --color-light-bg-quaternary-inverted-tint-15: rgb(88, 88, 89);
  --color-light-bg-quaternary-inverted-tint-20: rgb(97, 97, 99);
}
