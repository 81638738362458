.graphic {
  color: var(--color-light-graphic-tertiary-inverted);
}

.additionalOptionIcon {
  margin-right: var(--gap-2xs);
}

.sltpTooltipContent {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: stretch;
}

.sltpTooltipRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}