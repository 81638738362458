.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--widget-header-height);
  padding: var(--widget-header-padding);
  background-color: var(--widget-header-bg);
}

.inputs {
  display: flex;
  gap: var(--gap-xs);
}

.count {
  white-space: nowrap;
}

.buySell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.direction {
  width: 14px;
  height: 14px;
  margin-right: 7px;
  color: var(--color-light-graphic-secondary);
}

.directionEmpty {
  width: 23px;
}

.buy {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-buy);
}

.sell {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-sell);
}

.settingIcon {
  min-height: 18px;
  min-width: 18px;
}

.settingIcon:hover {
  cursor: pointer;
  color: var(--color-light-text-primary);
}

.timerPopover {
  width: 290px;
}

.popoverTitle {
  color: var(--color-light-graphic-secondary);
  padding: 4px 8px;
}

.dropdownItemBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.customInput {
  --form-control-s-min-height: 32px;
}

.customInputField > div:first-child > div > div:first-of-type {
  font-size: 13px;
  line-height: 16px;
}

.customInputField input {
  font-size: 13px;
  line-height: 16px;
}

.firstRow {
  margin-bottom: 8px;
}

.secondRow {
  display: flex;
  gap: 8px;
  flex-wrap: nowrap;
}

.amountInputWrapper {
  position: relative;
}

.submitButton {
  margin-left: 24px !important;
}

.highlightedCell {
  border: 2px solid var(--color-light-graphic-secondary);
  position: relative;
  z-index: 100;
}

.divider {
  border: 0;
  border-top: 1px solid var(--color-light-border-primary);
  margin: 0 0 var(--gap-l);
}
