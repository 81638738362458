.container {
  background-color: var(--color-light-bg-primary);
  width: 320px;
  padding: var(--gap-m);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--gap-2xs);
}

.closeIcon {
  color: #8d8d93;
  cursor: pointer;
  transition: var(--button-transition);
}

.closeIcon:hover {
  color: var(--color-light-text-primary);
}

.buttonToolbar {
  display: flex;
  gap: var(--gap-m);
}

.customButton span {
  font-size: 11px;
  line-height: 16px;
}

.customCancelButton {
  padding-left: var(--gap-s) !important;
  padding-right: var(--gap-m);
  border: 1px solid var(--color-dark-border-underline);
}

.orderTextButton {
  display: flex;
  align-items: center;
  margin: 0 0 var(--gap-l) 0;
  color: #c5c5c7;
}

.orderTextButton > span {
  border-bottom: 1px solid #c5c5c7;
  margin: 0 var(--gap-xs);
  cursor: pointer;
}

.orderTextButton > svg:last-child {
  display: none;
}

.orderInfoRow {
  display: flex;
  margin-top: 8px;
}

.orderInfoPreposition {
  width: 20px;
  display: inline-block;
}

.orderBuySellIcon {
  margin-top: 4px;
  margin-right: 2px;
}