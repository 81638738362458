.popover {
  width: 200px;
}

.icon {
  vertical-align: middle;
  margin-left: 1ch;
}

.list {
  margin: 0;
  padding: 0;
  padding-left: var(--gap-s);
  margin-left: var(--gap-2xs);
}

.title {
  margin-bottom: var(--gap-2xs);
}
