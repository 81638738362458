.container {
  --history-item-padding-vertical: 6px;
  --history-item-padding-horizontal: 8px;
  --history-item-font-size: 11px;
  --history-item-line-height: 14px;
  --history-item-status-width: 100px;
}

.container {
  padding: var(--history-item-padding);
  display: flex;
  align-items: stretch;
  gap: var(--gap-xs);
  font-size: var(--history-item-font-size);
  line-height: var(--history-item-line-height);
  cursor: pointer;
}

.odd {
  background: var(--color-light-bg-secondary);
}

.content {
  flex-grow: 1;
  padding: var(--history-item-padding-vertical) 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.description {
  padding: 0 var(--history-item-padding-horizontal);
}

.subtitle {
  color: var(--color-light-text-secondary);
}

.status {
  flex-shrink: 0;
  width: var(--history-item-status-width);
  padding: var(--history-item-padding-vertical)
    var(--history-item-padding-horizontal) var(--history-item-padding-vertical)
    0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-xs);
  color: var(--text-color-secondary);
}

.statusGlyph {
  color: var(--color-light-graphic-secondary);
}
