.listPickerWrapper .listPicker {
  border-radius: 24px;
}

.listPicker {
  --size-xxs-height: 24px;
}

.listPicker span {
  font-size: 11px;
  line-height: 16px;
  white-space: nowrap;
}

.chevron {
  transition: color 0.2s ease, border 0.2s ease, color 0.2s ease,
    transform 0.12s ease;
}
