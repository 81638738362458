.tooltipTarget {
  min-width: 100%;
}

.wrapper,
.tooltipWrapper {
  overflow: hidden;
  display: flex;
  flex: 1;
  flex-direction: column;

  padding: var(--gap-2xs) var(--gap-s);

  text-overflow: ellipsis;

  background-color: var(--layout-color-surface-0);
  border-top: 2px solid var(--layout-color-background-1);
}

.wrapper:not(.row) > :nth-child(even) {
  margin-top: var(--gap-xs);
}

.tooltipContent {
  overflow: hidden;

  max-width: 290px;
  margin: var(--gap-m-neg);
  padding-top: var(--gap-xs);

  background-color: unset;
}

.positionWrapper {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  text-overflow: ellipsis;
}

.row {
  flex-direction: row;
  gap: var(--gap-xs);
}

.tooltipWrapper {
  flex-direction: row;
  align-items: center;
}

.icon {
  min-width: 18px;
  min-height: 18px;
  margin-right: var(--gap-s);
  margin-left: var(--gap-2xs);

  color: var(--color-light-graphic-secondary);
}

.tooltipWrapper:hover .icon {
  color: var(--dark-text-primary);
}

.tooltipWrapper .icon:last-of-type {
  margin-right: unset;
}

.amount {
  color: var(--color-light-text-primary);
}

.growText {
  flex: 1;
}
