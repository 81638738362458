.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: var(--gap-l);
  justify-content: center;
  flex: 1;
}

.title {
  text-align: center;
  color: var(--color-light-graphic-tertiary-inverted)
}

.description {
  text-align: center;
}