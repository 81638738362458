.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.title {
  color: var(--color-light-graphic-tertiary-inverted);
}

.table {
  margin-left: var(--gap-xs-neg);
}