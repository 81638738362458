.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 360px;
  text-align: center;
}

.title {
  font-size: 18px;
  color: var(--dark-text-primary);
}

.subTitle {
  margin: 24px 0;
  font-size: 14px;
}

.phoneRow {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.phoneRow + .phoneRow {
  margin-top: 8px;
}

.phone {
  font-weight: 700;
  color: var(--dark-text-primary);
}

.button {
  margin-top: 8px;
  font-size: 14px;
}
