@import url('@alfalab/core-components/vars/typography.css');

.toolipContent {
  max-width: 360px;
}

.target {
  display: flex;
  align-items: center;
  height: 100%;
}

.title {
  @mixin paragraph_primary_small;

  margin-bottom: var(--gap-m);
}

.content {
  display: flex;
  align-items: center;
}
