.modalWrapperClassName {
  max-width: 440px;
  margin: 0 auto;
}

.contentClassName {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: var(--gap-l) var(--gap-3xl);

  background: var(--color-light-bg-secondary);
  border-radius: var(--border-radius-m);
  box-shadow: var(--shadow-xl);
}

.baseModalClassName {
  background: none;
}

.iconWrapper {
  margin-bottom: var(--gap-2xl);
}

.title {
  margin-bottom: var(--gap-s);
  color: var(--dark-text-tertiary-inverted);
  text-align: center;
}

.subTitle {
  color: var(--color-light-graphic-secondary);
  text-align: center;
}

.notIsAllowIssueCertificate {
  margin: var(--gap-m);
  color: var(--color-light-graphic-secondary);
  text-align: center;
}

.buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: var(--gap-2xl);
}

.buttons * + * {
  margin-top: var(--gap-m);
}
