.container {
  display: flex;
  align-items: start;
  justify-content: space-between;

  padding: var(--widget-header-padding);

  background-color: var(--widget-header-bg);
}

.withoutMultiline {
  height: var(--widget-header-height);
}

.left {
  display: flex;
  align-items: center;
}

.inputs {
  display: flex;
  gap: var(--gap-xs);
  align-items: center;
}

.wraps {
  flex-wrap: wrap;
}

.filters {
  margin-left: var(--gap-m);
}

.controls {
  display: flex;
  gap: var(--gap-xs);
  align-items: center;
}
