.divider {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 32px;
  margin-top: 36px;
  margin-bottom: var(--gap-m);

  background-color: var(--dark-bg-secondary);
}

.list {
  position: relative;

  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  width: 100%;
  height: 100%;
  padding: var(--gap-s) 0;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.topBlock {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 300px;
}

.spinnerContainer {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}

.scrollButton {
  cursor: pointer;

  position: absolute;
  z-index: 101;
  right: 12px;
  bottom: 16px;

  width: 40px;
  height: 40px;
  padding: 10px;

  background-color: var(--dark-bg-tertiary);
  border-radius: 100%;
}
