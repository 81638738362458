.wrapper {
  position: relative;

  display: flex;
  flex-direction: row;
  gap: var(--gap-xs);

  box-sizing: content-box;
  max-height: 48px;
  padding: var(--gap-m) 0;
}

.wrapper.mobileVersion {
  padding: 0;
}

.bestPriceBox {
  cursor: pointer;

  display: flex;
  flex: 1;
  flex-direction: column;

  padding: var(--gap-xs);

  color: var(--color-light-text-secondary);

  border: 1px solid var(--color-light-border-primary);
  border-radius: var(--border-radius-s);
}

.askBox {
  align-items: flex-end;
}

.bestPrice {
  color: var(--color-light-graphic-tertiary-inverted);
}

.bidPrice {
  color: var(--color-buy);
}

.askPrice {
  color: var(--color-sell);
}

.spreadPrice {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);

  padding: var(--gap-2xs) var(--gap-m);

  color: var(--color-light-graphic-tertiary-inverted);

  background-color: var(--layout-color-background-2);
  border: 1px solid var(--color-light-border-primary);
  border-radius: 100px;
}
