.data {
  display: flex;
  flex-wrap: wrap;
}

.field {
  width: calc(50% - var(--gap-xs));
  margin: 0 var(--gap-xs) var(--gap-xl) 0;
}

.fieldFull {
  width: 100%;
}

.divider {
  width: 100%;
  height: var(--gap-m);
}

.qrSize {
  width: 116px;
  height: 116px;
}

.qrImage {
  border-radius: var(--border-radius-m);
  overflow: hidden;
  cursor: pointer;
}

.spinnerWrapper {
  display: grid;
  place-content: center;
}

.emailButton {
  margin-top: var(--gap-2xl)
}
