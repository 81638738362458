.status {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: var(--gap-xs);
}

.glyph {
  color: var(--dark-graphic-secondary-inverted);
}
