.loginForm {
  display: grid;
  gap: var(--gap-m);
  width: 320px;
  margin-bottom: var(--gap-4xl);
}

.submitButton {
  margin-top: 8px;
}

.errorLabel {
  text-align: center;
  margin-bottom: 8px;
}

.successWrapper {
  width: 650px;
  height: 450px;
}

.successContent {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.successTitle {
  margin-top: var(--gap-xl);
  margin-bottom: var(--gap-2xl);
  text-align: center;
}

.successButton {
  width: 320px;
}

.loginString {
  color: var(--color-light-text-secondary);
}
