.layoutTab {
  position: relative;

  display: flex;
  flex-shrink: 0;
  gap: var(--gap-xs);
  align-items: center;

  max-width: 200px;
  height: 24px;
  margin: 0 var(--gap-3xs);
  padding: var(--gap-xs) var(--gap-xs) var(--gap-xs) var(--gap-s);

  color: var(--color-light-graphic-tertiary-inverted);

  border-radius: var(--border-radius-s);
}

.layoutTab:hover {
  cursor: pointer;
  color: var(--color-light-bg-quaternary-inverted);
  background-color: var(--form-control-hover-bg-color);
}

.noRightAddonsTab {
  padding-right: var(--gap-s);
}

.iconTab {
  padding: 0;
}

.layoutName {
  overflow: hidden;
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.layoutNameInput {
  all: unset;
  box-sizing: border-box;
  width: 100%;
}

.selectedLayoutTab {
  z-index: 2;
  color: var(--button-primary-color);
  background-color: var(--button-primary-base-bg-color);
}

.selectedLayoutTab:not(:first-of-type) {
  margin-left: 10px;
}

.selectedLayoutTab:not(:last-of-type) {
  margin-right: 10px;
}

.layoutTab:not(.selectedLayoutTab) .closeButton {
  opacity: 0;
}

.layoutTab:hover .closeButton {
  opacity: 1;
}
