.topUpButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-xs);
}

.topUpButtonContainer .topUpButton {
  width: 50%;
  min-width: 184px;
  max-width: 306px;
  color: var(--button-primary-color) !important;
  background-color: var(--button-primary-base-bg-color) !important;
}

.text {
  color: var(--color-light-graphic-quaternary-inverted);
  text-align: center;
}