/*
 Переопределения стилей компонента ToastPlate
*/

:root {
  --toast-plate-action-divider-display: none;
  --toast-plate-close-button-align: flex-start;

  --toast-plate-inverted-color: var(--color-light-text-secondary);
  --toast-plate-inverted-bg-color: var(--layout-color-surface-1);
}
