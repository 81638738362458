.container {
  --send-email-email-font-size: 13px;
  --send-email-hint-font-size: 11px;
}

.sizeXxs {
  --send-email-email-font-size: 11px;
}

.hintMobile {
  display: inline-block;
  text-align: center;
  margin: 16px 0;
}
