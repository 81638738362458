.container {
  padding: 61px 47px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--color-light-bg-secondary);
  z-index: 2000;
}

.containerRounded {
  border-radius: 8px;
}

.backdrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background: var(--backdrop-visible-background);
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
}
