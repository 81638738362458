.container {
  --transfer-result-submit-max-width: 286px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.submit {
  width: var(--transfer-result-submit-max-width);
  margin-top: var(--gap-m);
}
