.container {
  display: flex;
  align-items: stretch;
  cursor: pointer;
  gap: var(--gap-xs);
  font-size: 11px;
  line-height: 14px;
}

.odd {
  background: var(--color-light-bg-secondary);
}

.icon {
  flex-shrink: 0;
  padding: var(--gap-s) 0 var(--gap-s) var(--gap-xs);
  display: flex;
  align-items: center;
  justify-content: center;
}

.glyph {
  color: var(--color-light-graphic-secondary);
}

.content {
  flex-grow: 1;
  padding: var(--gap-s) 0;
}

.status {
  flex-shrink: 0;
  width: 100px;
  padding: var(--gap-s) var(--gap-xs) var(--gap-s) 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-xs);
}
