.message {
  padding: var(--gap-3xs) 0;
}

.message.withHint {
  margin-bottom: var(--gap-l);
}

.messageBubbleContainer {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  padding-right: var(--gap-m);
  padding-left: 60px;
}

.myMessage .messageBubbleContainer {
  justify-content: end;
}

.elseMessage .messageBubbleContainer {
  justify-content: start;
}

.messageBubble {
  position: relative;

  overflow: hidden;

  max-width: 232px;
  padding: 10px var(--gap-m);

  text-overflow: ellipsis;
}

.myMessage .messageBubble {
  background-color: var(--dark-bg-secondary);
  border: 2px solid var(--dark-bg-secondary);
  border-radius: var(--border-radius-xxl) var(--border-radius-m)
    var(--border-radius-m) var(--border-radius-xxl);
}

.elseMessage .messageBubble {
  border: 2px solid var(--color-light-border-secondary);
  border-radius: var(--border-radius-m) var(--border-radius-xxl)
    var(--border-radius-xxl) var(--border-radius-m);
}

.myMessage:first-child .messageBubble {
  border-top-right-radius: var(--border-radius-xxl);
}

.myMessage:last-child .messageBubble {
  border-bottom-right-radius: var(--border-radius-xxl);
}

.elseMessage:first-child .messageBubble {
  border-top-left-radius: var(--border-radius-xxl);
}

.elseMessage:last-child .messageBubble {
  border-bottom-left-radius: var(--border-radius-xxl);
}

.time {
  position: absolute;
  right: 16px;
  bottom: 8px;
}

.timeFix {
  display: inline-block;
  width: 40px;
}

.hint {
  position: absolute;
  right: 34px;
  bottom: -18px;
  color: var(--color-light-text-accent);
}

.text {
  white-space: pre-wrap;
}
