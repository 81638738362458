/*
 Переопределения стилей компонента IconButton
*/

:root {
  --icon-button-secondary-hover-color: var(--color-light-graphic-primary);
  --icon-button-xs-size: 24px;
  --icon-button-xxs-size: 16px;
}

[class*='icon-button__xxs_'] {
  svg {
    width: 16px;
    height: 16px;
  }
}

[class*='icon-button__xs_'] {
  svg {
    width: 18px;
    height: 18px;
  }
}
