.root {
  position: relative;
  display: grid;
  justify-content: center;
  row-gap: var(--gap-4xl);
}

.description {
  text-align: center;
  color: var(--color-light-text-secondary);
  margin-bottom: var(--gap-m);
}

.codeInput > div {
  display: flex;
  justify-content: center;
}
