.title {
  margin-bottom: var(--gap-l);
}

.sectionTitle {
  display: block;
  margin-bottom: var(--gap-2xs);
}

.sectionText {
  display: block;
}

.section {
  margin-bottom: var(--gap-s);
}
