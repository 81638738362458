.wrapper {
  display: flex;
  align-items: center;
}

.filters {
  --tag-background-color-hover: #636364;
  --tag-background-color: #3a3a3c;

  display: flex;
  flex: 1;
  gap: var(--gap-xs);
  white-space: nowrap;
}

.filterIcon {
  color: var(--text-color-secondary);
}

.filterIcon:hover {
  cursor: pointer;
  color: #ffffff;
}

.orderStatusFilters {
  border-radius: 100%;
}

.accountsSelectButton {
  display: flex;
  padding: var(--gap-2xs) var(--gap-xs);
  background-color: var(--form-control-bg-color);
  width: 140px;
  height: 24px;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  align-items: center;
  color: #c5c5c7;
  margin-right: var(--gap-m);
}

.accountsSelectButton:hover {
  color: white;
}

.accountsSelectButtonSmall {
  width: unset;
}

.accountsSelectButtonSmall .accountsSelectButtonIcon {
  margin-right: var(--gap-xs);
}

.accountName {
  margin-left: var(--gap-xs);
}

.select {
  background-color: var(--form-control-bg-color);
  width: 240px;
  padding-top: 0;
}

.selectOrderBook {
  width: 180px;
}

.selectAllButton {
  cursor: pointer;
  border-top: 1px solid var(--divider-color);
  padding: 12px 12px 12px 8px;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selectAllButtonText {
  height: 18px;
}

.searchInputField,
.searchInputField:hover {
  background-color: var(--dark-bg-secondary) !important;
  min-height: unset !important;
  padding-top: 15px;
  padding-bottom: 15px;
}

.searchInput,
.searchInput::placeholder,
.searchInput:focus::placeholder {
  background-color: var(--dark-bg-secondary);
  font-size: 13px;
  line-height: 16px;
}

.searchIcon,
.searchIcon:hover {
  background-color: var(--dark-bg-secondary);
  color: #8d8d93;
}

.searchClearIcon {
  background-color: var(--dark-bg-secondary);
  color: #8d8d93;
}

.accountOption {
  display: flex;
  justify-content: space-between;
  color: var(--button-secondary-color);
  font-weight: bold;
  font-size: 11px;
  line-height: 16px;
}

.accountOptionOrderBook {
  cursor: default;
}

.option {
  font-size: 13px;
  line-height: 16px;
  display: flex;
  position: relative;
  align-items: center;
  gap: var(--gap-2xs);
}

.optionName {
  margin-left: 8.5px;
}

.orderStatusFiltersButton {
  display: flex;
  background-color: #3a3a3c;
  height: 24px;
  box-sizing: border-box;
  align-items: center;
  border-radius: 100px;
  cursor: pointer;
  padding: var(--gap-2xs) var(--gap-s);
  justify-content: space-between;
  font-size: 11px;
  line-height: 16px;
  transition: background-color 0.2s ease, border 0.2s ease, color 0.2s ease;
}

.orderStatusFiltersButton:hover {
  background-color: var(--tag-background-color-hover);
}

.orderStatusFiltersButton svg {
  margin-left: var(--gap-2xs);
}

.chevronUp {
  transform: rotate(-0.5turn);
}

.statusTag {
  padding: 0 var(--gap-s);
  --size-xs-height: 24px;
}

.statusTag span {
  display: inline-flex;
}

.activeFilterButton {
  background-color: white;
  color: black;
}

.activeFilterButton:hover {
  background-color: var(--tag-background-color-checked-hover);
}

.orderStatusFiltersSelect {
  padding: var(--gap-2xs) var(--gap-xs);
  background: var(--form-control-bg-color);
  width: 134px !important;
  min-width: 134px !important;
  font-size: 11px !important;
  line-height: 16px !important;
}

.positionTypeSelectPoppover {
  padding: 0;
  min-width: 140px;
  background: var(--form-control-bg-color);
}

.positionTypeSelectPoppover [class*='Dropdown_dropdownItem']:hover {
  background: var(--tag-background-color-hover);
}

.PUModeSelectPoppover {
  min-width: 215px;
}

button.orderStatusFiltersFilterInSelect {
  margin: 4px 0;
  display: flex;
  justify-content: flex-start;
  background: var(--tag-background-color-hover);
  font-size: 11px !important;
  line-height: 16px !important;
  min-width: 95px !important;
  width: 95px !important;
  height: 24px !important;
  min-height: 24px;
  padding: var(--gap-2xs) var(--gap-m);
  white-space: nowrap;
  text-overflow: ellipsis;
}

button.orderStatusFilterChecked {
  background-color: white;
  color: black;
}

.count {
  color: var(--text-color-secondary);
}

.customInput {
  --form-control-s-min-height: 32px;
}

.customInputField > div:first-child > div > div:first-of-type {
  font-size: 13px;
  line-height: 16px;
}

.customInputField input {
  font-size: 13px;
  line-height: 16px;
}

.customInput svg {
  height: 18px;
  width: 18px;
}

.customDatePicker {
  --form-control-s-min-height: 32px;
  height: var(--form-control-s-min-height);
}

.customDatePicker > span {
  --form-control-s-min-height: 32px;
  height: var(--form-control-s-min-height);
}

.calendarPopover {
  --calendar-today-color: var(--button-primary-base-bg-color);
  --color-light-bg-tertiary: var(--select-option-hover-background);
  --color-light-bg-tertiary-shade-7: var(--select-option-hover-background);
  --calendar-highlighted-background: var(--select-option-hover-background);
  --calendar-range-background: rgba(235, 235, 245, 0.3);
  --calendar-range-complete-background: rgba(235, 235, 245, 0.3);
  --calendar-select-icon-background: center/contain no-repeat
  url(@terminal/common/icons/chevron-down_m.svg);
  --calendar-arrow-background: center/contain no-repeat
  url(@terminal/common/icons/chevron-back_m.svg);
  --calendar-day-border-radius: 100%;
}

.optionWithRequirements {
  margin-left: var(--gap-xl);
}

.requirementIcon {
  position: absolute;
  margin-left: var(--gap-xl-neg);
}

.chevron {
  margin-left: auto;
  transition: color 0.2s ease, border 0.2s ease, color 0.2s ease,
    transform 0.12s ease;
}

.accountSelectedIcon {
  margin-left: auto;
}

.PUFiltersButton {
  margin-left: var(--gap-m);
}

.PUFiltersButtonSmall {
  margin-left: var(--gap-xs);
}

.calendarRange {
  --form-control-s-min-height: 24px;

  align-items: center;
}
