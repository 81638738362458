.root {
  color: var(--color-light-bg-primary-inverted, #ffffff);
  display: inline-block;
  height: 1.5em;
  width: 1.5em;
  animation: rotation 1s infinite linear;
  border: 3px solid currentColor;
  border-left-color: transparent;
  border-radius: 50%;
}

@keyframes rotation {
  from {
    transform: rotate(45deg);
  }
  to {
    transform: rotate(404deg);
  }
}
