.title {
  margin: 0 0 var(--gap-2xl);
}

.properties {
  display: flex;
  flex-wrap: wrap;
}

.prop {
  width: calc(50% - var(--gap-s));
  margin: 0 var(--gap-s) var(--gap-3xl) 0;
}

.submit {
  max-width: 233px;
}

.done {
  width: 220px;
  margin-top: var(--gap-xl);
}