.container {
  min-height: 520px;
}

.stage {
  background: var(--color-light-bg-secondary);
  border-radius: var(--border-radius-m);
  overflow: hidden;
}

.image {
  display: block;
  max-width: 100%;
}

.listItem {
  display: flex;
  align-items: center;
}

.listNumber {
  display: inline-block;
  min-width: 16px;
  margin-right: var(--gap-xs);
}