.root {
  min-width: 0;

  &_span {
    grid-column-start: span 2;
  }

  &_justify {
    &_start {
      justify-self: start;
    }

    &_end {
      justify-self: end;
    }

    &_center {
      justify-self: center;
    }
  }
}
