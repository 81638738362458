.confirmText {
  display: contents;
}

.confirmTextTitle {
  color: var(--color-light-text-secondary);
  margin-left: var(--gap-2xs);
}

.closeParams {
  color: var(--color-light-text-primary);
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
}

.type {
  color: var(--color-light-text-secondary);
}

.closeActionButtons {
  display: flex;
  gap: var(--gap-xs);
  padding: var(--gap-xs) 0;

  position: sticky;
  bottom: 0;
  background-color: inherit;
}

.instructions {
  display: contents;
}

.instructions > svg {
  margin-right: var(--gap-2xs);
}

.customSwitch {
  margin-top: 10px;
  margin-bottom: 10px;
}

.orderTextButton {
  display: flex;
  align-items: center;
  margin: 0 0 var(--gap-xs) 0;
  color: #c5c5c7;
}

.orderTextButton > span {
  border-bottom: 1px solid #c5c5c7;
  margin: 0 var(--gap-xs);
  cursor: pointer;
}

.orderTextButton.mobileVersion > span {
  flex: 1;
  margin: 0 var(--gap-m);
  text-decoration: none;
}

.orderTextButton > svg:last-child {
  display: none;
}

.orderTextButton.mobileVersion > svg {
  display: block;
}

.orderTextTitle {
  display: block;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.32px;

  text-align: center;
  margin-left: -48px;

  color: #ffffff;
}

.orderTextSheet {
  height: calc(var(--vh, 1vh) * 100) !important;
  max-height: calc(var(--vh, 1vh) * 100) !important;
  border-radius: 0;
  background-color: var(--dark-bg-primary);
}

.orderTextSheetContent {
  height: calc(100% - 40px - var(--gap-m));
  position: relative;
  background-color: var(--dark-bg-primary);
  color: #c5c5c7;
  overflow: auto;
}

.orderTextSheetHeader {
  background-color: var(--dark-bg-primary);
  padding: 0;
}

.orderTextSheetFooter {
  padding: var(--gap-s) var(--gap-l);
  background-color: var(--dark-bg-primary);
  padding-bottom: max(env(safe-area-inset-bottom), var(--gap-s));
}

.orderTextSheetSubmitButton {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.orderText {
  display: flex;
  flex: 1;
  white-space: pre-line;
  padding-bottom: var(--gap-m);
}

@media all and (display-mode: standalone) {
  .orderTextSheet {
    height: calc(
      var(--vh, 1vh) * 100 - env(safe-area-inset-bottom) - 14px
    ) !important;
    max-height: calc(
      var(--vh, 1vh) * 100 - env(safe-area-inset-bottom) - 14px
    ) !important;
    border-radius: 0;
    background-color: var(--dark-bg-primary);
  }
}

.orderTextErrorWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: rgba(235, 235, 245, 0.6);
}

.orderTextErrorIcon {
  margin-bottom: var(--gap-m);
}

.orderTextErrorTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #c5c5c7;
  margin-bottom: var(--gap-2xs);
}

.orderTextSectionTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.32px;
  color: #ffffff;
  padding-bottom: var(--gap-2xs);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--gap-2xs);
}

.closeIcon {
  color: #8d8d93;
  cursor: pointer;
  transition: var(--button-transition);
}

.closeIcon:hover {
  color: var(--dark-text-primary);
}

.sum {
  margin-top: var(--gap-xs);
}

.desktopModalContent {
  padding: var(--gap-m);
  background-color: var(--dark-bg-secondary);
}

.desktopModal {
  max-height: 90%;
  max-width: 520px;
  overflow: auto;
  background-color: var(--dark-bg-secondary);
}
