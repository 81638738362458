.searchIcon {
  display: inline-block;
  width: 16px;
  height: 16px;
  color: var(--color-light-graphic-secondary);
  cursor: pointer;
}

.searchInput {
  margin: 0 0 var(--gap-xs) 0!important;

  --form-control-paddings: 0 var(--gap-xs);
  --form-control-s-border-radius: 0;
}

.issuerLogo div,
.issuerLogo svg {
  width: 16px!important;
  height: 16px!important;
  line-height: 0;
}

.popover {
  display: block;
  max-width: 541px!important;
  width: 100%;
}

.optionsList div[class*="select__scrollable"] {
  width: 541px!important;
  min-width: unset!important;
}
