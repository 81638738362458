@charset "UTF-8";
/* CSS Document */

.chartContainer { /* DIV that the canvas expands to. */
	display: block;
	font-family: Roboto, Helvetica, sans-serif;
	font-size: 12px;
	position:relative; /* chart container must be relative for internal DOM elements to be correct */
}

.sharing .ciq-no-share {
	display: none !important;
}

.stx-canvas-shim {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
}

/* --------------------------------------------------------- IE NOTICE ------------------------------------------------------- */

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.chartContainer::before,
	cq-instant-chart::before{
		content: 'Internet Explorer is no longer supported.';
		display:block;
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 9999;
		background: transparent;
		padding-top: 120px;
		text-align: center;
		font-size:30px;
		font-weight: bold;
	}

	.chartContainer::after,
	cq-instant-chart::after{
		content: 'Internet Explorer does not provide the enhanced performance and features necessary to use this library. For the best experience, please use Microsoft\'s Chromium-based Edge or any other modern browser.';
		display:block;
		position: absolute;
		top:90px;
		left: 50%;
		width: 600px;
		height: 40px;
		z-index: 10000;
		background: transparent;
		border: solid 1px #f00;
		padding: 90px 50px;
		text-align: left;
		font-size:14px;
		font-weight: normal;
		line-height: 20px;
		transform: translateX(-50%);
	}
}

/* --------------------------------------------------------- BUTTONS --------------------------------------------------------- */

/* Basic Buttons */

.stx-btn { /* Inactive Button */
	display:inline-block;
	cursor: pointer;
	padding: 0px 8px;
	border: solid 1px #ddd;
	font-weight: bold;
	line-height: 27px;
	color: #7e7e7e;
	text-align: center;
	overflow: visible;
	background-repeat: no-repeat;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	-webkit-transition: color .25s, border .25s, box-shadow .25s;
	-moz-transition: color .25s, border .25s, box-shadow .25s;
	transition: color .25s, border .25s, box-shadow .25s;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
/* Button Hover State */
/*.stx-btn:hover {
	color: #444444;
	border: solid 1px #c5c5c5;
	-webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, .1), inset 0px -8px 6px rgba(100, 100, 100, .03);
	-moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, .1), inset 0px -8px 6px rgba(100, 100, 100, .03);
	box-shadow: 0px 1px 1px rgba(0, 0, 0, .1), inset 0px -8px 6px rgba(100, 100, 100, .03);
}*/
/* Button Active State */
.stx-btn:active {
	color: #333;
	-webkit-box-shadow: inset 0px 8px 6px rgba(100, 100, 100, .03);
	-moz-box-shadow: inset 0px 8px 6px rgba(100, 100, 100, .03);
	box-shadow: inset 0px 8px 6px rgba(100, 100, 100, .03);
}


/* Panel Buttons */

.stx-btn-panel,
.stx-btn-panel span {/* Button Defaults */
	display:inline-block;
	width:25px;
	height:25px;
	cursor:pointer;
   -webkit-transition: -webkit-transform .2s;
   transition: transform .2s;
}
.stx-btn-panel {/* Button Spacing */
	margin:3px 2px;
	}
/*.stx-btn-panel:hover span,*/
.stx-btn-panel:active span {/* Make buttons full opacity on hover or hit */
	opacity:1;
	}
.stx-btn-panel:active {/* Scale up when active */
	-webkit-transform: scale(1.2, 1.2);
	-ms-transform: scale(1.2, 1.2);
	transform: scale(1.2, 1.2);
}
.stx-btn-panel span {/* Button Icons */
	background-image:url(img/stx-sprite-panels.svg);
	opacity:.6;
   -webkit-transition: opacity .2s;
   -moz-transition: opacity .2s;
   transition: opacity .2s;
}
.no-svg .stx-btn-panel span {/* Button Icons for non SVG browsers */
	background-image:url(img/stx-sprite-panels.png);
}
.stx-btn-panel > span.stx-ico-up {background-position: -150px -25px;}
.stx-btn-panel > span.stx-ico-down {background-position: -200px -25px;}
.stx-btn-panel > span.stx-ico-close {background-position: -250px -25px;}
.stx-btn-panel > span.stx-ico-edit {background-position: -300px -25px;}
.stx-btn-panel > span.stx-ico-focus {background-position: -350px -25px;}



/* --------------------------------------------------------- CHART CONTROLS --------------------------------------------------------- */

.stx_chart_controls { /* Chart Control module */
	position: absolute;
	display: block;
	bottom: 60px;
	left: 50%;
	height: 25px;
	margin-bottom: 0;
	width: auto;
	text-align: center;
	z-index: 30;
	transform: translateX(calc(-50% - 0.5px)); /* half pixel to make sure tooltip border doesn't get blurred */
}

.chartSize, .chartSize span { /* Chart Control element defaults */
	height: 25px;
	display: inline-block;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.chartSize { /* Chart size container */
	margin: 0px auto;
	position:relative;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	background: rgba(250, 250, 250, 0.8); /* converted to rbg from #fafafa */
	-webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, .2);
	-moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, .2);
	box-shadow: 0px 1px 1px rgba(0, 0, 0, .2);

	-webkit-transform-origin: bottom center;
	-moz-transform-origin: bottom center;
	-ms-transform-origin: bottom center;
	transform-origin: bottom center;
	-webkit-transform: scale(1.15);
	-moz-transform: scale(1.15);
	-ms-transform: scale(1.15);
	transform: scale(1.15);
	-webkit-transition: transform 350ms cubic-bezier(0.770, 0.000, 0.175, 1.000), opacity 350ms linear;
	-moz-transition: transform 350ms cubic-bezier(0.770, 0.000, 0.175, 1.000), opacity 350ms linear;
	-ms-transition: transform 350ms cubic-bezier(0.770, 0.000, 0.175, 1.000), opacity 350ms linear;
	transition: transform 350ms cubic-bezier(0.770, 0.000, 0.175, 1.000), opacity 350ms linear;
}

.chartSize span:after {/* Divider between zoom buttons */
	content: "";
	display: block;
	position: relative;
	top: 10%;
	width: 1px;
	height: 80%;
	float: right;
	background: rgba(0,0,0,.2);
}
.chartSize span {/* Chart size (+) and (-) buttons */
	background-image:url(img/stx-sprite-panels.svg);
	width: 35px;
	cursor: pointer;
	transition: opacity .25s, transform .2s;
	-webkit-transition: opacity .25s, -webkit-transform .2s;
	opacity: .6;
}
.no-svg .chartSize span {/*Chart size (+) and (-) buttons for non SVG browsers */
	background-image:url(img/stx-sprite-panels.png);
}
.chartSize .stx-zoom-out {/* Zoom out */
	background-position: -45px -25px;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}
.chartSize .stx-zoom-in {/* Zoom in */
	background-position: -95px -25px;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}
.chartSize .stx-chart-control-button{
	position:relative; /* set relative to position tooltip */
	background:#ccc center no-repeat;
	background-size:contain;
	height: 25px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	transition: none;
	-webkit-transition: none;
}
.chartSize .stx-chart-control-button.active{
	border-bottom: solid 3px #398dff;
	height: 22px;
	margin-bottom: 0;
}
.chartSize span.stx-chart-control-button:active{/* override the scale set on :active because it has side effects on the .active border*/
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}

/* SmartZoom button icon */
.chartSize .stx-smart-zoom{
	background: url(img/smart-zoom.svg) center no-repeat;
	background-size: contain;
}

/* Full Screen button icon */
.chartSize .stx-full-screen{
	background:url(img/full-screen.svg) center no-repeat;
	background-size: 19px;
	background-position: 50% calc(50% + 1px);
}

/*.chartSize span:hover,*/
.chartSize span:active,
/*.stx_jump_today:hover,*/
.stx_jump_today:active
  {/* Make buttons full opacity on hover or hit */
	opacity: 1;
	}
.chartSize span:active {/* Scale up when active */
	-webkit-transform: scale(1.2, 1.2);
	-ms-transform: scale(1.2, 1.2);
	transform: scale(1.2, 1.2);
}

html:not([ciq-last-interaction="touch"]) .chartSize span:hover{
	opacity: 1;
}

/* Chart control tool-tips */
.stx_chart_controls .stx-tooltip{
	position: absolute;
	display: block;
	opacity: 0;
	top: 50%;
	left:50%;
	width: auto;
	height: auto;
	padding: 3px;
	min-width: 44px;
	text-align: center;
	font-weight: 400;
	font-size: 10px;
	background: #398dff;
	color: #f8f8f8;
	border: solid 1px #e4e4e4;
	-webkit-border-radius: 1px;
	-moz-border-radius: 1px;
	-ms-border-radius: 1px;
	border-radius: 1px;
	transform: translate(-50%,0) scale(.86); /* scale 0.86 corrects for 1.15 scale on parent */
	z-index: 4;
}

html:not([ciq-last-interaction="touch"]) .stx_chart_controls *:hover > .stx-tooltip {
	top: 110%;
	opacity: 1;
	transition: opacity 0.1s 0.1s, top 0.5s 0.1s;
}

.stx_notification_tray {
	cursor: pointer;
	/* height: 25px; */
	right: 70px;
	position: absolute;
	z-index: 30;
	background: #fafafa;
	border-radius: 3px;
}

.stx_notification_tray div {
	box-sizing: content-box;
	position: relative;
	height: 16px;
	padding: 4px;
	margin-bottom: 1px;
	text-align: end;
}

.stx_notification_tray div > * {
	height: 16px;
	padding: 4px;
	margin-bottom: 1px;
}

.stx_jump_today {/* Button  jumping to today's data */
	color: #FFF;
	overflow: hidden;
	position: absolute;
	bottom: 12px;
	right: 10px;
	line-height: 0px;
	height: 25px;
	width: 30px;
	padding: 0px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	background:#fafafa;
	-webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, .2);
	-moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, .2);
	box-shadow: 0px 1px 1px rgba(0, 0, 0, .2);
	transition: background .25s;
	-webkit-transition: background .25s;
	cursor: pointer;
	z-index:30;
}

.stx_jump_today span {
	background-image:url(img/stx-sprite-panels.svg);
	background-repeat: no-repeat;
	display: inline-block;
	line-height: 25px;
	height: 25px;
	width: 30px;
	padding-top: 25px;
	background-position: -448px -25px;
	transition: opacity .25s, transform .2s;
	-webkit-transition: opacity .25s, -webkit-transform .2s;
	opacity: .6;
}
.no-svg .stx_jump_today span {/* Button Icons for non SVG browsers */
	background-image:url(img/stx-sprite-panels.png);
}
/*.stx_jump_today:hover span {
	opacity: 1;
}*/
translate { /* so translate nodes don't affect interactivity */
	pointer-events: none;
}

/* --------------------------------------------------------- PANEL CONTROL TEMPLATES --------------------------------------------------------- */

.stx-baseline-handle { /* used for dragging the baseline up and down */
	background-image:url(img/stx-sprite-ui.svg);
	background-position: -770px -26px;
	display: none;
	height: 25px;
	position: absolute;
	width: 25px;
	z-index: 30;
	background-color: #eee;
	border: solid 1px #999;
	border-radius: 5px;
	transform: scale(0.75);
	box-sizing: border-box;
}
.stx-baseline-handle:hover, .stx-baseline-handle:active{
	transform: scale(1);
}

.stx_anchor_handle {
	width: 5px;
	position: absolute;
	z-index: 30;
	background-color: #398dff;
	border-radius: 6px;
	cursor: ew-resize;
	border-left-color: #888;
}
/*.stx-baseline-handle:hover,*/
.stx-baseline-handle:active,
.stx-baseline-handle.stx-grab {
	opacity: 1;
}

.stx-drag-chart { /* Use the hand icon when grabbing and moving the chart */
	cursor: url(img/closedhand.cur), default !important;
}
.stx-ico-handle {/* The div that allows users to adjust the panels */
	position: absolute;
	left: 0%;
	height: 6px;
	width: 100%;
	overflow: hidden;
	cursor: url(img/vertical.cur), ns-resize;
	z-index: 30;
}
.stx-ico-handle:active {/* Use the vertical resize when adjusting panels */
	cursor: url(img/vertical.cur), ns-resize;
}


.stx-panel-control {/* Panel Control buttons template */
	position: absolute;
	top: 10px;
	left: 0px;
	overflow: hidden;
	z-index: 30;
	display: none;
}

.stx-panel-control * {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.stx-panel-control.stx-show {
	display: inline-block;  /*backwards compat*/
}

.stx-panel-control.stx-show .stx-btn-panel {
	display: none;
}

.stx-panel-control.stx-show .stx-btn-panel.stx-show {
	display: inline;
}

.stx-panel-title {/* Panel/Chart Titles */
	margin: 2px 10px 5px;
	float: left;
	display: inline-block;
	color: #333;
	padding: 0 10px 0 10px;
	line-height:22px;
	font-size: 12px;
	text-align:left;
	text-transform: uppercase;
	-webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, .2);
	-moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, .2);
	/*box-shadow: 0px 1px 1px rgba(0, 0, 0, .2);*/ /* Messes up Android WebView pre-4.3 */
}

.stx-panel-control .stx-btn {/* Float all icons so they stay in line on the left */
	float: left;
}

.stx-panel-control .stx-panel-legend {
	display: none;
}

.stx-panel-legend .stx-panel-control .stx-panel-legend {
	display: inline-block;
	vertical-align: top;
	width: 154px;
}
/* ------------------------------------------------------- CHART OVERLAY ELEMENTS ------------------------------------------------------ */

.mSticky { /* These are the small divs that come up when you hover over a drawing or study overlay */
	display: none;
	background: #efefef;
	border: solid #ddd 1px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	color: #666;
	font-size: 11px;
	/*text-transform: capitalize;*/
	white-space: nowrap;
	z-index: 40;
}
.mSticky.hide {
	padding:0;
	border:none;
}
.mSticky, .vectorTrashCan, .overlayTrashCan, .overlayEdit, .overlayText {
	position: absolute;
	z-index: 40;
}
.mStickyInterior, #sMeasure {/* Drawing and study info div contents*/
	margin: 0px;
	padding: 5px 10px 5px 10px;
	display: inline-block;
	float: left;
}
.overlayText.stx-btn,
.overlayEdit.stx-btn,
.overlayTrashCan.stx-btn,
.vectorTrashCan.stx-btn {/* Drawing and study info div delete button wrappers for mobile */
	background: #efefef;
	cursor: pointer;
	display: inline-block;
	text-align: center;
	width: 23px;
	height: 23px;
	padding:0;
	border: solid #ddd 1px;
	margin: 0 0 0 18px;
}
.overlayText.stx-btn span,
.overlayEdit.stx-btn span,
.overlayTrashCan.stx-btn span,
.vectorTrashCan.stx-btn span {/* Drawing and study info div delete button content */
	display: block;
	margin: 0;
	width: 23px;
	height: 25px;

	overflow: hidden;
	text-indent: 100px;
	background: transparent;

	background-image:url(img/stx-sprite-panels.svg);
	background-position: -400px -26px;
}

.overlayEdit {
	opacity: 0;
}

.overlayTrashCan {
	margin-left:5px;
}

/* To enable editing of overlay studies on mobile. You'll need to also implement studyOverlayEdit callbackListener
.overlayEdit {
	margin-left: 5px;
	opacity: 1;
}
.overlayTrashCan {
	margin-left: 34px;
}
*/

.no-svg .overlayText.stx-btn span,
.no-svg .overlayEdit.stx-btn span,
.no-svg .overlayTrashCan.stx-btn span,
.no-svg .vectorTrashCan.stx-btn span {/* Button Icons for non SVG browsers */
	background-image:url(img/stx-sprite-panels.png);
}

.mouseDeleteInstructions, .stickyLongPressText, .customInstructions {
	margin: 0px;
	display: block;
	clear: both;
	font-size: xx-small;
}
.mouseDeleteText, .mouseManageText, .dragAnchorText { display:none; }
.rightclick_drawing .mouseDeleteText { display:inline; }
.rightclick_series .mouseDeleteText { display:inline; }
.rightclick_study .mouseDeleteText { display:inline; }
.customInstructions .dragAnchorText { display:inline; }

.stx-loader {/* Loading image size and placement */
	position: absolute;
	top: 280px;
	left: 50%;
	margin-left:-20px;
	width: 40px;
	height: 40px;
	z-index:50;
}

.stx_draw_ok, .stx_drag_ok {
	position:absolute;
	z-index: 40;
	background-color: rgba(255, 255, 255, 0.5);
	display: none;
	vertical-align: top;
	padding: 3px 3px 0;
	border-radius: 10px;
	border: 1px solid rgba(0, 0, 0, 0.2);
}

.stx_draw_ok .field, .stx_drag_ok .field {
	display: inline-block;
	padding-left: 3px;
	white-space: nowrap;
	color: #000000;
	float: right;
}

.stx_draw_ok .icon, .stx_drag_ok .icon {
	display: inline-block;
	margin-bottom: 0.5em;
	background-color: rgba(255, 255, 255, 1);
	border: 1px solid #5cb85c;
	color: #5cb85c;
	border-radius: 50%;
	width: 1em;
	height: 1em;
	float: left;
}

.stx_draw_ok .icon:after, .stx_drag_ok .icon:after {
	animation-duration: 1500ms;
	animation-timing-function: ease;
	animation-name: checkmark;
	transform: scaleX(-1) rotate(135deg);
	opacity: 1;
	height: 0.5em;
	width: 0.25em;
	transform-origin: left top;
	border-right: 2px solid #5cb85c;
	border-top: 2px solid #5cb85c;
	content: '';
	left: 0.4em;
	top: 0.8em;
	position: absolute;
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 0.25em;
    opacity: 1;
  }
  40% {
    height: 0.5em;
    width: 0.25em;
    opacity: 1;
  }
  100% {
    height: 0.5em;
    width: 0.25em;
    opacity: 1;
  }
}

.stx-draggable { /* Use the grab (hand) icon when hovering a draggable item */
	cursor: -webkit-grab;
	cursor: -moz-grab;
	cursor: grab;
}

.stx-draggable.stx-drag-series { /* Use the 4-directional icon when grabbing and moving a series or study */
	cursor: url(img/all-directional.cur), move !important;
}
.stx-draggable.stx-drag-axis { /* Use the horizontal icon when grabbing and moving an axis */
	cursor: url(img/horizontal.cur), ew-resize !important;
}

/* --------------------------------------------------------- CHART ELEMENT STYLES --------------------------------------------------------- */

/* -------- Current Price label (Floating last price on y-axis)-------- */

.stx_current_hr_up {/* 'Up' color for current chart value */
	background-color: #8cc176;
	color:#fff;
}
.stx_current_hr_down {/* 'Down' color for current chart value */
	background-color: #b82c0c;
	color:#fff;
}

/* -------- Axis Styles -------- */

.stx_xaxis {/* x-axis date styles */
	font-size: 12px;
	font-family: Helvetica, sans-serif;
	color: #666;
	}
.stx_xaxis_dark {/* x-axis date divider -- color only -- */
	color: #444444;
	}
.stx_yaxis {/* y-axis price styles */
	font-size: 12px;
	font-family: Helvetica, sans-serif;
	color:#333;
	}

/* -------- Crosshairs lines and labels-------- */

.stx-float-date {/* Crosshair Floating x-axis date label*/
	position: absolute;
	font-size: 10px;
	font-family: Helvetica, sans-serif;
	z-index: 30;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	color:#fff;
	padding: 1px 6px;
	text-align: center;
	width: 70px;
	background-color: #333;
}
.stx-float-price {/* Crosshair Floating y-axis value label*/
	color:#fff;
	background-color: #333;
}
.stx_crosshair {/* Standard Crosshair lines color */
	position: absolute;
	z-index: 10;
	background-color:#999;
	}
.stx_crosshair_drawing {/* Darker crosshairs lines when drawing */
	position: absolute;
	z-index: 10;
	background-color:#333;
	}
.crossY, .stx_crosshair_y {/* Vertical Crosshair line size */
	left: 0px;
	height: 1px;
	width: 98%;
	margin-top:-1px; /* since we're using border-top to draw the actual line */
}
.crossX, .stx_crosshair_x { /* Horizontal Crosshair line size */
	top: 0px;
	width: 1px;
	height: 100%;
}
.stx-crosshair-on{	/* Crosshair pointer icon */
	cursor:crosshair;
}


/* -------- Chart and Study Panel Styling -------- */

.stx_panels {/* Panel Title text */
	font-family: Helvetica, sans-serif;
	font-size: 10px;
	color: var(--dark-text-primary);
}
.stx_panel_background {/* Panel Title background */
	color: #7c878b;
	}
.stx_panel_border { /* Divider between panels */
	width: 1px;
	color:#cccccc;
	}
.stx_panel_drag { /* Darker Divider between panels when resizing */
	width: 2px;
	color:#666;
	}
.stx_solo_lit span {/* Add background to expand button when toggled on */
	background-color:#b1b7b8;
	color:#ffffff;
	opacity: 1;
}


/* -------- Chart Grid -------- */

.stx_grid { /* Default Grid */
	color:#efefef;
	opacity:1;
	border-style:solid;
	}
.stx_grid_dark {/* Default Grid dividers */
	color:#cccccc;
	opacity:.9;
	border-style:solid;
	}
.stx_grid_border {/* Default Grid borders */
	color:#999999;
	border-style:solid;
	}

/* -------- Chart Type Styles -------- */

.stx_line_chart, .stx_line_up, .stx_line_down {/* Default width for line Charts */
	width: 1px;
	border-top-style:solid;
}
.stx_bar_chart, .stx_bar_up, .stx_bar_down, .stx_bar_even {/* Default width for bar Charts */
	width: 1px;
}
/* Default color for line and bar Charts */
.stx_line_chart, .stx_bar_chart {color:#000;}

.stx_bar_down {/* Down Bar color */
	color: #b82c0c;
}
.stx_bar_up {/* Up Bar color */
	color: #8cc176;
}

.stx_baseline_delta_mountain {/* Default color for baseline delta mountain Charts */
	background-color: rgba(163, 102, 255,.5);		/* background color for mountain. */
	color: rgba(163, 102, 255,.01);					/* Optional gradient. */
	border: transparent;								/* No border */
	padding: 3px;									/* Optional padding between the baseline and the mountain shading*/
}

.stx_colored_mountain_chart {/* Default color for colored mountain Charts */
	background-color: rgba(163, 102, 255,.5);		/* background color for mountain. */
	color: rgba(163, 102, 255,.01);					/* Optional gradient. */
	width: 2px;										/* Optional line width */
	border: transparent;								/* No border */
	padding: 4px;									/* Optional padding between the baseline and the mountain shading*/
}

.stx_mountain_chart {/* Default color for mountain Charts */
	background-color: rgba(102,202,196,.5);			/* background color for mountain. */
	color: rgba(102,202,196,.01);					/* Optional gradient. */
	border: #66CAC4;								/* Optional line color */
	width: 1px;										/* Optional line width */
}
/*.stx_mountain_chart.color {width:24px;}*/

.stx_histogram_down {/* Down Bar color */
	color: #b82c0c;
	border-left-color: #000000;
}
.stx_histogram_up {/* Up Bar color */
	color: #8cc176;
	border-left-color: #000000;
}
.stx_histogram_even {/* Even Bar color */
	color: #999999;
	border-left-color: #000000;
}

.stx_highlight_vector {/* change the color and weight of drawings and study overlays on hover */
	color: red;
	width: 3px;
}

.stx_candle_shadow, .stx_bar_even {/* Candle wick color */
	color:#2e383b;
}
/******* Optionally use .stx_candle_shadow_up and .stx_candle_shadow_down to change shadow/wick color for up/down candles
 .stx_candle_shadow_up {
	color:#8cc176;
}
.stx_candle_shadow_down {
	color:#b82c0c;
}
********/

.stx_candle_down, .stx_line_down { /* Down Candle color */
	color: #b82c0c;
	border-left-color: #000000;
}
.stx_candle_up, .stx_line_up {/* Up Candle color */
	color: #8cc176;
	border-left-color: #000000;
}

.stx_hollow_candle_down{color:#b82c0c;}
.stx_hollow_candle_up{color:#8cc176;}
.stx_hollow_candle_even{color:#888888;}

.stx_baseline{/* color of baseline (dashed horizontal line) */
	color:#2e383b;
	width: 2px;
}

.stx_baseline_down { /* under area color */
	color: #eb242b;
  	width: 2px;
}
.stx_baseline_up {/* over area color */
	color: #49ba59;
  	width: 2px;
}

.stx_channel_down { /* under area color */
	color: rgba(235, 36, 43, 0.25);
}
.stx_channel_up {/* over area color */
	color: rgba(73, 186, 89, 0.25);
}

.stx_hlcbox_chart {
	color: cyan;
	background-color: blue;
	border-left-color: blue;
}

.stx_kagi_down {/* Kagi chart width and color for downtrend */
	color: #FF0000;
	width: 1px;
}
.stx_kagi_up {/* Kagi chart width and color for uptrend */
	color: #00FF00;
	width: 3px;
}

.stx_pandf_down {/* P&F chart color, padding, width for downtrend */
	color: #FF0000;
	padding: 2px 0px 2px 0px;
	width: 2px;
}
.stx_pandf_up {/* P&F chart color, padding, width for uptrend */
	color: #00FF00;
	padding: 2px 0px 2px 0px;
	width: 2px;
}

.stx_volume_up {/* Up Volume color */
	color: #8cc176; /* legacy only. Use study library output "Up Volume" instead */
	border: #000000;
}
.stx_volume_down {/* Down volume color */
	color: #b82c0c; /* legacy only. Use study library output "Down Volume" instead */
	border: #000000;
}

/* Volume underlay styles */
.stx_volume_underlay_up {
	color: rgb(140, 193, 118); /* legacy only. Use study library output "Up Volume" instead */
	opacity: .6;
	border-left-color: rgb(0, 0, 0);
}
.stx_volume_underlay_down {
	color: rgb(184, 45, 12); /* legacy only. Use study library output "Down Volume" instead */
	opacity: .6;
	border-left-color: rgb(0, 0, 0);
}

/* Volume profile styles */
.stx_volume_profile {
	opacity: .3;
	border-color: rgb(0, 0, 0);
}

/* Line color for projections */

.stx_projection_new {/* Line color for active line when drawing projections */
	color: blue;
}

.stx_gaps {/* Transparent gaps overlay style */
	color: #b1b7b8;
	opacity: .3;
}

.stx_histogram { /* Default color for histograms */
	color: #000000;
	opacity: .2;
}

/* -------- Annotations -------- */

.stx_annotation {/* Annotation styles */
	padding: 0px;
	font-size: 12px;
	font-family: Helvetica, sans-serif;
	background: transparent;
	border: none;
	box-shadow: none;
	outline: none;
	color: #333;
	resize: none;
	z-index: 1000;
}
.stx_annotation_bg {/* Annotation backgrounds */
	color: #f5f5f5;
}
.stx_annotation_highlight {/* Annotation highlight color */
	color: var(--dark-text-primary);
}
.stx_annotation_highlight_bg {/* Annotation highlight background color */
	color: #3d4447;
}
.stx-btn.stx_annotation_save, .stx-btn.stx_annotation_cancel {/* Annotation buttons */
	position: absolute;
	display: none;
	width: 25px;
	height: 25px;
	cursor: pointer;
	opacity: 0.8;
	z-index: 50;
}
.stx-btn.stx_annotation_save {
	background-image: url(img/stx-sprite-ui.svg);
	background-position: -573px -50px;
}
.stx-btn.stx_annotation_cancel {
	background-image: url(img/stx-sprite-ui.svg);
	background-position: -523px -77px;
}

.stx-btn.stx_annotation_save:hover, .stx-btn.stx_annotation_cancel:hover {
	opacity: 1;
}

.stx_watermark {/* Optional watermark style */
	font-size: 16px;
	font-family: Helvetica, sans-serif;
}
.stx_fib_levels {/* Text for fibonacci values */
	font-size: 12px;
	font-family: Helvetica, sans-serif;
	color: transparent;
}

/* -------- Text for shared charts -------- */

.stx_share {
	font-size: 14px;
	font-family: Georgia, "Times New Roman", serif;
	color: #000000;
	width: 1px;
}
.stx_share_symbol {
	font-size: 14px;
	font-family: Arial, sans-serif;
	font-weight: bold;
}

.stx-holder {
	display:block;
	position:absolute;
	overflow: hidden;
	z-index: 20;
}

.stx-subholder {
	position:absolute;
	overflow:hidden;
	box-sizing:border-box;
	border:none 5px #398BFF;
}

.stx-subholder.dropzone.all {
	border-style:solid;
}
.stx-subholder.dropzone.top {
	border-top-style:solid;
}
.stx-subholder.dropzone.bottom {
	border-bottom-style:solid;
}
.stx-subholder.dropzone.left {
	border-left-style:solid;
}
.stx-subholder.dropzone.right {
	border-right-style:solid;
}


/* -------- Colors for session shading -------- */
.stx_market_session.divider {
	background-color: rgba(0,255,0,0.8);
	width: 1px;
}
.stx_market_session.pre {
	background-color: rgba(255,255,0,0.1);
}
.stx_market_session.post {
	background-color: rgba(0,0,255,0.2);
}

/* -------- Heads up tooltip ( hover with cross hairs on )-------- */
stx-hu-tooltip {
	position: absolute;
	left: -50000px;
	z-index: 30;
	white-space: nowrap;
	padding: 6px;
	border: 2px solid #4ea1fe;
	background-color: rgba(255,255,255,.7);
	color: #000;
	font-size: 14px;
	fill: white;
}

stx-hu-tooltip-field {
	display:table-row;
}

stx-hu-tooltip-field-name {
	display:table-cell;
	font-weight:bold;
	padding-right:5px;
}

stx-hu-tooltip-field-name:after {
		content:':';
}

stx-hu-tooltip-field-value {
	display:table-cell;
	text-align:right;
}

.stx-hu-tooltip-highlight {
	position: absolute;
	height: 100%;
	z-index: 10;
	background-color: rgba(191, 191, 191, .2);
}
