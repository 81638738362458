.tertiary {
  color: var(--color-light-graphic-tertiary-inverted);
}

.popover {
  background-color: var(--color-light-bg-tertiary);
  padding: var(--gap-xs);
  border-radius: var(--border-radius-m);
}

.block {
  display: block;
}

.item {
  padding: var(--gap-3xs) 0;
  cursor: pointer;
}

.icon {
  color: var(--color-light-text-secondary);
}

.iconSelected {
  color: var(--control-accent-bg-color);
}

.tooltipContent {
  max-width: 200px;
}

button.reset {
  border-color: var(--color-dark-border-underline);
  color: var(--color-light-graphic-tertiary-inverted);
}