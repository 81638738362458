.container {
  min-height: 554px;
}

.docIcon {
  color: var(--color-light-graphic-secondary);
}

.submit {
  max-width: 239px;
}
