.cell {
  justify-content: flex-end;
  text-align: end;
  background-color: var(--dark-bg-primary);
}

.firstColumnCell {
  justify-content: flex-start !important;
  text-align: start !important;
}

.activeSort {
  display: block !important;
}

.filterIconOpen {
  display: block !important;
}

.activeFilter {
  color: #7938e0;
  display: block !important;
}

.sortDesc {
  transform: rotate(-0.5turn);
}

.rowSelected {
  background-color: #2c2c2e !important;
}

.customCell {
  display: flex;
  flex: 1 1;
  height: 100%;
  align-items: inherit;
  justify-content: inherit;
  min-width: 0;
  padding: var(--gap-xs);
  align-items: center;
  position: relative;
}

.customCell > :first-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.filterPopover {
  padding: var(--gap-xs) var(--gap-s);
}

.customInput {
  width: 280px;
  --form-control-s-min-height: 32px;
}

.customInputField > div:first-child > div > div:first-of-type {
  font-size: 13px;
  line-height: 16px;
}

.customInputField input {
  font-size: 13px;
  line-height: 16px;
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  gap: var(--gap-xs);
  margin-top: var(--gap-s);
}

.customButton span {
  font-size: 11px;
  line-height: 16px;
}

.tooltipContent {
  max-width: 200px;
  color: #c5c5c7;
}

.tooltipArrow:after {
  left: 6px !important;
}

.tooltip[data-popper-placement='top-end'] .tooltipArrow:after {
  left: -18px !important;
}

.tooltip {
  box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.16), 0px 4px 8px rgba(0, 0, 0, 0.16),
    0px 0px 1px rgba(0, 0, 0, 0.16);
}

.tooltipTarget {
  overflow: hidden;
  text-overflow: ellipsis;
}

.leftAlign {
  justify-content: flex-start;
}

.doubleRowCell [class*='customCell'] {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 6px;
}

.doubleRowCell [class*='customCell']::after {
  margin-top: -6px;
}

.doubleRowHeaderCellSecondRow {
  padding: 0 var(--gap-xs);
}

.doubleRowCell [class*='.bp4-table-header-content'] > :first-child {
  flex: initial;
}

.doubleRowCell [class*='bp4-table-truncated-text'] {
  display: flex;
  flex: 1 1;
  height: 100%;
  align-items: inherit;
  justify-content: inherit;
  cursor: pointer;
  padding: 0;
}

.borderedRowCell {
  border-bottom: 1px solid #2a2a2a;
}
