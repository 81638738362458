.container {
  display: flex;
  flex-direction: column;
  gap: var(--gap-m);

  width: 268px;
  padding: var(--gap-m);
}

.buttonWrapper {
  display: flex;
  gap: var(--gap-m);
  justify-content: space-between;
}

.customInput {
  --form-control-bg-color: var(--form-control-focus-mobile-bg-color);
}
