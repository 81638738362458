/*
 Переопределения стилей компонента Checkbox
*/

@import '@alfalab/core-components/vars/typography.css';

:root {
  --checkbox-bg-color: var(--color-light-graphic-tertiary);
  --checkbox-border-color: transparent;
  --checkbox-icon-color: var(--color-static-graphic-light);

  --checkbox-hover-bg-color: var(--color-light-graphic-tertiary-shade-7);
  --checkbox-hover-border-color: var(--checkbox-border-color);

  --checkbox-active-bg-color: var(--checkbox-bg-color);
  --checkbox-active-border-color: var(--checkbox-border-color);

  --checkbox-disabled-bg-color: var(--color-light-graphic-quaternary);
  --checkbox-disabled-border-color: var(--checkbox-border-color);

  --checkbox-checked-bg-color: var(--control-accent-bg-color);
  --checkbox-checked-hover-bg-color: var(--control-accent-hover-bg-color);
  --checkbox-checked-active-bg-color: var(--control-accent-active-bg-color);
}

[class*='checkbox__component_'] {
  &[class*='checkbox__s_'] {
    [class*='checkbox__label_'] {
      @mixin paragraph_secondary_large;
    }

    [class*='checkbox__box_'] {
      margin: 0;
    }
  }
}
