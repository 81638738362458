.title {
  margin-bottom: var(--gap-xl);
}

.progress {
  margin-bottom: var(--gap-l);
}

.action {
  max-width: 239px;
  margin-top: var(--gap-xl);
}