.stepContainer {
  padding: var(--gap-xl);
}

.slideContainer {
  position: absolute;
  top: 48px;
  left: 0;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
}

.firstStepImage {
  width: 100%;
  height: 186px;
  margin-top: var(--gap-2xl);
}

.secondStepImageOne {
  width: 100%;
  height: 188px;
  background-size: contain;
}

.secondStepImageTwo {
  width: 100%;
  height: 87px;
  background-size: contain;
  background-repeat: no-repeat;
}

.thirdStepImage {
  width: 100%;
  height: 186px;
  background-size: contain;
}

.fourthStepImage {
  width: 100%;
  height: 275px;
  background-size: contain;
}

.fifthStepImage {
  position: relative;
  left: 24px;
  width: 100%;
  height: 277px;
  background-size: contain;
}

.sixthStepImage {
  width: 100%;
  height: 328px;
  background-size: cover;
  background-repeat: no-repeat;
}

.lastStepTitle {
  margin-bottom: var(--gap-xs);
}

.lastStepSubtitle {
  margin-bottom: var(--gap-xl);
}
