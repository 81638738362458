@import '@alfalab/core-components/vars/gaps.css';

.instrumentSymbolCell {
  display: flex;
  align-items: center;
  gap: var(--gap-xs);
}

.instrumentIconMain {
  min-width: 18px;
  min-height: 18px;
  width: 18px;
  height: 18px;
}

.instrumentIconMainMobile {
  min-width: 32px;
  min-height: 32px;
  width: 32px;
  height: 32px;
}

.instrumentIconMainDesktop {
  min-width: 48px;
  min-height: 48px;
  width: 48px;
  height: 48px;
}

.instrumentIconMain img {
  width: 100%;
  height: auto;
  border-radius: 100%;
}

.instrumentIconFallback {
  background: #3a3a3c;
  border-radius: 100%;
  color: #8d8d93;
  font-size: 11px;
  line-height: 16px;
  width: 18px;
  height: 18px;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.instrumentIconFallbackMobile {
  min-width: 32px;
  min-height: 32px;
  width: 32px;
  height: 32px;
  font-size: 15px;
  line-height: 20px;
}

.instrumentIconFallbackDesktop {
  min-width: 48px;
  min-height: 48px;
  width: 48px;
  height: 48px;
  font-size: 22px;
  line-height: 30px;
}

.displayedName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
