.wide {
  max-width: 320px;
}

.section {
  margin: 0 0 var(--gap-xl);
}

.submit {
  margin-top: var(--gap-2xl);
}

.fields {
  margin-top: var(--gap-xl);
}

.types {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--gap-xs);
}
