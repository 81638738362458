.title {
  margin: 0 0 var(--gap-xs);
  display: block;
  max-width: 70px;
  min-height: 32px;
}

.content {
  min-height: 22px;
  display: flex;
  justify-content: flex-start;
}

.special {
  color: var(--color-light-text-attention);
}

.container.horizontal {
  display: flex;
  align-items: center;
}

.horizontal .title {
  max-width: none;
  min-height: auto;
  margin: 0;
}

.horizontal .content {
  margin-left: var(--gap-xs);
  min-height: auto;
}

