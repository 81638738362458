.root {
  padding-top: var(--gap-l);
  padding-bottom: var(--gap-l);
  background-color: var(--select-option-background);
  box-shadow: var(--select-options-list-box-shadow);
}

.statusWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;

  border-radius: 100%;

  &[role='button'] {
    cursor: pointer;
    background-color: var(--color-light-specialbg-tertiary-transparent);
    transition: background-color 0.2s ease;

    &:hover {
      background-color: var(
        --color-light-specialbg-tertiary-transparent-shade-7
      );
    }
  }
}

.status {
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.green {
  background-color: var(--color-light-graphic-positive);
}

.orange {
  background-color: var(--color-light-text-attention);
}

.red {
  background-color: var(--color-light-graphic-negative);
}

.tooltipContent {
  width: 215px;
}

.content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  padding-right: var(--gap-s);
  padding-left: var(--gap-s);
}

.content + .content {
  margin-top: var(--gap-m);
  padding-top: var(--gap-m);
  border-top: 1px solid var(--divider-color);
}

.contentCell {
  display: flex;
  flex-direction: column;
  max-width: 220px;
  margin-left: var(--gap-s);
}

.title {
  margin-bottom: var(--gap-3xs);
}

.certificateEnrollWrapper {
  cursor: pointer;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  margin-top: var(--gap-m);
  padding-right: var(--gap-s);
  padding-left: var(--gap-s);
}

.certificateEnrollIcon {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 18px;
  height: 18px;

  color: var(--color-light-graphic-secondary);
}

.serviceNotifications {
  display: block;
}
