.container {
  background-color: var(--color-light-bg-secondary);
  width: 320px;
  padding: var(--gap-m);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeIcon {
  color: #8d8d93;
  cursor: pointer;
  transition: var(--button-transition);
}

.closeIcon:hover {
  color: var(--color-light-text-primary);
}

.info {
  margin: var(--gap-m) 0 var(--gap-2xl);
}

.buttonToolbar {
  display: flex;
  justify-content: flex-end;
  gap: var(--gap-m);
}
