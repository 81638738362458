.wrapper {
  display: flex;
  overflow-x: auto;
  width: 100%;
}

.table {
  width: 100%;
  border-spacing: 0;
  white-space: nowrap;
}

.head th {
  --color-dark-text-tertiary-inverted: #b6bcc3;
  padding: 0 var(--gap-xs);
  height: 32px;
  padding-left: calc(2 * var(--gap-xs) + 2 * var(--gap-s));
  background-color: var(--dark-bg-secondary);

  text-align: end;
  color: var(--color-dark-text-tertiary-inverted);

  border: 2px solid var(--dark-bg-primary);
  border-bottom: none;
  border-right: none;
}

.head th:first-child {
  border-left: none;
  text-align: start;
  padding-left: var(--gap-xs);
  padding-right: calc(2 * var(--gap-xs) + 2 * var(--gap-s));
}

.head th:hover {
  user-select: none;
  cursor: pointer;
  color: var(--color-dark-text-primary);
}

.row {
  background-color: var(--color-light-bg-secondary-alpha-30);
}

.row:nth-child(2n) {
  background-color: var(--color-light-bg-primary);
}

.rowSmall {
  height: 50px;
}

.rowExtraSmall {
  height: 34px;
}

.rowExtraSmallMobile {
  height: 40px;
}

.cell {
  padding: 0 var(--gap-xs);
  text-align: end;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cell:first-child {
  text-align: start;
}

td.textAlignStart,
th.textAlignStart {
  text-align: start;
  padding-left: var(--gap-xs);
  padding-right: calc(2 * var(--gap-xs) + 2 * var(--gap-s));
}

td.textAlignEnd,
th.textAlignEnd {
  padding-left: calc(2 * var(--gap-xs) + 2 * var(--gap-s));
  text-align: end;
}
