/* stylelint-disable selector-class-pattern, no-descending-specificity */
@import url('@alfalab/core-components/vars/typography.css');

@keyframes skeleton-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes skeleton-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.textAlignStart {
  justify-content: start;
}

.alignStartColumnHeader {
  flex-direction: row-reverse;
}

.customSelection .bp4-table-truncated-text {
  cursor: pointer;

  display: flex;
  flex: 1 1;
  align-items: inherit;
  justify-content: inherit;

  height: 100%;
  padding: 0;
}

.bp4-table-cell {
  display: flex;
  align-items: center;
  box-shadow: inset 0 -1px 0 var(--color-light-specialbg-nulled),
    inset -1px 0 0 var(--color-light-specialbg-nulled);
  transition: color 300ms;
}

.bp4-table-cell.bp4-intent-primary {
  color: #7938e0;
  background-color: rgb(19 124 189 / 10%);
}

.bp4-table-cell.bp4-intent-success {
  color: #0f9960;
  background-color: rgb(15 153 96 / 10%);
}

.bp4-table-cell.bp4-intent-warning {
  color: #d9822b;
  background-color: rgb(217 130 43 / 10%);
}

.bp4-table-cell.bp4-intent-danger {
  color: #db3737;
  background-color: rgb(219 55 55 / 10%);
}

.bp4-table-editing-enabled .bp4-table-cell {
  cursor: text;
}

.bp4-table-selection-enabled .bp4-table-cell {
  cursor: pointer;
}

.bp4-table-cell.bp4-table-truncated-cell {
  overflow: unset;
}

.bp4-table-cell.bp4-large,
.bp4-large .bp4-table-cell {
  @mixin paragraph_primary_small;

  height: 30px;
  /* stylelint-disable-next-line property-disallowed-list */
  line-height: 30px;
}

.bp4-table-cell.bp4-loading {
  display: -webkit-box;
  display: flexbox;
  display: flex;
  flex-direction: column;
  -webkit-box-orient: vertical;
  justify-content: center;

  color: transparent;

  -webkit-box-direction: normal;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
}

.bp4-table-cell:focus {
  outline: none;
}

.bp4-table-cell-interactive {
  z-index: 21;
}

.bp4-table-striped .bp4-table-cell-ledger-even {
  background-color: #fff;
}

.bp4-table-striped .bp4-table-cell-ledger-odd {
  background-color: #fafcfd;
}

.bp4-table-editable-name input {
  height: 20px;
}

.bp4-table-editable-text {
  position: absolute;
  inset: 0;
  padding: 0 10px;
}

.bp4-table-null {
  color: rgb(92 112 128 / 60%);
}

.bp4-table-truncated-value {
  overflow: hidden;
  max-height: 100%;
  text-overflow: ellipsis;
}

.bp4-table-truncated-format-text {
  overflow: hidden;
  max-height: 100%;
  text-overflow: ellipsis;
}

.bp4-table-truncated-popover-target {
  cursor: pointer;

  position: absolute;
  top: 0;
  right: 5px;
  bottom: 0;

  padding: 0 5px;

  text-align: center;

  opacity: 0.3;
  border-radius: 3px;
}

.bp4-table-truncated-popover-target .bp4-icon-standard {
  @mixin paragraph_primary_small;
}

.bp4-table-truncated-popover-target.bp4-popover-open {
  opacity: 1;
}

.bp4-table-truncated-popover-target.bp4-popover-open .bp4-icon-standard {
  color: #7938e0;
}

.bp4-table-truncated-popover-target:hover {
  opacity: 1;
}

.bp4-table-truncated-popover {
  overflow: auto;

  min-width: 200px;
  max-width: 600px;
  max-height: 300px;
  padding: 10px;

  font-family: monospace;
}

.bp4-table-popover-whitespace-pre {
  white-space: pre;
}

.bp4-table-popover-whitespace-normal {
  white-space: normal;
}

.bp4-table-horizontal-cell-divider {
  height: 1px;
  box-shadow: inset 0 1px 0 rgb(16 22 26 / 15%);
}

.bp4-table-column-headers .bp4-table-header {
  @mixin action_secondary_large;

  min-height: 24px;
  vertical-align: middle;
  box-shadow: 0 2px 0 var(--color-light-bg-primary),
    inset -2px 0 0 var(--color-light-bg-primary);
}

.bp4-table-column-headers .bp4-table-header::before {
  right: 2px;
  bottom: 0;
}

.bp4-table-row-headers .bp4-table-header {
  @mixin paragraph_primary_small;

  overflow: hidden;
  min-width: 30px;
  box-shadow: inset 0 -1px 0 rgb(16 22 26 / 15%), 1px 0 0 rgb(16 22 26 / 15%);
}

.bp4-table-row-headers .bp4-table-header::before {
  right: 0;
  bottom: 1px;
}

.bp4-table-body .bp4-table-last-in-row {
  box-shadow: inset 0 -1px 0 rgb(16 22 26 / 15%), 1px 0 0 rgb(16 22 26 / 15%);
}

.bp4-table-body .bp4-table-last-in-column {
  box-shadow: 0 1px 0 rgb(16 22 26 / 15%), inset -1px 0 0 rgb(16 22 26 / 15%);
}

.bp4-table-body .bp4-table-last-in-row.bp4-table-last-in-column {
  box-shadow: 0 1px 0 rgb(16 22 26 / 15%), 1px 0 0 rgb(16 22 26 / 15%);
}

.bp4-table-quadrant-top-left .bp4-table-cell.bp4-table-last-in-row {
  box-shadow: inset 0 -1px 0 rgb(16 22 26 / 15%),
    inset -3px 0 0 rgb(16 22 26 / 15%);
}

.bp4-table-quadrant-top-left .bp4-table-cell.bp4-table-last-in-column {
  box-shadow: inset 0 -3px 0 rgb(16 22 26 / 15%),
    inset -1px 0 0 rgb(16 22 26 / 15%);
}

.bp4-table-quadrant-top-left
  .bp4-table-cell.bp4-table-last-in-column.bp4-table-last-in-row {
  box-shadow: inset 0 -3px 0 rgb(16 22 26 / 15%),
    inset -3px 0 0 rgb(16 22 26 / 15%);
}

.bp4-table-quadrant-top-left .bp4-table-header.bp4-table-last-in-row {
  box-shadow: 0 1px 0 rgb(16 22 26 / 15%), inset -3px 0 0 rgb(16 22 26 / 15%);
}

.bp4-table-quadrant-top-left .bp4-table-header.bp4-table-last-in-row::before {
  right: 3px;
  bottom: 0;
}

.bp4-table-quadrant-top-left .bp4-table-header.bp4-table-last-in-column {
  box-shadow: inset 0 -3px 0 rgb(16 22 26 / 15%), 1px 0 0 rgb(16 22 26 / 15%);
}

.bp4-table-quadrant-top-left
  .bp4-table-header.bp4-table-last-in-column::before {
  right: 0;
  bottom: 3px;
}

.bp4-table-quadrant-left .bp4-table-cell.bp4-table-last-in-row {
  box-shadow: inset 0 -1px 0 rgb(16 22 26 / 15%),
    inset -3px 0 0 rgb(16 22 26 / 15%);
}

.bp4-table-quadrant-top .bp4-table-cell.bp4-table-last-in-column {
  box-shadow: inset 0 -3px 0 rgb(16 22 26 / 15%),
    inset -1px 0 0 rgb(16 22 26 / 15%);
}

.bp4-table-header {
  user-select: none;

  position: relative;

  flex: 1 1 auto;
  align-items: flex-start;
  justify-content: space-between;

  text-overflow: ellipsis;

  box-shadow: 0 1px 0 rgb(16 22 26 / 15%);

  -webkit-box-align: start;
  -webkit-box-flex: 1;
  -webkit-box-pack: justify;
  -ms-flex-align: start;
  -ms-flex-pack: justify;
}

.bp4-table-header:hover {
  color: var(--color-light-text-primary);
}

.bp4-table-header::before {
  content: '';

  position: absolute;
  top: 0;
  left: 0;

  display: block;
}

.bp4-table-header .bp4-table-row-name,
.bp4-table-header .bp4-table-column-name,
.bp4-table-header .bp4-table-header-content {
  position: absolute;

  display: flex;
  flex: 1;

  width: 100%;
  height: 100%;
}

.bp4-table-header.bp4-table-header-selected::before {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgb(19 124 189 / 10%)),
    to(rgb(19 124 189 / 10%))
  );
  background-image: linear-gradient(
    90deg,
    rgb(19 124 189 / 10%),
    rgb(19 124 189 / 10%)
  );
}

.bp4-table-th-menu-container {
  position: absolute;
  right: 1px;

  flex-shrink: 0;

  text-align: right;

  opacity: 0;

  -ms-flex-negative: 0;
}

.bp4-table-header:hover .bp4-table-th-menu-container,
.bp4-table-header-active .bp4-table-th-menu-container,
.bp4-table-th-menu-container.bp4-table-th-menu-open {
  opacity: 1;
}

.bp4-table-interaction-bar .bp4-table-th-menu-container {
  @mixin paragraph_primary_small;
}

.bp4-table-th-menu-container-background {
  pointer-events: none;

  position: absolute;
  top: 0;
  right: 0;

  width: 50px;
  height: 30px;
}

.bp4-table-interaction-bar .bp4-table-th-menu-container-background {
  height: 20px;
}

.bp4-table-th-menu-open .bp4-table-th-menu-container-background {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgb(245 248 250 / 0%)),
    color-stop(50%, #f5f8fa)
  );
  background-image: linear-gradient(
    90deg,
    rgb(245 248 250 / 0%) 0%,
    #f5f8fa 50%
  );
}

.bp4-table-header:hover .bp4-table-th-menu-container-background,
.bp4-table-header-active .bp4-table-th-menu-container-background,
.bp4-table-th-menu-open .bp4-table-th-menu-container-background {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgb(245 248 250 / 0%)),
    color-stop(50%, #f5f8fa)
  );
  background-image: linear-gradient(
    90deg,
    rgb(245 248 250 / 0%) 0%,
    #f5f8fa 50%
  );
}

.bp4-table-th-menu {
  cursor: pointer;
  position: relative;
  width: 30px;
  height: 30px;
}

.bp4-table-interaction-bar .bp4-table-th-menu {
  right: 1px;
  width: 20px;
  height: 20px;
  text-align: center;
}

.bp4-table-interaction-bar .bp4-table-th-menu .bp4-icon {
  margin: var(--gap-3xs);
  margin-left: 3px;
  vertical-align: top;
}

.bp4-table-th-menu .bp4-icon {
  margin-top: 7px;
  margin-right: 7px;

  color: #5c7080;

  background-color: #f5f8fa;
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px rgb(16 22 26 / 20%);
}

.bp4-table-th-menu:hover .bp4-icon {
  color: #182026;
  box-shadow: inset 0 0 0 1px rgb(16 22 26 / 40%);
}

.bp4-table-th-menu.bp4-popover-open .bp4-icon {
  color: var(--color-light-text-primary-inverted);
  background-color: #7938e0;
  box-shadow: none;
}

.bp4-table-thead {
  display: block;
  white-space: nowrap;
}

.bp4-table-column-header-tr {
  display: -webkit-box;
  display: flexbox;
  display: flex;
}

.bp4-table-column-header-tr .bp4-table-header {
  flex: 0 0;

  -webkit-box-flex: 0;
}

.bp4-table-column-headers .bp4-table-interaction-bar {
  position: relative;
  height: 20px;
}

.bp4-table-column-headers
  .bp4-table-header:first-child
  .bp4-table-column-name-text {
  min-height: 32px;
  text-align: start;
}

.bp4-table-column-name-text,
.bp4-table-row-name-text {
  pointer-events: none;
  flex-grow: 1;

  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
}

.bp4-table-truncated-text {
  overflow: hidden;
  max-height: 100%;
  padding: 0 var(--gap-xs);
  text-overflow: ellipsis;
}

.bp4-table-no-wrap-text {
  white-space: nowrap;
}

.bp4-table-column-name-text {
  min-height: 32px;
  text-align: end;
}

.bp4-table-editable-name {
  pointer-events: all;
  display: block;
}

.bp4-table-editable-name.bp4-editable-text::before {
  inset: -1px -10px 0 -11px;
  border-radius: 0;
}

.bp4-table-editable-name.bp4-editable-text:not(.bp4-editable-editing)::before {
  box-shadow: none;
}

.bp4-table-editable-name.bp4-editable-text.bp4-editable-editing::before {
  cursor: text;
  inset: 0 -9px 1px -10px;
}

.bp4-table-editable-name.bp4-editable-text.bp4-editable-editing.bp4-table-editable-text::before {
  right: 1px;
  left: 0;
}

.bp4-table-column-name .bp4-table-editable-name.bp4-editable-text::before {
  bottom: -1px;
}

.bp4-table-column-name
  .bp4-table-editable-name.bp4-editable-text.bp4-editable-editing::before {
  bottom: 0;
}

.bp4-table-column-name-text .bp4-table-editable-name input {
  height: 30px;
}

.bp4-table-column-name {
  display: -webkit-box;
  display: flexbox;
  display: flex;
  flex-direction: column;
  -webkit-box-orient: vertical;

  -webkit-box-direction: normal;
}

.bp4-table-row-name {
  @mixin paragraph_secondary_medium;

  display: block;
  padding: 0 5px;
  text-align: right;
}

.bp4-table-header-content .is-searchable:not(.is-focused) > .Select-control {
  background: none;
  box-shadow: none;
}

.bp4-table-header-content .is-searchable > .Select-control {
  border-radius: 0;
}

.bp4-table-header-content .is-searchable > .Select-control .Select-value {
  cursor: pointer;
}

.bp4-table-header-content .Select-value {
  right: -1px;
}

.bp4-table-column-name,
.bp4-table-row-name {
  transition: color 300ms;
}

.bp4-table-header.bp4-loading {
  display: -webkit-box;
  display: flexbox;
  display: flex;
  flex-direction: column;
  -webkit-box-orient: vertical;
  justify-content: center;

  -webkit-box-direction: normal;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
}

.bp4-table-header.bp4-loading .bp4-table-column-name,
.bp4-table-header.bp4-loading .bp4-table-row-name {
  flex: 1;

  -webkit-box-flex: 1;
}

.bp4-table-header.bp4-loading .bp4-table-column-name-text {
  display: -webkit-box;
  display: flexbox;
  display: flex;
  flex-direction: column;
  -webkit-box-orient: vertical;
  justify-content: center;

  padding: 10px;

  -webkit-box-direction: normal;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
}

.bp4-table-header.bp4-loading .bp4-table-column-name-text .bp4-skeleton {
  height: 8px;
}

.bp4-table-header.bp4-loading .bp4-table-row-name {
  display: -webkit-box;
  display: flexbox;
  display: flex;
  flex-direction: column;
  -webkit-box-orient: vertical;
  justify-content: center;

  -webkit-box-direction: normal;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
}

.bp4-table-column-header-cell.bp4-table-has-reorder-handle:not(
    .bp4-table-has-interaction-bar,
    .firstColumnHeaderCell
  ):hover
  .bp4-table-header-content {
  padding: 0 0 0 var(--gap-l);
}

.alignStartColumnHeader.bp4-table-column-header-cell.bp4-table-has-reorder-handle:not(
    .bp4-table-has-interaction-bar
  ):hover
  .bp4-table-header-content {
  padding: 0 var(--gap-l) 0 0;
}

.bp4-table-column-header-cell.bp4-table-has-reorder-handle:not(
    .bp4-table-has-interaction-bar
  ):hover
  .bp4-table-reorder-handle-target {
  display: flex;
}

.bp4-table-reorder-handle-target {
  cursor: grab;

  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;

  display: -webkit-box;
  display: flexbox;
  display: none;
  align-items: center;
  justify-content: center;

  width: 20px;
  min-height: 24px;

  background-color: currentcolor;

  fill: currentcolor;
  mask: url('../icons/drag_xs.svg') no-repeat right;

  -webkit-box-align: center;
  -webkit-box-pack: center;
  -ms-flex-align: center;
  -ms-flex-pack: center;
}

.bp4-table-column-header-cell.firstColumnHeaderCell
  .bp4-table-reorder-handle-target {
  right: var(--gap-xs);
}

.bp4-table-reorder-handle-target:active {
  cursor: grabbing;
  display: flex;
  color: var(--control-accent-bg-color);
}

.bp4-table-reorder-handle-target:hover {
  fill: currentcolor;
}

.bp4-icon-drag-handle-vertical {
  display: none;
}

.bp4-table-reorder-handle {
  display: -webkit-box;
  display: flexbox;
  display: flex;
}

.bp4-table-resize-handle-target {
  user-select: none;
  position: absolute;
  z-index: 20;
  opacity: 0;
}

.bp4-table-resize-handle-target:hover,
.bp4-table-resize-handle-target.bp4-table-dragging {
  opacity: 1;
}

.bp4-table-resize-handle-target.bp4-table-resize-vertical {
  cursor: ew-resize;

  top: 0;
  right: 0;
  bottom: -1px;

  width: 5px;
}

.bp4-table-resize-handle-target.bp4-table-resize-horizontal {
  cursor: ns-resize;

  right: -1px;
  bottom: 0;
  left: 0;

  height: 5px;
}

.bp4-table-resize-handle {
  position: absolute;
  z-index: 20;
  background-color: var(--control-accent-bg-color);
}

.bp4-table-resize-handle.bp4-table-dragging {
  background-color: var(--control-accent-bg-color);
}

.bp4-table-resize-vertical .bp4-table-resize-handle {
  top: 0;
  bottom: 0;
  left: 2px;
  width: 3px;
}

.bp4-table-resize-horizontal .bp4-table-resize-handle {
  top: 2px;
  right: 0;
  left: 0;
  height: 3px;
}

.bp4-table-resize-guides .bp4-table-horizontal-guide {
  height: 3px;
  margin-top: -3px;
  background-color: var(--control-accent-bg-color);
}

.bp4-table-resize-guides
  .bp4-table-horizontal-guide.bp4-table-horizontal-guide-flush-top {
  margin-top: 0;
}

.bp4-table-resize-guides .bp4-table-vertical-guide {
  width: 3px;
  margin-left: -3px;
  background-color: var(--control-accent-bg-color);
}

.bp4-table-resize-guides
  .bp4-table-vertical-guide.bp4-table-vertical-guide-flush-left {
  margin-left: 0;
}

.bp4-table-overlay-layer {
  pointer-events: none;

  position: absolute;
  z-index: 20;
  inset: 0;

  overflow: hidden;
}

.bp4-table-overlay {
  position: absolute;
}

.bp4-table-column-headers .bp4-table-region {
  border-bottom: none;
}

.bp4-table-row-headers .bp4-table-region {
  border-right: none;
}

.bp4-table-vertical-guide {
  top: 0;
  bottom: 0;
}

.bp4-table-horizontal-guide {
  right: 0;
  left: 0;
}

.bp4-table-reordering-cursor-overlay {
  cursor: grabbing;
}

.bp4-table-reordering .bp4-table-reordering-cursor-overlay {
  pointer-events: all;
}

.bp4-table-quadrant-stack {
  position: relative;

  display: -webkit-box;
  display: flexbox;
  display: flex;

  height: 100%;
}

.bp4-table-quadrant {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.bp4-table-quadrant-scroll-container {
  will-change: transform;
  user-select: none;

  position: relative;
  inset: 0;

  overflow: auto;
}

.bp4-table-no-vertical-scroll .bp4-table-quadrant-scroll-container {
  overflow-y: hidden;
}

.bp4-table-no-horizontal-scroll .bp4-table-quadrant-scroll-container {
  overflow-x: hidden;
}

.bp4-table-quadrant-body-container {
  position: relative;
}

.bp4-table-quadrant-main {
  position: relative;
  z-index: 0;
  top: auto;
  left: auto;

  width: 100%;
  height: 100%;
}

.bp4-table-quadrant-main .bp4-table-quadrant-scroll-container {
  width: 100%;
  height: 100%;
}

.bp4-table-quadrant-top {
  z-index: 1;
  right: 0;
}

.bp4-table-quadrant-top .bp4-table-quadrant-scroll-container {
  bottom: -20px;
  overflow-y: hidden;
}

.bp4-table-quadrant-left {
  z-index: 2;
  bottom: 0;
  transition: width 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
}

.bp4-table-quadrant-left .bp4-table-quadrant-scroll-container {
  position: absolute;
  top: 0;
  right: -20px;
  bottom: 0;

  overflow-x: hidden;

  height: auto;
}

.bp4-table-quadrant-left .bp4-table-body-virtual-client {
  min-width: 1px;
}

.bp4-table-quadrant-top-left {
  z-index: 3;
  transition: width 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
}

.bp4-table-quadrant-top-left .bp4-table-quadrant-scroll-container {
  right: -20px;
  bottom: -20px;
  overflow: hidden;
}

.bp4-table-quadrant-top-left .bp4-table-body-virtual-client {
  min-width: 1px;
}

.bp4-table-container {
  will-change: transform;

  overflow: hidden;
  display: -webkit-box;
  display: flexbox;
  display: flex;
  flex-direction: column;
  -webkit-box-orient: vertical;

  max-width: 100%;
  min-height: 60px;
  max-height: 100%;

  border-top: 2px solid var(--color-light-bg-primary);

  -webkit-box-direction: normal;
}

.bp4-table-container .bp4-loading {
  color: transparent;
}

.bp4-table-container .bp4-loading .bp4-skeleton {
  height: 5px;
  opacity: 0;
  animation: 300ms linear forwards skeleton-fade-in,
    1000ms linear infinite alternate skeleton-glow;
  animation-delay: 0s, 300ms;
}

.bp4-table-top-container {
  display: -webkit-box;
  display: flexbox;
  display: flex;
  flex: 0 0 auto;

  min-height: 0%;

  -webkit-box-flex: 0;
}

.bp4-table-container.bp4-table-no-rows .bp4-table-top-container {
  padding-bottom: 1px;
}

.bp4-table-bottom-container {
  display: -webkit-box;
  display: flexbox;
  display: flex;
  flex: 1 1 auto;

  height: 100%;
  min-height: 0%;

  -webkit-box-flex: 1;
}

.bp4-table-menu {
  position: relative;
  z-index: 13;

  flex: 0 0 auto;

  background-color: #f5f8fa;
  box-shadow: 0 1px 0 rgb(16 22 26 / 15%), 1px 0 0 rgb(16 22 26 / 15%);

  -webkit-box-flex: 0;
}

.bp4-table-column-headers {
  @mixin paragraph_secondary_medium;

  position: relative;
  z-index: 11;

  display: block;

  color: var(--color-light-text-secondary);

  background-color: var(--color-light-bg-secondary);
}

.bp4-table-row-headers {
  position: relative;
  z-index: 12;

  flex: 0 0 auto;

  color: #5c7080;

  background-color: #f5f8fa;

  transition: width 100ms cubic-bezier(0.4, 1, 0.75, 0.9);

  -webkit-box-flex: 0;
}

.bp4-table-body {
  position: relative;
  z-index: 10;

  overflow: scroll;
  flex: 1 1 100%;

  -webkit-box-flex: 1;
}

.bp4-table-body-virtual-client {
  position: relative;
}

.bp4-table-tbody {
  display: block;
  white-space: nowrap;
}

.bp4-table-no-layout {
  position: absolute;
  display: inline-block;
}

.bp4-table-scrollbar-measure {
  position: absolute;
  top: -9999px;

  overflow: scroll;

  width: 100px;
  height: 100px;
}

.bp4-context-menu-popover-target {
  position: fixed;
}

.bp4-context-menu .bp4-popover-target {
  display: block;
}

.bp4-popover-wrapper.bp4-fill {
  width: 100%;
}

.bp4-portal {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

body.bp4-overlay-open {
  overflow: hidden;
}

.bp4-overlay {
  position: static;
  z-index: 20;
  inset: 0;
}

.bp4-overlay:not(.bp4-overlay-open) {
  pointer-events: none;
}

.bp4-overlay.bp4-overlay-container {
  position: fixed;
  overflow: hidden;
}

.bp4-overlay.bp4-overlay-container.bp4-overlay-inline {
  position: absolute;
}

.bp4-overlay.bp4-overlay-scroll-container {
  position: fixed;
  overflow: auto;
}

.bp4-overlay.bp4-overlay-scroll-container.bp4-overlay-inline {
  position: absolute;
}

.bp4-overlay.bp4-overlay-inline {
  overflow: visible;
  display: inline;
}

.bp4-overlay-content {
  position: fixed;
  z-index: 20;
}

.bp4-overlay-inline .bp4-overlay-content,
.bp4-overlay-scroll-container .bp4-overlay-content {
  position: absolute;
}

.bp4-overlay-backdrop {
  user-select: none;

  position: fixed;
  z-index: 20;
  inset: 0;

  overflow: auto;

  opacity: 1;
  background-color: rgb(16 22 26 / 70%);
}

.bp4-popover-backdrop {
  background: var(--color-light-specialbg-nulled);
}

.bp4-transition-container {
  z-index: 20;

  display: -webkit-box;
  display: flexbox;
  display: flex;

  opacity: 1;
}

.bp4-popover.bp4-minimal.bp4-popover {
  transform: scale(1);
}

.bp4-popover.bp4-minimal {
  margin: 0 !important;
}

.bp4-popover {
  z-index: 20;
  transform: scale(1);
  display: inline-block;
  border-radius: 3px;
}

.bp4-popover .bp4-popover-content,
.bp4-popover .bp4-heading {
  color: inherit;
}

.bp4-popover .bp4-popover-content {
  position: relative;
  border-radius: 3px;
}

.noHandle .bp4-table-reorder-handle-target,
.noHandle .bp4-table-resize-handle-target {
  display: none !important;
}

.alignStartColumnHeader .bp4-table-header-content > :first-child {
  flex-direction: row-reverse;
}

.alignStartColumnHeader .bp4-table-reorder-handle-target {
  right: 0;
  flex: 1;

  mask-position: left;
}

.headerCell .bp4-table-header-content > :first-child {
  cursor: pointer;

  overflow: hidden;
  display: flex;
  flex: 1;
  gap: var(--gap-xs);
  align-items: center;
  justify-content: flex-end;

  padding: 0 var(--gap-xs);

  text-align: end;
  text-overflow: ellipsis;
}

.headerCell.firstColumnHeaderCell .bp4-table-header-content > :first-child {
  flex-direction: row-reverse;
}

.headerCell:hover .sortIcon,
.headerCell:hover .filterIcon {
  display: block;
}

.headerCell_noResize .bp4-table-resize-handle-target {
  display: none;
}

.headerCell_disabledSorting .bp4-table-header-content > :first-child {
  cursor: default;
}

.sortIcon,
.filterIcon {
  transform-origin: center;

  display: none;

  min-width: 12px;
  min-height: 12px;

  transition: display 0.2s ease, transform 0.2s ease;
}

.doubleRowHeaderCell.doubleRowHeaderCell.doubleRowHeaderCell {
  @mixin paragraph_secondary_large;

  height: 48px;
  min-height: 16px;
}

.doubleRowHeaderCell .bp4-table-header-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  padding: var(--gap-xs) 0;
}

.doubleRowHeaderCell.alignStartColumnHeader.bp4-table-column-header-cell.bp4-table-has-reorder-handle:not(
    .bp4-table-has-interaction-bar
  ):hover
  .bp4-table-header-content {
  padding: var(--gap-xs) 0;
}

.noReorder.noReorder.noReorder .bp4-table-reorder-handle-target {
  display: none !important;
}
