.dividendsTitle {
  margin-bottom: 16px;
}

.couponsTitle {
  margin-bottom: 28px;
}

.progressBar {
  margin-bottom: 12px;
}

.positionTitle {
  margin-bottom: 20px;
}

.positionCount {
  margin-bottom: 36px;
}

.tabs {
  margin-bottom: 32px !important;
}

.datesRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}
