/*
 Переопределения стилей компонента Switch
*/

@import '@alfalab/core-components/vars/typography.css';

:root {
  --switch-bg-color: var(--color-light-graphic-tertiary);
  --switch-border-color: var(--color-light-graphic-tertiary);
  --switch-checked-bg-color: var(--control-accent-bg-color);
  --switch-checked-border-color: var(--control-accent-bg-color);
}

[class*='switch__component_'] {
  [class*='switch__content_'] {
    [class*='switch__reversed_']& {
      margin-right: var(--gap-s);
    }
  }

  [class*='switch__label_'] {
    @mixin paragraph_secondary_large;
  }

  [class*='switch__switch_'] {
    width: 28px;
    height: 16px;
    margin: 0;

    &::before {
      width: 12px;
      height: 12px;

      [class*='switch__checked_']& {
        transform: translateX(12px);
      }
    }
  }
}
