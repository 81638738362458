.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 325px;
  margin-top: var(--gap-5xl);
  margin-bottom: var(--gap-5xl);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  margin-bottom: var(--gap-2xl);
}

.title {
  width: 320px;
  text-align: center;
}

.description {
  width: 320px;
  margin-top: var(--gap-s);
  text-align: center;
}

.action {
  width: 239px;
}
