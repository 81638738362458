.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 554px;
  padding-top: var(--gap-7xl);
}

.icon {
  margin: var(--gap-6xl) auto var(--gap-xl);
}

.title {
  margin-bottom: var(--gap-s);
}

.description {
  margin-bottom: var(--gap-2xl);
  width: 250px;
  text-align: center;
}

.actions {
  width: 239px;
}
