.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 4px;
}

.wrapper + .wrapper {
  margin-top: 22px;
}

.icon {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 24px;
  max-width: 24px;
}

.currency {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-light-text-secondary);
  margin-left: 4px;
}

.subtitleRight {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: var(--color-light-text-secondary);
  white-space: nowrap;
}
