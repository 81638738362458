.section {
  display: flex;
  flex-wrap: wrap;
}

.field {
  width: calc(50% - var(--gap-m) / 2);
  margin-bottom: var(--gap-m);
}

.fieldFull {
  width: 100%;
}

.label {
  font-size: 11px;
  line-height: 16px;
  color: var(--text-color-secondary);
}

.value {
  font-size: 13px;
  line-height: 16px;
  color: var(--text-color-primary);
}
