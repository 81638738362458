.container {
  width: 100%;
  max-width: 300px;
  text-align: center;
  margin: 0 auto;
  height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: var(--gap-s);
}

.iconPlaceholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  margin-bottom: var(--gap-s);
  background: var(--color-light-bg-secondary);
  border-radius: var(--border-radius-xl);
}

.repeat {
  width: 190px;
  margin-top: var(--gap-s);
}
