.spinnerContainer {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.spinner {
  height: 100%;
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
