.listItem {
  padding: var(--gap-s) 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.text {
  flex-grow: 1;
}

.leftIcon {
  color: var(--text-color-secondary);
  margin-right: var(--gap-m);
}

.rightIcon {
  color: var(--text-color-secondary);
  margin-left: var(--gap-m);
}
