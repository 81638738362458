@import url('@alfalab/core-components/vars/typography.css');

.label {
  display: flex;
  align-items: center;
  margin-bottom: var(--gap-2xs);
  color: var(--text-color-secondary);
}

.graphic {
  color: var(--color-light-graphic-tertiary-inverted);
}

.customInput {
  --form-control-s-min-height: 24px;
  --form-control-disabled-opacity: 0.8;
}

.customInputField input {
  @mixin action_secondary_large;
}

.customInputField input:disabled {
  opacity: 0;
}

.customInputField input + div {
  @mixin action_secondary_large;

  /* stylelint-disable-next-line property-disallowed-list */
  font-weight: 500 !important;
}

.priceAddons {
  user-select: none;
  display: flex;
  gap: var(--gap-2xs);
  align-items: center;
}

.playButton {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;
  padding: var(--gap-3xs);

  color: var(--color-light-graphic-secondary);

  border-radius: var(--border-radius-s);
}

.playButton:not(.playButtonActive):hover {
  color: var(--color-light-graphic-primary);
}

.playButtonActive {
  color: var(--color-light-text-primary-inverted);
  background-color: var(--color-light-text-primary);
}

.stepper,
.additionalPrice,
.playButton {
  transition: background 0.2s ease;
}
