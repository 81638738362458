.back {
  display: flex;
  align-items: center;
  gap: var(--gap-xs);
}

.backIcon {
  width: 32px;
  height: 32px;
  background-color: var(--color-light-bg-quaternary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  cursor: pointer;
}
