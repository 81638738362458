.certificate {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.certificateIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
}

.certificateRows {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.rowTitle {
  margin-bottom: var(--gap-2xs);
}

.buttons {
  width: 100%;
  margin-top: var(--gap-s);
}

.certificates {
}

.otherCertificatesTitle {
  margin-bottom: 24px;
}

.notificationsContainer {
  margin-bottom: var(--gap-xl);
}

.allowIssueCertificate {
  margin-top: var(--gap-xs);
}