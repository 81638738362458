.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--gap-m);
  padding: var(--gap-xs) var(--gap-m);
}

.inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input {
  flex-grow: 1;
}

.dash {
  margin: 0 var(--gap-xs);
}

/* stylelint-disable */
.customInputField > div:first-child > div > div:first-of-type {
  font-size: 13px;
  font-weight: normal;
  line-height: 16px;
}

.customInputField input {
  font-size: 13px;
  font-weight: normal;
  line-height: 16px;
}
/* stylelint-enable */

.customInputField input:disabled {
  opacity: 0;
}

.customInput {
  --form-control-s-min-height: 24px;
  --form-control-bg-color: var(--form-control-focus-mobile-bg-color);

  max-width: 120px;
}

.calendarModeButton {
  --size-xxs-height: 24px;
  --button-font-weight: 400;

  text-align: left;
}
