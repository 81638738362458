.cascana-chat-widget + div {
  border: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  > div {
    &:nth-child(1) {
      background-color: var(--color-dark-bg-tertiary);
    }

    &:nth-child(2) {
      background-color: var(--color-dark-bg-secondary);

      > div {
        background-color: var(--color-dark-bg-secondary);

        &::-webkit-scrollbar {
          width: auto;
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          border: 6px solid transparent;
          border-radius: 24px;
          background-clip: content-box;
          background-color: var(--color-dark-specialbg-secondary-transparent);

          &:hover {
            border: 4px solid transparent;
            background-color: var(--color-dark-specialbg-tertiary-transparent);
          }
        }
      }
    }
  }
}
