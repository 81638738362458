/*
 Не редактировать. Сгенерировано автоматически
*/

:root {
  --color-light-border-primary: #dcdcdd;
  --color-light-border-secondary: #e9e9eb;
  --color-light-border-tertiary: #f3f4f5;
  --color-light-border-underline: #c5c5c7;
  --color-light-border-key: #000000;
  --color-light-border-accent: #7938e0;
  --color-light-border-link: #0072ef;

  --color-light-border-primary-inverted: #2b2b2e;
  --color-light-border-secondary-inverted: #262629;
  --color-light-border-tertiary-inverted: #1c1c1e;
  --color-light-border-underline-inverted: #464649;
  --color-light-border-key-inverted: #ffffff;

  --color-light-border-underline-alpha-30: rgba(197, 197, 199, 0.3);
  --color-light-border-key-alpha-7: rgba(0, 0, 0, 0.07);
  --color-light-border-key-alpha-15: rgba(0, 0, 0, 0.15);
  --color-light-border-underline-inverted-alpha-30: rgba(70, 70, 73, 0.3);
  --color-light-border-key-inverted-alpha-7: rgba(255, 255, 255, 0.07);
  --color-light-border-key-inverted-alpha-15: rgba(255, 255, 255, 0.15);
}
