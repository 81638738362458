.wrapper {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
}

.rowInvert {
  flex-direction: row-reverse;
}

.label {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-light-text-secondary);
  margin-bottom: 2px;
}

.labelRight {
  text-align: right;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-light-text-secondary);
  margin-bottom: 2px;
}

.priceRowBackground {
  width: 100%;
  height: 4px;
  background: var(--color-light-bg-secondary);
  border-radius: 4px;
}

.priceRow {
  position: relative;
  height: 100%;
}

.openPick {
  position: absolute;
  width: 2px;
  height: 8px;
  top: -4px;
  background-color: var(--color-light-text-primary);
}

.lastPick {
  position: absolute;
  width: 0;
  top: -14px;
}

.lastPick svg {
  position: relative;
  left: -6px;
}

.pickLeft {
  left: 0;
  text-align: left;
}

.pickRight {
  right: 0;
  text-align: right;
}

.lastPrice {
  display: flex;
  justify-content: end;
}
