.head {
  padding: var(--gap-xs) 0;
  margin-bottom: var(--gap-m);
  display: flex;
  justify-content: space-between;
}

.recomendation {
  margin-bottom: var(--gap-xl);
}

.title {
  /* Дизайн настаивает что это нужные цвета, так как им не хватает градаций серого в тексте */
  color: var(--color-light-graphic-quaternary-inverted);
  margin-right: var(--gap-m);
}

.action {
  min-width: 180px;
  display: flex;
  align-items: center;
}

.button {
  /* !important - чтобы перебить специфичность сложных селекторов из _button.css */
  font-size: 11px !important;
  line-height: 16px !important;
  padding: 0 var(--gap-s) !important;
}

.video {
  margin-bottom: var(--gap-xl);
}

.description {
  margin-bottom: var(--gap-l);
}

.addTitle {
  margin-bottom: var(--gap-xl);
}

.documents {
  background: var(--color-light-bg-secondary);
  border-radius: var(--border-radius-s);
  padding: var(--gap-m) var(--gap-xl);
}

.docItem {
  margin-top: var(--gap-m);
}

.docItem:first-child {
  margin-top: 0;
}

.filespinner {
  width: 12px;
  height: 12px;
}