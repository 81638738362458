.popover {
  display: flex;
  flex-direction: column;
  background-color: var(--color-light-bg-quaternary);
  border-radius: var(--border-radius-l);
  padding: var(--gap-s);
  gap: var(--gap-3xs);
}

.percent {
  display: inline-flex;
  align-items: center;
}

.quaternary {
  color: var(--color-light-graphic-quaternary-inverted);
}

.tertiary {
  color: var(--color-light-graphic-tertiary-inverted);
}