.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--color-light-bg-primary);
}

.body {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  overflow: auto;
  padding-top: var(--gap-xl);
  padding-left: var(--gap-m);
  padding-right: var(--gap-m);
  padding-bottom: var(--gap-4xl);
}
