.cellDoubleRow {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.firstRowContent {
  min-height: 15.5px;
}

.secondRowWrapper {
  min-height: 15.5px;
}

.secondRowContent {
  margin: -4px -8px 0;
}

.symbolCellSecondRow {
  color: var(--color-light-text-secondary);
}

.symbolCellDoubleRow {
  display: flex;
}

.symbolCellDoubleRowRightSide {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 9px;
}

.directionCell {
  display: flex;
  gap: var(--gap-xs);
  align-items: center;
  justify-content: flex-end;
}

.buy {
  color: var(--color-buy);
}

.sell {
  color: var(--color-sell);
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.hoverActions {
  position: absolute;
  top: 0;
  z-index: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: var(--gap-xs);
  background: linear-gradient(
    270deg,
    var(--color-light-bg-tertiary) 60%,
    var(--color-light-specialbg-nulled) 100%
  );
}

.iconsWrapper {
  display: flex;
  justify-content: flex-start;
  gap: var(--gap-xs);
}

.tooltipContent {
  max-width: 165px;
}

.tooltipTarget {
  display: flex;
}

.deleteIcon {
  text-align: left;
  cursor: pointer;
  color: var(--color-light-graphic-secondary);
}

.deleteIcon:hover {
  color: var(--color-light-text-primary);
}


