.amount {
  font-size: 11px;
  margin: 0 0 var(--gap-2xs);
  color: var(--color-light-text-secondary);
}

.bar {
  height: 6px;
  background: var(--color-light-bg-quaternary);
}
