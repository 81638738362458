.doubleRowSetting {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 var(--gap-xs);
}

.doubleRowDragIcon:hover,
.deleteIcon:hover {
  color: var(--icon-button-secondary-hover-color);
}

.doubleRowSetting:hover {
  background-color: var(--select-option-hover-background);
}

.doubleRowSetting .doubleRowDragIcon,
.doubleRowSetting .deleteIcon {
  display: none;
}

.doubleRowSetting:hover .doubleRowDragIcon,
.doubleRowSetting:hover .deleteIcon {
  display: block;
}

.doubleRowSetting::after {
  position: absolute;

  /* doubleRowColumnHeader height + margin-bottom */
  top: 44px;
  bottom: var(--gap-xs);

  width: 1px;

  background-color: rgba(235 235 245 / 60%);
}

.doubleRowSetting.insertAfter::after {
  content: '';
  right: -4px;
}

.doubleRowSetting.insertBefore::after {
  content: '';
  left: -4px;
}

.doubleRowColumnHeader {
  display: flex;
  align-items: center;

  height: 36px;
  padding: var(--gap-xs) 0;

  color: var(--dark-graphic-secondary);
}

.doubleRowColumnName {
  flex: 1;
}

.selectWrapper {
  display: flex;
  flex-direction: column;
}
