.root {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: var(--gap-xs);
  color: var(--color-light-graphic-attention);
}

.icon {
  width: 16px;
  height: 16px;
}
