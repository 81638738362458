.tooltipTarget {
  display: inline-block;
  vertical-align: top;
}

.icon {
  width: 12px;
  height: 12px;
  vertical-align: middle;
}
