.modal {
  border-radius: var(--border-radius-l);
  overflow: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 700px;
  background-color: var(--color-light-bg-primary);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--header-height);
  min-height: var(--header-height);
  background-color: var(--color-light-bg-secondary);
  padding: 0 var(--gap-m);
}

.rightButtons {
  display: flex;
  align-items: center;
  gap: var(--gap-m);
}

.quaternary {
  color: var(--color-light-graphic-quaternary-inverted);
}

.body {
  position: relative;
  overflow: hidden;
  flex-grow: 1;
}

.tabs {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.tabs > div:first-child {
  overflow: unset;
}

.tabs [role='tablist'] {
  padding: var(--gap-m) var(--gap-m) 0;
  z-index: 10;
  position: sticky;
  top: -1px;
  background-color: var(--color-light-bg-primary);
  transition: var(--button-transition);
}

.tabs [role='tabpanel'] {
  flex-grow: 1;
  overflow-y: auto;
}

.tabs span {
  line-height: 16px;
}