.tooltipContent {
  width: 200px;

  b {
    margin-bottom: var(--gap-s);
  }
}

.costEstimateTitle {
  margin-bottom: 20px;
}
