.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.attentionWrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: var(--gap-2xs);
}

.infoWrapper {
  padding: var(--gap-2xs) 0;
  text-align: left;
}

.label {
  display: flex;
  gap: var(--gap-2xs);
  align-items: center;
}

.icon {
  position: relative;
  top: 1px;
}

.footer {
  position: sticky;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: end;

  width: 100%;
  margin-top: auto;
  margin-bottom: var(--gap-m-neg);
  padding: var(--gap-s) 0;

  background-color: var(--color-light-bg-primary);
  border-top: 1px solid var(--divider-color);
}

.checkIcon {
  position: relative;
  top: 2px;
}

.input {
  --form-control-s-min-height: 24px;
}

.inputAddons svg {
  width: 16px;
  height: 16px;
}

.selectionColumn {
  width: 16px;
}
