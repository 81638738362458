.modal {
    width: 360px;
    position: fixed;
    bottom: 0;
    right: 0;
}

.background {
    height: 220px;
    padding-bottom: 0;
    width: 100%;
}

.modalContent {
    padding: var(--gap-2xl) var(--gap-xl);
    padding-bottom: 0;
    font-size: 16px;
    text-align: center;
}

.modalHeader {
    padding: 0px;
}

.modalFooter {
    padding: var(--gap-2xl) var(--gap-xl);
}