.container {
  display: flex;
  align-items: center;
  gap: var(--gap-xs);
}

.iconWrapper {
  display: flex;
  align-items: center;
  color: var(--color-light-text-secondary);
  height: 16px;
  width: 16px;
}

.icon {
  max-width: 100%;
  max-height: 100%;
}

/* Для иконок с заливкой (icons-classic) */
.icon *[fill-rule] {
  fill: currentcolor;
}
