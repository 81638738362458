.content {
  border-radius: var(--border-radius-m);
  background-color: var(--select-option-background);
  box-shadow: var(--shadow-xl);
}

.dateButtons {
  display: flex;
  justify-content: space-between;
  padding: 20px 22px;
  border-radius: 0 0 var(--border-radius-m) var(--border-radius-m);
  background: var(--color-light-bg-tertiary);
}

.dateButton {
  width: calc(50% - 5px);
}
