.entryPointContainer {
  margin-bottom: var(--gap-xl);
}

.entryPointContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: var(--gap-s);
}

.entryPointButton {
  width: 231px;
  margin-bottom: var(--gap-xs);
}

.entryPointEmptySubtitle {
  max-width: 320px;
}

.entryPointButtonsBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: var(--gap-m);
  margin: var(--gap-l) 0;
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.detailsIcon {
  margin-bottom: var(--gap-xl);
}

.detailsTitle {
  margin-bottom: var(--gap-m);
}

.detailsDescription {
  margin-bottom: var(--gap-5xl);
}

.detailsAcceptText {
  margin-bottom: var(--gap-2xl);
  width: 400px;
}

.detailsFooterText {
  width: 400px;
}

.innerDocumentHtml,
.innerDocumentHtml a {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: var(--color-light-text-primary);
}

.questionContainer {
  margin: var(--gap-xl) 0 var(--gap-2xl);
}

.testSubtitle {
  margin-bottom: var(--gap-xl);
}

.testQuestionTitle {
  display: inline-block;
  margin-bottom: var(--gap-l);
}

.textNextButton {
  width: 239px;
}

.divider {
  margin-bottom: 8px;
}
