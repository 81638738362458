.container {
  --notifications-item-padding-vertical: 4px;
  --notifications-item-padding-horizontal: 8px;
  --notifications-item-font-weight: 500;
  --notifications-item-status-width: 12px;
  --notifications-item-marker-size: 6px;
}

.container {
  display: flex;
  padding: var(--notifications-item-padding-vertical)
    var(--notifications-item-padding-horizontal);
  font-weight: var(--notifications-item-font-weight);
  background: var(--dark-bg-secondary);
  border-radius: var(--border-radius-s);
  cursor: pointer;
}

.container:hover {
  background: var(--dark-bg-tertiary);
}

.status {
  width: var(--notifications-item-status-width);
  text-align: right;
  margin-right: var(--gap-xs);
  flex-shrink: 0;
}

.marker {
  display: inline-block;
  vertical-align: middle;
  width: var(--notifications-item-marker-size);
  height: var(--notifications-item-marker-size);
  border-radius: 100%;
  background-color: var(--dark-graphic-accent);
}
