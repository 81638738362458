.tableContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tableRow {
  height: 24px;
  display: flex;
}

.tableHeaderRow {
  & > div {
    background-color: var(--color-light-bg-secondary);
    box-shadow: 0 2px 0 var(--color-light-bg-primary), inset -2px 0 0 var(--color-light-bg-primary);
  }
}

.tableContentRow {
  &:nth-child(odd) {
    /* Договор с дизайном временно переиспользовать background с Table2 (таблица инструменты)
    пока они готовят новую палитру */
    background-color: color-mix(in srgb, var(--color-light-bg-secondary) 60%, transparent);
  }
}

.tableColumn {
  display: flex;
  align-items: center;
  overflow: hidden;
  padding-left: 8px;

  & > * {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
