.cellCheck {
  width: 48px;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.cellType {
  width: 35%;
}

.cellIsin {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cellCount {
  width: 18%;
  text-align: right;
}

.summary {
  margin-top: var(--gap-s);
}

.divider {
  margin: var(--gap-l) 0;
}

.attach.attachBlock {
  display: flex;
}

.attach [class*='button__addons'] {
  display: none;
}

.attach [class*='attach__file'] {
  display: none;
}

.attach [class*='attach__noFile'] {
  display: none;
}
