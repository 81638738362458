.container {
  display: flex;
  flex-direction: column;
}

.statusContainer {
  display: flex;
  gap: var(--gap-xs);
}

.acceptionLink {
  margin-top: var(--gap-xs);
  display: flex;
  align-items: center;
}

.tooltipContent {
  width: 352px;
}

.tooltipList {
  margin-top: var(--gap-xl);
}

.tooltipArrowHide {
  display: none;
}

.infoLink {
  margin-top: var(--gap-xs);
}

.sentStatus {
  color: var(--text-color-secondary);
  display: flex;
  align-items: center;
  gap: var(--gap-2xs);
  margin-top: var(--gap-xs);
}
