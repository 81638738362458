.title {
  display: flex;
  align-items: center;
}

.title svg {
  display: block;
}

.tooltipContent {
  b {
    display: block;
  }
}
