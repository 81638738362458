.items {
  display: flex;
  flex-wrap: wrap;
}

.item {
  max-width: 223px;
  margin: 0 var(--gap-m) var(--gap-m) 0;
}

.nofiticationsContainer {
  margin-top: var(--gap-xs);
}
