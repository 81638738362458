.wrapper {
  display: flex;
  flex-direction: column;
  padding: var(--gap-xs) 0;

  min-width: 220px;
  background-color: var(--dark-bg-secondary);
  border-radius: 1px;
  box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.16), 0px 4px 8px rgba(0, 0, 0, 0.16),
    0px 0px 1px rgba(0, 0, 0, 0.16);
  z-index: 2;
}

.subMenu {
  overflow: auto;
  padding-top: 0;
}

.subMenu::-webkit-scrollbar {
  background-color: var(--dark-bg-secondary);
}

.subMenu::-webkit-scrollbar-thumb {
  background-color: #464649;
}

.headerButton {
  height: 32px;
  margin-top: var(--gap-xs) !important;
  margin-bottom: var(--gap-xs) !important;
  padding-left: var(--gap-xs) !important;
  padding-right: var(--gap-xs) !important;
  font-size: 13px !important;
  line-height: 16px !important;
  color: #dcdcdd !important;
  border-radius: 0px !important;
  justify-content: flex-start !important;
}

.headerButton::before {
  content: none !important;
}

.headerButton:hover {
  background-color: var(--select-option-hover-background);
  cursor: pointer;
}

.tooltipArrow:after {
  left: 6px !important;
}

.tooltip[data-popper-placement='top-end'] .tooltipArrow:after {
  left: -18px !important;
}

.tooltip {
  box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.16), 0px 4px 8px rgba(0, 0, 0, 0.16),
    0px 0px 1px rgba(0, 0, 0, 0.16);
}

.tooltipTarget {
  display: flex;
}

.dropDownItemContent {
  display: flex;
  align-items: center;
  gap: var(--gap-2xs);
}

.tooltipContent {
  max-width: 200px;
  color: #c5c5c7;
}

.blockHeading {
  padding: var(--gap-xs);
  cursor: default;
  color: var(--color-light-graphic-quaternary-inverted);
}