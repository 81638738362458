.customBottomSheet {
  padding-bottom: env(safe-area-inset-bottom);
  overflow-wrap: break-word;
}

.customHeader span {
  color: #c5c5c7;
}

.container::-webkit-scrollbar {
  background-color: var(--dark-bg-secondary);
}

.container::-webkit-scrollbar-thumb {
  background-color: var(--dark-graphic-tertiary);
}
