.cardName {
  margin: 0 0 var(--gap-l);
}

.blockPlate {
  background-color: var(--dark-bg-tertiary);
  border-color: var(--dark-border-color-secondary);
  padding: var(--gap-xs);
  border-radius: var(--border-radius-m);
  display: flex;
}

.blockPlateIcon {
  margin-right: var(--gap-s);
}

.blockPlateText {
  margin-top: 3px;
  font-size: 13px;
  line-height: 16px;
}

.feeInputContent {
  display: flex;
  align-items: center;
  gap: var(--gap-xs);
}

.plate {
  height: var(--form-control-s-min-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: var(--gap-xs);
  border: 1px solid var(--color-light-border-primary);
  border-radius: var(--border-radius-s);
}

.tooltipIcon {
  transform: translateY(2px);
}

.footer {
  margin: var(--gap-l) 0 0;
}

.inputSmall {
  --gap-s: var(--gap-xs);
  --form-control-s-min-height: 32px;
  --form-control-paddings: 0 var(--gap-xs);
  --form-control-hint-color: var(--text-color-secondary);
  --input-disabled-color: var(--text-color-disabled);
  --form-control-disabled-bg-color: var(--dark-bg-tertiary);
}

.inputSmall input {
  font-size: 13px;
}

.inputSmall input + div[class*='suffixContainer'] {
  font-size: 13px;
}

.inputSmall [class*='form-control__sub'] {
  font-size: 11px;
}

.label {
  display: flex;
  align-items: center;
  margin: 0 0 var(--gap-2xs);
}
