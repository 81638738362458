.cell {
  position: relative;
}
.cellName {
  width: 26.3538%;
  white-space: nowrap;
  overflow: hidden;
}
.cellAccount {
  width: 31.4079%;
  color: var(--color-light-text-secondary);
  white-space: nowrap;
  overflow: hidden;
}
.cellBank {
  width: 41.5162%;
  color: var(--color-light-text-secondary);
  white-space: nowrap;
  overflow: hidden;
}

.cell:not(.cellHead)::after {
  content: '';
  width: 24px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(
    90deg,
    var(--color-light-specialbg-nulled) 0%,
    var(--color-light-bg-primary) 24px
  );
}

.cellBank:not(.cellHead)::after {
  width: 56px;
}

.rowOdd .cell:not(.cellHead)::after {
  background: linear-gradient(
    90deg,
    var(--color-light-specialbg-nulled) 0%,
    var(--color-light-bg-secondary) 24px
  );
}
