.tooltipContent {
  width: 200px;

  b {
    margin-bottom: var(--gap-2xs);
  }
}

.companyIndicatorsTitle {
  margin-top: var(--gap-3xl);
  margin-bottom: var(--gap-l);
}
