.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.where {
  margin: 0 0 var(--gap-2xl);
}

.position {
  margin: 0 0 var(--gap-2xl);
}

.footer {
  margin: var(--gap-l) 0 0;
}

.fields {
  margin: 0 0 var(--gap-2xl);
}

.marginControl {
  margin-top: var(--gap-xs);
}

.taxDetail {
  margin: 0 0 var(--gap-2xl);
}

.tab {
  padding-top: var(--gap-l);
}

.glyph {
  color: var(--color-white);
}