.container {
  padding: var(--gap-xl) 0 0;
}

.treaty {
  margin-bottom: var(--gap-xl);
}

.filter {
  margin-bottom: var(--gap-2xl);
}

.tagFilter {
  margin-right: var(--gap-xs);
}