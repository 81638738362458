.treaty {
  margin: 0 0 var(--gap-2xl);
}

.filter {
  margin: 0 0 var(--gap-2xl);
}

.totals {
  margin: 0 0 var(--gap-2xl);
}

.download {
  margin: 0 0 var(--gap-3xl);
}

.loader {
  color: wheat;
}
