.graphic {
  color: var(--color-light-graphic-quaternary-inverted);
}

.dropdownRef {
  display: flex;
  flex: 1;
  align-items: center;
  user-select: none;
  height: 24px;

  padding: var(--gap-2xs) var(--gap-xs);
  border-radius: 4px;
  color: var(--form-control-color);
  background-color: var(--form-control-bg-color);
  transition: background-color 0.2s ease, border 0.2s ease, color 0.2s ease,
  transform 0.12s ease;
}

.dropdownRef:not(.disabled):hover {
  cursor: pointer;
  background-color: var(--form-control-hover-bg-color);
}

.dropdownRef.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.dropDownContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: var(--gap-m);
  min-width: 0;
}

.dropDownItemContent {
  display: flex;
  gap: var(--gap-2xs);
}

.text {
  flex: 1;
  color: var(--dark-text-primary);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chevron {
  margin-left: auto;
  transition: color 0.2s ease, border 0.2s ease, color 0.2s ease,
  transform 0.12s ease;
}

.chevronUp {
  transform: rotate(-0.5turn);
}

.popper {
  min-width: unset !important;
}

.popperSmall {
  min-width: 120px !important;
}
