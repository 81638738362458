.qtyWrapper {
  display: flex;
  flex-direction: column;

  padding: var(--gap-2xs) var(--gap-xs) var(--gap-xs) var(--gap-xs);

  --progressbar-positive-color: var(--color-buy);
  --progressbar-bg-color: var(--color-sell);
}

.qtyWrapper [class*='progress-bar__filled'] {
  border-radius: var(--border-radius-s) 0 0 var(--border-radius-s);
}

.qtyValues {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--gap-2xs);

  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  color: var(--color-light-graphic-quaternary-inverted);
}
