@import url('@alfalab/core-components/vars/typography.css');

.buttonsWrapper {
  display: flex;
  gap: var(--gap-xs);
  justify-content: flex-end;
  padding: var(--gap-s) var(--gap-m) 0;
}

.customButton span {
  @mixin paragraph_secondary_small;
}
