/*
 Не редактировать. Сгенерировано автоматически
*/

:root {
  --color-light-graphic-primary: #0f0f0f;
  --color-light-graphic-secondary: #86868a;
  --color-light-graphic-tertiary: #c1c1c3;
  --color-light-graphic-quaternary: #dcdcdd;
  --color-light-graphic-accent: #7938e0;
  --color-light-graphic-link: #0072ef;
  --color-light-graphic-positive: #2fc26e;
  --color-light-graphic-attention: #f6bf65;
  --color-light-graphic-negative: #f15045;

  --color-light-graphic-primary-inverted: #ffffff;
  --color-light-graphic-secondary-inverted: #85858d;
  --color-light-graphic-tertiary-inverted: #4f4f52;
  --color-light-graphic-quaternary-inverted: #303032;

  --color-light-graphic-primary-alpha-30: rgba(15, 15, 15, 0.3);
  --color-light-graphic-primary-alpha-40: rgba(15, 15, 15, 0.4);
  --color-light-graphic-primary-alpha-50: rgba(15, 15, 15, 0.5);
  --color-light-graphic-primary-alpha-60: rgba(15, 15, 15, 0.6);
  --color-light-graphic-primary-tint-15: rgb(51, 51, 51);
  --color-light-graphic-primary-tint-20: rgb(63, 63, 63);
  --color-light-graphic-primary-tint-30: rgb(87, 87, 87);
  --color-light-graphic-primary-shade-10: rgb(14, 14, 14);
  --color-light-graphic-primary-shade-30: rgb(11, 11, 11);
  --color-light-graphic-secondary-alpha-30: rgba(134, 134, 138, 0.3);
  --color-light-graphic-secondary-shade-30: rgb(94, 94, 97);
  --color-light-graphic-secondary-shade-50: rgb(67, 67, 69);
  --color-light-graphic-tertiary-alpha-30: rgba(193, 193, 195, 0.3);
  --color-light-graphic-tertiary-tint-15: rgb(202, 202, 204);
  --color-light-graphic-tertiary-tint-20: rgb(205, 205, 207);
  --color-light-graphic-tertiary-shade-7: rgb(179, 179, 181);
  --color-light-graphic-tertiary-shade-15: rgb(164, 164, 166);
  --color-light-graphic-quaternary-shade-10: rgb(198, 198, 199);
  --color-light-graphic-accent-alpha-30: rgba(121, 56, 224, 0.3);
  --color-light-graphic-accent-tint-15: rgb(141, 86, 229);
  --color-light-graphic-accent-shade-7: rgb(113, 52, 208);
  --color-light-graphic-accent-shade-10: rgb(109, 50, 202);
  --color-light-graphic-accent-shade-15: rgb(103, 48, 190);
  --color-light-graphic-accent-shade-30: rgb(85, 39, 157);
  --color-light-graphic-link-alpha-15: rgba(0, 114, 239, 0.15);
  --color-light-graphic-positive-alpha-10: rgba(47, 194, 110, 0.1);
  --color-light-graphic-positive-shade-10: rgb(42, 175, 99);
  --color-light-graphic-positive-shade-30: rgb(33, 136, 77);
  --color-light-graphic-negative-alpha-10: rgba(241, 80, 69, 0.1);
  --color-light-graphic-negative-alpha-30: rgba(241, 80, 69, 0.3);
  --color-light-graphic-negative-alpha-40: rgba(241, 80, 69, 0.4);
  --color-light-graphic-negative-tint-20: rgb(244, 115, 106);
  --color-light-graphic-negative-tint-30: rgb(245, 133, 125);
  --color-light-graphic-negative-shade-7: rgb(224, 74, 64);
  --color-light-graphic-negative-shade-15: rgb(205, 68, 59);
  --color-light-graphic-negative-shade-30: rgb(169, 56, 48);
  --color-light-graphic-primary-inverted-alpha-30: rgba(255, 255, 255, 0.3);
  --color-light-graphic-primary-inverted-alpha-40: rgba(255, 255, 255, 0.4);
  --color-light-graphic-primary-inverted-alpha-50: rgba(255, 255, 255, 0.5);
  --color-light-graphic-primary-inverted-alpha-60: rgba(255, 255, 255, 0.6);
  --color-light-graphic-primary-inverted-shade-7: rgb(237, 237, 237);
  --color-light-graphic-primary-inverted-shade-10: rgb(230, 230, 230);
  --color-light-graphic-primary-inverted-shade-15: rgb(217, 217, 217);
  --color-light-graphic-primary-inverted-shade-30: rgb(179, 179, 179);
  --color-light-graphic-secondary-inverted-alpha-30: rgba(133, 133, 141, 0.3);
  --color-light-graphic-secondary-inverted-tint-15: rgb(151, 151, 158);
  --color-light-graphic-secondary-inverted-tint-20: rgb(157, 157, 164);
  --color-light-graphic-secondary-inverted-shade-10: rgb(120, 120, 127);
  --color-light-graphic-secondary-inverted-shade-30: rgb(93, 93, 99);
  --color-light-graphic-tertiary-inverted-alpha-30: rgba(79, 79, 82, 0.3);
  --color-light-graphic-tertiary-inverted-tint-15: rgb(105, 105, 108);
  --color-light-graphic-tertiary-inverted-tint-20: rgb(114, 114, 117);
}
