.count {
  --color-light-text-primary-inverted: var(--text-color-disabled);
}

.count > div {
  font-size: 11px;
  font-weight: 400;
}

.alert {
  padding-right: 6px;
}
