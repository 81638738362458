.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: var(--header-height);
  padding: var(--gap-xs);

  background-color: var(--dark-bg-secondary);
}

.header > svg {
  min-width: 18px;
  min-height: 18px;
}

.header > div:first-child {
  margin-right: var(--gap-m);
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 var(--gap-xs) 0 var(--gap-xs);
}

.content.mobileVersion {
  padding: 0 var(--gap-l) var(--gap-xl) var(--gap-l);
}

.content .limitsPortal {
  display: flex;
  margin: 0 var(--gap-xs-neg);
}

.content .limitsPortal > div {
  display: flex;
  flex: 1;
}

.ordersContainer {
  display: flex;
  flex-direction: row;
  padding: var(--gap-m) 0;
  gap: var(--gap-xs);
  max-height: 48px;
  position: relative;
  box-sizing: content-box;
}

.ordersContainer.mobileVersion {
  padding: var(--gap-m) 0 var(--gap-xl);
}

.bestPriceBox {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 4px;
  border: 1px solid #2b2b2e;
  padding: var(--gap-xs);
  color: #8d8d93;
  cursor: pointer;
}

.askBox {
  align-items: flex-end;
}

.bestPrice {
  color: #c5c5c7;
}

.bidPrice {
  color: var(--color-buy);
}

.askPrice {
  color: var(--color-sell);
}

.spreadPrice {
  color: #c5c5c7;
  background-color: var(--dark-bg-primary);

  padding: var(--gap-2xs) var(--gap-m);
  border: 1px solid #2b2b2e;
  border-radius: 100px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.form {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.directionContainer {
  display: flex;
  justify-content: space-between;
  gap: var(--gap-xs);
}

.columnContainer {
  display: flex;
  justify-content: space-between;
  gap: var(--gap-xs);
  margin-bottom: var(--gap-m);
}

.columnContainer > div {
  flex: 1;
}
.columnContainer > span {
  margin: var(--gap-xs-neg) 0;
  flex: 1;
}

.directionButton {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  height: 32px;

  color: var(--dark-text-primary);
  background-color: var(--dark-bg-tertiary);
  border-radius: 100px;

  cursor: pointer;
  transition: background-color 0.2s ease, border 0.2s ease, color 0.2s ease,
    transform 0.12s ease;
}

.directionButton:hover {
  background-color: #343436;
}

.activeDirectionButton {
  color: #000000;
  background-color: #dcdcdd;
}

.activeDirectionButton:hover {
  background-color: #dcdcdda3;
}

.buy {
  background-color: var(--color-buy) !important;
  color: var(--color-dark-text-positive-inverted) !important;
}

.sell {
  background-color: var(--color-sell) !important;
  color: var(--color-dark-text-negative-inverted) !important;
}

.orderTypeDropdown {
  margin: var(--gap-m) 0;
  flex: 0 1 !important;
}

.orderTypeDropdown.mobileVersion {
  margin: var(--gap-xl) 0;
}

.dropDownItemDisabled,
.dropDownItemDisabled:hover {
  opacity: 0.5;
}

.dropDownContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: var(--gap-m);
  min-width: 0;
}

.chevron {
  margin-left: auto;
  transition: color 0.2s ease, border 0.2s ease, color 0.2s ease,
    transform 0.12s ease;
}

.chevronUp {
  transform: rotate(-0.5turn);
}

.orderName {
  flex: 1;
  color: var(--dark-text-primary);

  max-width: 55%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.instructionName {
  color: var(--dark-text-primary);

  max-width: 55%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.orderName.mobileVersion {
  text-align: left;
}

.menuTitleWrapper {
  display: flex;
  padding: var(--gap-xs);
  color: var(--text-color-secondary);
}

.dropDownItemContent {
  display: flex;
  gap: var(--gap-2xs);
}

.tooltipContent {
  max-width: 200px;
}

.formRow {
  display: grid;
  grid-template-columns: calc(50% - var(--gap-xs) / 2) calc(
      50% - var(--gap-xs) / 2
    );
  justify-content: space-between;
  gap: var(--gap-xs);
  margin-bottom: var(--gap-xs);
}

.inputRow {
  margin-bottom: var(--gap-s);
}

.inputRow.mobileVersion {
  margin-bottom: var(--gap-m);
}

.inputRow.miniVersion {
  margin-bottom: var(--gap-xs);
}

.formRow.mobileVersion {
  margin-bottom: var(--gap-m);
}

.formRow.directionColumn {
  flex-direction: column;
}

.formRow.mobileVersion.mobileMarginsMTopXsBottom {
  margin-top: var(--gap-m);
  margin-bottom: var(--gap-xs);
}

.additionalInstructions.mobileVersion {
  margin: var(--gap-xs) 0 var(--gap-xs);
  width: auto;
  background-color: var(--select-option-background);
}

.dropdownRef {
  display: flex;
  flex: 1;
  align-items: center;
  user-select: none;
  cursor: pointer;
  height: 32px;

  padding: var(--gap-xs);
  border-radius: 4px;
  color: #8d8d93;
  background-color: var(--select-option-background);
  transition: background-color color 0.2s ease, border 0.2s ease,
    color 0.2s ease, transform 0.12s ease;
}

.dropdownRef:hover {
  color: var(--dark-text-primary);
  background-color: var(--select-option-hover-background);
}

.volumeInput {
  margin-bottom: 16px;
}

.chartForm {
  margin-bottom: 0;
}

.customInput {
  --form-control-bg-color: var(--select-option-background);
  --form-control-s-min-height: 32px;
  --form-control-disabled-bg-color: var(--dark-bg-tertiary);
}

.customInput.mobileVersion {
  --form-control-s-min-height: 40px;
}

.customInput.chartForm {
  --form-control-bg-color: var(--dark-bg-tertiary);
  --form-control-s-min-height: 24px;
}

.customInput.chartFormMobile {
  --form-control-bg-color: transparent;
  --form-control-s-min-height: 40px;
  margin-bottom: var(--gap-2xs);
  position: relative;
}

.customInput.chartFormMobile .customInputField {
  z-index: 1;
}

.customInput.chartFormMobile .customInputField:hover {
  background: transparent;
}

.customInput.chartFormMobile:after {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 8px);
  height: 40px;
  background: #2c2c2e;
  z-index: 0;
  border-radius: 4px;
}

.customInputField > div:first-child > div > div:first-of-type {
  font-size: 13px;
  line-height: 16px;
  font-weight: normal;
}

.customInputField.mobileVersion > div:first-child > div > div:first-of-type {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.customInputField input {
  font-size: 13px;
  line-height: 16px;
  font-weight: normal;
}

.customInputField input:disabled {
  opacity: 0;
}

.bottomWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.plate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin-top: var(--gap-m);
  padding: var(--gap-xs);
  border: 1px solid #2b2b2e;
  border-radius: var(--border-radius-s);

  color: #c5c5c7;
  --color-light-text-secondary: #8d8d93;
}

.plateBond {
  flex-direction: column;
}

.plateBond > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  width: 100%;
}

.mobileFormRowFullWidth {
  width: 100%;
  display: flex;
}

.plate.mobileVersion {
  padding: var(--gap-xs) var(--gap-l);
}

.mobilePlateLeftPart {
  text-align: left;
  display: flex;
  flex-direction: column;
}

.mobilePlateRightPart {
  text-align: right;
}

.mobileFinalAmount {
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
}

.fee.mobileVersion {
  font-size: 12px;
  line-height: 16px;
}

.extraSmallButton {
  font-size: 11px;
  line-height: 16px;
  background-color: var(--dark-bg-tertiary);
}

.extraSmallButton:hover {
  background-color: #343436;
}

.positions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--gap-2xs);
  margin-top: calc(-1 * var(--gap-s));
  margin-bottom: var(--gap-m);
}

.priceAddons {
  display: flex;
  gap: var(--gap-2xs);
  align-items: center;
  user-select: none;
}

.priceAddons.mobileVersion {
  gap: var(--gap-xs);
}

.priceAddons .playButton,
.priceAddons .otherButton {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 16px;
  width: 16px;
  padding: var(--gap-3xs);

  cursor: pointer;
  background-color: #3a3a3c;
  border-radius: var(--border-radius-xs);
}

.priceAddons.mobileVersion .playButton,
.priceAddons.mobileVersion .otherButton {
  height: 32px;
  width: 32px;
  border-radius: var(--border-radius-s);
}

.settingsWrapper {
  user-select: none;
}

.settingsWrapper > svg:hover {
  cursor: pointer;
  color: var(--dark-text-primary);
}

.additionalOptions {
  display: flex;
  flex-direction: column;
}

.additionalOptionsTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: var(--gap-m) 0;
}

.additionalOption {
  color: #fff;
  margin: var(--gap-s) 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.additionalOptionIcon {
  margin-right: var(--gap-m);
}

.priceLabel {
  font-size: 11px;
  line-height: 16px;
  color: var(--text-color-secondary);
  margin-bottom: 4px;
  display: block;
}

.priceLabel.mobileVersion {
  margin-top: var(--gap-xs);
  margin-bottom: var(--gap-s);
}

.additionalInstructionSheet {
  height: calc(var(--vh, 1vh) * 100) !important;
  max-height: calc(var(--vh, 1vh) * 100) !important;
  border-radius: 0;
  background-color: var(--dark-bg-primary);
}

@media all and (display-mode: standalone) {
  .additionalInstructionSheet {
    height: calc(
      var(--vh, 1vh) * 100 - env(safe-area-inset-bottom) - 14px
    ) !important;
    max-height: calc(
      var(--vh, 1vh) * 100 - env(safe-area-inset-bottom) - 14px
    ) !important;
    border-radius: 0;
    background-color: var(--dark-bg-primary);
  }

  .additionalInstructionSheetContent {
    padding-bottom: env(safe-area-inset-bottom);
  }
}

.additionalInstructionSheetContent {
  height: 100%;
  position: relative;
  min-width: 250px;
}

.additionalInstructionSheetContent.mobileVersion {
  height: 100%;
  position: relative;
  background-color: var(--dark-bg-primary);
  min-width: unset;
}

.additionalInstructionSheetTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.32px;
  text-align: center;
  margin-left: -48px;
}

.additionalInstructionSheetVisibleQuantityLabel {
  display: block;
  margin-bottom: var(--gap-m);
}

.additionalInstructionSheetSubmitButton {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-top: var(--gap-m);
}

.additionalInstructionSheetSubmitButton.mobileVersion {
  margin-top: 0;
  position: absolute;
  bottom: var(--gap-m);
  left: 0;
  font-size: 16px;
  line-height: 24px;
}

.additionalInstructionSheetSubmitButtonContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.additionalInstructionSheetHeader {
  background-color: var(--dark-bg-primary);
  padding: 0;
}

.additionalInstructionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--gap-xs);
  background-color: var(--color-light-bg-tertiary);
}


.additionalInstructionHeader svg:hover {
  color: var(--dark-text-primary);
  cursor: pointer;
}

.additionalInstructionContent {
  display: flex;
  flex-direction: column;
  padding: var(--gap-m) var(--gap-xs);
  background-color: var(--dark-bg-secondary);
}

.error {
  color: var(--form-control-error-color);
}

.additionalInstructionSelect {
  --form-control-s-min-height: 32px;
  height: var(--form-control-s-min-height);
  max-height: var(--form-control-s-min-height);
  background-color: var(--select-option-background) !important;
  padding: var(--gap-xs);
}

.additionalInstructionSelectDesktop {
  --select-option-background: #2c2c2e;
}

.additionalInstructionSearchValue {
  display: flex;
  align-items: center;
}

.additionalInstructionSearch {
  display: flex;
  align-items: center;
  background-color: var(--select-option-background);
  border-radius: var(--form-control-border-radius);
  padding: var(--gap-xs);
}

.additionalInstructionSearch > svg {
  margin-right: var(--gap-2xs);
}

.switchSmall {
  --color-light-graphic-primary-inverted: #fff;
  align-items: center !important;
  width: 100%;
  margin-bottom: var(--gap-m);
}

.switchSmall.mobileVersion {
  margin-bottom: var(--gap-l);
}

.slBlock {
  margin-top: var(--gap-l);
}

.tpBlock {
  margin: var(--gap-l) 0 var(--gap-3xl) 0;
}
.tpBlock.mobileVersion {
  margin-top: var(--gap-2xl);
}

.smallMargin {
  margin-top: var(--gap-s);
}

.containerModal {
  margin: 0 auto;
  position: absolute;
  width: 80%;
  max-height: 80%;
}

.buttonToolbar {
  display: flex;
  justify-content: flex-end;
  gap: var(--gap-m);
  background-color: var(--dark-bg-tertiary);
  margin: var(--gap-m) var(--gap-xs-neg) var(--gap-m-neg) var(--gap-xs-neg);
  padding: var(--gap-xs);
}

.customButton span {
  font-size: 11px;
  line-height: 16px;
}

.smallInput {
  --form-control-s-min-height: 32px;
  --form-control-disabled-bg-color: var(--dark-bg-tertiary);
}

.textWithTooltip {
  display: flex;
  align-items: center;
  margin-bottom: var(--gap-m);
}

.textWithTooltip svg {
  margin-left: var(--gap-2xs);
}

.textWithTooltip > div {
  display: flex;
}

.textWithTooltip svg:hover {
  color: var(--dark-text-primary);
}

.customInputLabel {
  color: #8d8d93;
  margin-top: var(--gap-xs);
  margin-bottom: var(--gap-2xs);
}

.secondaryTitle {
  display: block;
  margin-top: var(--gap-m);
  margin-bottom: var(--gap-xs);
}

.formStickyBottom {
  margin-top: auto;
  position: sticky;
  bottom: 0;
  background-color: var(--dark-bg-primary);
}

.amountInputBRSOrder {
  margin-top: var(--gap-s);
}

.limits {
  margin-bottom: var(--gap-xl);
}

.priceSetting {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.blockDivider {
  display: flex;
  flex: 1;
  height: 1px;
  background-color: #464649;
  margin: var(--gap-l) var(--gap-xs-neg) 0 var(--gap-xs-neg);
}

.customCheckbox {
  margin-top: var(--gap-m);
}

.customCheckbox[class*='checkbox__disabled'] .customCheckboxContent,
.customCheckbox[class*='checkbox__disabled'] .customCheckboxBox {
  opacity: 0.6;
}

.customCheckboxContent {
  align-self: center;
}

.customCheckboxContent * {
  font-size: 13px;
  line-height: 16px;
  --checkbox-label-color: var(--text-color-tertiary-inverted);
}

.customCheckboxBox {
  --checkbox-bg-color: #8d8d93;
  --checkbox-hover-bg-color: #8d8d93;
  --checkbox-border-color: #8d8d93;
  --checkbox-disabled-bg-color: #8d8d93;

  --checkbox-checked-bg-color: var(--switch-checked-bg-color);
  --checkbox-checked-hover-bg-color: var(--switch-checked-border-color);
  --checkbox-checked-border-color: var(--switch-checked-border-color);
  --checkbox-checked-disabled-bg-color: var(--switch-checked-border-color);
  --checkbox-checked-active-bg-color: var(--switch-checked-bg-color);
}

.sltpPrice {
  color: var(--color-light-text-secondary);
  margin-top: var(--gap-s);
}
