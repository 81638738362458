/*
 Переопределения стилей компонента Calendar
*/

@import '@alfalab/core-components/vars/typography.css';

:root {
  --calendar-range-background: var(
    --color-light-specialbg-tertiary-transparent
  );
  --calendar-range-complete-background: var(--calendar-range-background);
  --calendar-today-color: var(--color-light-text-primary);
  --calendar-selected-background: var(--color-light-graphic-secondary);
  --calendar-selected-hover-background: var(--calendar-selected-background);
  --calendar-highlighted-background: var(--button-secondary-base-bg-color);
}

[class*='cc-calendar'] {
  [class*='calendar__dayName_'],
  [class*='calendar__dayContent_'] {
    @mixin paragraph_secondary_large;

    &[class*='calendar__today_'] {
      @mixin accent_secondary_large;
    }
  }

  [class*='calendar__monthYear_'],
  [class*='calendar__button_'] {
    height: var(--size-s-height);
  }

  [class*='calendar__monthYear_'] {
    gap: var(--gap-s);
  }

  [class*='calendar__button_'] {
    &[class*='calendar__filled_'] {
      background-color: var(--button-secondary-base-bg-color);

      &:hover:not(:disabled) {
        background-color: var(--button-secondary-hover-bg-color);
      }
    }
  }

  [class*='calendar__upDownIcon_'] {
    width: calc(0.82 * var(--select-checkmark-size));
    height: calc(0.82 * var(--select-checkmark-size));
    color: var(--select-arrow-color);
  }

  [class*='calendar__button_'] {
    padding-left: var(--select-option-left-padding);
    padding-right: var(--select-option-right-padding);
  }

  [class*='calendar__year_'] {
    margin-left: 0;
  }

  [class*='calendar__selected_'] {
    &:hover {
      color: var(--calendar-selected-color);
    }
  }
}
