.root {
  max-width: 495px;
  width: 312px;
  display: flex;
  flex-direction: column;
  margin: var(--gap-2xl) auto var(--gap-xl);
}

.rejectButton {
  background-color: var(--color-light-bg-quaternary);
}
