.wrapper {
  display: flex;
  align-items: center;
}

.filtersGrid {
  display: flex;
  gap: var(--gap-m);
}

.filters {
  display: flex;
  flex: 1;
  gap: var(--gap-xs);
  white-space: nowrap;
}