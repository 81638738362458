.container {
  --account-requisites-header-margin-bottom: 8px;
}

.sizeXxs {
  --account-requisites-header-margin-bottom: 4px;
}

.header {
  margin-bottom: var(--account-requisites-header-margin-bottom);
}

.requisitesItem {
  margin: 0 0 4px;
}
