.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 var(--gap-s);
}

.content {
  display: flex;
}

.tooltip {
  margin-left: var(--gap-xs);
}