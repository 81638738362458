:root {
  --shadow-xs-hard: 0px 0px 1px 0px rgba(0, 0, 0, 0.16),
    0px 4px 8px 0px rgba(0, 0, 0, 0.16);
  --shadow-s-hard: 0px 0px 1px 0px rgba(0, 0, 0, 0.16),
    0px 4px 8px 0px rgba(0, 0, 0, 0.16), 0px 12px 16px 0px rgba(0, 0, 0, 0.16);
  --shadow-m-hard: 0px 0px 1px 0px rgba(0, 0, 0, 0.16),
    0px 4px 8px 0px rgba(0, 0, 0, 0.16), 0px 12px 16px 0px rgba(0, 0, 0, 0.16),
    0px 20px 24px 0px rgba(0, 0, 0, 0.2);
  --shadow-l-hard: 0px 0px 1px 0px rgba(0, 0, 0, 0.16),
    0px 4px 8px 0px rgba(0, 0, 0, 0.16), 0px 12px 16px 0px rgba(0, 0, 0, 0.16),
    0px 20px 24px 0px rgba(0, 0, 0, 0.2), 0px 24px 32px rgba(0, 0, 0, 0.2);
  --shadow-xl-hard: 0px 0px 1px 0px rgba(0, 0, 0, 0.16),
    0px 4px 8px 0px rgba(0, 0, 0, 0.16), 0px 12px 16px 0px rgba(0, 0, 0, 0.16),
    0px 20px 24px 0px rgba(0, 0, 0, 0.2), 0px 24px 32px 0px rgba(0, 0, 0, 0.2),
    0px 32px 40px 0px rgba(0, 0, 0, 0.2);

  --shadow-xs-hard-up: 0px 0px 1px 0px rgba(0, 0, 0, 0.16),
    0px -4px 8px 0px rgba(0, 0, 0, 0.16);
  --shadow-s-hard-up: 0px 0px 1px 0px rgba(0, 0, 0, 0.16),
    0px -4px 8px 0px rgba(0, 0, 0, 0.16), 0px -12px 16px 0px rgba(0, 0, 0, 0.16);
  --shadow-m-hard-up: 0px 0px 1px 0px rgba(0, 0, 0, 0.16),
    0px -4px 8px 0px rgba(0, 0, 0, 0.16), 0px -12px 16px 0px rgba(0, 0, 0, 0.16),
    0px -20px 24px 0px rgba(0, 0, 0, 0.2);
  --shadow-l-hard-up: 0px 0px 1px 0px rgba(0, 0, 0, 0.16),
    0px -4px 8px 0px rgba(0, 0, 0, 0.16), 0px -12px 16px 0px rgba(0, 0, 0, 0.16),
    0px -20px 24px 0px rgba(0, 0, 0, 0.2), 0px -24px 32px 0px rgba(0, 0, 0, 0.2);
  --shadow-xl-hard-up: 0px 0px 1px 0px rgba(0, 0, 0, 0.16),
    0px -4px 8px 0px rgba(0, 0, 0, 0.16), 0px -12px 16px 0px rgba(0, 0, 0, 0.16),
    0px -20px 24px 0px rgba(0, 0, 0, 0.2), 0px -24px 32px 0px rgba(0, 0, 0, 0.2),
    0px -32px 40px 0px rgba(0, 0, 0, 0.2);
}
