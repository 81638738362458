div.popover {
  background-color: #007AFF;
  padding: var(--gap-m);
  border-radius: var(--border-radius-l);

  --color-light-text-primary: var(--color-dark-text-primary);
  --button-primary-color: var(--color-dark-text-primary-inverted);
  --button-primary-base-bg-color: var(--color-dark-bg-secondary-inverted);
  --button-primary-hover-bg-color: var(--color-dark-bg-secondary-inverted-shade-15);
  --button-primary-active-bg-color: var(--color-dark-bg-secondary-inverted-shade-20);
}