.container {
  --active-button-width: 18px;
  --active-button-height: 18px;
}

.container {
  display: flex;
  width: 100%;
}

.volume {
  color: var(--color-light-text-secondary);
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-xs);
}

.button {
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--active-button-width);
  height: var(--active-button-height);
  color: var(--color-light-text-primary);
  transition: color 0.25s;
}

.buttonDelete {
  color: var(--color-light-graphic-secondary);
}

.button:hover {
  color: var(--color-light-graphic-tertiary-inverted);
}
