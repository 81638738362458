.container {
  --threeds-title-width: 280px;
  --threeds-title-font-size: 18px;
  --threeds-title-margin-bottom: 18px;
}

.sizeXxs {
  --threeds-title-width: 280px;
  --threeds-title-font-size: 16px;
  --threeds-title-margin-bottom: 18px;
}

.container {
  position: relative;
  min-height: 660px;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
}

.loadingTitle {
  width: var(--threeds-title-width);
  font-size: var(--threeds-title-font-size);
  margin-bottom: var(--threeds-title-margin-bottom);
}

.loader {
  margin: 0 auto;
}

.iframe {
  position: relative;
  z-index: 10;
}
