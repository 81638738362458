.tradingLockAttention {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  padding: var(--gap-m) 0;
  padding-bottom: max(env(safe-area-inset-bottom), var(--gap-m));

  background-color: var(--dark-bg-secondary);
  color: #f6bf65;
}

.tradingLockAttention > svg {
  margin-right: var(--gap-s);
}

.tradingLockAttentionMobile {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
