.container {
  --detail-requisites-action-margin-top: 24px;
  --detail-requisites-action-margin-bottom: 12px;
}

.sizeXxs {
  --detail-requisites-action-margin-top: 16px;
  --detail-requisites-action-margin-bottom: 8px;
}

.actions {
  margin-top: var(--detail-requisites-action-margin-top);
  margin-bottom: var(--detail-requisites-action-margin-bottom);
}

@media (max-width: 768px) {
  .mobileColumn {
    margin-bottom: 16px;
  }
}
