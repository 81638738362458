.box {
  --advantage-icon-margin-bottom: 16px;
  --advantage-title-font-size: 13px;
  --advantage-title-line-height: 16px;
  --advantage-title-margin-bottom: 8px;
  --advantage-text-font-size: 11px;
  --advantage-text-line-height: 16px;
  --advantage-text-margin-bottom: 26px;
  --advantage-button-width: 163px;
}

.sizeXxs {
  --advantage-title-font-size: 11px;
  --advantage-text-margin-bottom: 25px;
}

.box {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  margin: 0 0 var(--advantage-icon-margin-bottom);
}

.title {
  font-size: var(--advantage-title-font-size);
  line-height: var(--advantage-title-line-height);
  font-weight: 700;
  margin: 0 0 var(--advantage-title-margin-bottom);
}

.text {
  font-size: var(--advantage-text-font-size);
  line-height: var(--advantage-text-line-height);
  margin: 0 0 var(--advantage-text-margin-bottom);
  color: var(--text-color-secondary);
}

.button {
  width: var(--advantage-button-width);
}
