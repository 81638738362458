$base-font:			'Roboto', sans-serif !default;
$heading-font:		'Roboto Condensed', sans-serif !default;

$red:				#ff002a !default;
$green:				#85c730 !default;
$accent: 			#398dff !default;

$bg1:				#fff !default;
$bg2:				#f3f3f3 !default;

$font-xs:			9px !default;
$font-s:			11px !default;
$font-m:			12px !default;
$font-l:			14px !default;

$soft-corners: 1px !default;

/* Basic Text */

$text-subtle:		#999 !default;
$text-medium:		#666 !default;
$text-strong:		#000 !default;


/* Borders */

$border-subtle:		#ddd !default;
$border-medium:		#ccc !default;
$border-strong:		#999 !default;


/* Light Theme */

$theme1-bg:			#f8f8f8 !default;
$theme1-main:		#fff !default;
$theme1-hu-bg:		#ffffff !default;
$theme1-hu-bg-dynamic: #ffffff !default;
$theme1-text:		#000 !default;


/* ciq-night Theme */

$theme2-bg:			#151f28 !default;
$theme2-main:		#1c2a35 !default;
$theme2-hu-bg: 		#1C2A35 !default;
$theme2-hu-bg-dynamic: #2a51d0 !default;
$theme2-text:		#fff !default;


/* Layout */

$nav-height: 45px !default;


//@mixin box-layer {
//}

@mixin hide-text {
	font: 0/0 a;
	text-shadow: none;
	color: transparent;
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
}

@mixin background-opacity($color, $opacity: 0.3) {
    background: $color; /* The Fallback */
    background: rgba($color, $opacity);
}

cq-timezone-dialog {
  width: 375px;
}

cq-timezone-dialog h4 {
  display: block;
  text-align: center;
}

cq-timezone-dialog p {
  text-align: center;
  font-weight: 400;
}

cq-timezone-dialog {
  ul, .ciq-ul {
  	padding-left: 20px;
  	text-align: left;
  	border-left: 0 none;
  	margin-left: 0;
	}
}

cq-timezone-dialog li {
  margin-left: 0px;
  padding: 8px 0;
}

.timezoneDialogWrapper {
  border-top: solid #ddd 1px;
  border-bottom: solid #ddd 1px;
}

cq-timezone-dialog .detect {
  text-align: center;
  margin: 20px auto;
}

cq-timezone-dialog .instruct {
  text-align: center;
  margin: 10px auto 0px;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 11px;
  font-style: italic;
  color: #999;
}

#{$hovering} cq-timezone-dialog li:hover {
  background-color: #F0F0F0;
}

#{$touching} cq-timezone-dialog li:active {
  background: #efefef;
  color: $DEFAULT-text-strong;
}



/* --------------------------------------------------------- Trade from Chart --------------------------------------------------------- */
/* We add body selectors to increase the specificity over defaults from tfc.css */

body .stx-panel-side {
	position:absolute;
	width:0px;
	right:0px;
	top:0;
	bottom:0;
}

body .stx-trade-panel ul {
	list-style:none;
	margin:0;
	padding:0;
}


body .#{$DARK} .tfc-price {
  color:#000;
}


body .stx-trade-login-panel {background:none;}
body .stx-trade-login-panel .stx-wrapper {
	padding:20px;
}


body .stx-tooltip {
	display:block;
	position:absolute;
	top:5px;
	font-size:9px;
	padding:0;
	height:14px;
	width:0px;
	overflow:hidden;
	transition:margin .25s ease-out .1s;
}
#{$hovering} body *:hover > .stx-tooltip {
	overflow:visible;
	width:auto;
	opacity:1;
}
body .stx-tooltip.right {
	left:40px;
	margin-left:5px;
}
#{$hovering} body *:hover > .stx-tooltip.right {
	margin-left:0px;
}
body .stx-tooltip.left {
	right:40px;
	margin-right:5px;
}
#{$hovering} body *:hover > .stx-tooltip.left {
	margin-right:0px;
}
body .stx-tooltip > div {
	display:block;
	position:absolute;
	font-size:9px;
	height:14px;
	line-height: 14px;
	z-index:2;
}
body .stx-tooltip.right > div {
	left:6px;
	padding:0 4px 0 4px;
}
body .stx-tooltip.left > div {
	right:6px;
	padding:0 4px 0 4px;
}
body .stx-tooltip > span {
	position: absolute;
	top:2px;
	display: block;
	width: 10px;
	height: 10px;
	transform: rotate(45deg) scale(1);
	z-index:1;
}
body .stx-tooltip.left span {
	right:2px;
}
body .stx-tooltip.right span  {
	left:2px;
}

body .stx-tooltip div,
body .stx-tooltip span {
	background:$accent;
	color:$theme1-main;
}

.stx-trade-panel {
	display: none;

	cq-side-panel & {
		display: inherit;
	}
}

body .stx-trade-panel .stx-wrapper {
	height:100%;
	float:left;
	border-right:none;
	overflow:hidden;
	display:none;
	padding-top: 10px;
    box-sizing: border-box;
}
body .stx-trade-panel .stx-wrapper.active {
	display:block;
}
body .stx-wrapper.stx-trade-nav {
	z-index:2;
	width: 68px;
}
body .stx-wrapper.stx-trade-info {
	z-index:1;
	width: 280px;
}

.break-md .stx-wrapper.stx-trade-info.active,
.break-sm .stx-wrapper.stx-trade-info.active {
	display:none;
}

.break-md .stx-wrapper.stx-trade-nav,
.break-sm .stx-wrapper.stx-trade-nav {
	display:block;
}

.break-md .stx-trade-ticket-toggle,
.break-sm .stx-trade-ticket-toggle {
	display:none;
}

body .stx-orders li {
	border:solid 1px transparent;
	transition: border-color .25s;
	margin: 0 0 10px 10px;
}

body .stx-orders li:active,
#{$hovering} body .stx-orders li:hover {border-color:darken( $theme1-bg, 8% );}

body .#{$DARK} .stx-orders li:active,
#{$hovering} body .#{$DARK} .stx-orders li:hover{border-color:lighten( $theme2-bg, 8% );}

body .stx-orders li.active,
#{$hovering} body .stx-orders li.active:hover {border-color:$accent;}

body .stx-orders a {
	cursor: pointer;
}

body .stx-orders li.stx-market a {background-position:0px 0px;}
body .stx-orders li.stx-buy a {background-position:0px -40px;}
body .stx-orders li.stx-sell a {background-position:0px -80px;}
body .stx-orders li.stx-short a {background-position:0px -280px;}
body .stx-orders li.stx-cover a {background-position:0px -240px;}
body .stx-orders li.stx-strangle a {background-position:0px -120px;}
body .stx-orders li.stx-straddle a {background-position:0px -160px;}
body .stx-orders li.stx-bracket a {background-position:0px -200px;}

body .#{$DARK} .stx-orders li.stx-market a {background-position:-40px 0px;}
body .#{$DARK} .stx-orders li.stx-buy a {background-position:-40px -40px;}
body .#{$DARK} .stx-orders li.stx-sell a {background-position:-40px -80px;}
body .#{$DARK} .stx-orders li.stx-short a {background-position:-40px -280px;}
body .#{$DARK} .stx-orders li.stx-cover a {background-position:-40px -240px;}
body .#{$DARK} .stx-orders li.stx-strangle a {background-position:-40px -120px;}
body .#{$DARK} .stx-orders li.stx-straddle a {background-position:-40px -160px;}
body .#{$DARK} .stx-orders li.stx-bracket a {background-position:-40px -200px;}


body .stx-trade-info ul.stx-orders {
	width:auto;
	margin: 0 auto;
	display: inline;
}
body .stx-trade-info .stx-orders li {
	display:inline-block;
	margin: 10px 5px;
}

body .stx-trade-current .stx-head-bar .stx-ico {background-position:0px -525px;}
body .stx-trade-positions .stx-head-bar .stx-ico {background-position:-40px -525px;}

#{$hovering} body .stx-panel-module .stx-head-bar span.stx-switch-account:hover a,
body .stx-panel-module .stx-head-bar span.stx-switch-account:active a {color:#666;}

#{$hovering} body .#{$DARK} .stx-panel-module .stx-head-bar span.stx-switch-account:hover a,
.#{$DARK} .stx-panel-module .stx-head-bar span.stx-switch-account:active a {color:#999;}

body .stx-panel-module .stx-section {
	padding:0px 10px;
	position:relative;
}

body .stx-trade-account .stx-section {
	padding: 5px 10px;
}

body .stx-panel-module .stx-section h4 {
	text-transform:uppercase;
	font-weight:normal;
	font-size:10px;
	margin:5px 0 5px;
	color:#999;
}

body .#{$DARK} .stx-panel-module .stx-section h4 {
	color:#666;
}

body .stx-panel-module table {
	width:100%;
	font-size:11px;
	border-spacing: 0 4px;
	position:relative;
	z-index:2;
}

body .stx-panel-module cq-scroll.ps--active-y table {
	width: calc( 100% - 20px );
}

body .stx-panel-module th,
body .stx-panel-module td {
	line-height:20px;
}
body .stx-panel-module th {color:#999;}

body .stx-panel-module td.total {
	font-weight:bold;
	color:#000;
}
body .#{$DARK} .stx-panel-module td.total {
	color:#fff;
}

body .stx-panel-module .stx-divider td {
	height:1px;
	background:#efefef;
}

.#{$DARK} .stx-panel-module .stx-divider td {
	background:#333;
}

body .stx-panel-module .stx-divider tr {
	border-spacing: 0 6px;
}

body .stx-trade-account tr td:nth-of-type(2) {text-align:right;}
body .stx-panel-module .stx-current-position tr.tfc-current-symbol td:nth-of-type(1),
body .stx-panel-module .stx-current-orders tr.tfc-current-symbol td:nth-of-type(3) {
	font-weight:bold;
	color:#000;
}
body .#{$DARK} .stx-panel-module .stx-current-position tr.tfc-current-symbol td:nth-of-type(1),
body .#{$DARK} .stx-panel-module .stx-current-orders tr.tfc-current-symbol td:nth-of-type(3) {
	color:#fff;
}

body .stx-orders li {
	border-radius: 0px;
}

body .stx-trade-account .stx-section {
	border-top: 0px;
}

body .tfc-lots-position .stx-btn.click.up,.tfc-current-trades .stx-btn.click.up {
	background: rgba(139,194,115,.6);
}
body .tfc-lots-position .stx-btn.click.down,.tfc-current-trades .stx-btn.click.down {
	background: rgba(236,85,60,.6);
}
body .#{$DARK} .stx-btn.click.up, .#{$DARK} .stx-btn.click.down {
	color:#ccc;
}
#{$hovering} body .#{$DARK} .stx-btn.click.up:hover, #{$hovering} body .#{$DARK} .stx-btn.click.down:hover {
	border-color: #fff;
	color:#fff;
}
body .#{$DARK} .stx-trade-positions .stx-current-position .stx-btn.click:not(.up):not(.down),
body .#{$DARK} .stx-trade-positions .stx-head-bar .stx-btn.click {
	color: #999;
	background-color: #1f1f1f;
	border-color: #333;
}
body .#{$DARK} .stx-trade-positions .stx-current-position .stx-btn.click:active:not(.up):not(.down),
#{$hovering} body .#{$DARK} .stx-trade-positions .stx-current-position .stx-btn.click:hover:not(.up):not(.down),
body .#{$DARK} .stx-trade-positions .stx-head-bar .stx-btn.click:active,
#{$hovering} body .#{$DARK} .stx-trade-positions .stx-head-bar .stx-btn.click:hover {
	border-color: #666;
	color: #fff;
}

body .stx-section {
	background: #fff;
}

body .#{$DARK} .stx-section {
	background: none;
}

body .stx-trade-positions .stx-head-bar li.tfc-positions-view.active {
    border: solid 1px rgba(0,0,0,.1);
    background-color: rgba(0,0,0,.1);
}

#{$hovering} body .stx-trade-positions .stx-head-bar li.tfc-positions-view:hover {
    border: solid 1px rgba(0,0,0,.05);
    background-color: rgba(0,0,0,.05);
}

body .#{$DARK} .stx-trade-positions .stx-head-bar li.tfc-positions-view.active {
    border: solid 1px rgba(255,255,255,.2);
    background-color: rgba(255,255,255,.2);
}

#{$hovering} body .#{$DARK} .stx-trade-positions .stx-head-bar li.tfc-positions-view:hover {
    border: solid 1px rgba(255,255,255,.1);
    background-color: rgba(255,255,255,.1);
}

body .stx-panel-module th, .stx-panel-module td {
	font-weight: 300;
}

body .stx-select {
	color: #000;
}

body .stx-trade-ticket-toggle {
	cursor: pointer;
}

body .stx-trade-ticket-toggle.open {
	width:45px;
	left:5px;
}

body.break-lg .stx-watch-panel.active {
	width:180px;
}
/*.break-lg*/ .stx-trade-panel.active {
	//width:290px;
	width:auto;
}

body .stx-trade-panel ul.stx-account {
	border-top: 1px solid #4A4A4A;
}

body .stx-trade-panel ul.stx-account li {
	margin-left: 0;
}

/***** TFC dialog close icons ***/
body .stx-btn.stx-ico,
 #{$hovering} body .stx-btn.stx-ico:hover,
 #{$hovering} body .stx-btn.stx-ico > span .stx-btn.stx-ico:hover > span,
 body .stx-btn.stx-ico:active > span {
	overflow: hidden;
	padding: 0px !important;
	line-height: 0px;
	border: none;
	background: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}
body .stx-btn.stx-ico { /* This is the button wrapper and hit area */
	width: 27px;
	height: 27px;
	padding: 0px;
}
body .stx-btn.stx-ico > span,
 #{$hovering} body .stx-btn.stx-ico:hover > span,
 body .stx-btn.stx-ico:active > span { /* Styling of visible area and text hiding */
	display:initial;
	position: absolute;
	left: 0;
	background-image: url(img/stx-sprite-panels.svg);
	background-position: -250px -25px;
	width: 17px;
	height: 17px;
	transition: color .25s, border .25s, background-color .25s, box-shadow .25s;
	-webkit-transition: color .25s, border .25s, background-color .25s, box-shadow .25s;
	opacity:.7;
}

#{$hovering} body .stx-btn.stx-ico:hover > span {
	opacity:1;
}

body .stx-dialog-container {
    width: 100%;
    top: 0px;
    left: 0px;
    text-align: center;
    position: absolute;
}

body .stx-dialog {
	position: relative;
    text-align: left;
    margin: 100px auto;
    padding: 25px 30px;
    width: 200px;
    border: solid 1px #ccc;
    background-color: #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0 3px 9px 0px rgba(0,0,0,.5);
    -moz-box-shadow: 0 3px 9px 0px rgba(0,0,0,.5);
    box-shadow: 0 3px 9px 0px rgba(0,0,0,.5);
}

body .trade .stx-btn.stx-ico {
	position: absolute;
    right: 0;
    top: 0;
}

/* ------------------------------------------------------- CHART OVERLAY ELEMENTS ------------------------------------------------------ */

.rightclick_drawing .no_edit .mouseDeleteText,
.rightclick_study .no_edit .mouseDeleteText,
.rightclick_drawing .mouseManageText,
.rightclick_study .mouseManageText {
	display:inline;
}
.rightclick_drawing .no_edit .mouseManageText,
.rightclick_study .no_edit .mouseManageText,
.rightclick_drawing .mouseDeleteText,
.rightclick_study .mouseDeleteText {
	display:none;
}

.rightclick_drawing .overlayText,
.rightclick_drawing .overlayEdit,
.rightclick_study .overlayEdit {
	margin-top: -6px;
	opacity: 1;
	background: transparent;
	border:none;
	&.stx-btn span {
		background-color: $DEFAULT-main;
		width: 23px;
		border: solid #cfcfcf 1px;
		border-radius: 3px;
		.#{$DARK} & {
			background-color: $DARK-main;
		}
	}
}

.rightclick_drawing .overlayText {
	margin-left: 70px;
	&.stx-btn span {
		background-position: -505px -26px;
		.#{$DARK} & {
			background-position: -505px -52px;
		}
	}
}

.rightclick_drawing .overlayEdit,
.rightclick_study .overlayEdit {
	margin-left: 10px;
	&.stx-btn span {
		background-position: -301px -26px;
		.#{$DARK} & {
			background-position: -301px -52px;
		}
	}
}
.rightclick_drawing .overlayTrashCan,
.rightclick_study .overlayTrashCan {
	margin-left: 39px;
}

.rightclick_drawing .overlayTrashCan,
.rightclick_study .overlayTrashCan,
.rightclick_series .overlayTrashCan {
	margin-top: -6px;
	background: transparent;
	border:none;
	&.stx-btn span {
		background-color: $DEFAULT-main;
		background-position: -400px -26px;
		width: 25px;
		border: solid #cfcfcf 1px;
		border-radius: 3px;
		.#{$DARK} & {
			background-color: $DARK-main;
			background-position: -400px -52px;
		}
	}
}

/* ------------------------------------------------------- SLEEP MODE ------------------------------------------------------ */
.ciq-sleeping .chartContainer {
	opacity:.5;
}

/* ------------------------------------------------------- RANGE SLIDER SHADING ------------------------------------------------------ */

.stx_range_slider.shading {
	background-color: rgba(128,128,128,0.3);
	border: solid 2px rgba(0,144,183,1);
	width: 5px;
}

.stx_range_slider .stx-chart-panel.stx-show {
    display: inline-block;
}


.stx_range_slider .stx-chart-panel.stx-show > * {
    display: none;
}

/* ------------------------------------------------------- PLUGIN PROTECTION ------------------------------------------------------ */

cq-orderbook {
	display:none;
}

/* Stuff in nav bar */
body {
	.tfc-ui,
	.analystviews-ui,
	.technicalinsights-ui,
	.tableview-ui {
		display:none;
	}
}

/* Stuff in menus */
.marketdepth-ui,
.scriptiq-ui,
.timespanevent-ui,
.ta_markers-ui,
.video_markers-ui,
.crosssection-ui,
.rangeslider-ui,
.extendedhours-ui,
.animation-ui,
.shortcuts-ui,
.outliers-ui,
.dataLoader-ui,
.tooltip-ui,
.signaliq-ui {
	display: none;
}

/* Hide shortcuts and info toggle (if tooltip addOn has not been initiated) on mobile screens */
cq-context[ciq-mobile] {
	&:not([tooltip-active]) cq-toggle[cq-member="headsUp"],
	&[dataLoader-active] .dataLoader-ui,
	&[shortcuts-active] .shortcuts-ui {
		display: none;
	}
}

/* Hide additional features section as long as all the features in it have also been hidden */
cq-context[ciq-mobile] cq-menu-dropdown-section.chart-addons {
	display: none;
}

/* Enable activated addOn UI */
body {
	*[extendedhours-active] .extendedhours-ui,
	*[animation-active] .animation-ui,
	*[shortcuts-active] .shortcuts-ui,
	*[outliers-active] .outliers-ui,
	*[rangeslider-active] .rangeslider-ui,
	*[tableview-active] .tableview-ui,
	*[dataLoader-active] .dataLoader-ui,
	*[tooltip-active] .tooltip-ui,
	*[help-active] .help-ui {
		display: inherit;
	}
}
