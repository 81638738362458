.container {
  padding: 0 10px;
}

.topRow {
  display: flex;
  align-items: center;
  margin-bottom: var(--gap-s);
}

.icon {
  margin-right: var(--gap-s);
}

.bottomRow {
  padding-left: 36px;
}
