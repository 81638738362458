.container {
  --message-detail-title-margin-bottom: var(--gap-s);
  --message-detail-font-size: 11px;
  --message-detail-line-height: 16px;
}

.title {
  margin: 0 0 var(--message-detail-title-margin-bottom);
  color: var(--color-light-text-primary);
}

.details {
  margin-bottom: var(--gap-l);
}

.parameters {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap-m);
}

.field {
  width: calc(50% - var(--gap-m) / 2);
}

.content {
  font-size: var(--message-detail-font-size);
  line-height: var(--message-detail-line-height);
}

.divider {
  margin: var(--gap-m) 0;
}

.text {
  margin: var(--gap-m) 0;
}

.textToggle {
  font-size: 13px;
}

.textContent {
  margin: var(--gap-m) 0 0;
  color: var(--color-light-text-secondary);
}
