.container {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 32px;
  padding: var(--gap-2xs) var(--gap-xl) var(--gap-2xs) var(--gap-xs);

  background-color: var(--color-light-bg-info-muted);
}

.text {
  color: var(--color-light-bg-quaternary-inverted);
}

.closeButton {
  position: absolute;
  top: 50%;
  right: var(--gap-xs);
  transform: translateY(-50%);
}
