.plusButton {
  color: var(--color-light-graphic-tertiary-inverted) !important;
  border-radius: var(--border-radius-l) var(--border-radius-l) 0 0 !important;
}

.plusButton:hover {
  color: var(--color-light-graphic-quaternary-inverted) !important;
  background-color: var(--layout-color-surface-0);
}

.plusButton:focus {
  color: var(--color-light-graphic-tertiary) !important;
  background-color: var(--button-primary-base-bg-color);
}
