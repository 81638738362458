.group {
  margin-bottom: var(--gap-l);
}

.groupTitle {
  padding: var(--gap-s) var(--gap-xs);
  font-size: 11px;
  line-height: 16px;
  font-weight: 700;
  color: var(--color-light-text-secondary);
}

.groupTitle:not(:first-child) {
  padding: var(--gap-xl) var(--gap-xs) var(--gap-s) var(--gap-xs);
}
