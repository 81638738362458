.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--gap-xs);
}

.tooltipTarget {
  display: flex;
  margin-left: var(--gap-2xs);
}

.linkTitle {
  color: var(--color-light-text-secondary);
}

.popperClassName {
  min-width: 190px !important;
}

.tooltipContent {
  max-width: 225px;
}
