.container {
  min-height: calc(608px - 2 * var(--gap-xl));
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: var(--gap-s);
}

.tooltipIcon {
  width: 14px;
  height: 14px;
  color: var(--color-light-graphic-secondary);
  vertical-align: bottom;
}

.tooltipContent {
  max-width: 360px;
}

.testsListContainer {
  margin-top: 36px;
  margin-right: 44px;
}

.testListItem {
  height: 96px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: var(--color-light-bg-secondary);
  margin-bottom: 16px;
  border-radius: 5px;
  transition: all ease-in-out 0.15s;
}

.testListItem:hover {
  background-color: var(--color-light-bg-tertiary);
}

.testDescription {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.testStatus {
  flex-shrink: 0;
  margin-left: auto;
}

.testIcon {
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.checkmarkIcon {
  width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 100%;
  padding: 76px 0;
  display: flex;
  justify-content: center;
}
