.icon {
  width: 20px;
  height: 20px;
  color: #8a8a8e;
}

.loader {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  color: var(--text-color-secondary);
}

.requirementIcon {
  margin-right: var(--gap-xs);
}

.option {
  display: flex;
  align-items: center;
}

.requirementColor {
  color: #cb830f !important;
}

.immediateColor {
  color: #ef3124 !important;
}

.iisLabel {
  color: var(--text-color-secondary);
  margin-left: var(--gap-xs);
}
