.container {
  --notification-detail-title-margin-bottom: var(--gap-s);
  --notification-detail-font-size: 11px;
  --notification-detail-line-height: 16px;
}

.title {
  margin: 0 0 var(--notification-detail-title-margin-bottom);
}

.content {
  font-size: var(--notification-detail-font-size);
  line-height: var(--notification-detail-line-height);
}

.content a {
  color: var(--color-light-text-primary);
}
