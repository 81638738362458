/*
 Переопределения стилей компонента Typography
*/

[class*='typography__paragraphWithMargins_'] {
  &[class*='typography__primary-small_'],
  &[class*='typography__secondary-large_'] {
    --text-paragraph-margin-bottom: 0 0 var(--gap-xs);
  }
}
