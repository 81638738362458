.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.icon {
  margin-bottom: var(--gap-m);
}

.title {
  margin-bottom: var(--gap-xl);
  text-align: center;
}