.wrapper {
  min-width: 200px;
  max-width: 300px;
}

.list {
  overflow-y: scroll;
  max-height: 190px;
}

.switch {
  padding: var(--gap-xs);
}

.searchInput {
  --form-control-s-min-height: var(--size-xs-height);

  /* нет возможности сделать размер инпута меньше иначе */
  /* stylelint-disable-next-line */
  font-size: 13px;
}

.filterItems {
  display: flex;
  flex-direction: column;
}

.checkbox {
  --checkbox-bg-color: transparent;
  --checkbox-border-color: #86868a;
  --checkbox-hover-bg-color: transparent;
  --checkbox-hover-border-color: var(--checkbox-border-color);
  --checkbox-icon-color: #dcdcdd;

  border-radius: var(--border-radius-circle);
}

.checkboxContent {
  display: flex;
  align-items: center;

  box-sizing: border-box;
  height: 40px;
  padding: var(--gap-xs);
}

.checkboxContentChips {
  height: 24px;
}

.checkboxContent:hover {
  background-color: var(--select-option-hover-background);
}

.filterWrapper.checkboxContent {
  overflow: hidden;
  margin-bottom: 0;
}

.text {
  /* stylelint-disable-next-line */
  line-height: 1;
  white-space: nowrap;
}

.checkBoxContent {
  overflow: hidden;
}

.checkBoxContent > span {
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltipContent {
  display: flex;
  flex-direction: column;
  gap: var(--gap-xs);
  max-width: 180px !important;
}

.divider {
  margin-bottom: var(--gap-xs);
}
