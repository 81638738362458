.buttonWrapper {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;

  &_size {
    &_s {
      max-width: 220px;
    }

    &_xs {
      max-width: 116px;
    }
  }
}
