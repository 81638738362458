.container {
  margin-left: var(--gap-s-neg);
}

.tabs {
  padding: 0 var(--gap-s);
  display: flex;
  align-items: center;
  gap: var(--gap-xs);
}

div.tag {
  min-width: unset;
}