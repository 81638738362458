.disabledContextButton {
  cursor: not-allowed !important;
  opacity: 0.4;
}

.quantityError {
  max-width: 190px;
  display: block;
  color: var(--form-control-error-color);
}

.triangleUp {
  transform: rotate(-0.5turn);
  color: var(--color-buy);
}

.triangleDown {
  color: var(--color-sell);
}

.secondaryText {
  color: var(--color-light-text-secondary);
}

.quantitySourceHint {
  color: var(--color-light-text-secondary);
  max-width: 190px;
  display: block;
}

.amount {
  color: var(--color-light-text-primary);
}
