.graphic {
  color: var(--color-light-graphic-tertiary-inverted);
}

.quaternary {
  color: var(--color-light-graphic-quaternary-inverted);
}

.icon {
  vertical-align: middle;
}
