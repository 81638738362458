.container {
  --notifications-list-group-margin-bottom: var(--gap-s);
  --notifications-list-group-title-padding: 4px 8px;
}

.group {
  margin-bottom: var(--notifications-list-group-margin-bottom);
}

.groupTitle {
  padding: var(--notifications-list-group-title-padding);
  margin: 0 0 var(--gap-xs);
  font-weight: 700;
  color: var(--text-color-secondary);
}

.item {
  margin-bottom: var(--gap-xs);
}
