.groupTitle {
  color: var(--dark-graphic-secondary);
  padding: var(--gap-2xs) var(--gap-xs);
  margin-bottom: var(--gap-2xs);
}

.dropdownItem {
  max-height: 32px;
  padding: 0;
}

.divider {
  margin: var(--gap-xs) 0;
}
