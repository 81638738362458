.container {
  --securities-form-min-height: 350px;
  --securities-form-padding-top: 21px;
  --securities-form-form-margin-top: var(--gap-l);
  --securities-form-progress-margin-bottom: var(--gap-m);
  --securities-form-faq-margin-bottom: 20px;
  --securities-form-agree-margin-top: var(--gap-xs);
  --securities-form-active-list-margin-top: 20px;
  --securities-form-active-list-margin-bottom: 24px;
}

.container {
  padding: var(--securities-form-padding-top) 0 0;
  min-height: var(--securities-form-min-height);
}

.progress {
  margin: 0 0 var(--securities-form-progress-margin-bottom);
}

.faq {
  margin-bottom: var(--securities-form-faq-margin-bottom);
}

.agree {
  margin-top: var(--securities-form-agree-margin-top);
}

.activeList {
  margin: var(--securities-form-active-list-margin-top) 0
    var(--securities-form-active-list-margin-bottom);
}
