.container {
  --test-required-max-width: 374px;
  --test-required-action-width: 318px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: var(--test-required-max-width);
  text-align: center;
}

.icon {
  margin: 0 0 var(--gap-m);
}

.title {
  margin: 0 0 var(--gap-s);
}

.text {
  margin: 0 0 var(--gap-xl);
}

.action {
  width: 204px;
  max-width: var(--test-required-action-width);
  margin-bottom: var(--gap-xl);
}
