.puFiltersButton {
  margin-left: var(--gap-m);
}

.puFiltersButtonSmall {
  margin-left: var(--gap-xs);
}

.chevron {
  margin-left: auto;
  color: var(--color-light-graphic-secondary);
  transition: color 0.2s ease, border 0.2s ease, color 0.2s ease,
    transform 0.12s ease;
}

.chevronUp {
  transform: rotate(-0.5turn);
}

.positionTypeSelectPoppover {
  min-width: 140px;
}

.puModeSelectPoppover {
  min-width: 215px;
}
