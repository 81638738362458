.dropdownContainer {
  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;

  height: 24px;
  padding: var(--gap-xs);

  color: var(--form-control-color);

  background-color: var(--form-control-bg-color);
  border-radius: var(--border-radius-s);

  transition: background-color 0.2s ease, border 0.2s ease, color 0.2s ease,
    transform 0.12s ease;
}

.dropdownContainer:hover {
  background-color: var(--form-control-hover-bg-color);
}

.dropDownContent {
  display: flex;
  flex: 1;
  gap: var(--gap-xs);
  align-items: center;
  justify-content: center;

  min-width: 0;
}

.instructionPlusIcon {
  margin-right: var(--gap-2xs);
}

.instructionName {
  overflow: hidden;
  display: inline-flex;
  align-items: center;

  color: var(--color-light-graphic-tertiary-inverted);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chevron {
  margin-left: auto;
  transition: color 0.2s ease, border 0.2s ease, color 0.2s ease,
    transform 0.12s ease;
}

.chevronUp {
  transform: rotate(-0.5turn);
}

.menuTitleWrapper {
  display: flex;
  padding: var(--gap-xs);
  color: var(--text-color-secondary);
}

.containerModal {
  position: absolute;

  width: 100%;
  max-height: 80%;
  margin: 0 auto;
  padding: 0 10%;
}

.dropdownItem {
  display: grid;
  grid-template-columns: 16px 1fr;
  gap: 6px;
  align-items: center;
}

.dropdownItem svg {
  width: 14px;
  height: 14px;
}

.additionalInstructionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: var(--gap-xs);

  background-color: var(--color-light-bg-tertiary);
}

.additionalInstructionContent {
  display: flex;
  flex-direction: column;
  padding: var(--gap-m) var(--gap-xs);
}
