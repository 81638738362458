.table {
  border-collapse: collapse;
  width: 100%;
  text-align: left;
}

.header {
  color: var(--color-light-graphic-tertiary-inverted);
  background-color: var(--color-light-bg-secondary);
}

.row {
  vertical-align: middle;
}

.cell {
  padding: var(--gap-xs);
}

.body tr:nth-child(even) {
  background-color: var(--color-light-bg-secondary);
}

.actionCell {
  width: 70px;
  text-align: right;
}

.actionButtons {
  display: flex;
  justify-content: space-around;
}
