.mainContainer {
  --welcome-modal-height: 650px;
  position: absolute;
  right: var(--gap-s);
  bottom: var(--gap-s);
  width: 380px;
  height: var(--welcome-modal-height);
  background-color: var(--layout-color-surface-0);
  z-index: 20;
  box-shadow: var(--shadow-xl);
  overflow: hidden;
  pointer-events: auto;
}

.welcomeTop {
  width: 100%;
  height: 333px;
}

.welcomeBottom {
  margin: var(--gap-4xl) auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 300px;
}

.playerHeader,
.playerFooter {
  height: var(--gap-4xl);
  width: 100%;
  background-color: var(--color-light-bg-tertiary);
  padding: var(--gap-xs) var(--gap-s);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.playerSection {
  width: 100%;
  height: calc(var(--welcome-modal-height) - var(--gap-7xl));
  overflow: hidden;
}

.dotsContainer {
  display: flex;
  gap: 6px;
}

.stepDot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--color-light-graphic-secondary);
}

.stepDotActive {
  background-color: var(--color-light-graphic-accent);
}

.buttons {
  display: flex;
  gap: var(--gap-m);
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-dark-bg-overlay);
  transition: opacity 300ms ease;
  opacity: 1;
  z-index: 19;
  pointer-events: auto;
}

.backdropHidden {
  opacity: 0;
  pointer-events: none;
}

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 300ms;
  pointer-events: none;
}

.wrapperVisible {
  opacity: 1;
  z-index: 200;
}
