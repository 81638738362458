.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: var(--gap-4xl) 0;
}

.title {
  margin: var(--gap-xl) 0 var(--gap-s);
}
