@import url('@alfalab/core-components/vars/typography.css');

.graphic {
  color: var(--color-light-graphic-tertiary-inverted);
}

.grid {
  display: grid;
  grid-gap: var(--gap-s) var(--gap-xs);
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.fullWidth {
  grid-column: 1 / -1;
}

.halfWidth {
  grid-column: span 1;
}

.plate {
  @mixin paragraph_secondary_large;

  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;

  margin-top: var(--gap-m);
  padding: var(--gap-2xs) var(--gap-xs);

  color: var(--color-light-graphic-tertiary-inverted);

  border-radius: var(--border-radius-s);
  box-shadow: 0 0 0 1px var(--color-light-border-secondary);
}

.plateBond {
  flex-direction: column;
}

.plateBond > div {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.form {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.directionContainer {
  display: flex;
  gap: var(--gap-xs);
  justify-content: space-between;
}

.directionButton {
  flex: 1;
}

.dropdownRef {
  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;

  height: 24px;
  padding: var(--gap-xs);

  color: var(--form-control-color);

  background-color: var(--form-control-bg-color);
  border-radius: var(--border-radius-s);

  transition: background-color 0.2s ease, border 0.2s ease, color 0.2s ease,
    transform 0.12s ease;
}

.dropdownRef:hover {
  background-color: var(--form-control-hover-bg-color);
}

.dropDownContent {
  display: flex;
  flex: 1;
  gap: var(--gap-xs);
  align-items: center;
  justify-content: center;

  min-width: 0;

  color: var(--color-light-graphic-tertiary-inverted);
}

.orderName {
  overflow: hidden;
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chevron {
  margin-left: auto;
  transition: color 0.2s ease, border 0.2s ease, color 0.2s ease,
    transform 0.12s ease;
}

.chevronUp {
  transform: rotate(-0.5turn);
}

.menuTitleWrapper {
  display: flex;
  color: var(--color-light-graphic-tertiary-inverted);
}

.dropDownItemContent {
  display: flex;
  gap: var(--gap-2xs);
}

.tooltipTarget {
  display: inline-flex;
}

.tooltipContent {
  display: flex;
  flex-direction: column;
  gap: var(--gap-xs);
  max-width: 180px;
}

.formRow {
  display: grid;
  grid-template-columns: calc(50% - var(--gap-xs) / 2) calc(
      50% - var(--gap-xs) / 2
    );
  gap: var(--gap-xs);
  justify-content: space-between;

  margin-bottom: var(--gap-xs);
}

.bottomWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.formStickyBottom {
  position: sticky;
  bottom: 0;
  margin-top: auto;
  background-color: var(--dark-bg-primary);
}

.mobileFormRowFullWidth {
  display: flex;
  width: 100%;
}

.submitButton {
  color: var(--color-static-graphic-light);
  background-color: var(--dark-bg-tertiary);
}

.buy {
  background-color: var(--color-buy) !important;
}

.sell:not([disabled]) {
  background-color: var(--color-sell) !important;
}

html[data-direction-colors='blueAndWhite'] .sell:not([disabled]) {
  color: var(--color-light-text-primary-inverted) !important;
}

.buy:disabled,
.sell:disabled {
  opacity: 0.4;
}

.hintWrapper {
  display: block;
  padding: var(--gap-2xs) var(--gap-2xs) 0;
}

/* stylelint-disable-next-line selector-class-pattern */
.boldTag:not(.fix-specificity) {
  @mixin action_secondary_large;
}
