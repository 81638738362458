.difficulty {
  display: flex;
  align-items: center;
  justify-content: center;
}

.item {
  width: 10px;
  height: 10px;
  margin-right: var(--gap-xs);
  border-radius: 100%;
  border: 1px solid var(--color-light-text-accent);
}

.item:last-child {
  margin-right: 0;
}

.active {
  background: var(--color-light-text-accent);
}