.root {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: var(--transfer-modal-bg);
  z-index: 1;
  display: grid;
  place-content: center;
}
