/* Нет возможности сделать инпут меньше иначе */
.searchInput {
  --form-control-s-min-height: var(--size-xs-height);

  width: 170px;
  margin-right: var(--gap-m);
}

.searchInput input {
  /* stylelint-disable-next-line */
  font-size: 13px !important;
}
