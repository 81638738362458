.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.body {
  display: block;
  position: relative;
  height: 100%;
  overflow: auto;
  background-color: var(--color-light-bg-primary);
  padding-top: var(--gap-xs);
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: var(--gap-xl) 0;
}

.newsPageHeader {
  height: var(--widget-header-height);
  padding: var(--widget-header-padding);
  background-color: var(--widget-header-bg);
}

.newsPageHeader:hover {
  cursor: pointer;
}

.newsPageHeader:hover .newsPageButtonText {
  color: var(--color-light-graphic-tertiary-inverted);
}

.newsPageButtonText {
  color: var(--color-light-graphic-quaternary-inverted);
}
