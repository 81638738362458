.row {
  display: flex;
  width: 100%;
  min-height: 24px;
  gap: var(--gap-3xs);
  overflow: hidden;
}

.cell {
  padding: var(--gap-2xs) var(--gap-xs);
  flex-shrink: 0;
}

.cellType {
  width: 23%;
}
.cellName {
  width: 30%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cellAmount {
  width: 14%;
  text-align: left;
}
.cellTime {
  width: 12%;
  text-align: left;
}
.cellStatus {
  width: 20%;
  text-align: right;
}
