.instrumentTitleContainer {
  display: flex;
  align-items: center;
}

.instrumentTitleTextContainer {
  margin-left: 32px;
  display: flex;
  flex-direction: column;
}

.instrumentTitle {
  margin-bottom: 4px;
}

.instrumentName {
  font-weight: 700;
  margin-right: 20px;
}

.instrumentSubTitle {
  margin-bottom: 12px;
}

.instrumentCountryInfo {
  display: flex;
  align-items: center;
}

.price {
  margin-left: var(--gap-2xs)
}

.pricePositive {
  color: var(--color-buy);
}

.priceNegative {
  color: var(--color-sell);
}

.flag {
  width: 14px;
  height: 14px;
  margin-right: var(--gap-xs);
}