.item {
  display: flex;
  padding: var(--gap-2xs) var(--gap-xs);
  background: var(--dark-bg-secondary);
  border-radius: var(--border-radius-s);
  transition: background 0.2s ease;
  cursor: pointer;
}

.item:hover {
  background: var(--dark-bg-tertiary);
}

.content {
  flex-grow: 1;
}

.rightAddon {
  display: grid;
  place-content: center;
  margin-left: var(--gap-m);
}

.name {
  color: #c1c1c3;
  font-weight: 500;
}

.code {
  color: #86868a;
  font-weight: 500;
  margin-left: var(--gap-2xs);
}

.arrow {
  color: var(--dark-graphic-secondary);
}
