.popover {
  background-color: var(--color-light-bg-quaternary);
  border-radius: var(--border-radius-l);
  padding: var(--gap-s);
}

.quaternary {
  color: var(--color-light-graphic-quaternary-inverted);
}

.tertiary {
  color: var(--color-light-graphic-tertiary-inverted);
}