.container {
  height: 100%;
  padding: var(--gap-m);
}

.filtersWrapper {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: var(--gap-xs);
}

button.tag {
  min-width: unset;
}

.symbol {
  color: var(--color-light-text-secondary);
}