.button {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 16px;
  width: 16px;
  padding: var(--gap-3xs);

  cursor: pointer;
  background-color: var(--form-control-bg-color);
  border-radius: var(--border-radius-xs);
}

.popover {
  min-width: 90px !important;
  padding: 0;
}

.dropDownItemContent {
  display: flex;
  gap: var(--gap-2xs);
}