.inputField {
  --select-option-background: var(--form-control-bg-color);
  padding: 10px 12px;
  background-color: var(--color-light-bg-tertiary);
  border-radius: 4px;
  display: flex;
  justify-content: left;
  align-items: center;
  height: 40px;
}

.selectedValue {
  flex-grow: 1;
  padding: 10px;
  color: var(--text-primary-color);
}

.disabled {
  color: var(--text-color-secondary);
}

.chevron {
  margin-left: auto;
  transition: transform 0.3s;
  width: 18px;
  height: 18px;
}

.hint {
  color: var(--text-color-secondary);
}

.chevronOpen {
  transform: rotateZ(-180deg);
}

.customBottomSheet {
  --bottom-sheet-title-font-size: 16px;
  --bottom-sheet-title-line-height: 24px;
  --bottom-sheet-title-font-weight: 600;
}

.optionList {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.optionListItem {
  display: flex;
  align-items: center;
}

.optionListItem > span:first-child {
  flex-grow: 1;
  max-width: 95%;
  margin-right: auto;
}

.optionList > div {
  margin: 22px 0;
}

.tooltipContainer {
  z-index: 10000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  padding: 0 24px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.tooltipContainer::-webkit-scrollbar {
  display: none;
}

.tooltipHeader {
  position: sticky;
  background-color: #000000;
  height: 44px;
  top: 0;
  text-align: center;
  padding-top: 10px;
}

.tooltipDescription {
  flex-grow: 1;
}

.backIcon {
  position: absolute;
  left: 0;
  top: 15px;
}

.tooltipItem {
  margin: 28px 0;
  display: flex;
  align-items: center;
}

.tooltipCheckedField {
  min-width: 36px;
  display: flex;
  justify-content: flex-end;
}
