.root {
  min-height: 560px;
}

.treaty {
  margin-bottom: var(--gap-2xl);
}

.tab {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 436px;
  padding: var(--gap-2xl) 0;
}