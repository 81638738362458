.wrapper .content {
  padding: 0 var(--gap-xl);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.closeWrapper {
  padding: var(--gap-xs);
}

.wrapper .header {
  padding: 0 var(--gap-xs);
}

.header {
  display: flex;
  justify-content: flex-end;
}

.title {
  font-size: 22px;
  line-height: 28px;
}

.customButton {
  width: 100%;
}