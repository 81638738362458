.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}

.limitsPortal {
  display: flex;
}

.limitsPortal > div {
  display: flex;
  flex: 1;
}
