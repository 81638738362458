.otherButton {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;
  padding: var(--gap-3xs);

  border-radius: var(--border-radius-s);
}

.icon {
  color: var(--color-light-graphic-secondary-inverted);
}

.icon:hover {
  color: var(--color-light-graphic-primary);
}

.popover {
  padding: 0;
}

.precisionPopover {
  min-width: 80px !important;
}

.dropDownItemDisabled,
.dropDownItemDisabled:hover {
  opacity: 0.5;
}

.dropDownItemContent {
  display: flex;
  gap: var(--gap-2xs);
}
