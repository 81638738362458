/*
 Переопределения стилей компонента Textarea
*/

:root {
  --textarea-s-spacer: 7px;
}

[class*='textarea__textarea_'] {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
}
