.container {
  padding: var(--gap-xs) var(--gap-s);
  border-radius: var(--border-radius-l);
  background: var(--color-light-bg-tertiary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 var(--gap-xs) var(--gap-l);
  cursor: pointer;
}

.image {
  margin-left: var(--gap-s);
}
