.item {
  --main-menu-item-padding-vertical: 4px;
  --main-menu-item-padding-horizontal: 8px;
  --main-menu-item-font-size: 11px;
  --main-menu-item-line-height: 16px;
}

.item {
  position: relative;
  display: flex;
  align-items: center;
  padding: var(--main-menu-item-padding-vertical)
    var(--main-menu-item-padding-horizontal);
  font-size: var(--main-menu-item-font-size);
  line-height: var(--main-menu-item-line-height);
  color: var(--color-light-graphic-tertiary-inverted);
  width: 100%;
  background: transparent;
  border: 0;
  cursor: pointer;
}

.item:hover,
.active {
  background: var(--color-light-bg-tertiary);
  color: var(--color-light-text-primary);
}

.item:focus-visible {
  outline: none;
  background: var(--color-light-bg-tertiary);
}

.icon,
.rightIcon {
  width: 24px;
  height: 16px;
  display: flex;
  align-items: center;
  color: var(--color-light-graphic-secondary);
}

.icon {
  justify-content: flex-start;
}

.rightIcon {
  justify-content: flex-end;
}

.item:hover .icon,
.item:hover .rightIcon,
.active .icon,
.active .rightIcon {
  color: var(--color-light-graphic-tertiary-inverted);
}

.icon svg,
.rightIcon svg {
  width: 16px;
  height: 16px;
}

.name {
  flex-grow: 1;
  text-align: left;
}