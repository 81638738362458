.strategy {
  margin: 0 0 var(--gap-l);
}

.time {
  display: flex;
}

.timeGlyph {
  width: 16px;
  height: 16px;
  margin-right: var(--gap-2xs);
  color: var(--color-light-graphic-primary);
}

.properties {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin: 0 0 var(--gap-l);
}

.enrollBtn {
  width: 160px;
}

.themeItem {
  margin: 0 0 var(--gap-s);
}

.themeItemPercent {
  margin-right: var(--gap-xs);
}

.note {
  margin-top: var(--gap-l);
}