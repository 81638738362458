.tooltip {
  margin-top: var(--gap-s-neg);
}

.tooltipContent {
  padding: var(--gap-2xs) var(--gap-xs) !important;
}

.tooltipArrow::after {
  display: none;
}

div.popper {
  display: flex;
  flex-direction: column;

  width: 320px;

  background-color: var(--layout-color-surface-0);
  border-radius: var(--border-radius-m);
  box-shadow: var(--select-options-list-box-shadow);
}

.popoverChildrenWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--gap-2xs);
  padding: var(--gap-s) var(--gap-xs);
}

button.guide,
a.guide {
  display: flex;

  width: 100%;
  padding: var(--gap-xs) var(--gap-s);

  text-align: start;

  background-color: var(--color-light-bg-tertiary);
  border-radius: var(--border-radius-l);
}

button.guide:hover,
a.guide:hover {
  background-color: var(--color-light-bg-quaternary);
}

.guide span {
  display: flex;
  justify-content: space-between;
}

.guideImage {
  width: 40px;
  height: 40px;
}

.guideText {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

button.closeButton {
  position: absolute;
  top: 6px;
  right: 6px;
}
