.title {
  margin-bottom: var(--gap-s);
}

.table {
  width: 100%;
  text-align: left;
  color: var(--color-light-text-primary);
}

.table th,
.table td {
  width: 50%;
}

.table tr {
  height: 32px;
}

.table tr:nth-child(even) {
  background: var(--color-light-bg-secondary);
}

.table th {
  background: var(--color-light-bg-secondary);
  color: var(--color-light-text-secondary);
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  padding: 8px;
}

.table td {
  padding: 8px;
}

.tableSubTitle {
  text-align: left;
}

.secondRow {
  display: flex;
  margin: 12px 0 24px;
}

.tableIcon {
  margin-right: 9px;
}

.wrapperEmpty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 64px;
}