.priceSetting {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
}

.popover {
    width: 240px;
    padding: 0;
}

.dropDownItemContent {
    display: flex;
    gap: var(--gap-2xs);
}

.tooltipContent {
    max-width: 200px;
}