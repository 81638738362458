.container {
  display: flex;
  align-items: center;
  padding: var(--gap-m) var(--gap-xl);
  border-radius: var(--border-radius-s);
  background: var(--color-light-bg-secondary);
}

.active {
  cursor: pointer;
}

.active:hover {
  background: var(--color-light-bg-tertiary);
}

.icon {
  margin-right: var(--gap-l);
}

.body {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.content {
  flex-grow: 1;
}

.title {
  /* Дизайн настаивает что это нужные цвета, так как им не хватает градаций серого в тексте */
  color: var(--color-light-graphic-quaternary-inverted);
}

.text {
  margin-top: var(--gap-2xs);
}

.addons {
  margin-left: var(--gap-m);
}

/* size S */
.container.size_s {
  padding: var(--gap-s) var(--gap-m);
}