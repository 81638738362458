.spinnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.captha {
  margin-bottom: var(--gap-m);
}

.capthaImg {
  max-width: 100%;
  display: block;
  border-radius: 4px;
  transition: opacity 0.2s;
}

.capthaImgPending {
  opacity: 0.5;
}

.capthaRefresh {
  font-size: 16px;
  text-align: center;
  padding: 0 0 var(--gap-l);
}

.captchaField {
  margin: 0 0 var(--gap-m);
}

.submitItem {
  margin-bottom: var(--gap-m);
}
