.wrapper {
  display: flex;
  align-items: center;
}

.filters {
  display: flex;
  flex: 1;
  gap: var(--gap-xs);
  white-space: nowrap;
}

.orderStatusFilters {
  border-radius: 100%;
}

.accountsSelectButton {
  display: flex;
  padding: var(--gap-2xs) var(--gap-xs);
  background-color: var(--form-control-bg-color);
  width: 160px;
  height: 24px;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  align-items: center;
  color: var(--color-light-graphic-tertiary-inverted);
  transition: background-color 0.2s ease, border 0.2s ease, color 0.2s ease;
}

.accountsSelectButton:hover {
  background-color: var(--form-control-hover-bg-color);
}

.accountsSelectButtonSmall {
  width: unset;
}

.accountsSelectButtonSmall .accountsSelectButtonIcon {
  margin-right: var(--gap-xs);
}

.accountName {
  margin-left: var(--gap-xs);
}

.select {
  width: 240px;
  padding-top: 0;
}

.selectOrderBook {
  width: 180px;
}

.accountOption {
  display: flex;
  justify-content: space-between;
  color: var(--button-secondary-color);
  font-weight: bold;
  font-size: 11px;
  line-height: 16px;
}

.accountOptionOrderBook {
  cursor: default;
}

.option {
  font-size: 13px;
  line-height: 16px;
  display: flex;
  position: relative;
  align-items: center;
  gap: var(--gap-2xs);
}

.optionName {
  margin-left: 8.5px;
}

.chevronUp {
  transform: rotate(-0.5turn);
}

.activeFilterButton {
  background-color: var(--dark-text-primary);
  color: var(--dark-bg-primary);
}

.activeFilterButton:hover {
  background-color: var(--tag-background-color-checked-hover);
}

.orderStatusFiltersSelect {
  padding: var(--gap-2xs) var(--gap-xs);
  background: var(--form-control-bg-color);
  width: 134px !important;
  min-width: 134px !important;
  font-size: 11px !important;
  line-height: 16px !important;
}

.positionTypeSelectPoppover {
  min-width: 140px;
}

.PUModeSelectPoppover {
  min-width: 215px;
}

button.orderStatusFiltersFilterInSelect {
  margin: 4px 0;
  display: flex;
  justify-content: flex-start;
  background: var(--tag-background-color-hover);
  font-size: 11px !important;
  line-height: 16px !important;
  min-width: 95px !important;
  width: 95px !important;
  height: 24px !important;
  min-height: 24px;
  padding: var(--gap-2xs) var(--gap-m);
  white-space: nowrap;
  text-overflow: ellipsis;
}

.count {
  opacity: 0.8;
}

.optionWithRequirements {
  margin-left: var(--gap-xl);
}

.requirementIcon {
  position: absolute;
  margin-left: var(--gap-xl-neg);
}

.chevron {
  margin-left: auto;
  color: var(--color-light-graphic-secondary);
  transition: color 0.2s ease, border 0.2s ease, color 0.2s ease,
    transform 0.12s ease;
}

.accountSelectedIcon {
  margin-left: auto;
}

.PUFiltersButton {
  margin-left: var(--gap-m);
}

.PUFiltersButtonSmall {
  margin-left: var(--gap-xs);
}
