div.modal {
  border-radius: var(--border-radius-l);
  background-color: var(--layout-color-background-1);
}

.container {
  padding: var(--gap-m) var(--gap-m) var(--gap-xl);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.body {
  padding-top: var(--gap-xs);
  position: relative;
}