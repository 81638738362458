/*
 Не редактировать. Сгенерировано автоматически
*/

:root {
  --color-dark-graphic-primary: #ffffff;
  --color-dark-graphic-secondary: #85858d;
  --color-dark-graphic-tertiary: #4f4f52;
  --color-dark-graphic-quaternary: #303032;
  --color-dark-graphic-accent: #7938e0;
  --color-dark-graphic-link: #0072ef;
  --color-dark-graphic-positive: #2fc26e;
  --color-dark-graphic-attention: #f6bf65;
  --color-dark-graphic-negative: #d91d0b;

  --color-dark-graphic-primary-inverted: #0f0f0f;
  --color-dark-graphic-secondary-inverted: #86868a;
  --color-dark-graphic-tertiary-inverted: #c1c1c3;
  --color-dark-graphic-quaternary-inverted: #dcdcdd;

  --color-dark-graphic-primary-alpha-30: rgba(255, 255, 255, 0.3);
  --color-dark-graphic-primary-alpha-40: rgba(255, 255, 255, 0.4);
  --color-dark-graphic-primary-alpha-50: rgba(255, 255, 255, 0.5);
  --color-dark-graphic-primary-alpha-60: rgba(255, 255, 255, 0.6);
  --color-dark-graphic-primary-shade-15: rgb(217, 217, 217);
  --color-dark-graphic-primary-shade-20: rgb(204, 204, 204);
  --color-dark-graphic-primary-shade-30: rgb(179, 179, 179);
  --color-dark-graphic-primary-tint-10: rgb(255, 255, 255);
  --color-dark-graphic-primary-tint-30: rgb(255, 255, 255);
  --color-dark-graphic-secondary-alpha-30: rgba(133, 133, 141, 0.3);
  --color-dark-graphic-secondary-tint-30: rgb(170, 170, 175);
  --color-dark-graphic-secondary-tint-50: rgb(194, 194, 198);
  --color-dark-graphic-tertiary-alpha-30: rgba(79, 79, 82, 0.3);
  --color-dark-graphic-tertiary-shade-15: rgb(67, 67, 70);
  --color-dark-graphic-tertiary-shade-20: rgb(63, 63, 66);
  --color-dark-graphic-tertiary-tint-7: rgb(91, 91, 94);
  --color-dark-graphic-tertiary-tint-15: rgb(105, 105, 108);
  --color-dark-graphic-quaternary-tint-10: rgb(69, 69, 70);
  --color-dark-graphic-accent-alpha-30: rgba(121, 56, 224, 0.3);
  --color-dark-graphic-accent-shade-15: rgb(103, 48, 190);
  --color-dark-graphic-accent-tint-7: rgb(130, 70, 226);
  --color-dark-graphic-accent-tint-10: rgb(134, 76, 227);
  --color-dark-graphic-accent-tint-15: rgb(141, 86, 229);
  --color-dark-graphic-accent-tint-30: rgb(161, 116, 233);
  --color-dark-graphic-link-alpha-15: rgba(0, 114, 239, 0.15);
  --color-dark-graphic-positive-alpha-10: rgba(47, 194, 110, 0.1);
  --color-dark-graphic-positive-tint-10: rgb(68, 200, 125);
  --color-dark-graphic-positive-tint-30: rgb(109, 212, 154);
  --color-dark-graphic-negative-alpha-10: rgba(217, 29, 11, 0.1);
  --color-dark-graphic-negative-alpha-30: rgba(217, 29, 11, 0.3);
  --color-dark-graphic-negative-alpha-40: rgba(217, 29, 11, 0.4);
  --color-dark-graphic-negative-shade-20: rgb(174, 23, 9);
  --color-dark-graphic-negative-shade-30: rgb(152, 20, 8);
  --color-dark-graphic-negative-tint-7: rgb(220, 45, 28);
  --color-dark-graphic-negative-tint-15: rgb(223, 63, 48);
  --color-dark-graphic-negative-tint-30: rgb(228, 97, 84);
  --color-dark-graphic-primary-inverted-alpha-30: rgba(15, 15, 15, 0.3);
  --color-dark-graphic-primary-inverted-alpha-40: rgba(15, 15, 15, 0.4);
  --color-dark-graphic-primary-inverted-alpha-50: rgba(15, 15, 15, 0.5);
  --color-dark-graphic-primary-inverted-alpha-60: rgba(15, 15, 15, 0.6);
  --color-dark-graphic-primary-inverted-tint-7: rgb(32, 32, 32);
  --color-dark-graphic-primary-inverted-tint-10: rgb(39, 39, 39);
  --color-dark-graphic-primary-inverted-tint-15: rgb(51, 51, 51);
  --color-dark-graphic-primary-inverted-tint-30: rgb(87, 87, 87);
  --color-dark-graphic-secondary-inverted-alpha-30: rgba(134, 134, 138, 0.3);
  --color-dark-graphic-secondary-inverted-shade-15: rgb(114, 114, 117);
  --color-dark-graphic-secondary-inverted-shade-20: rgb(107, 107, 110);
  --color-dark-graphic-secondary-inverted-tint-10: rgb(146, 146, 150);
  --color-dark-graphic-secondary-inverted-tint-30: rgb(170, 170, 173);
  --color-dark-graphic-tertiary-inverted-alpha-30: rgba(193, 193, 195, 0.3);
  --color-dark-graphic-tertiary-inverted-shade-15: rgb(164, 164, 166);
  --color-dark-graphic-tertiary-inverted-shade-20: rgb(154, 154, 156);
}
