.row {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap-xs);
}

button.tag {
  min-width: unset;
}

div.popover {
  background-color: var(--color-light-bg-secondary);
  border-radius: var(--border-radius-xl);
  padding: var(--gap-m) var(--gap-m) var(--gap-xl);
}