.banner {
  padding: var(--gap-s) var(--gap-m);
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: var(--gap-m);
  align-items: center;
  background-color: var(--color-light-bg-tertiary);
  border-radius: var(--border-radius-xl);
  cursor: pointer
}

.banner:hover {
  background-color: var(--color-light-bg-quaternary);
}