.container {
  --color-light-bg-secondary: var(--color-light-graphic-quaternary);
}

.container {
  padding: 90px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.icon {
  margin: 0 0 var(--gap-l);
}

.title {
  margin-bottom: var(--gap-m);
}

.content {
  margin-top: var(--gap-xl);
}