.root {
  display: flex;
  justify-content: space-between;
  gap: var(--gap-s);
  background-color: var(--color-light-bg-tertiary);
  padding: var(--gap-xs);

  &_withNegativeMargins {
    margin: var(--gap-m) calc(-1 * var(--gap-xs)) calc(-1 * var(--gap-m));
  }
}
