.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.body {
  width: 320px;
  margin-top: 24px;
}

.logo {
  color: var(--color-light-graphic-tertiary-inverted);
  margin: 0 auto 20px;
}

.title {
  text-align: center;
}

.footer {
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  color: #8d8d93;
}

.restore {
  font-weight: 500;
  text-decoration-line: underline;
  margin-bottom: 8px;
  cursor: pointer;
  /* явно проставляем высоту контейнеру чтоб не прыгало при скрытии текста */
  height: 16px;
}

.copyright {
  font-weight: 400;
}

.passportLoginLink {
  text-decoration: none;
  width: 20rem;
}

.passportLoginButton {
  width: 100%;
}

.controlls {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: var(--gap-xs);
  padding: var(--gap-xs) var(--gap-l);
  background: var(--dark-bg-secondary-background-color-secondary, #202022);
}

.fixedContainer {
  position: fixed;
  top: var(--gap-xl);
  left: 0;
  right: 0;
}

.notification {
  max-width: 320px;
}
