.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  background: #2c2c2e;
  border-radius: var(--border-radius-s);
  padding: var(--gap-3xs) var(--gap-xs);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrapper:hover {
  cursor: pointer;
  background: #3a3a3c;
}

.icon {
  color: var(--icon-button-primary-base-color);
  min-width: 16px;
}

.count {
  margin-left: 6px;
}

.desktopVersion {
  padding: var(--gap-3xs) 0;
  background: unset;
  height: 20px;
  color: inherit;
}

.desktopVersion .icon {
  color: inherit;
}

.chevron {
  margin-left: 6px;
  transition: color 0.2s ease, border 0.2s ease, color 0.2s ease,
    transform 0.12s ease;
}

.chevronUp {
  transform: rotate(-0.5turn);
}

.popoverFull {
  width: 100%;
  padding: 0;
  border: none;
}

.popoverFull > div > div,
.popover > div > div {
  padding: 0;
}
