.title {
  display: block;
  margin-bottom: var(--gap-xs);
}

.divider {
  margin-top: var(--gap-l);
  margin-bottom: var(--gap-l);
}

.fields {
  row-gap: var(--gap-m);
}

.target {
  display: inline-block;
  vertical-align: middle;
}

.icon {
  width: 12px;
  height: 12px;
  color: var(--color-light-graphic-secondary);
}
