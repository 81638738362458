/*
 Переопределения стилей компонента CalendarInput
*/

[class*='calendar-input__calendarIcon_'] {
  width: 16px;
  height: 16px;
  color: var(--color-light-graphic-secondary);
}

[class*='calendar-input__calendarContainer_'] {
  border: none;
  border-radius: var(--border-radius-m);
  background-color: var(--select-option-background);
  box-shadow: var(--shadow-xl);
}
