.notFound {
  display: flex;
  height: 300px;
  justify-content: center;
  align-items: center;
}

.center {
  text-align: center;
}

.tableHeader {
  padding: var(--gap-xs);
  background: var(--color-light-bg-tertiary);
}

.tableRow {
  padding: var(--gap-2xs) var(--gap-xs);
  display: flex;
  justify-content: space-between;
}

.odd {
  background: var(--color-light-bg-secondary);
}
