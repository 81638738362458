.container {
  --account-control-header-margin-bottom: 24px;
  --account-control-field-margin-bottom: 24px;
  --account-control-save-margin-top: 24px;
}

.sizeXxs {
  --account-control-header-margin-bottom: 20px;
  --account-control-field-margin-bottom: 20px;
  --account-control-save-margin-top: 20px;
}

.header {
  margin-bottom: var(--account-control-header-margin-bottom);
}

.field {
  margin-bottom: var(--account-control-field-margin-bottom);
}

.save {
  max-width: 239px;
  margin-top: var(--account-control-save-margin-top);
}

.tariffPending {
  display: flex;
  margin-top: 8px;
  color: var(--text-color-secondary);
  font-size: 11px;
}

.tariffPendingText {
  margin-left: 4px;
}

.mobileButtonLink {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  color: var(--color-light-text-primary);
  margin: 18px 0;
  text-align: left;
}
.mobileButtonLink > span:nth-child(2) {
  margin-left: auto;
  color: var(--text-color-secondary);
}
