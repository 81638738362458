.direction {
  display: flex;
  align-items: flex-start;
  align-items: center;
}

.icon {
  color: #8a8a8e;
  margin-right: 10px;
  flex-shrink: 0;
}