.wrapper,
.tooltipWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: var(--gap-xs) var(--gap-s);
  background-color: var(--layout-color-surface-0);
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrapper:not(.row) > :nth-child(even) {
  margin-top: var(--gap-xs);
}

.row {
  flex-direction: row;
  gap: var(--gap-xs);
}

.positionWrapper {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
}

.amountWrapper,
.limitsBlock {
  display: flex;
  align-items: center;
  color: var(--color-light-text-secondary);
}

.limitsBlock {
  flex: 1;
}

.limitsBlockText {
  display: flex;
}

.limitsBlockMobile {
  flex-direction: column;
  gap: 8px;
}

.limitsWrapper {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  gap: var(--gap-2xs);
  overflow: hidden;
  text-overflow: ellipsis;
}

.amount {
  color: var(--color-light-text-primary);
}

.icon {
  margin-left: var(--gap-2xs);
  margin-right: var(--gap-s);
  min-height: 18px;
  min-width: 18px;
  color: var(--color-light-graphic-secondary);
}

.secondaryText {
  color: var(--color-light-text-secondary);
}

.triangleUp {
  color: var(--color-buy);
}

.triangleDown {
  color: var(--color-sell);
}

.tooltipWrapper {
  flex-direction: row;
  align-items: center;
}

.tooltipWrapper:hover .icon {
  color: var(--dark-text-primary);
}

.tooltipWrapper .icon:last-of-type {
  margin-right: unset;
}

.growText {
  flex: 1;
}

.tooltipTarget {
  min-width: 100%;
}

.tooltipContent {
  margin: var(--gap-m-neg);
  padding-top: var(--gap-xs);
  background-color: unset;
  max-width: 290px;
  overflow: hidden;
}

.tooltipView {
  align-items: flex-start;
}

.tooltipView .limitsWrapper {
  flex-direction: column;
}

.settingsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--gap-s) var(--gap-xs);
  background-color: var(--color-light-bg-tertiary);
}

.settingsContent {
  display: flex;
  flex-direction: column;
  padding: var(--gap-m) var(--gap-xs);
  min-width: 270px;
}

.buttonToolbar {
  display: flex;
  justify-content: flex-end;
  gap: var(--gap-m);
  background-color: var(--color-light-bg-tertiary);
  margin: var(--gap-xl) var(--gap-xs-neg) var(--gap-m-neg) var(--gap-xs-neg);
  padding: var(--gap-xs);
}

.customButton span {
  font-size: 11px;
  line-height: 16px;
}

.customButton {
  --size-xs-height: 24px;
}

.settingWrapper {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: var(--gap-2xs) 0;
  margin: var(--gap-xs) 0;
  user-select: none;
}

.settingWrapper:first-of-type {
  margin-top: unset;
}

.chevron {
  color: var(--color-light-graphic-secondary);
  margin-left: auto;
  transition: color 0.2s ease, border 0.2s ease, color 0.2s ease,
    transform 0.12s ease;
}

.chevronUp {
  transform: rotate(-0.5turn);
}

.dropdownRef {
  display: flex;
  flex: 1;
  align-items: center;
  user-select: none;
  cursor: pointer;
  height: 24px;

  padding: var(--gap-2xs) var(--gap-xs);
  border-radius: 4px;
  color: var(--form-control-color);
  background-color: var(--form-control-bg-color);
  transition: background-color 0.2s ease, border 0.2s ease, color 0.2s ease,
    transform 0.12s ease;
}

.dropdownRef:hover {
  background-color: var(--form-control-hover-bg-color);
}

.dropDownContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: var(--gap-m);
  min-width: 0;
}

.optionName {
  flex: 1;
  max-width: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropDownItemContent {
  display: flex;
  gap: var(--gap-2xs);
}

.label {
  margin-bottom: var(--gap-2xs);
}

.simple {
  color: var(--color-light-text-secondary);
}

.error {
  color: var(--dark-graphic-color-accent, #ef3124);
}

.contextMenuSettings {
  max-width: 270px;
}

.customSwitch:not(:last-of-type) {
  margin-bottom: var(--gap-xl);
}

.customInput {
  --form-control-s-min-height: 24px;
}

.customInputField > div:first-child > div > div:first-of-type {
  font-size: 13px;
  line-height: 16px;
  font-weight: 500 !important;
}

.customInputField input {
  font-size: 13px;
  line-height: 16px;
  font-weight: 500 !important;
}

.hintText {
  margin-top: var(--gap-2xs);
}

.hintTextForSwitch {
  margin-bottom: var(--gap-xs);
  color: var(--color-light-text-secondary);
}

.white {
  color: #666;
}

.settingsSelect {
  min-width: 140px;
  padding-top: 0;
}