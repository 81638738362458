.label {
  display: flex;
  align-items: center;
}

.switchSmall {
  width: 100%;
  margin-bottom: var(--gap-m);
}

.slGrid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  row-gap: var(--gap-s);
  column-gap: var(--gap-xs);
}

.fullWidth {
  grid-column: 1 / -1;
}

.halfWidth {
  grid-column: span 1;
}

.slInputs {
  margin-top: var(--gap-m);
  display: flex;
  flex-direction: column;
  gap: var(--gap-s);
}

.graphic {
  color: var(--color-light-graphic-tertiary-inverted);
}

.blockDivider {
  display: flex;
  flex: 1;
  height: 1px;
  background-color: var(--divider-color);
  margin: var(--gap-l) var(--gap-xs-neg);
}

.row {
  display: grid;
  grid-template-columns: minmax(0, 50%) minmax(0, 1fr);
  grid-gap: var(--gap-xs);
}

.row > div {
  grid-column: span 1;
}

.tooltipTarget {
  display: inline-flex;
}

.tooltipContent {
  max-width: 180px !important;
  display: flex;
  flex-direction: column;
  gap: var(--gap-xs);
}

.dropdownPopover {
  min-width: unset !important;
}

.dropdownRef {
  display: flex;
  flex: 1;
  align-items: center;
  user-select: none;
  height: 24px;

  padding: var(--gap-2xs) var(--gap-xs);
  border-radius: 4px;
  color: var(--form-control-color);
  background-color: var(--form-control-bg-color);
  transition: background-color 0.2s ease, border 0.2s ease, color 0.2s ease,
  transform 0.12s ease;
}

.dropdownRef:not(.disabled):hover {
  cursor: pointer;
  background-color: var(--form-control-hover-bg-color);
}

.dropdownRef.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.dropDownContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: var(--gap-m);
  min-width: 0;
}

.slOrderTypeDropdownItem {
  gap: var(--gap-3xs) !important;
}

.orderName {
  flex: 1;
  color: var(--dark-text-primary);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chevron {
  margin-left: auto;
  transition: color 0.2s ease, border 0.2s ease, color 0.2s ease,
  transform 0.12s ease;
}

.chevronUp {
  transform: rotate(-0.5turn);
}

.dropDownItemContent {
  display: flex;
  align-items: center;
  gap: var(--gap-3xs);
}



