.tooltipTrigger {
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
}

.tooltipContent {
  max-width: 300px;
}

.documentList {
  margin-top: var(--gap-l);
}
