.container {
  display: flex;
  width: 100%;
  gap: var(--gap-m);
  font-family: inherit;
  font-size: 11px;
  line-height: 16px;
  cursor: pointer;
  transition: color 0.2s;
  background: transparent;
  border: 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
  color: var(--text-color-primary);
}

.container:hover {
  color: var(--text-color-secondary);
}

.name {
  flex-grow: 1;
}

.glyph {
  color: var(--dark-graphic-secondary);
}

.loader {
  width: 16px;
  height: 16px;
}
