.title {
  margin: 0 0 var(--gap-xs);
}

.date {
  margin: 0 0 var(--gap-xl);
}

.details {
  margin-top: var(--gap-2xl);
}

.parameters {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap-m);
}

.field {
  width: calc(50% - var(--gap-m) / 2);
}
