/* 
 * Общий стиль для таблиц. 
 * Возможно, стили отсюда нужно перенести в index.css или типа того
*/
.table {
  font-size: 13px;
  line-height: 16px;
}

.group {
  padding: var(--gap-xs) 0 0;
}

.groupTitle {
  padding: var(--gap-xs);
  color: var(--color-light-text-secondary);
  font-weight: 700;
  font-size: 11px;
}

.row {
  display: flex;
  width: 100%;
  min-height: 32px;
  gap: 2px;
  overflow: hidden;
}

.rowOdd {
  background: var(--color-light-bg-secondary);
}

.cell {
  padding: var(--gap-xs);
  flex-shrink: 0;
}

.cellHead {
  background: var(--color-light-bg-secondary);
  color: var(--color-light-text-secondary);
}

.cellFade {
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}

.cellFade:not(.cellHead)::after {
  content: '';
  width: 24px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(
    90deg,
    var(--color-light-specialbg-nulled) 0%,
    var(--color-light-bg-primary) 24px
  );
}

.rowOdd .cellFade:not(.cellHead)::after {
  background: linear-gradient(
    90deg,
    var(--color-light-specialbg-nulled) 0%,
    var(--color-light-bg-secondary) 24px
  );
}
